import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { UnityStockBalanceData } from 'types/reports/stockBalance';

type UnityItemModuleProps = {
  unity: UnityStockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

const UnityItemModule: React.FC<UnityItemModuleProps> = ({ unity }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{unity.unidade}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Est total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.EstTotal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedCustoTotal}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default UnityItemModule;
