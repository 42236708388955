import React, { useEffect, useState } from 'react';
import useTableOrder from 'hooks/tableOrder';
import { darken, Icon, styled, Typography } from '@mui/material';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';
import history from 'services/history';
import { Print } from '@mui/icons-material';

interface Props {
  orderItems: PurchaseOrderItem[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#f8eef3',
  '& > p': {
    color: darken('#f8eef3', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '30px 100px 80px 80px 130px 60px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '30px 0.4fr 0.4fr 1fr 1.3fr 0.5fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '30px 1fr 1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '30px 1fr 0.4fr 0.8fr 1fr 1fr',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  padding: '0 10px',
});

const CommercialAnalysisProductOrderItemsListDesktop: React.FC<Props> = ({ orderItems }) => {
  const [filtered, setFiltered] = useState<PurchaseOrderItem[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(orderItems);
  }, [orderItems]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Pedido de compras</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography></Typography>
            <Typography onClick={() => handleSort('fornecedor')} variant="caption" fontWeight={600}>
              Fornecedor
            </Typography>

            <Typography onClick={() => handleSort('id_pedido')} variant="caption" fontWeight={600}>
              Pedido
            </Typography>

            <Typography onClick={() => handleSort('formattedMinForecast')} variant="caption" fontWeight={600}>
              Previsão
            </Typography>

            <Typography align="right" onClick={() => handleSort('valor_negociado')} variant="caption" fontWeight={600}>
              Valor negociado
            </Typography>

            <Typography onClick={() => handleSort('pedido')} variant="caption" fontWeight={600}>
              Pedido
            </Typography>
          </Row>
          {filtered.map((item, index) => (
            <Row
              onClick={() =>
                history.push(
                  `/purchasing/registration/print/${item.id_pedido}/commercial-bi_analysis-product_${item.id_produto}`,
                )
              }
              className="item"
              key={index}
            >
              <Icon fontSize="small" color="primary">
                <Print fontSize="small" />
              </Icon>
              <Typography className="item-name" variant="caption">
                {item.fornecedor}
              </Typography>

              <Typography variant="caption">{item.id_pedido}</Typography>

              <Typography variant="caption">{item.previsaomin}</Typography>
              <Typography align="right" variant="caption">
                {item.formattedNegotiatedValue}
              </Typography>

              <Typography variant="caption">{item.pedido}</Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductOrderItemsListDesktop;
