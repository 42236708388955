import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import SubgroupItemTable from './SubgroupItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { subgroupStockTableTemplate } from '../subgroupStockTableTemplate';

type SubgroupListTableProps = {
  subgroups: SubgroupStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const SubgroupListTable: React.FC<SubgroupListTableProps> = ({ subgroups, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <TableContainer tableTemplate={subgroupStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {subgroupStockTableTemplate.map(item => (
              <HeaderItem
                className={
                  item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight>
            {subgroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subgroup, index) => (
              <TableRow key={index}>
                <SubgroupItemTable subgroup={subgroup} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default SubgroupListTable;
