import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type RmaTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const FinancialCardTabs: React.FC<RmaTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="chart" />
        <Tab label="Filiais" value="branches" />
        <Tab label="Bandeira" value="flag" />
        <Tab label="Detalhamento" value="detail" />
      </Tabs>
    </Container>
  );
};

export default FinancialCardTabs;
