import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { Diversity1, Groups, PsychologyAlt } from '@mui/icons-material';

const Bi: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('bi') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('bi')}
            icon={<Diversity1 />}
            text="BI"
            opened={collapsible.rh}
          />

          <Collapse in={collapsible.bi}>
            {checkPermission('bi.commercial') && (
              <SidebarItem to="/commercial-bi" icon={<PsychologyAlt />} text="Comercial" nested={5} />
            )}
            {checkPermission('bi.financial') && (
              <SidebarItem to="/financial-bi" icon={<Groups />} text="Financeiro" nested={5} />
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Bi;
