import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, IconButton, MenuItem, TextField, Tooltip, styled } from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcProductFilterDates, AbcProductFilterParams } from './AbcProduct';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import ptBR from 'date-fns/locale/pt-BR';
// registerLocale('ptBR', ptBR);

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '170px 130px 1fr 1fr 100px 80px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '170px 130px 1fr 0.7fr 100px 80px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DatePickerContent = styled('div')(props => ({
  display: 'flex',
  position: 'relative',
  borderRadius: 4,
  flex: 1,
  '& > .label': {
    position: 'absolute',
    top: -12,
    left: 10,
    fontSize: 11,
  },
}));

const DatePickerContainer = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '100px 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
  [theme.breakpoints.down('sm')]: {
    '& > button': {
      width: 100,
      justifySelf: 'end',
    },
  },
}));

interface AbcProductFilterBoxProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleDatesFilterChange(index: keyof AbcProductFilterDates, value: any): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcProductFilterBox: React.FC<AbcProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenExcel,
  disabled,
  handleDatesFilterChange,
}) => {
  const { checkPermission } = usePermissionRules();

  function handleChangeInitialRangeDates(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDate', start);
    handleDatesFilterChange('finalDate', end);
  }

  function handleChangeRangeDatesToCompare(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleDatesFilterChange('initialDateToCompare', start);
    handleDatesFilterChange('finalDateToCompare', end);
  }

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <TextField
          select
          label="Tipo de venda"
          value={filter.typeSale}
          onChange={e => handleChangeFilter('typeSale', e.target.value)}
        >
          <MenuItem value="T">Todas</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </TextField>

        <DatePickerContainer>
          <DatePickerContent>
            <span className="label">Periodo inicial</span>
            <DatePicker
              selected={filter.dates.initialDate}
              onChange={dates => handleChangeInitialRangeDates(dates)}
              locale={ptBR}
              startDate={filter.dates.initialDate}
              endDate={filter.dates.finalDate}
              selectsRange
              dateFormat="dd/MM/yyyy"
            />
          </DatePickerContent>
          <DatePickerContent>
            <span className="label">Periodo a comparar</span>
            <DatePicker
              selected={filter.dates.initialDateToCompare}
              onChange={dates => handleChangeRangeDatesToCompare(dates)}
              locale={ptBR}
              startDate={filter.dates.initialDateToCompare}
              endDate={filter.dates.finalDateToCompare}
              selectsRange
              dateFormat="dd/MM/yyyy"
            />
          </DatePickerContent>
        </DatePickerContainer>

        <TextField
          label="Produto"
          placeholder="Produto"
          autoFocus
          fullWidth
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />
        <Actions>
          <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
            Buscar
          </Button>
          <Tooltip title="Outros filtros">
            <CustomIconButton onClick={handleOpenDialogDesktop} color="inherit">
              <FilterList />
            </CustomIconButton>
          </Tooltip>

          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <CustomIconButton
                disabled={disabled}
                onClick={handleOpenExcel}
                style={{ color: disabled ? '#eee' : 'green' }}
              >
                <FaFileExcel />
              </CustomIconButton>
            </Tooltip>
          )}
        </Actions>
      </Filter>
    </FilterBox>
  );
};

export default AbcProductFilterBox;
