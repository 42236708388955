import { LineAxis } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { NoteEntry } from 'types/noteEntry';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .second': {
    background: '#fdf2cd',
  },
  '& .third': {
    background: '#eeeff8',
  },
  '& .fourth': {
    background: '#f8eef3',
  },
  '& .fifth': {
    background: '#e3e3e3',
  },
  '& .sixth': {
    background: '#ffc8c8',
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
});

interface NoteEntryDashboardTotalProps {
  notes: NoteEntry[];
}

const NoteEntryDashboardTotal: React.FC<NoteEntryDashboardTotalProps> = ({ notes }) => {
  const formattedTotal = moneyFormat(notes.reduce((acc, note) => acc + note.valor_total, 0));
  const formattedIPI = moneyFormat(notes.reduce((acc, note) => acc + note.valor_ipi, 0));
  const formattedShipment = moneyFormat(notes.reduce((acc, note) => acc + note.valor_frete, 0));
  const formattedPurchases = moneyFormat(
    notes.filter(item => item.tipo_cfop === 'Compras').reduce((acc, note) => acc + note.valor_total, 0),
  );
  const formattedTransfers = moneyFormat(
    notes.filter(item => item.tipo_cfop === 'Transferencia').reduce((acc, note) => acc + note.valor_total, 0),
  );
  const formattedOthers = moneyFormat(
    notes.filter(item => item.tipo_cfop === 'Outros').reduce((acc, note) => acc + note.valor_total, 0),
  );

  return (
    <Container>
      <Box>
        <Typography className="title" variant="body2" fontSize={18}>
          Total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Compras
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedPurchases}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Transferências
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTransfers}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fourth" variant="body2" fontSize={18}>
          Outros
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedOthers}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          IPI
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedIPI}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title sixth" variant="body2" fontSize={18}>
          Frete
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedShipment}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default NoteEntryDashboardTotal;
