import React, { useState } from 'react';
import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { H2iCustomer } from 'types/h2iCustomer';
import { CustomerProvider } from './hooks/useCustomers';
import CustomerListTable from './list/table/CustomerListTable';
import CustomerListModule from './list/module/CustomerListModule';
import TableContainer from 'components/table/TableContainer';
import { customerTableTemplate } from './customerTableTemplate';
import Customer from './customer/register/edit/Customer';
import NewCustomer from './customer/register/new/NewCustomer';
import { useFetchCustomers } from './hooks/useFetchCustomers';
import { useDisplayMode } from 'hooks/useDisplayMode';
import CustomerFilterBox from './CustomerFilterBox';
import CustomerActions from './action/CustomerActions';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Customers: React.FC = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<H2iCustomer | null>(null);
  const [editCustomerDialog, setEditCustomerDialog] = useState(false);
  const [newCustomerDialog, setNewCustomerDialog] = useState(false);
  const [displayMode] = useDisplayMode();
  const [
    handleCustomerDelete,
    handleSort,
    handleFormatCustomer,
    loading,
    saving,
    orderedIndex,
    setCustomers,
    setSearchText,
    searchText,
    setIndexToSearch,
    indexToSearch,
    filteredCustomers,
    fetchCustomer,
  ] = useFetchCustomers();

  function handleExitEditCustomerDialog() {
    setEditCustomerDialog(false);
    fetchCustomer();
  }

  function handleExitNewCustomerDialog() {
    setNewCustomerDialog(false);
    fetchCustomer();
  }

  return (
    <CustomerProvider
      value={{
        selectedCustomer,
        setSelectedCustomer,
        setEditCustomerDialog,
        setNewCustomerDialog,
        setCustomers,
        handleFormatCustomer,
        handleCustomerDelete,
        saving,
      }}
    >
      {editCustomerDialog && (
        <Customer onSubmit={handleExitEditCustomerDialog} onExited={() => setEditCustomerDialog(false)} />
      )}

      {newCustomerDialog && (
        <NewCustomer onSave={handleExitNewCustomerDialog} onExited={() => setEditCustomerDialog(false)} />
      )}

      <Appbar title="Favorecidos" ActionsComponent={<CustomerActions onClick={() => setNewCustomerDialog(true)} />} />
      <PageHeaderActions
        title="Favorecidos"
        description="Cadastro de favorecidos"
        ActionComponent={
          <Button variant="contained" size="medium" color="primary" onClick={() => setNewCustomerDialog(true)}>
            Adicionar
          </Button>
        }
      />

      <CustomerFilterBox
        fetchCustomer={fetchCustomer}
        indexToSearch={indexToSearch}
        searchText={searchText}
        setIndexToSearch={setIndexToSearch}
        setSearchText={setSearchText}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredCustomers.length === 0 ? (
        <NoData background="#fff" message="Nenhum favorecido para mostrar" />
      ) : (
        <TableContainer tableTemplate={customerTableTemplate}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <CustomerListTable customers={filteredCustomers} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <CustomerListModule customers={filteredCustomers} />
              )}
              <Pagination count={filteredCustomers.length} />
            </Container>
          </PaginationProvider>
        </TableContainer>
      )}
    </CustomerProvider>
  );
};

export default Customers;
