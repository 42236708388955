import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PriceProtection } from 'types/priceProtection';

export interface PriceProtectionProviderProps {
  selected: PriceProtection | null;
  setSelected: Dispatch<SetStateAction<PriceProtection | null>>;
}

const Context = createContext<PriceProtectionProviderProps>({} as PriceProtectionProviderProps);
export const PriceProtectionProvider = Context.Provider;

export function usePriceProtection() {
  return useContext(Context);
}
