import React from 'react';
import DailySummaryTotal from './DailySummaryTotal';
import { Icon, styled, Typography } from '@mui/material';
import { Insights } from '@mui/icons-material';
import DailySummaryBranch from './branch/DailySummaryBranch';
import DailySummaryMadeBy from './made-by/DailySummaryMadeBy';
import DailySummaryUnity from './unity/DailySummaryUnity';
import DailySummaryPayment from './payment/DailySummaryPayment';
import { useFetchDailySummary } from './hooks/useFetchDailySummary';
import DailySummaryFilterBox from './DailySummaryFilterBox';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

const Title = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const DailySummary: React.FC = () => {
  const {
    handleChangeFilter,
    filter,
    loadingPayments,
    filteredPaymentMethods,
    filteredUnits,
    loadingUnits,
    handleSortUnits,
    handleSearch,
    handleSortPaymentMethods,
    filteredSales,
    loadingSales,
    handleSortSales,
    loadingBranches,
    filteredBranches,
    handleSortBranches,
  } = useFetchDailySummary();
  return (
    <Container>
      <Title>
        <Icon color="primary" fontSize="medium">
          <Insights color="primary" fontSize="medium" />
        </Icon>

        <Typography color="primary" fontWeight={600} variant="subtitle1">
          Resumo diário
        </Typography>
      </Title>

      <DailySummaryFilterBox handleSearch={handleSearch} filter={filter} handleChangeFilter={handleChangeFilter} />

      <DailySummaryTotal />

      <DailySummaryBranch
        branches={filteredBranches}
        loading={loadingBranches}
        handleSortBranches={handleSortBranches}
      />

      <DailySummaryMadeBy sales={filteredSales} loading={loadingSales} handleSortSales={handleSortSales} />

      <DailySummaryUnity units={filteredUnits} loading={loadingUnits} handleSortUnits={handleSortUnits} />

      <DailySummaryPayment
        loading={loadingPayments}
        handleSortPaymentMethods={handleSortPaymentMethods}
        paymentMethods={filteredPaymentMethods}
      />
    </Container>
  );
};

export default DailySummary;
