import React from 'react';
import { Typography, ListItemButton, styled } from '@mui/material';
import { CreditLimitRequest } from 'types/creditLimitRequest';
import { useCreditLimitRequests } from 'pages/credit-limit-requests/hooks/useCreditLimitRequests';
import { moneyFormat } from 'helpers/numberFormat';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type CreditLimitRequestItemModuleProps = {
  creditLimitRequest: CreditLimitRequest;
};

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    width: '100%',
    alignItems: 'center',
  },
}));

const CreditLimitRequestItemModule: React.FC<CreditLimitRequestItemModuleProps> = ({ creditLimitRequest }) => {
  const { setSelectedCreditLimitRequest } = useCreditLimitRequests();

  function handleClick() {
    setSelectedCreditLimitRequest(creditLimitRequest);
  }

  return (
    <ListItemButtonStyled onClick={handleClick}>
      <Typography variant="body1">{creditLimitRequest.company_name}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          CNPJ:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {creditLimitRequest.document}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Contatado:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {creditLimitRequest.contacted}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          ID Cliente:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {creditLimitRequest.company_id}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Status:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {creditLimitRequest.status}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Criado em:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {format(parseISO(creditLimitRequest.created_at), 'PPp', { locale: ptBR })}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Limite solicitado:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {moneyFormat(creditLimitRequest.limit_value_requested)}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Limite concedido:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {moneyFormat(creditLimitRequest.limit_value)}
        </Typography>
      </div>
    </ListItemButtonStyled>
  );
};

export default CreditLimitRequestItemModule;
