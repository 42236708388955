import React from 'react';
import { Button, Typography, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useInvoices } from './hooks/useInvoices';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { Add, Print } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: 7,
  [theme.breakpoints.down('sm')]: {
    padding: '0px 15px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'space-between',
  },
}));

const Dialog = styled('div')({
  display: 'flex',
  gap: 7,
  '& button': {
    color: '#fff',
  },
});

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

interface PageHeaderButtonsProps {
  title: string;
}

const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({ title }) => {
  const {
    invoices,
    setIsReportOpen,
    setIsNewInvoiceOpen,
    setAppropriationDialog,
    setPaymentDialog,
    filter,
    selectedInvoices,
  } = useInvoices();

  const { handleDownload } = useDefaultExcelExport(invoices);

  function handleReportClick() {
    setIsReportOpen(true);
  }

  return (
    <Container>
      <Dialog>
        {filter.branch_id !== 1001 && selectedInvoices.length > 0 && (
          <Button onClick={() => setPaymentDialog(true)} variant="contained" color="secondary" size="small">
            Informar pagamento
          </Button>
        )}
        {filter.branch_id !== 1001 && selectedInvoices.length > 0 && (
          <Button onClick={() => setAppropriationDialog(true)} variant="contained" color="error" size="small">
            Apropriar selecionados
          </Button>
        )}
      </Dialog>

      <Title align="center" variant="h6">
        {title}
      </Title>

      <Actions>
        <ExcelButtonDownload handleDownload={handleDownload} size="small" marginTop={0} disabled={!invoices.length} />

        <Button
          disabled={!invoices.length}
          startIcon={<Print />}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleReportClick}
        >
          Imprimir
        </Button>

        <Button
          onClick={() => setIsNewInvoiceOpen(true)}
          startIcon={<Add />}
          variant="contained"
          color="primary"
          size="small"
        >
          Novo
        </Button>
      </Actions>
    </Container>
  );
};

export default PageHeaderButtons;
