import { Button, MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import React, { Dispatch, SetStateAction } from 'react';
import { PriceProtectionQuery } from './PriceProtection';
import { Search } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 200px 120px',
  gap: 15,
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

interface Props {
  query: PriceProtectionQuery;
  loading: boolean;
  setQuery: Dispatch<SetStateAction<PriceProtectionQuery>>;
  onButtonClick(): void;
}

const PriceProtectionFilterBox: React.FC<Props> = ({ query, setQuery, loading, onButtonClick }) => {
  return (
    <FilterBox>
      <Container>
        <TextField
          label="Produto"
          placeholder="Digite o nome do produto"
          value={query.term}
          onChange={e => setQuery(state => ({ ...state, term: e.target.value }))}
          fullWidth
        />
        <TextField
          onChange={event => setQuery(state => ({ ...state, pago: event.target.value as any }))}
          select
          value={query.pago}
          label="Pago"
          fullWidth
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="S">Sim</MenuItem>
          <MenuItem value="N">Não</MenuItem>
        </TextField>
        <Button disabled={loading} onClick={onButtonClick} startIcon={<Search />} variant="contained" color="primary">
          Procurar
        </Button>
      </Container>
    </FilterBox>
  );
};

export default PriceProtectionFilterBox;
