import React from 'react';
import { LineAxis } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { AgroupedBranchReplacement } from './hooks/useFetchAnalysisReplacementDashboard';
import { Replacement } from 'types/replacement';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .second': {
    background: '#fdf2cd',
  },
  '& .third': {
    background: '#eeeff8',
  },
  '& .fourth': {
    background: '#f8eef3',
  },
  '& .fifth': {
    background: '#e3e3e3',
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
});

interface AnalysisReplacementDashboardTotalProps {
  branches: AgroupedBranchReplacement[];
  replacements: Replacement[];
}

const AnalysisReplacementDashboardTotal: React.FC<AnalysisReplacementDashboardTotalProps> = ({
  replacements,
  branches,
}) => {
  const formattedInvestment = moneyFormat(branches.reduce((acc, replacement) => acc + replacement.investimento, 0));
  const formattedQuantity = replacements.length;

  return (
    <Container>
      <Box>
        <Typography className="title" variant="body2" fontSize={18}>
          Investimento Total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedInvestment}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Qtd Itens
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedQuantity}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default AnalysisReplacementDashboardTotal;
