import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { branchTableTemplate } from '../template/branchTableTemplate';
import { AbcProductBranch } from 'types/abcProduct';
import DailySummaryBranchItemTable from './DailySummaryBranchItemTable';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type DailySummaryBranchListTableProps = {
  branches: AbcProductBranch[];
  handleSort(index: string): void;
};

const DailySummaryBranchListTable: React.FC<DailySummaryBranchListTableProps> = ({ branches, handleSort }) => {
  return (
    <TableContainer tableTemplate={branchTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {branchTableTemplate.map(item => (
            <HeaderItem
              className={
                item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={12} variant="caption" color="primary">
                {item.description}
              </Typography>
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={false}>
          {branches.map((branch, index) => (
            <TableRow style={{ minHeight: 30 }} key={index}>
              <DailySummaryBranchItemTable branch={branch} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default DailySummaryBranchListTable;
