import { styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { CommercialDashboard } from 'types/commercialDashboard';

const Container = styled('div')(({ theme }) => ({
  gap: 30,
  padding: 15,
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gap: 10,
    gridTemplateColumns: '1fr',
  },
}));

const BoxTitle = styled('div')({
  backgroundColor: '#30519f',
  justifySelf: 'end',
  padding: '5px 10px',
});

const Box = styled('div')({
  border: '1px solid #30519f',
  borderRadius: '5px 5px 0 0',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 70,
});

const Row = styled('div')({
  height: '100%',
  width: 100,
});

const Loading = styled('div')({
  padding: 10,
  flex: 1,
});

interface CommercialDashboardHeaderProps {
  orders: CommercialDashboard[];
  loading: boolean;
}

const CommercialDashboardHeader: React.FC<CommercialDashboardHeaderProps> = ({ orders, loading }) => {
  return (
    <Container>
      <Box>
        {loading ? (
          <Loading>
            <Row className={`animated-background`} />
          </Loading>
        ) : (
          <div style={{ flex: 1, padding: '2px 10px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">{moneyFormat(orders.reduce((sum, item) => sum + item.valor_total, 0))}</Typography>
            <Typography variant="h6">{orders.length}</Typography>
          </div>
        )}
        <BoxTitle>
          <Typography color="#fff" variant="body2">
            Total DAV
          </Typography>
        </BoxTitle>
      </Box>
      <Box>
        {loading ? (
          <Loading>
            <Row className={`animated-background`} />
          </Loading>
        ) : (
          <div style={{ flex: 1, padding: '2px 10px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {moneyFormat(
                orders.filter(item => item.vendido === 'S').reduce((sum, item) => sum + item.valor_total, 0),
              )}
            </Typography>

            <Typography variant="h6">{orders.filter(item => item.vendido === 'S').length}</Typography>
          </div>
        )}
        <BoxTitle>
          <Typography color="#fff" variant="body2">
            Faturados
          </Typography>
        </BoxTitle>
      </Box>
      <Box>
        {loading ? (
          <Loading>
            <Row className={`animated-background`} />
          </Loading>
        ) : (
          <div style={{ flex: 1, padding: '2px 10px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {moneyFormat(
                orders.filter(item => item.vendido === 'N').reduce((sum, item) => sum + item.valor_total, 0),
              )}
            </Typography>
            <Typography variant="h6">{orders.filter(item => item.vendido === 'N').length}</Typography>
          </div>
        )}
        <BoxTitle>
          <Typography color="#fff" variant="body2">
            Não fat
          </Typography>
        </BoxTitle>
      </Box>
    </Container>
  );
};

export default CommercialDashboardHeader;
