import { TableTemplate } from 'types/tableTemplate';

export const retailTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'formattedIntelbrasCode',
    description: 'Cód Intelbras',
    originalId: 'codigo_intelbras',
    width: 130,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'subgrupo',
    description: 'Subgrupo',
    originalId: 'subgrupo',
    width: 200,
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'pmd',
    description: 'PMD',
    originalId: 'pmd',
    width: 70,
    dataType: 'number',
  },
  {
    id: 'formattedPrice2',
    description: 'Preço 2',
    originalId: 'preco2',
    width: 100,
    dataType: 'number',
  },
];
