import React from 'react';
import { Button } from '@mui/material';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuidv4';
import { usePermissionRules } from 'hooks/permissionRules';
import { api } from 'services/api';
import { RetailTable } from 'types/retailTable';

interface Props {
  tables: RetailTable[];
  loading: boolean;
}

const RetailTableAction: React.FC<Props> = ({ tables, loading }) => {
  const { checkPermission } = usePermissionRules();

  async function handleExportExcel() {
    const responseExcel = await api.post('/excel-exports', tables, { responseType: 'blob' });

    download(responseExcel.data, `tabela-varejo-${uuidv4()}`);
  }

  return (
    <div>
      {checkPermission('general.excel_export_button') && (
        <Button
          disabled={loading || tables.length === 0}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleExportExcel}
        >
          Excel
        </Button>
      )}
    </div>
  );
};

export default RetailTableAction;
