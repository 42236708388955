import React from 'react';
import NoData from 'components/no-data/NoData';
import { styled } from '@mui/material';
import RmaDashboardTotal from './RmaDashboardTotal';
import { Rma } from 'types/rma';
import { useFetchRmaDashboard } from './hooks/useFetchRmaDashboard';
import RmaDashboardBuyer from './buyer/RmaDashboardBuyer';
import RmaDashboardManufacturer from './manufacturer/RmaDashboardManufacturer';
import RmaDashboardBranch from './branch/RmaDashboardBranch';
import RmaDashboardSubgroup from './subgroup/RmaDashboardSubgroup';

interface RmaDashboardProps {
  items: Rma[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const RmaDashboard: React.FC<RmaDashboardProps> = ({ items }) => {
  const { groupedManufacturers, groupedBranches, groupedBuyers, groupedSubgroups } = useFetchRmaDashboard(items);

  return (
    <Container>
      {items.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <RmaDashboardTotal items={items} />

          <RmaDashboardBranch branches={groupedBranches} />

          <RmaDashboardBuyer buyers={groupedBuyers} />

          <RmaDashboardManufacturer manufacturers={groupedManufacturers} />

          <RmaDashboardSubgroup subgroups={groupedSubgroups} />
        </Content>
      )}
    </Container>
  );
};

export default RmaDashboard;
