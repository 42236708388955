import React, { useState } from 'react';
import { useCommercialBi } from '../hooks/useCommercialBi';
import { IconButton, Typography, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { OpenInNew } from '@mui/icons-material';
import TopOrdersSalesExpanded from './dialog/TopOrdersSalesExpanded';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
});

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#fdf2cd',
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > button': {
    padding: 0,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
  },
  display: 'grid',
  paddingBottom: 2,
  columnGap: 10,
  borderBottom: '1px solid #eee',
  gridTemplateColumns: '40px 1fr 80px 80px',
  '& .sale-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 2,
});

const TopOrdersSales: React.FC = () => {
  const { orders } = useCommercialBi();
  const [open, setOpen] = useState(false);
  const { rowsPerPage, page } = usePagination();

  const itemsReport = orders.sort((a, b) => {
    if (a.valor_total < b.valor_total) {
      return 1;
    }

    if (a.valor_total > b.valor_total) {
      return -1;
    }

    return 0;
  });

  return (
    <Container>
      {open && <TopOrdersSalesExpanded itemsReport={itemsReport} onExited={() => setOpen(false)} />}
      <Title>
        <Typography fontSize={13}>Relação de pedidos de compra</Typography>

        <IconButton disabled={itemsReport.length === 0} size="small" onClick={() => setOpen(true)}>
          <OpenInNew fontSize="small" />
        </IconButton>
      </Title>
      {itemsReport.length > 0 ? (
        <Content>
          <List>
            <Row className="header">
              <Typography variant="caption" fontWeight={600}>
                Cod
              </Typography>
              <Typography variant="caption" fontWeight={600}>
                Produto
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                Qtd
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                Previsão
              </Typography>
            </Row>
            {itemsReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => (
              <Row key={index}>
                <Typography variant="caption">{sale.id_produto}</Typography>
                <Typography className="sale-name" variant="caption">
                  {sale.produto}
                </Typography>
                <Typography align="right" variant="caption">
                  {sale.pedido}
                </Typography>

                <Typography align="right" variant="caption">
                  {sale.previsaomin}
                </Typography>
              </Row>
            ))}
          </List>
          <Pagination count={itemsReport.length} />
        </Content>
      ) : (
        <NoData message="Nenhum registro encontrado" />
      )}
    </Container>
  );
};

export default TopOrdersSales;
