import { TableTemplate } from 'types/tableTemplate';

export const customerTableTemplate: TableTemplate[] = [
  {
    id: 'codigo',
    description: 'Código',
    originalId: 'codigo',
    width: 85,
  },
  {
    id: 'nome',
    description: 'Nome',
    originalId: 'nome',
    width: 230,
  },
  {
    id: 'uf',
    description: 'UF',
    originalId: 'uf',
    width: 50,
  },
  {
    id: 'cidade',
    description: 'Cidade',
    originalId: 'cidade',
    width: 150,
  },
  {
    id: 'endereco',
    description: 'Endereço',
    originalId: 'endereco',
    width: 310,
  },
  {
    id: 'bairro',
    description: 'Bairro',
    originalId: 'bairro',
    width: 150,
  },
  {
    id: 'cnpj_cpf',
    description: 'CPF/CNPJ',
    originalId: 'cnpj_cpf',
    width: 150,
  },
  {
    id: 'tipo',
    description: 'Tipo',
    originalId: 'tipo',
    width: 100,
  },
  {
    id: 'tipo_pessoa',
    description: 'Tipo Pessoa',
    originalId: 'tipo_pessoa',
    width: 100,
  },
  {
    id: 'atividade',
    description: 'Atividade',
    originalId: 'atividade',
    width: 200,
  },
  {
    id: 'situacao',
    description: 'Situação',
    originalId: 'situacao',
    width: 100,
  },
];
