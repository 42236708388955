import { KeyboardArrowRight, ShoppingBasket } from '@mui/icons-material';
import { Icon, lighten, styled, Typography } from '@mui/material';
import NoData from 'components/no-data/NoData';
import { moneyFormat } from 'helpers/numberFormat';
import { GroupedSale } from 'pages/reports/orders-list/dashboard/list/order-by/list/table/GroupedSaleListTable';
import React from 'react';
import DailySummaryLoading from '../loading/DailySummaryLoading';
import DailySummarySaleListTable from './table/DailySummarySaleListTable';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const Content = styled('div')({
  maxWidth: 600,
  padding: 15,
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  gap: 10,
});

const Totalizer = styled('div')({
  display: 'flex',
  maxWidth: 600,
  gap: 10,
});

const Box = styled('div')(({ theme }) => ({
  padding: 6,
  flex: 1,
  background: lighten(theme.palette.primary.light, 0.3),
  color: theme.palette.primary.contrastText,
  borderRadius: 6,
  gap: 5,
  display: 'flex',
  justifyContent: 'space-between',
}));

const Flex = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
});

interface Props {
  loading: boolean;
  sales: GroupedSale[];
  handleSortSales(index: string): void;
}

const DailySummaryMadeBy: React.FC<Props> = ({ handleSortSales, loading, sales }) => {
  return (
    <Container>
      <Title>
        <Icon color="primary" fontSize="medium">
          <ShoppingBasket color="primary" fontSize="medium" />
        </Icon>

        <Typography color="primary" fontWeight={600} variant="subtitle1">
          Pedido por
        </Typography>
      </Title>

      {loading ? (
        <DailySummaryLoading />
      ) : (
        <>
          <Totalizer>
            <Box>
              <Typography variant="caption" fontWeight={600}>
                Valor total:
              </Typography>

              <Flex>
                <Typography variant="caption" fontWeight={600}>
                  {moneyFormat(sales.reduce((acc, item) => acc + item.vl_total, 0))}
                </Typography>

                <Icon color="inherit" fontSize="small">
                  <KeyboardArrowRight fontSize="small" color="inherit" />
                </Icon>
              </Flex>
            </Box>

            <Box>
              <Typography variant="caption" fontWeight={600}>
                Qtd:
              </Typography>

              <Flex>
                <Typography variant="caption" fontWeight={600}>
                  {sales.length}
                </Typography>

                <Icon color="inherit" fontSize="small">
                  <KeyboardArrowRight fontSize="small" color="inherit" />
                </Icon>
              </Flex>
            </Box>
          </Totalizer>

          {sales.length === 0 ? (
            <Content>
              <NoData message="Nenhuma venda encontrada" />
            </Content>
          ) : (
            <>
              <Content>
                <DailySummarySaleListTable handleSort={handleSortSales} sales={sales} />
              </Content>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DailySummaryMadeBy;
