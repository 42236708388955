import { LineAxis } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { PriceProtection } from 'types/priceProtection';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  marginBottom: 10,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .second': {
    background: '#fdf2cd',
  },
  '& .third': {
    background: '#eeeff8',
  },
  '& .fourth': {
    background: '#f8eef3',
  },
  '& .fifth': {
    background: '#e3e3e3',
  },
  '& .sixth': {
    background: '#ffc8c8',
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
});

interface PriceProtectionDashboardTotalProps {
  datas: PriceProtection[];
}

const PriceProtectionDashboardTotal: React.FC<PriceProtectionDashboardTotalProps> = ({ datas }) => {
  const formattedTotal = moneyFormat(datas.reduce((acc, product) => acc + product.valor, 0));
  const formattedTotalPaid = moneyFormat(
    datas.filter(item => item.pago === 'S').reduce((acc, product) => acc + product.valor, 0),
  );
  const formattedTotalNoPaid = moneyFormat(
    datas.filter(item => item.pago === 'N').reduce((acc, product) => acc + product.valor, 0),
  );

  return (
    <Container>
      <Box>
        <Typography className="title" variant="body2" fontSize={18}>
          Total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Total Pago
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotalPaid}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Total Não Pago
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotalNoPaid}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default PriceProtectionDashboardTotal;
