import { useState, useCallback, FormEvent, useEffect } from 'react';
import { useApp } from 'hooks/app';
import { useSelector } from 'store/selector';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { ProductSubGroup } from 'types/product';
import { useParams } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import { H2iBuyer } from 'types/h2iBuyer';
import { Reserve } from 'types/reserve';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export interface CommercialAnalysisSubgroupFilterParams {
  selectedBranchId: number;
  buyerId: string;
  subgroupId: string;
  initial_date: Date;
  final_date: Date;
  type: string;
  forecast: string;
  working_days: string;
}

export interface CommercialAnalysisTotal {
  rma: number;
  formattedRma: string;
  qtd: string;
  orders: number;
  stock: number;
  stockValue: number;
  reserve: number;
  formattedStockValue: string;
  suggestion: number;
  averageSales: string;
  averagePurchases: string;
  averageSalesValue: string;
  averagePurchasesValue: string;
}

const initial_value: CommercialAnalysisSubgroupFilterParams = {
  selectedBranchId: 999,
  buyerId: 'all',
  subgroupId: '',
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  type: 'C',
  forecast: '30',
  working_days: '25',
};

export function useFetchCommercialAnalysisSubgroup() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const { id } = useParams<{ id: string }>();
  const { isOpenedMenu, handleOpenMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [loadingBuyers, setLoadingBuyers] = useState(false);
  const [buyers, setBuyers] = useState<H2iBuyer[]>([]);
  const [reserves, setReserves] = useState<Reserve[]>([]);
  const [subgroups, setSubgroups] = useState<ProductSubGroup[]>([]);
  const [subgroup, setSubgroup] = useState<ProductSubGroup | null>(null);
  const [suggestions, setSuggestions] = useState<ShoppingSuggestion[]>([]);
  const [total, setTotal] = useState<CommercialAnalysisTotal>({
    rma: 0,
    formattedRma: '0',
    qtd: '',
    orders: 0,
    stock: 0,
    suggestion: 0,
    formattedStockValue: 'R$ 0,00',
    stockValue: 0,
    averagePurchases: '0',
    averageSales: '0',
    averageSalesValue: 'R$ 0,00',
    averagePurchasesValue: 'R$ 0,00',
    reserve: 0,
  });
  const [filter, setFilter] = useState<CommercialAnalysisSubgroupFilterParams>(initial_value);

  useEffect(() => {
    setFilter(state => ({
      ...state,
      subgroupId: '',
    }));
  }, [filter.buyerId]);

  function handleChangeFilter(index: keyof CommercialAnalysisSubgroupFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  useEffect(() => {
    if (!h2iApi) return;
    setLoadingSubgroups(true);
    h2iApi
      .get(`/api/subgrupo`, {
        params: {
          id_comprador: filter.buyerId === 'all' ? '' : filter.buyerId,
        },
      })
      .then(_response => {
        const response = _response.data.RESULT[0];

        setSubgroups(response);
      })
      .catch(err => {
        console.error(err);
        setSubgroups([]);
      })
      .finally(() => setLoadingSubgroups(false));
  }, [filter.buyerId, h2iApi]);

  useEffect(() => {
    if (!h2iApi) return;
    setLoadingBuyers(true);
    h2iApi
      .get(`/api/getcompradores`)
      .then(response => {
        if (response.data.RESULT) {
          setBuyers([]);
          return;
        }
        const _buyers = response.data.compradores
          .filter(item => item.ativo === 'S')
          .sort((a: H2iBuyer, b: H2iBuyer) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });

        setBuyers(_buyers);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingBuyers(false));
  }, [h2iApi]);

  const handleSearch = useCallback(
    (filter: CommercialAnalysisSubgroupFilterParams) => {
      if (!h2iApi) return;

      setLoading(true);
      const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });

      const params = {
        id_filial: filter.selectedBranchId === 0 ? '' : filter.selectedBranchId,
        data_ini: formattedInitialDate,
        data_fim: formattedFinalDate,
        tipo_cfop: filter.type === 'all' ? '' : filter.type,
        forecast: filter.forecast,
        id_comprador: filter.buyerId === 'all' ? '' : filter.buyerId,
        id_subgrupo: filter.subgroupId,
        dias_uteis: filter.working_days,
      };

      const fetchReserves = h2iApi
        .get('/api/getreservaproduto', {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setReserves([]);
            setTotal(state => ({
              ...state,
              reserve: 0,
            }));
            return;
          }
          const _reserves: Reserve[] = response.data.itens.map((item: Reserve) => {
            item.formattedUnitValue = moneyFormat(item.valor_unitario);
            item.formattedTotalValue = moneyFormat(item.valor_total);
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          });

          setTotal(state => ({
            ...state,
            reserve: response.data.itens.reduce((acc, item) => acc + item.qtd, 0),
          }));

          setReserves(_reserves);
        });

      const fetchIndexRma = h2iApi
        .get(`/api/getrelindicerma`, {
          params,
        })
        .then(_response => {
          const response = _response.data[0];

          if (!response) {
            setTotal(state => ({
              ...state,
              formattedRma: '',
              rma: 0,
              qtd: '',
            }));

            return;
          }

          setTotal(state => ({
            ...state,
            formattedRma: percentFormat(response.indice, 3),
            rma: response.indice,
            qtd: response.qtd,
          }));
        });

      const fetchStockBalance = h2iApi
        .get(`/api/saldoestoque`, {
          params,
        })
        .then(response => {
          if (response.data[0].result) {
            setTotal(state => ({
              ...state,
              stock: 0,
              stockValue: 0,
              formattedStockValue: 'R$ 0,00',
            }));
            return;
          }

          setSubgroup({
            NOME: response.data[0].subgrupo,
            CODIGO_SUBGRUPO: parseInt(filter.subgroupId),
          });

          setTotal(state => ({
            ...state,
            stock: response.data.reduce((acc, item) => acc + item.Estoque, 0),
            stockValue: response.data.reduce((acc, item) => acc + item.CustoTotal, 0),
            formattedStockValue: moneyFormat(response.data.reduce((acc, item) => acc + item.CustoTotal, 0)),
          }));
        });

      const fetchSuggestions = h2iApi
        .get(`/api/getrelsugestaocomprasproduto`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setTotal(state => ({
              ...state,
              suggestion: 0,
            }));
            return;
          }

          setSuggestions(
            response.data.map(item => ({
              ...item,
              pedidos: parseInt(item.pedidos || '0'),
              forecast: parseInt(item.forecast || '0'),
              sugestao: item.sugestao,
              estoque: parseInt(item.estoque || '0'),
              estoque_forecast: parseInt(item.estoque || '0') + parseInt(item.pedidos || '0'),
            })),
          );

          const suggestionTotal = response.data.reduce((acc, curr) => acc + curr.sugestao, 0);

          setTotal(state => ({
            ...state,
            suggestion: suggestionTotal,
          }));
        });

      Promise.all([fetchIndexRma, fetchStockBalance, fetchSuggestions, fetchReserves])
        .catch(([indexRmaErr, stockBalanceErr, suggestionErr, reserveErr]) => {
          console.error(indexRmaErr, stockBalanceErr, suggestionErr, reserveErr);
          setSubgroup(null);
          setTotal({
            formattedStockValue: 'R$ 0,00',
            stockValue: 0,
            formattedRma: '',
            rma: 0,
            qtd: '',
            orders: 0,
            stock: 0,
            suggestion: 0,
            averagePurchases: '0',
            averageSales: '0',
            averageSalesValue: 'R$ 0,00',
            averagePurchasesValue: 'R$ 0,00',
            reserve: 0,
          });
        })
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  function handleSearchSubmit(filter: CommercialAnalysisSubgroupFilterParams, event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch(filter);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    const _filter = {
      ...initial_value,
      selectedBranchId:
        user.branchList?.length !== 0 ? parseInt(user.branchList.length === 12 ? '0' : user.branchList[0]) : 999,
      productId: id || '',
    };

    setFilter(_filter);

    if (!_filter.productId) {
      return;
    }

    if (!id) {
      return;
    }

    if (_filter.selectedBranchId === 999) {
      return;
    }

    handleSearch(_filter);
  }, [handleSearch, id, user]);

  return {
    subgroup,
    subgroups,
    buyers,
    loadingBuyers,
    loadingSubgroups,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    filter,
    total,
    suggestions,
    reserves,
  };
}
