import React from 'react';
import { LineAxis } from '@mui/icons-material';
import { darken, styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { Rma } from 'types/rma';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridColumn: '1/4',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/2',
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .third': {
    background: '#eeeff8',
    color: darken('#eeeff8', 0.5),
  },
  '& .fourth': {
    background: '#f8eef3',
    color: darken('#f8eef3', 0.5),
  },
  '& .fifth': {
    background: '#e3e3e3',
    color: darken('#e3e3e3', 0.5),
  },
  '& .sixth': {
    background: '#ffc8c8',
    color: darken('#ffc8c8', 0.5),
  },
  '& .seven': {
    background: '#898fbd',
    color: darken('#898fbd', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface RmaDashboardTotalProps {
  items: Rma[];
}

const RmaDashboardTotal: React.FC<RmaDashboardTotalProps> = ({ items }) => {
  const quantityTotal = items.reduce((acc, item) => acc + item.formattedQuantity, 0);
  const quantityBadTotal = items
    .filter(item => item.situacao === 'Ruim')
    .reduce((acc, item) => acc + item.formattedQuantity, 0);
  const quantityGoodTotal = items
    .filter(item => item.situacao === 'Bom')
    .reduce((acc, item) => acc + item.formattedQuantity, 0);
  const formattedValueBadTotal = moneyFormat(
    items.filter(item => item.situacao === 'Ruim').reduce((acc, item) => acc + item.valor, 0),
  );
  const quantityWithoutAnalysis = items
    .filter(item => item.situacao === 'Sem Laudo')
    .reduce((acc, item) => acc + item.formattedQuantity, 0);
  const quantityReplacement = items.filter(item => item.troca_24 === 'S').length;
  const quantityUsedValue = items.filter(item => item.vale_usado === 'S').length;

  return (
    <Container>
      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Qtd Total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Qtd Ruim
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityBadTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title first" variant="body2" fontSize={18}>
          Qtd Boa
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityGoodTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Aguardando análise
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityWithoutAnalysis}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fourth" variant="body2" fontSize={18}>
          Qtd Troca 24h
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityReplacement}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title seven" variant="body2" fontSize={18}>
          Qtd Vale Usado
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {quantityUsedValue}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title sixth" variant="body2" fontSize={18}>
          Valor Total Ruim
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedValueBadTotal}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default RmaDashboardTotal;
