import React, { MouseEvent } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { ManufacturerStockBalanceData } from 'types/reports/stockBalance';
import { manufacturerStockTableTemplate } from '../manufacturerStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import { KeyboardArrowDown } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

type ManufacturerListItemProps = {
  manufacturer: ManufacturerStockBalanceData;
};

const ManufacturerListItem: React.FC<ManufacturerListItemProps> = ({ manufacturer }) => {
  const { setMoreManufacturer, moreManufacturer } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreManufacturer(moreManufacturer ? null : manufacturer);
  }

  return (
    <>
      {manufacturerStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={
                  moreManufacturer?.id_fabricante === manufacturer.id_fabricante
                    ? { transform: 'rotate(180deg)' }
                    : undefined
                }
              />
            </CustomIconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {manufacturer[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ManufacturerListItem;
