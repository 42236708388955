import { TableTemplate } from 'types/tableTemplate';

export const stockTableTemplate: TableTemplate[] = [
  {
    id: 'CodProd',
    description: 'Cod',
    originalId: 'CodProd',
    width: 50,
  },
  {
    id: 'Produto',
    description: 'Descrição',
    originalId: 'Produto',
    width: 300,
  },
  {
    id: 'formattedCusto',
    description: 'Custo',
    originalId: 'Custo',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedCustoTotal',
    description: 'Custo Total',
    originalId: 'CustoTotal',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'Estoque',
    description: 'Estoque',
    originalId: 'Estoque',
    width: 75,
    dataType: 'quantity',
  },
  {
    id: 'formattedIndex',
    description: 'IGE',
    originalId: 'indice',
    width: 60,
    dataType: 'quantity',
  },
  {
    id: 'forecast',
    description: 'Forecast',
    originalId: 'forecast',
    width: 70,
    dataType: 'quantity',
  },
  {
    id: 'Reserva',
    description: 'Reserva',
    originalId: 'Reserva',
    width: 75,
    dataType: 'quantity',
  },
  {
    id: 'EstTotal',
    description: 'Est Total',
    originalId: 'EstTotal',
    width: 70,
    dataType: 'quantity',
  },
  {
    id: 'pedido',
    description: 'Pedidos',
    originalId: 'pedido',
    width: 75,
    dataType: 'quantity',
  },
  {
    id: 'formattedValueOrder',
    description: 'Vl. Pedido',
    originalId: 'valor_pedido',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedValueReserve',
    description: 'Vl. Reserva',
    originalId: 'valor_reserva',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'ultima_entrada',
    description: 'Ult. Entrada',
    originalId: 'formattedLastEntry',
    width: 120,
  },
  {
    id: 'ultima_venda',
    description: 'Ult. Venda',
    originalId: 'formattedLastSale',
    width: 120,
  },
];
