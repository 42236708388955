import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useWastes } from 'pages/material-residues/waste/hooks/useWastes';
import { WasteOnList } from 'pages/material-residues/waste/types/waste';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});
type WasteItemTableProps = {
  waste: WasteOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const WasteItemTable: React.FC<WasteItemTableProps> = ({ waste, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedWaste } = useWastes();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedWaste(waste);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleMoreClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <Tooltip key={item.id} title={waste[item.id]}>
              <NumericData className={item.dataType === 'number' ? 'numericData' : undefined}>
                <Description variant="body2">{waste[item.id as any]}</Description>
              </NumericData>
            </Tooltip>
          ),
        )}
    </>
  );
};

export default WasteItemTable;
