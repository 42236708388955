import React, { useEffect, useState } from 'react';
import { AgroupedBranchesNotesEntry } from '../hooks/useFetchNoteEntryDashboard';
import { styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';

interface NoteEntryDashboardBranchesProps {
  branches: AgroupedBranchesNotesEntry[];
}

const Container = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
});

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f1f7ef',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '1fr 100px',
  '& .branch-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const NoteEntryDashboardBranches: React.FC<NoteEntryDashboardBranchesProps> = ({ branches }) => {
  const [filtered, setFiltered] = useState<AgroupedBranchesNotesEntry[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(branches);
  }, [branches]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por filial</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>
            <Typography onClick={() => handleSort('valor_total')} align="right" variant="caption" fontWeight={600}>
              Valor Total
            </Typography>
          </Row>
          {filtered.map((branch, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{branch.filial}</Typography>
              <Typography align="right" variant="caption">
                {branch.formattedTotal}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default NoteEntryDashboardBranches;
