import { TableTemplate } from 'types/tableTemplate';

export const branchStockTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 40,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 130,
  },
  {
    id: 'formattedCustoTotal',
    description: 'Custo Total',
    originalId: 'CustoTotal',
    width: 130,
    dataType: 'number',
  },
  {
    id: 'Estoque',
    description: 'Estoque',
    originalId: 'Estoque',
    width: 90,
    dataType: 'quantity',
  },
  {
    id: 'Reserva',
    description: 'Reserva',
    originalId: 'Reserva',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'EstTotal',
    description: 'Est Total',
    originalId: 'EstTotal',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'pedido',
    description: 'Pedidos',
    originalId: 'pedido',
    width: 100,
    dataType: 'quantity',
  },
  {
    id: 'formattedValueOrder',
    description: 'Vl. Pedido',
    originalId: 'valor_pedido',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'formattedValueReserve',
    description: 'Vl. Reserva',
    originalId: 'valor_reserva',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'ultima_entrada',
    description: 'Ult. Entrada',
    originalId: 'formattedLastEntry',
    width: 130,
  },
  {
    id: 'ultima_venda',
    description: 'Ult. Venda',
    originalId: 'formattedLastSale',
    width: 120,
  },
  {
    id: 'excel',
    description: 'Excel',
    originalId: 'excel',
    width: 100,
    dataType: 'quantity',
  },
];
