import React, { useEffect, useState } from 'react';
import { AgroupedSubgroupRma } from '../hooks/useFetchRmaDashboard';
import { styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';

interface RmaDashboardSubgroupProps {
  subgroups: AgroupedSubgroupRma[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1/4',
  [theme.breakpoints.down('lg')]: {
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#ffc8c8',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '360px 150px 100px 100px',
  '& .subgroup-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1.4fr 1fr 50px 1fr',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const RmaDashboardSubgroup: React.FC<RmaDashboardSubgroupProps> = ({ subgroups }) => {
  const [filtered, setFiltered] = useState<AgroupedSubgroupRma[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(subgroups);
  }, [subgroups]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por subgrupo</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('subgrupo')} variant="caption" fontWeight={600}>
              Subgrupo
            </Typography>
            <Typography onClick={() => handleSort('comprador')} variant="caption" fontWeight={600}>
              Comprador
            </Typography>
            <Typography onClick={() => handleSort('qtd')} align="center" variant="caption" fontWeight={600}>
              Qtd
            </Typography>
            <Typography onClick={() => handleSort('valor')} align="right" variant="caption" fontWeight={600}>
              Valor
            </Typography>
          </Row>
          {filtered.map((subgroup, index) => (
            <Row className="item" key={index}>
              <Typography className="subgroup-name" variant="caption">
                {subgroup.subgrupo}
              </Typography>
              <Typography className="subgroup-name" variant="caption">
                {subgroup.comprador}
              </Typography>
              <Typography align="center" variant="caption">
                {subgroup.qtd}
              </Typography>
              <Typography align="right" variant="caption">
                {subgroup.formattedValue}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default RmaDashboardSubgroup;
