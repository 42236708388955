import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { unityTableTemplate } from '../template/unityTableTemplate';
import { GroupedUnity } from 'pages/reports/orders-list/dashboard/list/unity/table/GroupedUnityListTable';

type DailySummaryUnityItemTableProps = {
  unity: GroupedUnity;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const DailySummaryUnityItemTable: React.FC<DailySummaryUnityItemTableProps> = ({ unity }) => {
  return (
    <>
      {unityTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item => (
          <div
            key={_item.id}
            className={
              _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
            }
          >
            <Tooltip title={unity[_item.id]}>
              <Description variant="body2" fontSize={12}>
                {unity[_item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default DailySummaryUnityItemTable;
