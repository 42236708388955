import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ItemEntryItemTable from './ItemEntryItemTable';
import TableContainer from 'components/table/TableContainer';
import { ItemEntry } from 'types/itemEntry';
import { itemEntryTableTemplate } from '../../template/itemEntryTableTemplate';
import ItemEntryListNotes from '../../dialog/ItemEntryListNotes';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type ItemEntryListTableProps = {
  entries: ItemEntry[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ItemEntryListTable: React.FC<ItemEntryListTableProps> = ({ entries, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const [selectedEntry, setSelectedEntry] = useState<ItemEntry | null>(null);

  return (
    <TableContainer tableTemplate={itemEntryTableTemplate}>
      {selectedEntry && <ItemEntryListNotes onExited={() => setSelectedEntry(null)} selectedEntry={selectedEntry} />}
      <TableContent useMinHeight={false}>
        <TableHeader>
          {itemEntryTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? `numericData` : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={10} variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={110}>
          {entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((entry, index) => (
            <TableRow onClick={() => setSelectedEntry(entry)} key={index}>
              <ItemEntryItemTable entry={entry} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default ItemEntryListTable;
