import { Box, Skeleton, styled } from '@mui/material';
import React from 'react';

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '2fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Header = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridColumn: '1/3',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/2',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const StockBalanceTotalLoading: React.FC = () => {
  return (
    <Content>
      <Header>
        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Skeleton variant="text" width={'100%'} height={30} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Box>
      </Header>
    </Content>
  );
};

export default StockBalanceTotalLoading;
