import React from 'react';
import { Typography } from '@mui/material';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';
import { subgroupStockTableTemplate } from '../subgroupStockTableTemplate';

type SubgroupItemTableProps = {
  subgroup: SubgroupStockBalanceData;
};

const SubgroupItemTable: React.FC<SubgroupItemTableProps> = ({ subgroup }) => {
  return (
    <>
      {subgroupStockTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div
            key={item.id}
            className={item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''}
          >
            <Typography style={{ fontSize: 11 }} variant="body2">
              {subgroup[item.id as keyof SubgroupStockBalanceData]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default SubgroupItemTable;
