import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

export type ItemEntryTabsOptions = 'items' | 'dashboard';

type ItemEntryTabsProps = {
  handleChange(value: ItemEntryTabsOptions): void;
  tab: ItemEntryTabsOptions;
};

const ItemEntryTabs: React.FC<ItemEntryTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="dashboard" />
        <Tab label="Relação de itens" value="items" />
      </Tabs>
    </Container>
  );
};

export default ItemEntryTabs;
