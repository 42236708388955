import React, { FormEvent } from 'react';
import { Button, Checkbox, FormControlLabel, styled, TextField } from '@mui/material';
import { RetailTableFilterProps } from './hooks/useFetchRetailTable';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';

interface Props {
  loading: boolean;
  filter: RetailTableFilterProps;
  fetchTables(filter: RetailTableFilterProps, e?: FormEvent<HTMLFormElement>): void;
  handleChangeFilter(index: keyof RetailTableFilterProps, value: any): void;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '1fr 115px 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 0.5,
  '& > .searchParams': {
    marginLeft: 20,
  },
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const RetailTableFilterBox: React.FC<Props> = ({ filter, handleChangeFilter, loading, fetchTables }) => {
  return (
    <FilterBox>
      <Filter onSubmit={e => fetchTables(filter, e)}>
        <TextField
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              checked={filter.hasStock === 1}
              onChange={e => handleChangeFilter('hasStock', e.target.checked ? 1 : 0)}
              color="primary"
            />
          }
          label="Estoque"
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default RetailTableFilterBox;
