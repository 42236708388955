import React from 'react';
import { styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';

interface BlockProps {
  backgroundColor?: string;
}

const Col1 = styled('div')(({ theme }) => ({
  gap: 5,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    '& > .first-child': {
      gridColumn: '1 / 3',
    },
  },
}));

const Col2 = styled('div')({
  gap: 5,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
});

const Row = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  padding: 10,
  gap: 5,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: 15,
  },
}));

const Block = styled('div')<BlockProps>(props => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  '& .title': {
    background: props.backgroundColor ?? 'transparent',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '20px 20px 8px 20px',
  },
  '& .main': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 16,
    [props.theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
}));

const PurchaseOrderDashboardTotal: React.FC = () => {
  const { totals, loading } = usePurchaseOrder();
  return (
    <Row>
      <Col1>
        <Block className="first-child">
          <span className="title">Compras + Transferências</span>
          <div className="main">
            {loading.totals ? <InsideLoading /> : <span className="value">{totals.formattedTotal}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">Compras</span>
          <div className="main">
            {loading.totals ? <InsideLoading /> : <span className="value">{totals.formattedShopping}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">Transferências</span>
          <div className="main">
            {loading.totals ? <InsideLoading /> : <span className="value">{totals.formattedTransfers}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>
      </Col1>

      <Col2>
        <Block>
          <span className="title">Valor de Estoque</span>
          <div className="main">
            {loading.totals ? <InsideLoading /> : <span className="value">{totals.formattedStockValue}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>

        <Block>
          <span className="title">AGING</span>
          <div className="main">
            {loading.totals ? <InsideLoading /> : <span className="value">{totals.formattedAging}</span>}
            <LineAxis color="secondary" />
          </div>
        </Block>
      </Col2>
    </Row>
  );
};

export default PurchaseOrderDashboardTotal;
