import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { H2iOrder } from 'types/h2iOrder';

type DavReviewValue = {
  selectedDav: H2iOrder | null;
  setSelectedDav: Dispatch<SetStateAction<H2iOrder | null>>;
};

const DavReview = createContext<DavReviewValue>({} as DavReviewValue);
export const DavReviewProvider = DavReview.Provider;

export function useDavReview(): DavReviewValue {
  const context = useContext(DavReview);
  return context;
}
