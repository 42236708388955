import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { StockBalanceData } from 'types/reports/stockBalance';

type ProductItemProps = {
  product: StockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  return (
    <ListItemStyled>
      <Typography color="primary" variant="caption" gutterBottom>
        {product.CodProd}
      </Typography>
      <Typography variant="body1">{product.Produto}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedCusto}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedCustoTotal}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default ProductItem;
