import React from 'react';
import { Typography, styled } from '@mui/material';
import { usePurchaseOrder } from '../hook/usePurchaseOrder';

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '60px 120px 120px',
  gap: 5,
  borderBottom: '1px solid #ddd',
  '& div:not(:last-child)': {
    borderRight: '1px solid #ddd',
  },
});

const Box = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: 5,
  height: '100%',
  '& > p': {
    fontSize: 13,
  },
  '&.numericData': {
    justifyContent: 'end',
  },
});

const Content = styled('div')({
  display: 'flex',
  marginTop: 20,
  flexDirection: 'column',
});

const PurchaseOrderPaymentsInform: React.FC = () => {
  const { order } = usePurchaseOrder();
  return (
    <div>
      {order.pagamentos.length > 0 && (
        <Content>
          <Grid>
            <Box>
              <Typography fontWeight={600}>ID</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Data</Typography>
            </Box>
            <Box className="numericData">
              <Typography fontWeight={600}>Valor</Typography>
            </Box>
          </Grid>
          {order.pagamentos
            .filter(item => item.cancelado !== 'S')
            .map((item, index) => (
              <Grid key={item.id}>
                <Box>
                  <Typography>{index + 1}</Typography>
                </Box>
                <Box>
                  <Typography>{item.vencimento}</Typography>
                </Box>
                <Box className="numericData">
                  <Typography>{item.formattedValue}</Typography>
                </Box>
              </Grid>
            ))}
        </Content>
      )}
    </div>
  );
};

export default PurchaseOrderPaymentsInform;
