import { useCallback } from 'react';
import { Waste, WasteOnList } from '../types/waste';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export function useWasteFormatter() {
  const formatter = useCallback((waste: Waste): WasteOnList => {
    console.log(waste.produtos.map(item => item.numeros_serie.map(item => item.numero_serie).join(', ')));
    return {
      ...waste,
      formattedTotal: moneyFormat(waste.valor_total),
      formattedPaymentDate: waste.data_pagamento && parsePtBRDate(waste.data_pagamento).toISOString(),
      formattedSerialNumbers: waste.produtos.map(item => item.numeros_serie.map(item => item.numero_serie)).join(', '),
      produtos: waste.produtos
        ? waste.produtos.map(product => ({
            ...product,
            formattedValue: moneyFormat(product.valor_unitario),
            formattedTotal: moneyFormat(product.valor_unitario * product.qtd),
          }))
        : [],
    };
  }, []);

  return formatter;
}
