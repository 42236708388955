import React from 'react';
import NoData from 'components/no-data/NoData';
import { styled } from '@mui/material';
import { Replacement } from 'types/replacement';
import AnalysisReplacementSubgroupMaster from './AnalysisReplacementSubgroupMaster';
import { AgroupedSubgroupReplacement } from './hooks/useFetchAnalysisReplacementSubgroup';

interface Props {
  replacements: Replacement[];
  subgroupsOpen: number[];
  onClickButton: (index: number) => void;
  filteredSubgroups: AgroupedSubgroupReplacement[];
  handleSort(index: string): void;
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr',
}));

const AnalysisReplacementSubgroup: React.FC<Props> = ({
  replacements,
  filteredSubgroups,
  handleSort,
  onClickButton,
  subgroupsOpen,
}) => {
  return (
    <Container>
      {filteredSubgroups.length === 0 ? (
        <NoData message="Não disponível" />
      ) : (
        <Content>
          <AnalysisReplacementSubgroupMaster
            filteredSubgroups={filteredSubgroups}
            handleSort={handleSort}
            onClickButton={onClickButton}
            replacements={replacements}
            subgroupsOpen={subgroupsOpen}
          />
        </Content>
      )}
    </Container>
  );
};

export default AnalysisReplacementSubgroup;
