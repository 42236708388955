import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { TransferAccount } from 'types/transferAccount';
import { accountsTableTemplate } from '../../accountsTableTemplate';
import { MoreHoriz } from '@mui/icons-material';

type TransferAccountItemTableProps = {
  account: TransferAccount;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
};

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

const TransferAccountItemTable: React.FC<TransferAccountItemTableProps> = ({ account, setAnchorEl, setSelectedId }) => {
  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedId(account.id);
  }

  return (
    <>
      {accountsTableTemplate.map(item =>
        item.id === 'actions' ? (
          <CustomIconButton onClick={handleClick} key={item.id}>
            <MoreHoriz />
          </CustomIconButton>
        ) : (
          <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
            <Typography variant="caption">{account[item.id]}</Typography>
          </NumericData>
        ),
      )}
    </>
  );
};

export default TransferAccountItemTable;
