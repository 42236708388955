import React, { useEffect, useState } from 'react';
import { AgroupedProductReserve } from '../hooks/useFetchReserveDashboard';
import { styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';

interface ReserveDashboardProductProps {
  products: AgroupedProductReserve[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1 / 4',
  [theme.breakpoints.down('lg')]: {
    gridColumn: '2 / 3',
  },
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 2',
    maxWidth: 'calc(100vw - 30px)',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f8eef3',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '90px 1fr 35px 100px',
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const ReserveDashboardProduct: React.FC<ReserveDashboardProductProps> = ({ products }) => {
  const [filtered, setFiltered] = useState<AgroupedProductReserve[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por produto</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cod Prod
            </Typography>
            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>
            <Typography onClick={() => handleSort('qtd')} align="right" variant="caption" fontWeight={600}>
              Qtd
            </Typography>
            <Typography onClick={() => handleSort('valor_total')} align="right" variant="caption" fontWeight={600}>
              Valor
            </Typography>
          </Row>
          {filtered.map((product, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{product.id_produto}</Typography>
              <Typography className="product-name" variant="caption">
                {product.produto}
              </Typography>
              <Typography align="right" variant="caption">
                {product.qtd}
              </Typography>
              <Typography align="right" variant="caption">
                {product.formattedTotalValue}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default ReserveDashboardProduct;
