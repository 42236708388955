import { TableTemplate } from 'types/tableTemplate';

export const replacementTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 40,
  },
  {
    id: 'comprador',
    description: 'Comprador',
    originalId: 'comprador',
    width: 110,
  },
  {
    id: 'id_produto',
    description: 'Cod Prod',
    originalId: 'id_produto',
    width: 85,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'formattedCusto',
    description: 'Custo',
    originalId: 'custo',
    width: 80,
    dataType: 'number',
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 70,
    dataType: 'quantity',
  },
  {
    id: 'pedidos',
    description: 'Pedidos',
    originalId: 'pedidos',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'reserva',
    description: 'Reserva',
    originalId: 'reserva',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'forecast',
    description: 'Forecast',
    originalId: 'forecast',
    width: 70,
    dataType: 'quantity',
  },
  {
    id: 'dias_estoque',
    description: 'Dias Est',
    originalId: 'dias_estoque',
    width: 90,
    dataType: 'quantity',
  },
  {
    id: 'sugestao',
    description: 'Sugestão',
    originalId: 'sugestao',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'previsao_chegada',
    description: 'Previsão Chegada',
    originalId: 'formattedForecastDate',
    width: 150,
  },
];
