import React from 'react';
import BranchItemModule from './BranchItemModule';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { BranchStockBalanceData } from 'types/reports/stockBalance';

type BranchListModuleProps = {
  branches: BranchStockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const BranchListModule: React.FC<BranchListModuleProps> = ({ branches }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(branch => (
        <BranchItemModule key={branch.id_filial} branch={branch} />
      ))}
    </ListStyled>
  );
};

export default BranchListModule;
