import { Search } from '@mui/icons-material';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import React from 'react';

interface Props {
  fetchCustomer: () => void;
  indexToSearch: string;
  setIndexToSearch: (index: string) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '150px 400px 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 0.5,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 100px',
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
}));

const CustomerFilterBox: React.FC<Props> = ({
  fetchCustomer,
  indexToSearch,
  searchText,
  setIndexToSearch,
  setSearchText,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={fetchCustomer}>
        <TextField value={indexToSearch} onChange={e => setIndexToSearch(e.target.value)} select label="Pesquisar em">
          <MenuItem value="nome">Nome</MenuItem>
          <MenuItem value="documento">Documento</MenuItem>
        </TextField>
        <TextField
          label="Pesquisar"
          placeholder="Pesquisar"
          autoFocus
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CustomerFilterBox;
