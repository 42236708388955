import React, { useReducer, FormEvent, useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import userReducer, { USER_INITIAL_STATE } from '../reducer/reducer';
import { userChange, setProfile } from '../reducer/actions';
import UserActions from './UserActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import UserTab from '../UserTab';
import UserProfile from './UserProfile';
import { UserProfile as UserProfileType } from 'types/userProfile';
import { useBranches } from 'providers/BranchesProviders';
import PageHeader from 'components/page-header/PageHeader';
import UserBranches from '../UserBranches';
import UserForm from '../UserForm';
import { useUserValidation } from '../validation/userValidation';
import { usePermissionRules } from 'hooks/permissionRules';
import { Profile } from 'types/profile';
import { User as UserType } from '../reducer/types';

const Form = styled('form')({
  maxWidth: 600,
  width: '100%',
  marginBottom: 200,
});

export type UserValidation = {
  username?: string;
  email?: string;
  name?: string;
  document_number?: string;
  password?: string;
  password_confirmation?: string;
};

const Content = styled('div')({
  display: 'flex',
  marginTop: 20,
  flexDirection: 'column',
  flex: 1,
});

const User: React.FC = () => {
  const [user, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserType[]>([]);
  const [tab, setTab] = useState('user');
  const messaging = useMessaging();
  const [userProfiles, setUserProfiles] = useState<UserProfileType[]>([]);
  const { branches } = useBranches();
  const [selectedBranchesId, setSelectedBranchesId] = useState<number[]>([]);
  const formattedBranches = useMemo(() => selectedBranchesId.join(','), [selectedBranchesId]);
  const [validation, setValidation, validate] = useUserValidation('new');
  const { checkPermission } = usePermissionRules();

  useEffect(() => {
    if (!branches.length) {
      return;
    }

    setSelectedBranchesId(branches.map(i => i.id));
  }, [branches]);

  useEffect(() => {
    dispatch(userChange('branches', formattedBranches));
  }, [formattedBranches]);

  useEffect(() => {
    api
      .get('/user-profiles')
      .then(response => {
        if (response.data.length > 0) {
          if (checkPermission('settings.user_control.access_rules')) {
            dispatch(setProfile(response.data[0]));
          } else {
            dispatch(setProfile({} as Profile));
          }
        }
        setUserProfiles(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [checkPermission]);

  useEffect(() => {
    api
      .get('/users', {
        params: {
          active: 1,
          term: '',
        },
      })
      .then(response => {
        setUsers(response.data);
      })
      .catch(err => console.error(err));
  }, []);

  function handleChange(index: string, value: any) {
    dispatch(userChange(index, value));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(user, users)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setLoading(true);

    const data = {
      ...user,
    };

    if (data.password === '') {
      delete data.password;
    }

    api
      .post('/users', user)
      .then(() => {
        messaging.handleOpen('Salvo');
        setLoading(false);
        history.push('/users');
      })
      .catch(err => {
        setLoading(false);
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o usuário');
      });
  }

  function handleChangeSelectedId(id: number) {
    if (selectedBranchesId.some(_id => _id === id)) {
      setSelectedBranchesId(state => state.filter(_id => _id !== id));
      return;
    }

    setSelectedBranchesId(state => [...state, id]);
  }

  return (
    <>
      <Appbar
        title="Novo usuário"
        ActionsComponent={<UserActions handleSubmit={handleValidation} />}
        Tab={<UserTab tab={tab} handleChange={value => setTab(value)} />}
        backAction={() => history.push('/users')}
      />
      <PageHeader backUrl="/users" title="Novo usuário" />

      {loading && <Loading />}

      <Content>
        {tab === 'user' ? (
          <Form onSubmit={handleValidation}>
            <UserForm type="new" handleChange={handleChange} user={user} validation={validation} />
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        ) : tab === 'branch' ? (
          <UserBranches selectedBranchesId={selectedBranchesId} handleChangeSelectedId={handleChangeSelectedId} />
        ) : (
          <UserProfile user={user} handleChange={handleChange} userProfiles={userProfiles} />
        )}
      </Content>
    </>
  );
};

export default User;
