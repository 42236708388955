import Appbar from 'components/appbar/Appbar';
import React from 'react';
import { useFetchRetailTable } from './hooks/useFetchRetailTable';
import RetailTableFilterBox from './RetailTableFilterBox';
import { useDisplayMode } from 'hooks/useDisplayMode';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import RetailTableListTable from './list/table/RetailTableListTable';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import RetailTableListModule from './list/module/RetailTableListModule';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import RetailTableAction from './RetailTableAction';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const RetailTable: React.FC = () => {
  const { handleSort, loading, orderedIndex, filter, handleChangeFilter, filteredTables, fetchTables } =
    useFetchRetailTable();
  const [displayMode] = useDisplayMode();
  return (
    <>
      <Appbar title="Tabela Varejo" />
      <PageHeaderActions
        title="Gestão da Tabela Varejo"
        ActionComponent={<RetailTableAction loading={loading} tables={filteredTables} />}
      />

      <RetailTableFilterBox
        fetchTables={fetchTables}
        loading={loading}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredTables.length === 0 ? (
        <NoData message="Tabela de preços não encontrada" />
      ) : (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <RetailTableListTable
                filteredTables={filteredTables}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
              />
            ) : (
              <RetailTableListModule tables={filteredTables} />
            )}
            <Pagination count={filteredTables.length} />
          </Container>
        </PaginationProvider>
      )}
    </>
  );
};

export default RetailTable;
