import React from 'react';
import { Typography, styled, ListItem } from '@mui/material';
import { Reserve } from 'types/reserve';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type ReserveAnalysisItemModuleProps = {
  reserve: Reserve;
};

const ReserveAnalysisItemModule: React.FC<ReserveAnalysisItemModuleProps> = ({ reserve }) => {
  return (
    <ListItemStyled>
      <Grid className="bottom">
        <Typography variant="caption">ID:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.id}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Reserva:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.id_reserva}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Pedido:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.id_pedido}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Filial:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.filial}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Cliente:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.cliente}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Cod Prod:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.id_produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Produto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Data:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.data}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Estoque:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.estoque}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Qtd:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.qtd}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Unitário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.formattedUnitValue}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {reserve.formattedTotalValue}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default ReserveAnalysisItemModule;
