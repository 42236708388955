import React, { useEffect, useState } from 'react';
import { darken, lighten, styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';

interface Props {
  suggestions: ShoppingSuggestion[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#fafafa',
  '& > p': {
    color: darken('#e3e3e3', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  padding: '2px 10px',
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  gridTemplateColumns: '120px 120px 1fr 1fr 60px 60px 60px 100px 70px',
  borderBottom: '1px solid #eee',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '80px 110px 150px 1fr 60px 60px 60px 100px 70px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '120px 120px 100px 160px 60px 60px 60px 100px 70px',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')(({ theme }) => ({
  display: 'grid',
  '& > li:nth-child(even)': {
    background: lighten(theme.palette.primary.light, 0.9),
    color: darken(theme.palette.primary.light, 0.5),
  },
}));

const CommercialAnalysisProductSuggestion: React.FC<Props> = ({ suggestions }) => {
  const [filtered, setFiltered] = useState<ShoppingSuggestion[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(suggestions);
  }, [suggestions]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Sugestão de reposição</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('comprador')} variant="caption" fontWeight={600}>
              Comprador
            </Typography>

            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography onClick={() => handleSort('subgrupo')} variant="caption" fontWeight={600}>
              Subgrupo
            </Typography>

            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>

            <Typography align="center" onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
              Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('forecast')} variant="caption" fontWeight={600}>
              Forecast
            </Typography>

            <Typography align="center" onClick={() => handleSort('pedidos')} variant="caption" fontWeight={600}>
              Pedidos
            </Typography>

            <Typography align="center" onClick={() => handleSort('dias_estoque')} variant="caption" fontWeight={600}>
              Dias Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
              Sugestão
            </Typography>
          </Row>

          {filtered.map((item, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{item.comprador}</Typography>
              <Typography variant="caption">{item.filial}</Typography>
              <Typography variant="caption">{item.subgrupo}</Typography>
              <Typography variant="caption">{item.produto}</Typography>
              <Typography variant="caption" align="center">
                {item.estoque}
              </Typography>
              <Typography variant="caption" align="center">
                {item.forecast}
              </Typography>
              <Typography variant="caption" align="center">
                {item.pedidos}
              </Typography>
              <Typography variant="caption" align="center">
                {item.dias_estoque}
              </Typography>
              <Typography variant="caption" align="center">
                {item.sugestao}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductSuggestion;
