import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { noteEntryTableTemplate } from '../../template/noteEntryTableTemplate';
import { NoteEntry } from 'types/noteEntry';
import NoteEntryItemTable from './NoteEntryItemTable';
import NoteEntryListItems from '../../dialog/NoteEntryListItems';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type NoteEntryListTableProps = {
  notes: NoteEntry[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const NoteEntryListTable: React.FC<NoteEntryListTableProps> = ({ notes, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const [selectedNote, setSelectedNote] = useState<NoteEntry | null>(null);

  function handleClick(note: NoteEntry) {
    setSelectedNote(note);
  }

  function onExited() {
    setSelectedNote(null);
  }

  return (
    <TableContainer tableTemplate={noteEntryTableTemplate}>
      {selectedNote && <NoteEntryListItems onExited={onExited} selectedNote={selectedNote} />}
      <TableContent useMinHeight={false}>
        <TableHeader>
          {noteEntryTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? `numericData` : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={10} variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={100}>
          {notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((note, index) => (
            <TableRow onClick={() => handleClick(note)} key={index}>
              <NoteEntryItemTable note={note} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default NoteEntryListTable;
