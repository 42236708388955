import React from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useFetchViews } from './hooks/useFetchViews';
import { styled } from '@mui/material';
import { useAbcUnits } from './hooks/useAbcUnits';
import Pagination from 'components/pagination/Pagination';
import { AbcUnityProvider } from './hooks/useAbcUnity';
import AbcUnityFilterBox from './AbcUnityFilterBox';
import UnityListTable from './unity/list/table/UnityListTable';
import UnityListModule from './unity/list/module/UnityListModule';
import AbcUnityDateFilter from './AbcUnityDateFilter';
import { AbcUnitsFilterParams } from './CurveAbcUnity';
import FilterMore from 'components/filter-more/FilterMore';
import AbcUnityExport from './export/AbcUnityExport';
import AbcUnityTotal from './total/AbcUnityTotal';
import InsideLoading from 'components/loading/InsideLoading';
import AbcUnityActions from './actions/AbcUnityActions';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface AbcUnityPageProps {
  filter: AbcUnitsFilterParams;
  handleChangeFilter(index: keyof AbcUnitsFilterParams, value: any): void;
}

const AbcUnityPage: React.FC<AbcUnityPageProps> = ({ filter, handleChangeFilter }) => {
  const {
    filteredUnits,
    handleSortUnity,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    orderedIndex,
    formattedTotal,
  } = useAbcUnits();

  const [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    dialogDateDesktop,
    setExcelExport,
    setDialogDate,
    setDialogDateDesktop,
  ] = useFetchViews(handleSearch, searchText);

  return (
    <AbcUnityProvider value={{ filter, handleChangeFilter, searchValue: searchText }}>
      {dialogDate && (
        <AbcUnityDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
        />
      )}

      {dialogDateDesktop && (
        <FilterMore
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDateDesktop(false)}
        />
      )}

      {excelExport && <AbcUnityExport units={filteredUnits} onExited={() => setExcelExport(false)} />}

      <Appbar
        title="Curva Abc de Unidade"
        ActionsComponent={
          <AbcUnityActions
            handleOpenExcel={() => setExcelExport(true)}
            disabled={loading}
            handleOpenDialog={() => setDialogDate(true)}
          />
        }
      />

      <AbcUnityFilterBox
        filter={filter}
        disabled={filteredUnits.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <AbcUnityTotal formattedTotal={formattedTotal} />

      {loading ? (
        <InsideLoading />
      ) : filteredUnits.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <PaginationProvider>
          <Container>
            {filteredUnits.length === 0 ? (
              <NoData message="Nenhuma unidade encontrada." />
            ) : displayMode === 'list' ? (
              <UnityListTable
                isSubgroup
                units={filteredUnits}
                handleSort={handleSortUnity}
                orderedIndex={orderedIndex}
              />
            ) : (
              <UnityListModule units={filteredUnits} />
            )}
            <Pagination count={filteredUnits.length} />
          </Container>
        </PaginationProvider>
      )}
    </AbcUnityProvider>
  );
};

export default AbcUnityPage;
