import React, { useEffect, useState } from 'react';
import useTableOrder from 'hooks/tableOrder';
import { darken, styled, Typography } from '@mui/material';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';

interface Props {
  suggestions: ShoppingSuggestion[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '20vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f1f7ef',
  padding: 10,
  '& > p': {
    color: darken('#f1f7ef', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  overflow: 'auto',
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '110px 90px 1fr 90px 90px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.7fr 0.4fr 1fr 0.5fr 0.5fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '0.7fr 0.4fr 1fr 0.5fr 0.5fr',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  padding: '0px 10px',
  display: 'grid',
});

const CommercialAnalysisSubgroupProducts: React.FC<Props> = ({ suggestions }) => {
  const [filtered, setFiltered] = useState<ShoppingSuggestion[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(suggestions.filter(item => item.sugestao > 0));
  }, [suggestions]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Sugestão de reposição</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cód Prod
            </Typography>

            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>

            <Typography onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
              Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
              Sugestão
            </Typography>
          </Row>

          {filtered.map((item, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{item.filial}</Typography>
              <Typography variant="caption">{item.id_produto}</Typography>
              <Typography className="item-name" variant="caption">
                {item.produto}
              </Typography>
              <Typography variant="caption">{item.estoque}</Typography>
              <Typography align="center" variant="caption">
                {item.sugestao}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisSubgroupProducts;
