import React from 'react';
import UnityItemTable from './UnityItemTable';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import { AbcProductUnity } from 'types/abcProduct';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { unityTableTemplate } from '../../unityTableTemplate';
import { Typography, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#eee',
  },
  headerSubgroup: {
    backgroundColor: '#d9f8e5',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type UnityListTableProps = {
  units: AbcProductUnity[];
};

const UnityListTable: React.FC<UnityListTableProps> = ({ units }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={unityTableTemplate}>
          <TableContent useMinHeight={false}>
            <TableHeader className={classes.header}>
              {unityTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                </div>
              ))}
            </TableHeader>
            <TableBody useMaxHeight maxHeight={500}>
              <div className={classes.tableRow}>
                {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
                  <TableRow style={{ minHeight: 10, padding: 0 }} key={unity.uuid}>
                    <UnityItemTable unity={unity} />
                  </TableRow>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default UnityListTable;
