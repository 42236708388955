import { darken, styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import history from 'services/history';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';

interface Props {
  suggestions: ShoppingSuggestion[];
}

interface Suggestion {
  id_produto: number;
  produto: string;
  dias: number;
  estoque: number;
  filial: string;
  forecast: number;
  qtdtemp: string;
  sobra: number;
  sobraReal: number;
  pedidos: number;
  sobraPosTransferencias: string;
  sugestaoDeTransferencia: string;
  transftmp: string;
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#fafafa',
  '& > p': {
    color: darken('#e3e3e3', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  padding: '2px 10px',
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  gridTemplateColumns: '70px 1fr 100px 90px 90px 70px 80px 80px 180px 1fr',
  borderBottom: '1px solid #eee',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '60px 1fr 100px 90px 90px 70px 80px 80px 180px 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '0.4fr 1fr 100px 70px 90px 70px 80px 80px 180px 300px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '0.4fr 1fr 100px 70px 90px 70px 80px 80px 180px 300px',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')(({ theme }) => ({
  display: 'grid',
}));

const CommercialAnalysisProductTransfer: React.FC<Props> = ({ suggestions }) => {
  const [filtered, setFiltered] = useState<Suggestion[]>([]);
  const [branches, setBranches] = useState<Suggestion[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(branches);
  }, [branches]);

  useEffect(() => {
    function transfersSuggestion(branches: Suggestion[]): Suggestion[] {
      const branchesByProducts = groupByProduct(branches);
      const result: Suggestion[] = [];

      Object.values(branchesByProducts).forEach(groupBranches => {
        const positivesBranches = groupBranches.filter(b => b.sobra > 0);
        const negativesBranches = groupBranches.filter(b => b.sobra < 0);

        negativesBranches.forEach(negative => {
          let necessaryRest = Math.abs(negative.sobra);

          while (necessaryRest > 0) {
            const biggestBranch = positivesBranches.sort((a, b) => b.sobra - a.sobra)[0];

            if (biggestBranch && biggestBranch.sobra > 0) {
              const transferencia = Math.min(biggestBranch.sobra, necessaryRest);

              const newRestLaterTransfer = biggestBranch.sobra - transferencia;
              biggestBranch.sobraPosTransferencias = newRestLaterTransfer.toFixed(2);

              negative.sugestaoDeTransferencia = `De ${biggestBranch.filial} para ${negative.filial} ${Math.round(
                parseFloat(transferencia.toFixed(2)),
              )}`;
              negative.sobraPosTransferencias = '0';

              necessaryRest -= transferencia;
              biggestBranch.sobra -= transferencia;
            } else {
              break; // Não há mais filiais positivas disponíveis
            }
          }
        });

        positivesBranches.forEach(positive => {
          if (!positive.sobraPosTransferencias) {
            positive.sobraPosTransferencias = positive.sobra.toFixed(2);
          }
        });
        result.push(...groupBranches);
      });

      return result;
    }

    const _suggestions: Suggestion[] = suggestions.map(item => ({
      id_produto: item.id_produto,
      produto: item.produto,
      dias: (item.estoque / item.forecast) * 30,
      estoque: item.estoque,
      filial: item.filial,
      forecast: parseInt(item.forecast.toString()),
      qtdtemp: '',
      pedidos: parseInt(item.pedidos.toString()),
      sobraReal:
        item.estoque + item.pedidos - item.forecast - ((item.estoque + item.pedidos - item.forecast) * 10) / 100,
      sobra: item.estoque + item.pedidos - item.forecast - ((item.estoque + item.pedidos - item.forecast) * 10) / 100,
      sobraPosTransferencias: '',
      sugestaoDeTransferencia: '',
      transftmp: '',
    }));

    const updatedBranches = transfersSuggestion(_suggestions);
    setBranches(updatedBranches);
  }, [suggestions]);

  function groupByProduct(branches: Suggestion[]): { [key: number]: Suggestion[] } {
    return branches.reduce((acc, filial) => {
      if (!acc[filial.id_produto]) {
        acc[filial.id_produto] = [];
      }
      acc[filial.id_produto].push(filial);
      return acc;
    }, {} as { [key: number]: Suggestion[] });
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Sugestão de transferência</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cod Prod
            </Typography>

            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>

            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography align="center" onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
              Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('dias')} variant="caption" fontWeight={600}>
              Dias Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('forecast')} variant="caption" fontWeight={600}>
              Forecast
            </Typography>

            <Typography align="center" onClick={() => handleSort('pedidos')} variant="caption" fontWeight={600}>
              Pedidos
            </Typography>

            <Typography align="center" onClick={() => handleSort('sobra_real')} variant="caption" fontWeight={600}>
              Sobra Real
            </Typography>

            <Typography
              align="center"
              onClick={() => handleSort('sobraPosTransferencias')}
              variant="caption"
              fontWeight={600}
            >
              Sobra Após Transferências
            </Typography>

            <Typography onClick={() => handleSort('sugestaoDeTransferencia')} variant="caption" fontWeight={600}>
              Sugestão de Transferência
            </Typography>
          </Row>

          {filtered
            .filter(item => !!item.sobraPosTransferencias)
            .filter(item => item.sobraReal !== parseFloat(item.sobraPosTransferencias))
            .map((item, index) => (
              <Row
                onClick={() => history.push(`/commercial-bi/analysis-product/${item.id_produto}`)}
                className="item"
                key={index}
              >
                <Typography variant="caption">{item.id_produto}</Typography>
                <Typography className="item-name" variant="caption">
                  {item.produto}
                </Typography>
                <Typography variant="caption">{item.filial}</Typography>
                <Typography align="center" variant="caption">
                  {item.estoque}
                </Typography>
                <Typography align="center" variant="caption">
                  {parseInt(item.dias.toString())}
                </Typography>
                <Typography align="center" variant="caption">
                  {item.forecast}
                </Typography>
                <Typography align="center" variant="caption">
                  {item.pedidos}
                </Typography>
                <Typography align="center" variant="caption">
                  {Math.round(item.sobraReal)}
                </Typography>
                <Typography align="center" variant="caption">
                  {item.sobraPosTransferencias}
                </Typography>
                <Typography variant="caption">{item.sugestaoDeTransferencia}</Typography>
              </Row>
            ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductTransfer;
