import React, { useState, useEffect } from 'react';
import Dialog from 'components/dialogs/Dialog';
import PrintData from 'components/print/PrintData';
import { Tooltip, styled } from '@mui/material';
import BankStatementReportHeader from './BankStatementReportHeader';
import { useExtracts } from '../hooks/useExtracts';
import { useApp } from 'hooks/app';
import { format, lastDayOfMonth, startOfMonth, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import InsideLoading from 'components/loading/InsideLoading';
import { TotalExtracts } from '../hooks/useFetchExtracts';
import { moneyFormat } from 'helpers/numberFormat';

const ColumnPL = styled('p')({
  fontSize: '9pt',
  textAlign: 'start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const ColumnPR = styled('p')({
  fontSize: '9pt',
  textAlign: 'end',
  '&.totals': {
    paddingTop: 5,
    borderTop: '1px solid #000',
    fontWeight: 600,
  },
});

const Row = styled('div')({
  display: 'grid',
  gridTemplateColumns: '75px 70px 180px 1fr 1fr 100px',
  marginBottom: 1,
  paddingBottom: 1,
  '&.table-header': {
    marginTop: 20,
  },
});

const Row2 = styled('div')({
  display: 'grid',
  gridTemplateColumns: '85px 180px 1fr 320px 90px',
  marginBottom: 1,
  paddingBottom: 1,
  '&.table-header': {
    marginTop: 20,
  },
});

const RowAccounts = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr',
  marginTop: 5,
  marginBottom: 1,
  paddingBottom: 1,
});

const Description = styled('p')({
  fontSize: '11pt',
  fontWeight: 600,
  marginTop: 10,
  color: '#30519f',
});

const Total = styled('p')({
  textAlign: 'end',
  fontSize: '9pt',
  gridColumn: '5/6',
  borderTop: '1px dashed #000',
  paddingTop: 3,
  fontWeight: 600,
});

interface BankStatementReportProps {
  onExited(): void;
}

const BankStatementReport: React.FC<BankStatementReportProps> = ({ onExited }) => {
  const { financialApi } = useApp();
  const { extracts: data, filter, total, groupedDataByDate, groupedDataByType } = useExtracts();
  const [resume, setResume] = useState<TotalExtracts[]>([]);
  const [loading, setLoading] = useState(false);
  const [defaultView, setDefaultView] = useState<'default' | 'date' | 'type'>('default');

  const getColor = (balance: number): string => {
    if (balance > 0) return '#018901';
    if (balance < 0) return '#d50a0a';
    return '#30519f';
  };

  useEffect(() => {
    setLoading(true);

    const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
    const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });
    const formattedLastDayOfMonth = filter.month_year
      ? format(lastDayOfMonth(filter.month_year), 'P', { locale: ptBR })
      : formattedFinalDate;

    const formattedFirstDayOfMonth = filter.month_year
      ? format(subDays(startOfMonth(filter.month_year), 1), 'P', { locale: ptBR })
      : formattedInitialDate;

    financialApi
      .get('/getextratoconsolidado', {
        params: {
          ignora_periodo: filter.ignorePeriod,
          id_filial: filter.branchId === 1001 ? '' : filter.branchId,
          id_conta: filter.accountId.join(',') || '',
          data_fim: formattedLastDayOfMonth,
          data_ini: formattedFirstDayOfMonth,
          movimentacao: filter.movimentation,
          mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
          conta_destino: filter.targetAccount,
          tipo_movimentacao: filter.type === 'DT' ? "'D','T'" : filter.type === 'TT' ? '' : `'${filter.type}'`,
        },
      })
      .then(response => {
        setResume(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  return (
    <Dialog
      fullScreen
      title="Extrato bancário"
      onExited={onExited}
      ComponentActions={
        <PrintData
          changeView={() =>
            setDefaultView(defaultView === 'type' ? 'default' : defaultView === 'default' ? 'date' : 'type')
          }
        />
      }
    >
      <div className="page">
        <BankStatementReportHeader />

        {loading ? (
          <InsideLoading />
        ) : (
          <>
            <RowAccounts className="table-header">
              <ColumnPL>Conta</ColumnPL>
              <ColumnPR>Saldo inicial</ColumnPR>
              <ColumnPR>Débitos</ColumnPR>
              <ColumnPR>Créditos</ColumnPR>
              <ColumnPR>Saldo final</ColumnPR>
              <ColumnPR>Saldo atual</ColumnPR>
            </RowAccounts>

            {resume.map((item, index) => (
              <RowAccounts key={index}>
                <ColumnPL>{item.conta}</ColumnPL>
                <ColumnPR>{moneyFormat(item.saldoinicial)}</ColumnPR>
                <ColumnPR>{moneyFormat(item.debito)}</ColumnPR>
                <ColumnPR>{moneyFormat(item.credito)}</ColumnPR>
                <ColumnPR>{moneyFormat(item.saldofinal)}</ColumnPR>
                <ColumnPR>{moneyFormat(item.saldocontaatual)}</ColumnPR>
              </RowAccounts>
            ))}

            <RowAccounts>
              <ColumnPL></ColumnPL>
              <ColumnPR style={{ color: getColor(total.saldoinicial) }} className="totals">
                {moneyFormat(total.saldoinicial)}
              </ColumnPR>
              <ColumnPR style={{ color: getColor(total.debito) }} className="totals">
                {moneyFormat(total.debito)}
              </ColumnPR>
              <ColumnPR style={{ color: getColor(total.credito) }} className="totals">
                {moneyFormat(total.credito)}
              </ColumnPR>
              <ColumnPR style={{ color: getColor(total.saldofinal) }} className="totals">
                {moneyFormat(total.saldofinal)}
              </ColumnPR>
              <ColumnPR style={{ color: getColor(total.saldocontaatual) }} className="totals">
                {moneyFormat(total.saldocontaatual)}
              </ColumnPR>
            </RowAccounts>

            {defaultView === 'default' ? (
              <>
                <Row className="table-header">
                  <ColumnPL>Data</ColumnPL>
                  <ColumnPL>Tipo</ColumnPL>
                  <ColumnPL>Conta</ColumnPL>
                  <ColumnPL>Conta destino</ColumnPL>
                  <ColumnPL>Descrição</ColumnPL>
                  <ColumnPR>Valor</ColumnPR>
                </Row>

                {data.map((item, index) => (
                  <Row key={index}>
                    <ColumnPL>{item.data}</ColumnPL>
                    <ColumnPL>{item.formattedType}</ColumnPL>
                    <ColumnPL>{item.conta}</ColumnPL>
                    <ColumnPL>{item.conta_destino}</ColumnPL>
                    <ColumnPL>{item.descricao}</ColumnPL>
                    <ColumnPR>{item.formattedValue}</ColumnPR>
                  </Row>
                ))}
              </>
            ) : defaultView === 'date' ? (
              <>
                <Row2 className="table-header">
                  <ColumnPL>Tipo</ColumnPL>
                  <ColumnPL>Conta</ColumnPL>
                  <ColumnPL>Conta destino</ColumnPL>
                  <ColumnPL>Descrição</ColumnPL>
                  <ColumnPR>Valor</ColumnPR>
                </Row2>

                {groupedDataByDate.map((item, index) => (
                  <div key={index}>
                    <Description>{item.date}</Description>

                    {item.result.map((data, index) => (
                      <Row2 key={index + groupedDataByDate.length}>
                        <ColumnPL>{data.formattedType}</ColumnPL>
                        <ColumnPL>{data.conta}</ColumnPL>
                        <Tooltip title={data.conta_destino}>
                          <ColumnPL>{data.conta_destino}</ColumnPL>
                        </Tooltip>
                        <Tooltip title={data.descricao}>
                          <ColumnPL>{data.descricao}</ColumnPL>
                        </Tooltip>
                        <ColumnPR style={{ color: getColor(data.valor) }}>{data.formattedValue}</ColumnPR>
                      </Row2>
                    ))}
                    <Row2>
                      <Total>{moneyFormat(item.total)}</Total>
                    </Row2>
                  </div>
                ))}
              </>
            ) : (
              <>
                <Row2 className="table-header">
                  <ColumnPL>ID</ColumnPL>
                  <ColumnPL>Conta</ColumnPL>
                  <ColumnPL>Conta destino</ColumnPL>
                  <ColumnPL>Descrição</ColumnPL>
                  <ColumnPR>Valor</ColumnPR>
                </Row2>

                {groupedDataByType.map((item, index) => (
                  <div key={index}>
                    <Description>{item.formattedType}</Description>

                    {item.result.map((data, index) => (
                      <Row2 key={index + groupedDataByType.length}>
                        <ColumnPL>{data.id_movimentacao}</ColumnPL>
                        <ColumnPL>{data.conta}</ColumnPL>
                        <Tooltip title={data.conta_destino}>
                          <ColumnPL>{data.conta_destino}</ColumnPL>
                        </Tooltip>
                        <Tooltip title={data.descricao}>
                          <ColumnPL>{data.descricao}</ColumnPL>
                        </Tooltip>
                        <ColumnPR style={{ color: getColor(data.valor) }}>{data.formattedValue}</ColumnPR>
                      </Row2>
                    ))}
                    <Row2>
                      <Total>{moneyFormat(item.total)}</Total>
                    </Row2>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default BankStatementReport;
