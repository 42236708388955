import { TableTemplate } from 'types/tableTemplate';

export const SalesTableTemplate: TableTemplate[] = [
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 110,
  },
  {
    id: 'origem',
    description: 'Origem',
    originalId: 'origem',
    width: 70,
  },
  {
    id: 'numero_nf',
    description: 'Nota',
    originalId: 'numero_nf',
    width: 90,
  },
  {
    id: 'modalidade',
    description: 'Entrega',
    originalId: 'modalidade',
    width: 120,
  },
  {
    id: 'lancamento',
    description: 'Lançamento',
    originalId: 'lancamento',
    width: 100,
  },
  {
    id: 'cidade',
    description: 'Cidade',
    originalId: 'cidade',
    width: 100,
  },
  {
    id: 'tipo_pessoa',
    description: 'Tipo',
    originalId: 'tipo_pessoa',
    width: 105,
  },
  {
    id: 'cnpj_cpf',
    description: 'CNPJ',
    originalId: 'cnpj_cpf',
    width: 135,
  },
  {
    id: 'pedido_por',
    description: 'Pedido por',
    originalId: 'pedido_por',
    width: 140,
  },
  {
    id: 'numero_venda',
    description: 'Nº Venda',
    originalId: 'numero_venda',
    width: 80,
  },
  {
    id: 'id_cliente',
    description: 'Cod',
    originalId: 'id_cliente',
    width: 60,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 300,
  },
  {
    id: 'atividade',
    description: 'Atividade',
    originalId: 'atividade',
    width: 180,
  },
  {
    id: 'formattedTotal',
    description: 'Vl total',
    originalId: 'vl_total',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'frete',
    description: 'Frete',
    originalId: 'frete',
    width: 50,
    dataType: 'number',
  },
  {
    id: 'mc',
    description: 'MC',
    originalId: 'mc',
    width: 60,
    dataType: 'number',
  },
  {
    id: 'formattedSpiff',
    description: 'Spiff',
    originalId: 'spiff',
    dataType: 'number',
    width: 50,
  },

  {
    id: 'formattedDiscount',
    description: 'Vl desconto',
    originalId: 'vl_desconto',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedDiscountPercent',
    description: 'Desconto (%)',
    originalId: 'perc_desconto',
    width: 90,
  },
  {
    id: 'peso',
    description: 'Peso',
    originalId: 'peso',
    width: 50,
    dataType: 'number',
  },
];
