import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type CommercialAnalysisSubgroupTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const CommercialAnalysisSubgroupTabs: React.FC<CommercialAnalysisSubgroupTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="secondary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="main" />
        <Tab label="Análise de reposição" value="suggestion" />
        <Tab label="Reserva" value="reserve" />
      </Tabs>
    </Container>
  );
};

export default CommercialAnalysisSubgroupTabs;
