import React from 'react';
import { IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DateRange } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

interface ResellerActionsProps {
  handleClick(): void;
  onClick(): void;
}

const ResellersActions: React.FC<ResellerActionsProps> = ({ handleClick, onClick }) => {
  return (
    <>
      <CustomIconButton color="inherit" onClick={handleClick}>
        <DateRange />
      </CustomIconButton>
      <CustomIconButton color="inherit" onClick={onClick}>
        <AddIcon />
      </CustomIconButton>
    </>
  );
};

export default ResellersActions;
