import { createContext, useContext } from 'react';
import { Agent } from 'types/agent';
import { H2iCustomer } from 'types/h2iCustomer';
import { Segment } from 'types/segment';

interface CustomerContextValue {
  handleValidation(): void;
  handleChange(index: keyof H2iCustomer, value: any): void;
  handleSearchAgents(): void;
  customer: H2iCustomer | null;
  segments: Segment[];
  agents: Agent[];
  registerType: 'new' | 'edit';
}

const CustomerContext = createContext<CustomerContextValue>({} as CustomerContextValue);
export const CustomerProvider = CustomerContext.Provider;
export const CustomerConsumer = CustomerContext.Consumer;

export function useCustomer(): CustomerContextValue {
  const context = useContext(CustomerContext);
  return context;
}
