import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import { useCallback, useState, FormEvent, useEffect } from 'react';
import { RetailTable } from 'types/retailTable';

export interface RetailTableFilterProps {
  product: string;
  hasStock: number;
}

export function useFetchRetailTable() {
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const { h2iApi } = useApp();
  const [filter, setFilter] = useState<RetailTableFilterProps>({
    hasStock: 0,
    product: '',
  });
  const [tables, setTables] = useState<RetailTable[]>([]);
  const [filteredTables, setFilteredTables] = useState<RetailTable[]>([]);

  useEffect(() => {
    setFilteredTables(tables);
  }, [tables]);

  const fetchTables = useCallback(
    (filter: RetailTableFilterProps, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;

      setLoading(true);
      const productId = parseInt(filter.product);

      h2iApi
        .get(`/api/gettabelavarejo`, {
          params: {
            produto: productId.toString().length !== filter.product.length ? filter.product : '',
            id_produto: productId.toString().length !== filter.product.length ? '' : productId,
            estoque: filter.hasStock,
          },
        })
        .then(response => {
          if (response.data.RESULT) {
            setTables([]);
            return;
          }

          setTables(
            response.data.map(item => ({
              ...item,
              formattedPrice2: moneyFormat(item.preco2),
              formattedIntelbrasCode: parseInt(item.codigo_intelbras),
            })),
          );
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi],
  );

  function handleSort(index: string) {
    const filtered = sort(index, filteredTables);
    setFilteredTables(filtered);
  }

  function handleChangeFilter(index: keyof RetailTableFilterProps, value: any) {
    setFilter(prev => ({
      ...prev,
      [index]: value,
    }));
  }

  return {
    handleSort,
    loading,
    orderedIndex,
    filter,
    handleChangeFilter,
    filteredTables,
    fetchTables,
  };
}
