import { moneyFormat } from 'helpers/numberFormat';
import { Rma } from 'types/rma';

export interface AgroupedManufacturerRma {
  fabricante: string;
  qtd: number;
  valor: number;
  formattedValue: string;
}

export interface AgroupedBranchRma {
  filial: string;
  qtd: number;
  valor: number;
  formattedValue: string;
}

export interface AgroupedBuyerRma {
  comprador: string;
  qtd: number;
  valor: number;
  formattedValue: string;
}

export interface AgroupedProductRma {
  id_produto: number;
  produto: string;
  qtd: number;
  valor: number;
  subgrupo: string;
  comprador: string;
  formattedValue: string;
}

export interface AgroupedSubgroupRma {
  subgrupo: string;
  comprador: string;
  qtd: number;
  valor: number;
  formattedValue: string;
}

export function useFetchRmaDashboard(items: Rma[]) {
  function groupByProduct(data: Rma[]): AgroupedProductRma[] {
    const productMap = new Map();

    data.forEach(_item => {
      if (!productMap.has(_item.id_produto)) {
        productMap.set(_item.id_produto, {
          id_produto: _item.id_produto,
          produto: _item.produto,
          comprador: _item.comprador,
          subgrupo: _item.subgrupo,
          valor: 0,
          qtd: 0,
          formattedValue: 'R$ 0,00',
        });
      }
      const item = productMap.get(_item.id_produto);
      item.valor += _item.valor;
      item.qtd += _item.formattedQuantity;
      item.formattedValue = moneyFormat(item.valor);
    });

    return Array.from(productMap.values());
  }

  function groupByManufacturer(data: Rma[]): AgroupedManufacturerRma[] {
    const manufacturerMap = new Map();

    data.forEach(_item => {
      if (!manufacturerMap.has(_item.fabricante)) {
        manufacturerMap.set(_item.fabricante, {
          fabricante: _item.fabricante,
          valor: 0,
          qtd: 0,
          formattedValue: 'R$ 0,00',
        });
      }
      const item = manufacturerMap.get(_item.fabricante);
      item.valor += _item.valor;
      item.qtd += _item.formattedQuantity;
      item.formattedValue = moneyFormat(item.valor);
    });

    return Array.from(manufacturerMap.values());
  }

  function groupByBranch(data: Rma[]): AgroupedBranchRma[] {
    const branchMap = new Map();

    data.forEach(_item => {
      if (!branchMap.has(_item.filial)) {
        branchMap.set(_item.filial, {
          filial: _item.filial,
          valor: 0,
          qtd: 0,
          formattedValue: 'R$ 0,00',
        });
      }
      const item = branchMap.get(_item.filial);
      item.valor += _item.valor;
      item.qtd += _item.formattedQuantity;
      item.formattedValue = moneyFormat(item.valor);
    });

    return Array.from(branchMap.values());
  }

  function groupByBuyer(data: Rma[]): AgroupedBuyerRma[] {
    const buyerMap = new Map();

    data.forEach(_item => {
      if (!buyerMap.has(_item.comprador)) {
        buyerMap.set(_item.comprador, {
          comprador: _item.comprador,
          valor: 0,
          qtd: 0,
          formattedValue: 'R$ 0,00',
        });
      }
      const item = buyerMap.get(_item.comprador);
      item.valor += _item.valor;
      item.qtd += _item.formattedQuantity;
      item.formattedValue = moneyFormat(item.valor);
    });

    return Array.from(buyerMap.values());
  }

  function groupBySubgroup(data: Rma[]): AgroupedSubgroupRma[] {
    const subgroupMap = new Map();

    data.forEach(_item => {
      if (!subgroupMap.has(_item.subgrupo)) {
        subgroupMap.set(_item.subgrupo, {
          subgrupo: _item.subgrupo,
          comprador: _item.comprador,
          valor: 0,
          qtd: 0,
          formattedValue: 'R$ 0,00',
        });
      }
      const item = subgroupMap.get(_item.subgrupo);
      item.valor += _item.valor;
      item.qtd += _item.formattedQuantity;
      item.formattedValue = moneyFormat(item.valor);
    });

    return Array.from(subgroupMap.values());
  }

  const groupedSubgroups = groupBySubgroup(items).sort((a, b) => b.valor - a.valor);
  const groupedProducts = groupByProduct(items).sort((a, b) => b.valor - a.valor);
  const groupedManufacturers = groupByManufacturer(items).sort((a, b) => b.valor - a.valor);
  const groupedBranches = groupByBranch(items).sort((a, b) => b.valor - a.valor);
  const groupedBuyers = groupByBuyer(items).sort((a, b) => b.valor - a.valor);

  return {
    groupedManufacturers,
    groupedBranches,
    groupedBuyers,
    groupedProducts,
    groupedSubgroups,
  };
}
