import React from 'react';
import { Button, styled } from '@mui/material';
import { useExtracts } from './hooks/useExtracts';

const Container = styled('div')(({ theme }) => ({
  gap: 7,
  display: 'flex',
  alignItems: 'center',
  '& > button': {
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PageFooterButtons: React.FC = () => {
  const { setAppropriationDialog, filter, selectedExtracts, setPaymentDialog } = useExtracts();

  return (
    <Container>
      {filter.branchId !== 1001 && selectedExtracts.length > 0 && (
        <>
          <Button onClick={() => setPaymentDialog(true)} variant="contained" color="secondary" size="small">
            Informar pagamento
          </Button>
          <Button onClick={() => setAppropriationDialog(true)} variant="contained" color="error" size="small">
            Apropriar selecionados
          </Button>
        </>
      )}
    </Container>
  );
};

export default PageFooterButtons;
