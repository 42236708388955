import Dialog from 'components/dialogs/Dialog';
import React, { useEffect, useMemo, useState } from 'react';
import { NewInvoiceProvider } from '../hooks/useNewInvoice';
import { useInvoiceReducer } from '../reducer/reduce';
import NewInvoiceForm from '../form/NewInvoiceForm';
import { useNewInvoiceValidation } from '../validation/newInvoiceValidation';
import NewInvoiceActions from '../NewInvoiceActions';
import InsideSaving from 'components/loading/InsideSaving';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import { NewInvoiceFavored } from '../reducer/types';
import { invoiceChange, setInvoice } from '../reducer/actions';
import { useFetchInvoice } from './hooks/useFetchInvoice';
import InsideLoading from 'components/loading/InsideLoading';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TotalizingAccount } from 'types/totalizingAccount';
import { useSelector } from 'store/selector';
import { invoiceColors, invoiceTypes } from '../../Invoices';
import { styled } from '@mui/material';
import EditInvoiceTabs from './EditInvoiceTabs';
import InvoicePaymentList from '../payment/InvoicePaymentList';

interface EditInvoiceProps {
  onExited(): void;
  handleUpdateList(): void;
  id: number;
  type: string;
}

const Container = styled('div')({
  padding: 5,
});

const EditInvoice: React.FC<EditInvoiceProps> = ({ onExited, type, id, handleUpdateList }) => {
  const user = useSelector(state => state.user);
  const [invoice, dispatch] = useInvoiceReducer();
  const [validation, setValidation, validate] = useNewInvoiceValidation();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState('data');
  const { financialApi } = useApp();
  const [favored, setFavored] = useState<NewInvoiceFavored | null>(null);
  const [loadedInvoice, loading, invoicePayments, fetch] = useFetchInvoice(id);
  const [accounts, setAccounts] = useState<TotalizingAccount[]>([]);

  useEffect(() => {
    if (!invoice.costCenterId) {
      return;
    }

    financialApi
      .get('/getcontastotalizadoras', {
        params: {
          id_filial: invoice.costCenterId,
          filtro: '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setAccounts([]);
          return;
        }
        setAccounts(
          response.data.map(item => {
            item.formattedSaldo = moneyFormat(item.saldo);
            item.data = new Date(parsePtBRDate(item.data_inicio));
            return item;
          }),
        );
      })
      .catch(err => console.error(err));
  }, [financialApi, invoice.costCenterId]);

  const endpoint = useMemo(() => {
    const endpoints = {
      T: `getcadastrofornecedores?`,
      D: `getcadastrodespesas?id_filial=${invoice.branchId}&id=${invoice.favoredId}`,
      R: `getcadastroreceitas?id_filial=${invoice.branchId}&id=${invoice.favoredId}`,
      F: `getcadastrofuncionarios?id_filial=${invoice.branchId}&id=${invoice.favoredId}`,
    };

    return endpoints[invoice.type];
  }, [invoice.type, invoice.branchId, invoice.favoredId]);

  useEffect(() => {
    if (!invoice.favoredId) {
      return;
    }

    financialApi
      ?.get(`${endpoint}`)
      .then(response => {
        if (Array.isArray(response.data) && response.data.length === 1) {
          setFavored(response.data[0]);
          return;
        }

        setFavored(null);
      })
      .catch(err => console.error(err));
  }, [financialApi, endpoint, invoice.favoredId]);

  useEffect(() => {
    if (!loadedInvoice) {
      return;
    }

    dispatch(setInvoice(loadedInvoice));
  }, [loadedInvoice, dispatch]);

  useEffect(() => {
    dispatch(invoiceChange('favoredId', favored ? favored.id : null));
  }, [favored, dispatch]);

  useEffect(() => {
    setFavored(null);
  }, [invoice.branchId, invoice.type]);

  async function handleValidation() {
    setValidation({});

    await validate(invoice, favored?.tipo_valores ?? 'M');
    await handleSubmit();
  }

  async function handleSubmit() {
    if (!user) {
      return;
    }

    if (!financialApi) {
      return;
    }

    try {
      setSaving(true);

      const data = {
        usuario: user.name,
        id_filial: invoice.branchId,
        id_favorecido: invoice.favoredId,
        id_centro_custo: invoice.costCenterId === 999 ? '' : invoice.costCenterId,
        id_conta_totalizadora: invoice.totalizingAccountId === 999 ? '' : invoice.totalizingAccountId,
        titulo: invoice.invoiceDescription,
        tipo: type === 'TT' ? invoice.type : type,
        vencimento: invoice.payday ? format(invoice.payday, 'dd/MM/yyyy') : '',
        valor: invoice.value,
        mes_ano: invoice.monthYear ? format(invoice.monthYear, 'MMyy') : '',
        numero_nf: invoice.invoiceNumber,
        chave_nf: invoice.invoiceKey,
        data_apropriacao: invoice.apropriatedAt ? format(invoice.apropriatedAt, 'dd/MM/yyyy') : '',
        data_pagamento: invoice.paidAt ? format(invoice.paidAt, 'dd/MM/yyyy') : '',
        sem_boleto: invoice.noBankBillet ? 'S' : 'N',
        credito_rma: invoice.rmaCredit ? 'S' : 'N',
        nota_validada: invoice.validatedInvoice ? 'S' : 'N',
        transportadora: invoice.isShippingCompany ? 'S' : 'N',
        id: invoice.id,
      };

      await financialApi.post('/savetitulo', data);

      setSaving(false);

      handleUpdateList();
    } finally {
      setSaving(false);
    }
  }

  return (
    <NewInvoiceProvider value={{ invoice, accounts, dispatch, favored, setFavored }}>
      <Dialog
        maxWidth="md"
        onExited={onExited}
        title={`Editar ${invoiceTypes[type].toLowerCase()}`}
        headerbackground="#fff"
        titleColor={invoiceColors[type]}
        tabStyle={{ paddingLeft: 0 }}
        Tab={<EditInvoiceTabs type={type} tab={tab} onChange={value => setTab(value)} />}
        ComponentActions={
          <NewInvoiceActions
            type={type}
            apropriatedAt={loadedInvoice?.apropriado !== 'N'}
            handleValidation={handleValidation}
          />
        }
      >
        {saving && <InsideSaving />}

        <Container>
          {loading || !invoice.id ? (
            <InsideLoading />
          ) : tab === 'data' ? (
            <NewInvoiceForm type={type} validation={validation} />
          ) : (
            <InvoicePaymentList fetch={fetch} invoicePayments={invoicePayments} selectedInvoice={loadedInvoice} />
          )}
        </Container>
      </Dialog>
    </NewInvoiceProvider>
  );
};

export default EditInvoice;
