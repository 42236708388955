import React, { useEffect, useState } from 'react';
import { TextField, Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { api } from 'services/api';
import { usePurchase } from '../../../hooks/usePurchase';
import InsideSaving from 'components/loading/InsideSaving';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useMessaging } from 'hooks/messaging';
import { useApp } from 'hooks/app';
import { PurchaseOrder } from 'types/purchaseOrder';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import PurchaseOrderListProducts, { PurchaseOrderProductWithEan } from './list-product/PurchaseOrderListProducts';
import InsideLoading from 'components/loading/InsideLoading';
import PurchaseOrderConfirmReceiptAction from './PurchaseOrderConfirmReceiptAction';

interface PurchaseOrderConfirmReceiptProps {
  onExited(): void;
  onSave(): void;
}

const Container = styled('div')({
  rowGap: 20,
  padding: '20px 10px',
  flex: 1,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const PurchaseOrderConfirmReceipt: React.FC<PurchaseOrderConfirmReceiptProps> = ({ onSave, onExited }) => {
  const [observation, setObservation] = useState('');
  const { showErrorDialog } = useErrorHandler();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedOrder } = usePurchase();
  const [order, setOrder] = useState<PurchaseOrder | null>(null);
  const { handleOpen } = useMessaging();
  const { h2iApi } = useApp();
  const [products, setProducts] = useState<PurchaseOrderProductWithEan[]>([]);

  function handleChange(productId: number, eans: string[]) {
    const _productIndex = products.findIndex(item => item.codigo_produto === productId);
    const _products = [...products];

    _products[_productIndex].eans = eans;

    setProducts(products);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }
    if (!selectedOrder) {
      return;
    }

    h2iApi
      .get(`/api/getpedidocompras?id=${selectedOrder.codigo_pedido}`)
      .then(response => {
        if (response.data.MESSAGE) {
          setOrder(null);
          return;
        }
        setOrder({
          ...response.data[0],
          formattedActualDate: new Date().toISOString(),
          formattedForecast: response.data[0].previsao && parsePtBRDate(response.data[0].previsao).toISOString(),
          formattedNewForecast: parsePtBRDate(response.data[0].nova_previsao).toISOString(),
          formattedDate: response.data[0].data && parsePtBRDate(response.data[0].data).toISOString(),
          formattedDateIssue:
            response.data[0].data_emissao_nfe && parsePtBRDate(response.data[0].data_emissao_nfe).toISOString(),
          formattedTotal: moneyFormat(response.data[0].valor_total_pedido),
          formattedShipment: moneyFormat(response.data[0].valor_frete),
          formattedIpi: moneyFormat(response.data[0].valor_ipi),
          formattedSt: moneyFormat(response.data[0].valor_st),
          formattedExpense: moneyFormat(response.data[0].valor_despesa),
          formattedDiscount: moneyFormat(response.data[0].valor_desconto),
        });

        setProducts(
          response.data[0].itens.map(product => ({
            ...product,
            eans: [],
          })),
        );
      })
      .catch(() => setOrder(null))
      .finally(() => setLoading(false));
  }, [selectedOrder, h2iApi]);

  function emailBodyMessage(): string {
    const unconfirmedProducts = products.filter(product => {
      return product.eans.some(ean => ean !== product.codigo_barra);
    });

    console.log(unconfirmedProducts);

    if (unconfirmedProducts.length > 0) {
      const productMessage = unconfirmedProducts.map(product =>
        product.eans
          .filter(ean => ean !== product.codigo_barra)
          .map(
            item =>
              `<p style="margin: 0px"><b>EAN Cadastro:</b> ${item}</p>  <p style="margin: 0px"><b>Cod Prod:</b> ${product.codigo_produto}</p> <p style="margin: 0px"><b>Produto:</b> ${product.produto}</p>  <p style="margin: 0px"><b>Qtd:</b> ${product.quantidade}</p>  <p style="margin: 0px"><b>EAN Físico:</b> ${product.codigo_barra}</p> <br />`,
          ),
      );
      return `Os produtos abaixo não tiveram seu EAN CONFIRMADOS FISICAMENTE.  ${productMessage} <p style="margin: 0px">${observation}</p>`;
    }

    return observation;
  }

  function handleSave() {
    if (!order) {
      return;
    }
    setSaving(false);
    const emails = [
      'compras@h2i.com.br',
      'compras2@h2i.com.br',
      'compras3@h2i.com.br',
      'compras4@h2i.com.br',
      'compras5@h2i.com.br',
      'compras6@h2i.com.br',
      'compras7@h2i.com.br',
      'compras8@h2i.com.br',
      'compras9@h2i.com.br',
      'alessandra@h2i.com.br',
      'elita@h2i.com.br',
      'helio@h2i.com.br',
      'junior@h2i.com.br',
      'exp.poc@h2i.com.br',
      'exp.lf@h2i.com.br',
      'ruicaribe@gmail.com',
      'ruy@h2i.com.br',
    ];

    const params = {
      email: emails,
      company_name: order.filial,
      street: 'Rua',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'Estado',
      postal_code: 'CEP',
      phone: 'Telefone',
      order_id: order.codigo_pedido,
      invoice_number: order.notafiscal,
      observation: emailBodyMessage(),
      supplier: order.cliente,
      order_link: `https://www.grupoh2i.com.br/purchasing/registration/print/${order.codigo_pedido}`,
    };

    api
      .post('/received-load-email', params)
      .then(() => {
        onSave();
        handleOpen('Confirmação enviada por e-mail');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível confirmar o recebimento',
        });
      })
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      title="Deseja realmente confirmar recebimento?"
      maxWidth="md"
      onExited={onExited}
      disableEscapeKeyDown
      disableBackdropClick
      ComponentActions={<PurchaseOrderConfirmReceiptAction onClick={handleSave} />}
    >
      <Container>
        {loading && <InsideLoading />}
        {saving && <InsideSaving />}
        {products.length > 0 ? (
          <PurchaseOrderListProducts handleChange={handleChange} products={products} />
        ) : (
          <Typography align="center" variant="caption">
            {':('} Nenhum produto encontrado
          </Typography>
        )}

        <TextField
          fullWidth
          multiline
          minRows={4}
          value={observation}
          onChange={e => setObservation(e.target.value)}
          label="Observação"
        />
      </Container>
    </Dialog>
  );
};

export default PurchaseOrderConfirmReceipt;
