import { createContext, useContext } from 'react';
import { CommercialBiMainFilterProps } from '../CommercialBi';
import { PaymentMethod } from 'types/salesReport';
import { Sales, SalesItem } from 'types/reports/sales';
import { StockBalanceData } from 'types/reports/stockBalance';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';

export interface CommercialBiContextValue {
  isFiltersLoading: boolean;
  mainFilter: CommercialBiMainFilterProps;
  handleMainFilterChange(index: keyof CommercialBiMainFilterProps, value: any): void;
  loading: boolean;
  paymentMethods: PaymentMethod[];
  sales: Sales[];
  salesItems: SalesItem[];
  stockItems: StockBalanceData[];
  orders: PurchaseOrderItem[];
  fetch(filter: CommercialBiMainFilterProps): void;
}

const Context = createContext<CommercialBiContextValue>({} as CommercialBiContextValue);
export const CommercialBiProvider = Context.Provider;

export function useCommercialBi(): CommercialBiContextValue {
  return useContext(Context);
}
