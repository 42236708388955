import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { PaymentMethod } from 'types/salesReport';
import { paymentTableTemplate } from '../template/paymentTableTemplate';
import DailySummaryPaymentItemTable from './DailySummaryPaymentItemTable';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type DailySummaryPaymentListTableProps = {
  paymentMethods: PaymentMethod[];
  handleSort(index: string): void;
};

const DailySummaryPaymentListTable: React.FC<DailySummaryPaymentListTableProps> = ({ paymentMethods, handleSort }) => {
  return (
    <TableContainer tableTemplate={paymentTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {paymentTableTemplate.map(item => (
            <HeaderItem
              className={
                item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={12} variant="caption" color="primary">
                {item.description}
              </Typography>
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={false}>
          {paymentMethods.map((method, index) => (
            <TableRow style={{ minHeight: 30 }} key={index}>
              <DailySummaryPaymentItemTable method={method} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default DailySummaryPaymentListTable;
