import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface VacancyActionsProps {
  handleSubmit(): void;
}

const VacancyActions: React.FC<VacancyActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default VacancyActions;
