import React, { ReactElement, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import AbcProductTotal from './AbcProductTotal';
import { useFetchViews } from './hooks/useFetchViews';
import { useAbcProducts } from './hooks/useAbcProducts';
import AbcProductFilterBox from './AbcProductFilterBox';
import AbcProductDateFilter from './AbcProductDateFilter';
import SelectedBranch from './modal/branch/SelectedBranch';
import { AbcProductProvider } from './hooks/useAbcProduct';
import ReportLoading from 'components/loading/ReportLoading';
import SelectedSale from 'components/sale/SelectedSale';
import SelectedSegment from './segment/SelectedSegment';
import FilterMorePage, { FilterMore } from 'components/filter-more/FilterMore';
import AbcProductBranches from './branch/AbcProductBranches';
import AbcProductCustomers from './customer/AbcProductCustomers';
import AbcProductSellers from './seller/AbcProductSellers';
import AbcProductManufacturers from './manufacturer/AbcProductManufacturers';
import AbcProductSubgroups from './subgroup/AbcProductSubgroups';
import AbcProductSegments from './segment/AbcProductSegments';
import AbcProductUnits from './unity/AbcProductUnits';
import AbcProductItems from './list/AbcProductItems';
import AbcProductExport from './export/AbcProductExport';
import AbcProductTabs, { AbProductTabOptions } from './AbcProductTabs';
import AbcProductActions from './actions/AbcProductActions';

export type AbcProductFilterDates = {
  initialDate: Date | null;
  finalDate: Date | null;
  initialDateToCompare: Date | null;
  finalDateToCompare: Date | null;
  formattedInitialDate: string;
  formattedFinalDate: string;
  formattedInitialDateToCompare: string;
  formattedFinalDateToCompare: string;
};

export interface AbcProductFilterParams extends FilterMore {
  dates: AbcProductFilterDates;
  selectedBranchId: number;
  productId: number;
  typeSale: string;
}

interface AbcProductPageProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleDatesFilterChange(index: keyof AbcProductFilterDates, value: any): void;
}

const AbcProductPage: React.FC<AbcProductPageProps> = ({ filter, handleChangeFilter, handleDatesFilterChange }) => {
  const {
    setSelectedSale,
    selectedSale,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    data,
  } = useAbcProducts();
  const [tabOption, setTabOption] = useState<AbProductTabOptions>('branch');

  const {
    setOpenModalSegment,
    setOpenModalBranch,
    setExcelExport,
    setDialogDateDesktop,
    openModalSegment,
    openModalBranch,
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDateDesktop,
    dialogDate,
    setDialogDate,
    excelExport,
  } = useFetchViews(handleSearch, searchText);

  function renderTabOption(): ReactElement {
    const map = new Map();

    map.set('branch', <AbcProductBranches />);
    map.set('product', <AbcProductItems />);
    map.set('unit', <AbcProductUnits />);
    map.set('segment', <AbcProductSegments />);
    map.set('subgroup', <AbcProductSubgroups />);
    map.set('manufacturer', <AbcProductManufacturers />);
    map.set('seller', <AbcProductSellers />);
    map.set('customer', <AbcProductCustomers />);

    return map.get(tabOption);
  }

  return (
    <AbcProductProvider
      value={{
        filter,
        handleChangeFilter,
        searchValue: searchText,
        setOpenModalBranch,
        setOpenModalSegment,
        handleDatesFilterChange,
      }}
    >
      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}
      {openModalBranch && <SelectedBranch onExited={() => setOpenModalBranch(false)} />}
      {openModalSegment && <SelectedSegment onExited={() => setOpenModalSegment(false)} />}

      {dialogDate && (
        <AbcProductDateFilter
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          handleDatesFilterChange={handleDatesFilterChange}
        />
      )}

      {dialogDateDesktop && (
        <FilterMorePage
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          onExited={() => setDialogDateDesktop(false)}
          handleChangeFilter={handleChangeFilter}
        />
      )}

      {excelExport && <AbcProductExport onExited={() => setExcelExport(false)} />}

      <Appbar
        title="Curva Abc de Produtos"
        ActionsComponent={
          <AbcProductActions
            handleOpenExcel={() => setExcelExport(true)}
            disabled={loading}
            handleOpenDialog={() => setDialogDate(true)}
          />
        }
      />

      <AbcProductFilterBox
        filter={filter}
        disabled={data.products.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
        handleDatesFilterChange={handleDatesFilterChange}
      />

      {loading ? (
        <ReportLoading displayMode={displayMode} />
      ) : data.products.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <div>
          <AbcProductTotal loading={loading} formattedTotal={data.total} />

          <AbcProductTabs value={tabOption} onTabChange={value => setTabOption(value)} />

          {renderTabOption()}
        </div>
      )}
    </AbcProductProvider>
  );
};

export default AbcProductPage;
