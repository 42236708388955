import { Checkbox, FormControlLabel, IconButton, TextField, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useCustomerNewAgentValidation } from './useCustomerNewAgentValidation';
import CpfInput from 'components/masked-input/CpfInput';
import { Done } from '@mui/icons-material';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import { postalCodeSearch } from 'services/postalCodeSearch';
import PhoneInput from 'components/masked-input/PhoneInput';
import MaskedInput from 'react-text-mask';
import { useApp } from 'hooks/app';
import InsideSaving from 'components/loading/InsideSaving';
import { useCustomer } from '../../hooks/useCustomer';
import { useMessaging } from 'hooks/messaging';
import { CustomerNewAgent } from './CustomerNewAgentDialog';
import { Agent } from 'types/agent';

interface CustomerNewAgentProps {
  onExited(): void;
  onSave(): void;
  selectedAgent: Agent;
}

const Form = styled('form')({
  gap: 15,
  display: 'flex',
  padding: '10px 0',
  flexDirection: 'column',
});

let timer: NodeJS.Timeout;

const CustomerEditAgentDialog: React.FC<CustomerNewAgentProps> = ({ selectedAgent, onExited, onSave }) => {
  const [validation, setValidation, validate] = useCustomerNewAgentValidation();
  const { customer } = useCustomer();
  const [agent, setAgent] = useState<CustomerNewAgent>({
    bairro: selectedAgent.bairro,
    cep: selectedAgent.cep,
    cidade: selectedAgent.cidade,
    cnpj_cpf_cadastro: customer ? customer.cnpj_cpf : '',
    cpf: selectedAgent.cpf,
    endereco: selectedAgent.endereco,
    nome: selectedAgent.nome,
    numero_endereco: parseInt(selectedAgent.numero_endereco),
    rg: selectedAgent.rg,
    socio: selectedAgent.socio,
    telefone: selectedAgent.telefone,
    uf: selectedAgent.uf,
    codigo_cliente: customer ? customer.codigo : 0,
  });
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);

  const inputs = {
    nome: useRef<HTMLInputElement>(null),
    rg: useRef<HTMLInputElement>(null),
    cep: useRef<HTMLInputElement>(null),
    endereco: useRef<HTMLInputElement>(null),
    cidade: useRef<HTMLInputElement>(null),
    bairro: useRef<HTMLInputElement>(null),
    uf: useRef<HTMLInputElement>(null),
    cpf: useRef<MaskedInput>(null),
    numero_endereco: useRef<HTMLInputElement>(null),
    telefone: useRef<MaskedInput>(null),
  };

  function handlePostalCodeChange(value: string) {
    handleChange('cep', value);
    handleChange('uf', '');
    handleChange('cidade', '');
    handleChange('bairro', '');
    handleChange('numero_endereco', '');
    handleChange('endereco', '');

    const postalCodeSanitized = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (postalCodeSanitized.length < 2) {
      return;
    }

    timer = setTimeout(() => {
      postalCodeSearch(postalCodeSanitized)
        .then(response => {
          if (response.data.erro) {
            return;
          }
          const { data } = response;

          handleChange('cidade', data.localidade);
          handleChange('uf', data.uf);
          handleChange('endereco', data.logradouro);
          handleChange('bairro', data.bairro);
        })
        .catch(err => {
          console.error(err);
        });
    }, 500);
  }

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    if (key === 'cpf' || key === 'telefone') {
      inputs[key].current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleChange(index: string, value: any) {
    setAgent(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(agent)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (!h2iApi) return;

    setSaving(true);

    h2iApi
      .post('/api/savepreposto', agent)
      .then(response => {
        if (!response.data.Result) {
          handleOpen(response.data.Value);
          return;
        }
        onSave();
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      disableEscapeKeyDown
      disableBackdropClick
      title="Adicionar preposto"
      hideBackdrop
      maxWidth="sm"
      onExited={onExited}
      ComponentActions={
        <IconButton onClick={() => handleValidation()} color="inherit">
          <Done />
        </IconButton>
      }
    >
      {saving && <InsideSaving />}
      <Form onSubmit={handleValidation}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agent.socio === 'S'}
              onChange={e => handleChange('socio', e.target.checked ? 'S' : 'N')}
              color="primary"
            />
          }
          label="Sócio"
        />

        <TextField
          autoFocus
          label="Nome"
          placeholder="Digite seu nome"
          onChange={e => handleChange('nome', e.target.value)}
          value={agent.nome}
          helperText={validation.nome}
          error={!!validation.nome}
          ref={inputs.nome}
        />

        <TextField
          autoFocus
          label="CPF"
          placeholder="Digite seu CPF"
          onChange={e => handleChange('cpf', e.target.value)}
          value={agent.cpf}
          helperText={validation.cpf}
          error={!!validation.cpf}
          inputMode="numeric"
          InputProps={{
            inputComponent: CpfInput as any,
          }}
          inputRef={inputs.cpf}
        />

        <TextField
          autoFocus
          label="RG"
          placeholder="Digite seu RG"
          onChange={e => handleChange('rg', e.target.value)}
          value={agent.rg}
          helperText={validation.rg}
          error={!!validation.rg}
          ref={inputs.rg}
        />

        <TextField
          inputRef={inputs.telefone}
          error={!!validation.telefone}
          helperText={validation.telefone}
          label="Telefone"
          placeholder="Telefone do preposto"
          value={agent.telefone}
          onChange={e => handleChange('telefone', e.target.value)}
          type="phone"
          autoComplete="phone"
          InputProps={{
            inputComponent: PhoneInput as any,
          }}
          fullWidth
        />

        <TextField
          autoFocus
          error={!!validation.cep}
          helperText={validation.cep}
          label="CEP"
          placeholder="Informe o CEP"
          value={agent.cep}
          onChange={e => handlePostalCodeChange(e.target.value)}
          fullWidth
          InputProps={{
            inputComponent: PostalCodeInput as any,
          }}
          inputRef={inputs.cep}
        />

        {agent.uf && (
          <>
            <TextField
              inputRef={inputs.endereco}
              placeholder="Endereço"
              label="Endereço"
              value={agent.endereco}
              onChange={e => handleChange('endereco', e.target.value)}
              error={!!validation.endereco}
              helperText={validation.endereco}
            />
            <TextField
              inputRef={inputs.numero_endereco}
              placeholder="Número"
              label="Número"
              value={agent.numero_endereco}
              onChange={e => handleChange('numero_endereco', e.target.value)}
              error={!!validation.numero_endereco}
              helperText={validation.numero_endereco}
              inputMode="numeric"
              type="number"
              inputProps={{
                maxLength: 20,
              }}
            />
            <TextField
              inputRef={inputs.bairro}
              placeholder="Bairro"
              label="Bairro"
              value={agent.bairro}
              onChange={e => handleChange('bairro', e.target.value)}
              error={!!validation.bairro}
              helperText={validation.bairro}
            />
            <TextField
              inputRef={inputs.cidade}
              placeholder="Cidade"
              label="Cidade"
              disabled
              value={agent.cidade}
              error={!!validation.cidade}
              helperText={validation.cidade}
              onChange={e => handleChange('cidade', e.target.value)}
            />
            <TextField
              inputRef={inputs.uf}
              placeholder="Estado"
              label="Estado"
              value={agent.uf}
              error={!!validation.uf}
              helperText={validation.uf}
              onChange={e => handleChange('uf', e.target.value)}
              disabled
            />
          </>
        )}

        <button type="submit" style={{ display: 'none' }}></button>
      </Form>
    </Dialog>
  );
};

export default CustomerEditAgentDialog;
