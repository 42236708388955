import React, { MouseEvent, useEffect, useState } from 'react';
import NoData from 'components/no-data/NoData';
import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import { Rma } from 'types/rma';
import { AgroupedProductRma, useFetchRmaDashboard } from '../dashboard/hooks/useFetchRmaDashboard';
import useTableOrder from 'hooks/tableOrder';
import { BarChart } from '@mui/icons-material';

interface RmaAnalysisProductProps {
  items: Rma[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1/4',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f8eef3',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
  },
  '& > span': {
    padding: 5,
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  '& > span:not(:last-child)': {
    borderRight: '1px solid #eee',
  },
  '& > button': {
    padding: 0,
  },
  display: 'grid',
  borderBottom: '1px solid #eee',
  alignItems: 'center',
  gridTemplateColumns: '40px 360px 200px 110px 70px 130px',
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('div')({});

const RmaAnalysisProduct: React.FC<RmaAnalysisProductProps> = ({ items }) => {
  const { groupedProducts } = useFetchRmaDashboard(items);
  const [filtered, setFiltered] = useState<AgroupedProductRma[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(groupedProducts);
  }, [groupedProducts]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleRedirect(e: MouseEvent<HTMLButtonElement>, item: AgroupedProductRma) {
    e.preventDefault();

    window.open(`/commercial-bi/analysis-product/${item.id_produto}`, '_blank');
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por produto</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography></Typography>
            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>
            <Typography onClick={() => handleSort('subgrupo')} variant="caption" fontWeight={600}>
              Subgrupo
            </Typography>
            <Typography onClick={() => handleSort('comprador')} variant="caption" fontWeight={600}>
              Comprador
            </Typography>
            <Typography align="center" onClick={() => handleSort('qtd')} variant="caption" fontWeight={600}>
              Qtd
            </Typography>
            <Typography align="right" onClick={() => handleSort('valor')} variant="caption" fontWeight={600}>
              Valor
            </Typography>
          </Row>

          {groupedProducts.length === 0 ? (
            <NoData message="Sem filial com estoque excedente" />
          ) : (
            groupedProducts.map((product, index) => (
              <Row key={index} className="item">
                <Tooltip title="Análise de produto">
                  <IconButton size="small" color="primary" onClick={e => handleRedirect(e, product)}>
                    <BarChart fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Typography className="product-name" variant="caption">
                  {product.produto}
                </Typography>

                <Typography className="product-name" variant="caption">
                  {product.subgrupo}
                </Typography>
                <Typography variant="caption">{product.comprador}</Typography>
                <Typography align="center" variant="caption">
                  {product.qtd}
                </Typography>
                <Typography align="right" variant="caption">
                  {product.formattedValue}
                </Typography>
              </Row>
            ))
          )}
        </List>
      </Content>
    </Container>
  );
};

export default RmaAnalysisProduct;
