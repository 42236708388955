import { Button, styled, Typography } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { Curriculum } from 'types/curriculum';
import { useResumes } from '../hooks/useResumes';
import { api } from 'services/api';

interface DeleteCurriculumProps {
  onExited(): void;
  onDelete(): void;
  selectedCurriculum: Curriculum;
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Data = styled('div')({
  padding: '10px 0',
  borderBottom: '1px solid #c1c1c1',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
  flex: 1,
  alignItems: 'flex-end',
});

const DeleteCurriculum: React.FC<DeleteCurriculumProps> = ({ onDelete, onExited, selectedCurriculum }) => {
  const { setSaving } = useResumes();

  function handleClickDeleteButton() {
    setSaving(true);

    api
      .delete(`/curriculums/${selectedCurriculum.id}`)
      .then(onDelete)
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      maxWidth="sm"
      height="35vh"
      onExited={onExited}
      title="Excluir CV"
      headerbackground="#fff"
      titleColor="#000"
    >
      <Container>
        <Data>
          <Typography variant="subtitle2" fontSize={13}>
            <b>Nome:</b> {selectedCurriculum.name}
          </Typography>

          <Typography variant="subtitle2" fontSize={13}>
            <b>Cargo:</b> {selectedCurriculum.role}
          </Typography>

          <Typography variant="subtitle2" fontSize={13}>
            <b>Telefone:</b> {selectedCurriculum.phone}
          </Typography>

          <Typography variant="subtitle2" fontSize={13}>
            <b>Escolaridade:</b> {selectedCurriculum.education_level}
          </Typography>

          <Typography variant="subtitle2" fontSize={13}>
            <b>Publicado:</b> {selectedCurriculum.formattedCreatedAt}
          </Typography>
        </Data>

        <Typography variant="h6" fontSize={14} fontWeight={900}>
          Excluir o CV do banco de dados?
        </Typography>

        <Actions>
          <Button onClick={onExited} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleClickDeleteButton} variant="contained">
            Excluir
          </Button>
        </Actions>
      </Container>
    </Dialog>
  );
};

export default DeleteCurriculum;
