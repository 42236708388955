import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import React, { FormEvent } from 'react';
import { CommercialFilterProps } from './CommercialDashboard';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, styled } from '@mui/material';

interface CommercialDashboardFilterBoxProps {
  filter: CommercialFilterProps;
  onSearch(filter: CommercialFilterProps): void;
  handleChangeFilter(index: keyof CommercialFilterProps, value: any);
  loading: boolean;
}

const Filter = styled('form')(({ theme }) => ({
  gap: 5,
  display: 'grid',
  gridTemplateColumns: '200px 400px 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '170px 1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 5,
});

const CommercialDashboardFilterBox: React.FC<CommercialDashboardFilterBoxProps> = ({
  handleChangeFilter,
  filter,
  onSearch,
  loading,
}) => {
  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch handleChange={value => handleChangeFilter('branch_id', value)} branch_id={filter.branch_id} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <Button variant="contained" type="submit">
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CommercialDashboardFilterBox;
