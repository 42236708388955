import { Button } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import { AggregatedProduct } from 'pages/purchase/order/list/shopping-suggestion/ShoppingSuggestion';
import SuggestionSelectedProduct from 'pages/purchase/order/list/shopping-suggestion/SuggestionSelectedProduct';
import TopShoppingSuggestionAggregated from 'pages/purchase/order/list/shopping-suggestion/TopShoppingSuggestionAggregated';
import React, { useCallback, useMemo, useState } from 'react';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import ReplacementForm from './dialog/ReplacementForm';
import { StackedBarChart } from '@mui/icons-material';

interface Props {
  suggestions: ShoppingSuggestion[];
  subgroupId: number;
}

const CommercialAnalysisSubgroupSuggestion: React.FC<Props> = ({ suggestions, subgroupId }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<AggregatedProduct | null>(null);
  const branches = useMemo(() => {
    return ['POC', 'JUA', ' FS', ' LF', 'VDC', 'BRA', 'ALA', 'PAF', 'SAJ', 'IRC'];
  }, []);

  const calculateAggregatedData = useCallback((): AggregatedProduct[] => {
    return Object.values(
      suggestions.reduce((acc, item) => {
        const { id_produto, produto, filial } = item;

        const key = id_produto;

        if (!acc[key]) {
          acc[key] = {
            id_subgrupo: item.id_subgrupo,
            subgrupo: item.subgrupo,
            id_produto,
            produto,
            sugestao_total: 0,
            estoque_total: 0,
            forecast_total: 0,
            ...Object.fromEntries(branches.map(filial => [`est_${filial.toLowerCase()}`, 0])),
            ...Object.fromEntries(branches.map(filial => [`sug_${filial.toLowerCase()}`, 0])),
          };
        }

        acc[key].sugestao_total += Number(item.sugestao);
        acc[key].estoque_total += Number(item.estoque);
        acc[key].forecast_total += Number(item.forecast);
        acc[key][`est_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.estoque.toString());
        acc[key][`sug_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.sugestao.toString());

        return acc;
      }, {} as { [key: string]: AggregatedProduct }),
    ).filter(item => parseInt(item.id_subgrupo) === subgroupId);
  }, [branches, suggestions, subgroupId]);

  function handleClickButton() {
    setOpen(true);
  }

  function onSave() {
    setOpen(false);
  }

  return (
    <div>
      {selectedProduct && (
        <PaginationProvider>
          <SuggestionSelectedProduct
            branches={branches}
            products={calculateAggregatedData()}
            onExited={() => setSelectedProduct(null)}
            selectedProduct={selectedProduct}
          />

          <Pagination count={calculateAggregatedData().length} />
        </PaginationProvider>
      )}

      {open && (
        <ReplacementForm
          suggestions={suggestions.filter(item => item.sugestao > 0)}
          onExited={() => setOpen(false)}
          onSave={onSave}
        />
      )}

      <Button
        style={{ marginBottom: 10, zoom: '90%' }}
        startIcon={<StackedBarChart />}
        variant="contained"
        onClick={handleClickButton}
      >
        Montar reposição
      </Button>

      <PaginationProvider>
        <TopShoppingSuggestionAggregated
          setSelectedProduct={setSelectedProduct}
          branches={branches}
          data={calculateAggregatedData()}
        />
      </PaginationProvider>
    </div>
  );
};

export default CommercialAnalysisSubgroupSuggestion;
