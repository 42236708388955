import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';

type ResponsibleItemModuleProps = {
  responsible: ResponsibleStockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

const ResponsibleItemModule: React.FC<ResponsibleItemModuleProps> = ({ responsible }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{responsible.responsavel}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {responsible.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Reserva
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {responsible.Reserva}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {responsible.formattedCustoTotal}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default ResponsibleItemModule;
