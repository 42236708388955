import React from 'react';
import ProductItem from './ProductItem';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { StockBalanceData } from 'types/reports/stockBalance';

type ProductListProps = {
  products: StockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const ProductStockListModule: React.FC<ProductListProps> = ({ products }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
        <ProductItem key={product.codigo_produto} product={product} />
      ))}
    </ListStyled>
  );
};

export default ProductStockListModule;
