import { styled } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import CommercialBiMainFilter from './CommercialBiMainFilter';
import CommercialBiTabs, { TabValues } from './tabs/CommercialBiTabs';
import CommercialBiDashboard from './dashboard/CommercialBiDashboard';
import CommercialBiFinancial from './financial/CommercialBiFinancial';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  rowGap: 10,
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid #eee',
  flex: 1,
});

const CommercialBiMain: React.FC = () => {
  const [tab, setTab] = useState<TabValues>('dashboard');

  function getContent(): ReactNode {
    const mapping = {
      dashboard: <CommercialBiDashboard />,
      financial: <CommercialBiFinancial />,
    };

    return mapping[tab];
  }

  return (
    <Container>
      <CommercialBiMainFilter />
      <CommercialBiTabs tab={tab} handleChange={tab => setTab(tab)} />
      <Content>{getContent()}</Content>
    </Container>
  );
};

export default CommercialBiMain;
