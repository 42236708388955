import { styled } from '@mui/material';
import React, { useState } from 'react';
import OrderByForm from '../form/OrderByForm';
import { OrderBy } from 'types/orderBy';
import Appbar from 'components/appbar/Appbar';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  padding: 10,
});

const OrderByNew: React.FC = () => {
  const [orderBy, setOrderBy] = useState<OrderBy>({
    cpf: '',
    id: 0,
    id_filial: 16,
    nome: '',
  });

  function handleChange(index: keyof OrderBy, value: any) {
    setOrderBy({
      ...orderBy,
      [index]: value,
    });
  }

  return (
    <Container>
      <Appbar title="Novo pedido por" />
      <OrderByForm handleChange={handleChange} orderBy={orderBy} />
    </Container>
  );
};

export default OrderByNew;
