import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { useEffect, useState } from 'react';
import { Replacement } from 'types/replacement';

export interface AgroupedBranchReplacement {
  custo: number;
  sugestao: number;
  investimento: number;
  estoque: number;
  forecast: number;
  filial: string;
  id_filial: number;
  pedidos: number;
  reserva: number;
  dias_estoque: number;
  visto: boolean;
  itens: Replacement[];
  formattedInvestimento?: string;
  formattedCusto?: string;
}

export function useFetchAnalysisReplacementBranch(replacements: Replacement[]) {
  const [, sort] = useTableOrder();
  const [filteredBranchesSurplusStock, setFilteredBranchesSurplusStock] = useState<AgroupedBranchReplacement[]>([]);
  const [filtered, setFiltered] = useState<AgroupedBranchReplacement[]>([]);
  const [branches, setBranches] = useState<AgroupedBranchReplacement[]>([]);

  function groupByBranch(data: Replacement[]) {
    const branchMap = new Map();

    data.forEach(item => {
      if (!branchMap.has(item.filial)) {
        branchMap.set(item.filial, {
          filial: item.filial,
          id_filial: item.id_filial,
          investimento: 0,
          sugestao: 0,
          custo: 0,
          visto: false,
          estoque: 0,
          forecast: 0,
          pedidos: 0,
          reserva: 0,
          dias_estoque: 0,
          itens: [],
        } as AgroupedBranchReplacement);
      }

      const entry: AgroupedBranchReplacement = branchMap.get(item.filial);
      entry.sugestao += item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao;
      entry.estoque += item.estoque;
      entry.forecast += item.forecast;
      entry.pedidos += item.pedidos;
      entry.reserva += item.reserva;
      entry.dias_estoque = isFinite((entry.estoque / entry.forecast) * 30) ? (entry.estoque / entry.forecast) * 30 : 0;
      entry.formattedInvestimento = moneyFormat(entry.investimento < 0 ? 0 : entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
    });

    setBranches(Array.from(branchMap.values()));
    setFiltered(Array.from(branchMap.values()));
    setFilteredBranchesSurplusStock(Array.from(branchMap.values()));
  }

  function handleSort(index: string) {
    const _filtered = sort(index, branches);
    setFiltered(_filtered);
  }

  function handleSortBranchSurplusStock(index: string) {
    const _filtered = sort(index, branches);
    setFilteredBranchesSurplusStock(_filtered);
  }

  useEffect(() => {
    groupByBranch(replacements);
  }, [replacements]);
  return {
    filteredBranches: filtered,
    handleSortBranch: handleSort,
    filteredBranchesSurplusStock,
    handleSortBranchSurplusStock,
  };
}
