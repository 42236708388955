import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { useEffect, useState } from 'react';
import { Replacement } from 'types/replacement';

export interface AgroupedSubgroupReplacement {
  id_subgrupo: number;
  subgrupo: string;
  custo: number;
  comprador: string;
  sugestao: number;
  investimento: number;
  estoque: number;
  forecast: number;
  pedidos: number;
  reserva: number;
  dias_estoque: number;
  visto: boolean;
  itens: Replacement[];
  formattedInvestimento?: string;
  formattedCusto?: string;
}

export function useFetchAnalysisReplacementSubgroup(replacements: Replacement[]) {
  const [, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<AgroupedSubgroupReplacement[]>([]);
  const [subgroups, setSubgroups] = useState<AgroupedSubgroupReplacement[]>([]);

  function groupBySubgroup(data: Replacement[]) {
    const subgroupMap = new Map();

    data.forEach(item => {
      if (!subgroupMap.has(item.subgrupo)) {
        subgroupMap.set(item.subgrupo, {
          subgrupo: item.subgrupo,
          comprador: item.comprador,
          investimento: 0,
          sugestao: 0,
          custo: 0,
          visto: false,
          estoque: 0,
          forecast: 0,
          pedidos: 0,
          reserva: 0,
          dias_estoque: 0,
          itens: [],
          id_subgrupo: item.id_subgrupo,
        } as AgroupedSubgroupReplacement);
      }

      const entry: AgroupedSubgroupReplacement = subgroupMap.get(item.subgrupo);
      entry.sugestao += item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao;
      entry.estoque += item.estoque;
      entry.forecast += item.forecast;
      entry.pedidos += item.pedidos;
      entry.reserva += item.reserva;
      entry.dias_estoque = (entry.estoque / entry.forecast) * 30;
      entry.formattedInvestimento = moneyFormat(entry.investimento < 0 ? 0 : entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
    });

    setSubgroups(Array.from(subgroupMap.values()));
    setFiltered(Array.from(subgroupMap.values()));
  }

  function handleSort(index: string) {
    const _filtered = sort(index, subgroups);
    setFiltered(_filtered);
  }

  useEffect(() => {
    groupBySubgroup(replacements);
  }, [replacements]);
  return {
    filteredSubgroups: filtered,
    handleSortSubgroup: handleSort,
  };
}
