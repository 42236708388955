import React from 'react';
import { PriceProtection } from 'types/priceProtection';
import { Typography, styled, ListItemButton } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import { usePriceProtection } from 'pages/price-protection/hooks/usePriceProtection';

type PriceProtectionItemModuleProps = {
  data: PriceProtection;
};

const CustomListItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: '#fff',
  border: '2px solid #f5f5f5',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '100px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

const PriceProtectionItemModule: React.FC<PriceProtectionItemModuleProps> = ({ data }) => {
  const { setSelected } = usePriceProtection();

  return (
    <CustomListItemButton onClick={() => setSelected(data)}>
      <Grid className="bottom">
        <Typography variant="caption">Cod Prod:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {data.id_produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Produto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {data.produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Price Protection:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {moneyFormat(data.valor)}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Custo Atual:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {moneyFormat(data.custo_atual)}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Novo Custo:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {moneyFormat(data.novo_custo)}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Fabricante:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {data.fabricante}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Usuário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {data.usuario}
        </Typography>
      </Grid>
    </CustomListItemButton>
  );
};

export default PriceProtectionItemModule;
