import { useState, useCallback, FormEvent, useEffect } from 'react';
import { useApp } from 'hooks/app';
import { useSelector } from 'store/selector';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { ProductCharacteristic } from 'types/product';
import { useParams } from 'react-router-dom';
import { format, parse, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { PurchaseReview } from 'types/purchaseReview';
import { SaleReview } from 'types/saleReview';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { ItemEntry } from 'types/itemEntry';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import { Reserve } from 'types/reserve';

export interface CommercialAnalysisProductFilterParams {
  selectedBranchId: number;
  productId: string;
  initial_date: Date;
  final_date: Date;
  type: string;
  forecast: string;
  working_days: string;
}

export interface CommercialAnalysisTotal {
  rma: number;
  formattedRma: string;
  qtd: string;
  orders: number;
  stock: number;
  stockValue: number;
  formattedStockValue: string;
  suggestion: number;
  averageSales: string;
  averagePurchases: string;
  averageSalesValue: string;
  averagePurchasesValue: string;
  reserve: number;
}

const initial_value: CommercialAnalysisProductFilterParams = {
  selectedBranchId: 999,
  productId: '',
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  type: 'C',
  forecast: '30',
  working_days: '25',
};

export function useFetchCommercialAnalysisProduct() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const { id } = useParams<{ id: string }>();
  const { isOpenedMenu, handleOpenMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [reserves, setReserves] = useState<Reserve[]>([]);
  const [purchases, setPurchases] = useState<PurchaseReview[]>([]);
  const [sales, setSales] = useState<SaleReview[]>([]);
  const [product, setProduct] = useState<ProductCharacteristic | null>(null);
  const [itemsEntry, setItemsEntry] = useState<ItemEntry[]>([]);
  const [suggestions, setSuggestions] = useState<ShoppingSuggestion[]>([]);
  const [orderItems, setOrderItems] = useState<PurchaseOrderItem[]>([]);
  const [total, setTotal] = useState<CommercialAnalysisTotal>({
    rma: 0,
    formattedRma: '0',
    qtd: '',
    orders: 0,
    stock: 0,
    suggestion: 0,
    formattedStockValue: 'R$ 0,00',
    stockValue: 0,
    averagePurchases: '0',
    averageSales: '0',
    averageSalesValue: 'R$ 0,00',
    averagePurchasesValue: 'R$ 0,00',
    reserve: 0,
  });
  const [filter, setFilter] = useState<CommercialAnalysisProductFilterParams>(initial_value);

  function handleChangeFilter(index: keyof CommercialAnalysisProductFilterParams, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  const handleSearch = useCallback(
    (filter: CommercialAnalysisProductFilterParams) => {
      if (!h2iApi) return;

      setLoading(true);
      const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });

      const params = {
        id_produto: filter.productId,
        id_filial: filter.selectedBranchId === 0 ? '' : filter.selectedBranchId,
        data_ini: formattedInitialDate,
        data_fim: formattedFinalDate,
        tipo_cfop: filter.type === 'all' ? '' : filter.type,
        forecast: filter.forecast,
        dias_uteis: filter.working_days,
      };

      const fetchReserves = h2iApi
        .get('/api/getreservaproduto', {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setReserves([]);
            setTotal(state => ({
              ...state,
              reserve: 0,
            }));
            return;
          }
          const _reserves: Reserve[] = response.data.itens.map((item: Reserve) => {
            item.formattedUnitValue = moneyFormat(item.valor_unitario);
            item.formattedTotalValue = moneyFormat(item.valor_total);
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          });

          setTotal(state => ({
            ...state,
            reserve: response.data.itens.reduce((acc, item) => acc + item.qtd, 0),
          }));

          setReserves(_reserves);
        });

      const fetchProduct = h2iApi
        .get(`/api/ProdutoID`, {
          params: {
            ...params,
            id: params.id_produto,
          },
        })
        .then(_response => {
          const response = _response.data.RESULT[0];
          const p = response.map((item: ProductCharacteristic) => {
            item.formattedCusto = moneyFormat(item.custo);
            item.formattedMargem = percentFormat(item.margem_lucro);
            item.formattedP2PJ = numberFormat(item.p2pj);
            item.formattedP2BR = numberFormat(item.p2pjbr);
            item.formattedICMSCred = numberFormat(item.icms_cred);
            item.formattedICMSDeb = numberFormat(item.icms_deb);
            item.formattedPromoPJ = item.promo_pj ? 'Sim' : 'Não';
            item.formattedDestaque = item.destaque ? 'Sim' : 'Não';
            item.formattedPmc = numberFormat(item.pmc);
            item.formattedPmd = numberFormat(item.pmd);
            item.formattedPp = numberFormat(item.pp);
            return item;
          });

          setProduct(p[0]);
        });

      const fetchIndexRma = h2iApi
        .get(`/api/getrelindicerma`, {
          params,
        })
        .then(_response => {
          const response = _response.data[0];

          if (!response) {
            setTotal(state => ({
              ...state,
              formattedRma: '',
              rma: 0,
              qtd: '',
            }));

            return;
          }

          setTotal(state => ({
            ...state,
            formattedRma: percentFormat(response.indice, 3),
            rma: response.indice,
            qtd: response.qtd,
          }));
        });

      const fetchPurchases = h2iApi
        .get(`/api/getrelretrospectocompras`, {
          params,
        })
        .then(_response => {
          const response = _response.data.map((item: PurchaseReview) => ({
            ...item,
            formattedAverageCost: moneyFormat(item.custo_medio),
            formattedTotalCost: moneyFormat(item.custo_total),
            formattedQuantity: parseInt(item.qtd),
            formattedMonthYear: format(parse(item.mes_ano, 'MM/yyyy', new Date()), 'yyyy/MM'),
          }));
          setPurchases(response);

          setTotal(state => ({
            ...state,
            averagePurchases: numberFormat(
              response.reduce((acc, item) => acc + item.formattedQuantity, 0) / response.length,
              0,
            ),
            averagePurchasesValue: moneyFormat(
              response.reduce((acc, item) => acc + parseFloat(item.custo_total), 0) / response.length,
            ),
          }));
        });

      const fetchSales = h2iApi
        .get(`/api/getrelretrospectovendas`, {
          params,
        })
        .then(_response => {
          const response = _response.data.map(item => ({
            ...item,
            formattedTotalSale: moneyFormat(item.venda_total),
            formattedMc: numberFormat(item.mc),
            formattedCmv: moneyFormat(item.cmv),
            formattedAveragePrice: moneyFormat(item.preco_medio),
            formattedQuantity: parseInt(item.qtd),
            formattedMonthYear: format(parse(item.mes_ano, 'MM/yyyy', new Date()), 'yyyy/MM'),
          }));

          setTotal(state => ({
            ...state,
            averageSales: numberFormat(
              response.reduce((acc, item) => acc + item.formattedQuantity, 0) / response.length,
              0,
            ),
            averageSalesValue: moneyFormat(
              response.reduce((acc, item) => acc + parseFloat(item.venda_total), 0) / response.length,
            ),
          }));

          setSales(response);
        });

      const fetchOrderItems = h2iApi
        .get(`/api/getpedidoitens`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setOrderItems([]);
            setTotal(state => ({
              ...state,
              orders: 0,
            }));
            return;
          }
          setTotal(state => ({
            ...state,
            orders: response.data.reduce((acc, item) => acc + item.pedido, 0),
          }));
          setOrderItems(
            response.data.map(item => ({
              ...item,
              formattedNegotiatedValue: moneyFormat(item.valor_negociado),
              formattedMinForecast: parsePtBRDate(item.previsaomin).toISOString(),
            })),
          );
        });

      const fetchStockBalance = h2iApi
        .get(`/api/saldoestoque`, {
          params,
        })
        .then(response => {
          if (response.data[0].result) {
            setTotal(state => ({
              ...state,
              stock: 0,
              stockValue: 0,
              formattedStockValue: 'R$ 0,00',
            }));
            return;
          }

          setTotal(state => ({
            ...state,
            stock: response.data.reduce((acc, item) => acc + item.Estoque, 0),
            stockValue: response.data.reduce((acc, item) => acc + item.CustoTotal, 0),
            formattedStockValue: moneyFormat(response.data.reduce((acc, item) => acc + item.CustoTotal, 0)),
          }));
        });

      const fetchItemsEntry = h2iApi
        .get('/api/getrelentradaitens', {
          params,
        })
        .then(response => {
          const _items: ItemEntry[] = response.data.map((item: ItemEntry) => {
            item.formattedUnitary = moneyFormat(item.valor_unitario);
            item.formattedQuantity = parseFloat(item.qtd);
            item.formattedDate = parsePtBRDate(item.data_entrada).toISOString();

            return item;
          });

          setItemsEntry(_items);
        });

      const fetchSuggestions = h2iApi
        .get(`/api/getrelsugestaocomprasproduto`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setTotal(state => ({
              ...state,
              suggestion: 0,
            }));
            return;
          }

          setSuggestions(
            response.data.map(item => ({
              ...item,
              pedidos: parseInt(item.pedidos || '0'),
              forecast: parseInt(item.forecast || '0'),
              sugestao: item.sugestao < 0 ? 0 : item.sugestao,
              estoque: parseInt(item.estoque || '0'),
              estoque_forecast: parseInt(item.estoque || '0') + parseInt(item.pedidos || '0'),
            })),
          );

          const suggestionTotal = response.data.reduce((acc, curr) => acc + curr.sugestao, 0);

          setTotal(state => ({
            ...state,
            suggestion: suggestionTotal < 0 ? 0 : suggestionTotal,
          }));
        });

      Promise.all([
        fetchProduct,
        fetchIndexRma,
        fetchPurchases,
        fetchSales,
        fetchOrderItems,
        fetchStockBalance,
        fetchItemsEntry,
        fetchSuggestions,
        fetchReserves,
      ])
        .catch(
          ([
            productErr,
            indexRmaErr,
            purchaseErr,
            salesErr,
            orderItemsErr,
            stockBalanceErr,
            itemsEntryErr,
            suggestionErr,
            reservesErr,
          ]) => {
            console.error(
              productErr,
              indexRmaErr,
              purchaseErr,
              salesErr,
              orderItemsErr,
              stockBalanceErr,
              itemsEntryErr,
              suggestionErr,
              reservesErr,
            );
            setProduct(null);
            setTotal({
              formattedStockValue: 'R$ 0,00',
              stockValue: 0,
              formattedRma: '',
              rma: 0,
              qtd: '',
              orders: 0,
              stock: 0,
              suggestion: 0,
              averagePurchases: '0',
              averageSales: '0',
              averageSalesValue: 'R$ 0,00',
              averagePurchasesValue: 'R$ 0,00',
              reserve: 0,
            });
          },
        )
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  function handleSearchSubmit(filter: CommercialAnalysisProductFilterParams, event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch(filter);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    const _filter = {
      ...initial_value,
      selectedBranchId:
        user.branchList?.length !== 0 ? parseInt(user.branchList.length === 12 ? '0' : user.branchList[0]) : 999,
      productId: id || '',
    };

    setFilter(_filter);

    if (!_filter.productId) {
      return;
    }

    if (!id) {
      return;
    }

    if (_filter.selectedBranchId === 999) {
      return;
    }

    handleSearch(_filter);
  }, [handleSearch, id, user]);

  return {
    product,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    filter,
    purchases,
    total,
    sales,
    itemsEntry,
    suggestions,
    orderItems,
    reserves,
  };
}
