import { styled } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import PurchaseOrderProductListTable from 'pages/purchase/order/list/items/table/PurchaseOrderProductListTable';
import React, { useEffect, useState } from 'react';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';

interface TopOrdersSalesExpandedProps {
  onExited(): void;
  itemsReport: PurchaseOrderItem[];
}

const Content = styled('div')({
  display: 'flex',
  maxWidth: '100%',
  overflow: 'auto',
  flexDirection: 'column',
});

const TopOrdersSalesExpanded: React.FC<TopOrdersSalesExpandedProps> = ({ itemsReport, onExited }) => {
  const [filtered, setFiltered] = useState<PurchaseOrderItem[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(itemsReport);
  }, [itemsReport]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <DialogInput maxWidth="lg" onExited={onExited}>
      <PaginationProvider>
        <Content>
          <PurchaseOrderProductListTable products={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
          <Pagination count={filtered.length} />
        </Content>
      </PaginationProvider>
    </DialogInput>
  );
};

export default TopOrdersSalesExpanded;
