import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import UnityItemTable from './UnityItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { UnityStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { unityStockTableTemplate } from '../unityStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import UnityMoreMenu from '../UnityMoreMenu';

type UnityListTableProps = {
  units: UnityStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const UnityListTable: React.FC<UnityListTableProps> = ({ units, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { moreUnity } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={unityStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {unityStockTableTemplate.map(item => (
              <HeaderItem
                className={
                  item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
                }
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody>
            {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((unity, index) => (
              <>
                <TableRow style={{ minHeight: 10 }} key={index}>
                  <UnityItemTable unity={unity} />
                </TableRow>
                {moreUnity?.id_unidade === unity.id_unidade && <UnityMoreMenu unityId={unity.id_unidade} />}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default UnityListTable;
