import React, { MouseEvent } from 'react';
import { InvoiceSummary } from 'types/summary/invoiceSummary';
import { useTable } from 'components/table/hook/useTable';
import { Tooltip, Typography, styled } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFinancialSummary } from '../../hooks/useFinancialSummary';

interface SummaryItemInvoiceProps {
  data: InvoiceSummary;
}

const Icon = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > svg': {
    transition: 'all ease 0.3s',
    '&.active': {
      transform: 'rotate(180deg)',
    },
  },
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const SummaryItemInvoice: React.FC<SummaryItemInvoiceProps> = ({ data }) => {
  const { tableTemplate } = useTable();
  const { setSelectedInvoice, selectedInvoice } = useFinancialSummary();

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setSelectedInvoice(selectedInvoice === null ? data : null);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'action' ? (
            <Icon onClick={e => handleClick(e)} key={item.id}>
              <KeyboardArrowDown className={selectedInvoice?.filial === data.filial ? 'active' : ''} />
            </Icon>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Tooltip title={data[item.id]}>
                <Description
                  fontWeight={item.originalId === 'valor' ? 600 : 0}
                  color={item.originalId === 'valor' ? '#ff0000' : ''}
                  variant="caption"
                >
                  {data[item.id as any]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default SummaryItemInvoice;
