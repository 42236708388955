import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type RmaTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const FinancialSummaryTabs: React.FC<RmaTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Títulos" value="titles" />
        <Tab label="Despesas" value="expenses" />
        <Tab label="Receitas" value="revenues" />
        <Tab label="Folha de Pagamento" value="payroll" />
        <Tab label="Por filial" value="branches" />
      </Tabs>
    </Container>
  );
};

export default FinancialSummaryTabs;
