import React, { useState, useEffect } from 'react';
import { Balance } from '@mui/icons-material';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useApp } from 'hooks/app';
import { format } from 'date-fns';
import useTableOrder from 'hooks/tableOrder';
import { useFinancialSummary } from '../hooks/useFinancialSummary';
import TableContainer from 'components/table/TableContainer';
import { resultTableTemplate } from './resultTableTemplate';
import InsideLoading from 'components/loading/InsideLoading';
import PaginationProvider from 'hooks/pagination';
import { SummaryResult } from 'types/summary/summaryResult';
import { moneyFormat } from 'helpers/numberFormat';
import { usePermissionRules } from 'hooks/permissionRules';
import NoData from 'components/no-data/NoData';
import { FaFileExcel } from 'react-icons/fa';
import Pagination from 'components/pagination/Pagination';
import FinancialSummaryResultExport from '../export/FinancialSummaryResultExport';
import SummaryListResults from '../list/results/SummaryListResults';

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #ddd',
});

const Title = styled('div')({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
  padding: 5,
  justifyContent: 'space-between',
  borderRadius: '6px 6px 0px 0px',
  border: '1px solid #8559ff',
  '& svg': {
    color: '#000',
  },
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledIconButton = styled(IconButton)({
  '& svg': {
    height: 20,
    width: 20,
  },
});

const Description = styled('span')({
  gap: 5,
  alignItems: 'center',
  display: 'flex',
});

const SummaryResultPage: React.FC = () => {
  const { financialApi } = useApp();
  const { filter } = useFinancialSummary();
  const { checkPermission } = usePermissionRules();
  const [loading, setLoading] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [results, setResults] = useState<SummaryResult[]>([]);
  const [filtered, setFiltered] = useState<SummaryResult[]>([]);

  useEffect(() => {
    setFiltered(results);
  }, [results]);

  useEffect(() => {
    setLoading(true);
    financialApi
      .get('/gettitulosresumo', {
        params: {
          data_ini: filter.initial_date ? format(filter.initial_date, 'dd/MM/yyyy') : '',
          data_fim: filter.final_date ? format(filter.final_date, 'dd/MM/yyyy') : '',
          id_filial: '',
          mes_ano: filter.month_year ? format(filter.month_year, 'MMyy') : '',
        },
      })
      .then(response => {
        setResults(
          response.data.titulos.map(item => {
            item.formattedValue = moneyFormat(item.valor);
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, financialApi]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <div>
      <Title>
        {excelExport && <FinancialSummaryResultExport onExited={() => setExcelExport(false)} data={filtered} />}
        <Description>
          <Balance fontSize="medium" />
          <Typography fontWeight={600} variant="caption">
            Resultado por filial
          </Typography>
        </Description>
        {checkPermission('general.excel_export_button') && (
          <Tooltip title="Exportar excel">
            <StyledIconButton disabled={!filtered.length} onClick={() => setExcelExport(true)}>
              <FaFileExcel />
            </StyledIconButton>
          </Tooltip>
        )}
      </Title>
      <TableContainer tableTemplate={resultTableTemplate}>
        <Box>
          {loading ? (
            <InsideLoading />
          ) : filtered.length > 0 ? (
            <PaginationProvider>
              <Container>
                <SummaryListResults results={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
                <Pagination count={filtered.length} />
              </Container>
            </PaginationProvider>
          ) : (
            <NoData message="Resumo não disponível" />
          )}
        </Box>
      </TableContainer>
    </div>
  );
};

export default SummaryResultPage;
