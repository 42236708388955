import { TableTemplate } from 'types/tableTemplate';

export const resellersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'formattedContacted',
    description: 'Contatado',
    originalId: 'contacted',
    width: 100,
  },
  {
    id: 'formattedMustContact',
    description: 'Contatar',
    originalId: 'must_contact',
    width: 100,
  },
  {
    id: 'seller_name',
    description: 'Vendedor',
    originalId: 'seller_name',
    width: 200,
  },
  {
    id: 'formattedApprovedAt',
    description: 'Aprovado em',
    originalId: 'approved_at',
    width: 140,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Criado em',
    originalId: 'created_at',
    width: 150,
    notSearchable: true,
  },
  {
    id: 'formattedCnpj',
    description: 'CNPJ',
    originalId: 'document',
    width: 170,
  },
  {
    id: 'company_name',
    description: 'Razão social',
    originalId: 'company_name',
    width: 350,
  },
  {
    id: 'company_trade_name',
    description: 'Fantasia',
    originalId: 'company_trade_name',
    width: 350,
  },
  {
    id: 'email',
    description: 'E-mail',
    originalId: 'email',
    width: 350,
  },
  {
    id: 'phone',
    description: 'Telefone',
    originalId: 'phone',
    width: 150,
  },
  {
    id: 'street',
    description: 'Logradouro',
    originalId: 'street',
    width: 300,
  },
  {
    id: 'number',
    description: 'Número',
    originalId: 'number',
    width: 100,
  },
  {
    id: 'neighborhood',
    description: 'Bairro',
    originalId: 'neighborhood',
    width: 150,
  },
  {
    id: 'complement',
    description: 'Complemento',
    originalId: 'complement',
    width: 150,
  },
  {
    id: 'city',
    description: 'Cidade',
    originalId: 'city',
    width: 150,
  },
  {
    id: 'state',
    description: 'Estado',
    originalId: 'state',
    width: 150,
  },
  {
    id: 'formattedUpdateRequiredAt',
    description: 'Solicitado em',
    originalId: 'update_required_at',
    width: 150,
    notSearchable: true,
  },
];
