import React, { MouseEvent, SetStateAction, Dispatch } from 'react';
import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { Bill } from 'types/bill';
import { useBills } from 'pages/bills/hooks/useBills';

type BillItemTableProps = {
  bill: Bill;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  padding: '5px 10px',
});

const AStyled = styled('a')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  fontSize: 14,
  padding: '0px 10px',
});

const BillItemTable: React.FC<BillItemTableProps> = ({ bill, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedBill } = useBills();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedBill(bill);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'url' ? (
            <Tooltip title={bill[item.id]}>
              <AStyled key={item.id} href={bill[item.id]} id={item.id} target="_blank" style={{ fontSize: 14 }}>
                {bill[item.id]}
              </AStyled>
            </Tooltip>
          ) : (
            <Tooltip key={item.id} title={bill[item.id]}>
              <Description variant="caption">{bill[item.id]}</Description>
            </Tooltip>
          ),
        )}
    </>
  );
};

export default BillItemTable;
