import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { useEffect, useState } from 'react';
import { Reserve } from 'types/reserve';

export interface AgroupedProductReserve {
  produto: string;
  qtd: number;
  id_produto: number;
  valor_total: number;
  formattedTotal: string;
}

export function useFetchGroupReserve(datas: Reserve[]) {
  const [filtered, setFiltered] = useState<AgroupedProductReserve[]>([]);
  const [, sort] = useTableOrder();

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function groupByProduct(data: Reserve[]) {
    const productMap = new Map();

    data.forEach(item => {
      if (!productMap.has(item.produto)) {
        productMap.set(item.produto, { produto: item.produto, id_produto: item.id_produto, qtd: 0, valor_total: 0 });
      }
      const product: AgroupedProductReserve = productMap.get(item.produto);
      product.id_produto = item.id_produto;
      product.produto = item.produto;
      product.valor_total += item.valor_total;
      product.qtd += item.qtd;
      product.formattedTotal = moneyFormat(product.valor_total);
    });

    setFiltered(Array.from(productMap.values()).sort((a, b) => b.qtd - a.qtd));
  }

  useEffect(() => {
    groupByProduct(datas);
  }, [datas]);

  return {
    filtered,
    handleSort,
  };
}
