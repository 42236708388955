import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Vacancy } from 'types/vacancy';

interface VacanciesContext {
  selectedVacancy: Vacancy | null;
  setSelectedVacancy: Dispatch<SetStateAction<Vacancy | null>>;
  setSaving: Dispatch<SetStateAction<boolean>>;
  onInactiveVacancy(): void;
}

const Context = createContext<VacanciesContext>({} as VacanciesContext);
export const VacanciesProvider = Context.Provider;

export function useVacancies(): VacanciesContext {
  return useContext(Context);
}
