import { Grid, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useProduct } from '../../Product';

const ProductTechnical: React.FC = () => {
  const { product, validation, handleChange } = useProduct();

  const inputs = {
    peso: useRef<HTMLInputElement>(null),
    altura: useRef<HTMLInputElement>(null),
    largura: useRef<HTMLInputElement>(null),
    profundidade: useRef<HTMLInputElement>(null),
    caixa_master: useRef<HTMLInputElement>(null),
    caracteristicas: useRef<HTMLTextAreaElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Grid gap={2} display={'grid'} paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.peso}
            error={!!validation.peso}
            helperText={validation.peso}
            label="Peso"
            placeholder="Digite o peso do produto"
            autoFocus
            fullWidth
            value={product.peso}
            onChange={e => handleChange('peso', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.caixa_master}
            error={!!validation.caixa_master}
            helperText={validation.caixa_master}
            label="Caixa master"
            placeholder="Digite o valor da caixa master"
            fullWidth
            value={product.caixa_master}
            onChange={e => handleChange('caixa_master', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.profundidade}
            error={!!validation.profundidade}
            helperText={validation.profundidade}
            label="Comprimento"
            placeholder="Digite o comprimento do produto"
            fullWidth
            value={product.profundidade}
            onChange={e => handleChange('profundidade', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.altura}
            error={!!validation.altura}
            helperText={validation.altura}
            label="Altura"
            placeholder="Digite o altura do produto"
            fullWidth
            value={product.altura}
            onChange={e => handleChange('altura', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            inputRef={inputs.largura}
            error={!!validation.largura}
            helperText={validation.largura}
            label="Largura"
            placeholder="Digite a largura do produto"
            fullWidth
            value={product.largura}
            onChange={e => handleChange('largura', e.target.value)}
            type="number"
            inputProps={{
              step: 0.01,
              inputMode: 'decimal',
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
          <TextField
            multiline
            rows={15}
            inputRef={inputs.caracteristicas}
            error={!!validation.caracteristicas}
            helperText={validation.caracteristicas}
            label="Especificações técnicas"
            placeholder="Especificações técnicas do produto"
            fullWidth
            onChange={e => handleChange('caracteristicas', e.target.value)}
            value={product.caracteristicas}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} xl={6} lg={6} md={9}>
          <TextField
            label="Palavras coringas"
            placeholder="Informe as palavras coringas"
            fullWidth
            onChange={e => handleChange('nome_coringa', e.target.value)}
            value={product.nome_coringa}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductTechnical;
