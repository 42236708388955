import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import { GroupedSale } from 'pages/reports/orders-list/dashboard/list/order-by/list/table/GroupedSaleListTable';
import { GroupedUnity } from 'pages/reports/orders-list/dashboard/list/unity/table/GroupedUnityListTable';
import { useCallback, useState } from 'react';
import { useSelector } from 'store/selector';
import { AbcProductBranch } from 'types/abcProduct';
import { SalesItem } from 'types/reports/sales';
import { PaymentMethod } from 'types/salesReport';

export interface DailySummaryFilterProps {
  initialDate: Date;
  finalDate: Date;
  branchId: number;
  type: string;
}

export function useFetchDailySummary() {
  const { h2iApi } = useApp();
  const [, sort] = useTableOrder();
  const user = useSelector(state => state.user);
  const [filteredUnits, setFilteredUnits] = useState<GroupedUnity[]>([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [filteredBranches, setFilteredBranches] = useState<AbcProductBranch[]>([]);
  const [loadingSales, setLoadingSales] = useState(false);
  const [filteredSales, setFilteredSales] = useState<GroupedSale[]>([]);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<PaymentMethod[]>([]);
  const [filter, setFilter] = useState<DailySummaryFilterProps>({
    branchId: user?.branchList?.length === 12 ? 0 : parseInt(user?.branchList[0] || '99'),
    finalDate: new Date(),
    initialDate: new Date(),
    type: 'all',
  });

  function handleChangeFilter(index: keyof DailySummaryFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  const handleSearch = useCallback(
    (filter: DailySummaryFilterProps) => {
      if (!h2iApi) return;

      const formattedInitialDate = format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.finalDate, 'P', { locale: ptBR });

      setLoadingBranches(true);
      setLoadingPayments(true);
      setLoadingUnits(true);
      setLoadingSales(true);

      const fetchPayments = h2iApi
        .get('/api/getrelvendasformapag', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            tipo_venda: filter.type === 'all' ? '' : filter.type,
          },
        })
        .then(response => {
          if (!response.data.relpag) {
            setFilteredPaymentMethods([]);
            return;
          }

          const methods = response.data.relpag.map(item => ({
            ...item,
            formattedValue: moneyFormat(item.valor),
            formattedPercentual: percentFormat(
              (item.valor / response.data.relpag.reduce((acc, curr) => acc + curr.valor, 0)) * 100,
            ),
          }));
          setFilteredPaymentMethods(methods);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingPayments(false));

      const fetchSalesItems = h2iApi
        .get(`/api/getrelvendasitem`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            tipo_venda: filter.type === 'all' ? '' : filter.type,
          },
        })
        .then(response => {
          if (!response.data.relitens) {
            setFilteredUnits([]);
            return;
          }

          const items = response.data.relitens.map((item: SalesItem) => {
            item.formattedTotal = moneyFormat(item.total);
            return item;
          });

          const groupedUnits: { [key: string]: GroupedUnity } = {};

          items.forEach(sale => {
            if (!groupedUnits[sale.unidade]) {
              groupedUnits[sale.unidade] = {
                unidade: sale.unidade,
                total: 0,
                spiff: 0,
                mc: 0,
                qtd: 0,
                custo: 0,
                percentual: 0,
                qtd_vendas: 0,
                formattedCusto: '',
                formattedMc: '',
                formattedSpiff: '',
                formattedPercentual: '',
                formattedTotal: '',
                vendas: [],
              };
            }
            groupedUnits[sale.unidade].qtd += sale.qtd;
            groupedUnits[sale.unidade].qtd_vendas += sale.qtd_vendas;
            groupedUnits[sale.unidade].custo += sale.custo;
            groupedUnits[sale.unidade].total += sale.total;
            groupedUnits[sale.unidade].mc += sale.mc;
            groupedUnits[sale.unidade].formattedCusto = moneyFormat(groupedUnits[sale.unidade].custo);
            groupedUnits[sale.unidade].formattedTotal = moneyFormat(groupedUnits[sale.unidade].total);
            groupedUnits[sale.unidade].formattedMc = moneyFormat(groupedUnits[sale.unidade].mc);
          });

          setFilteredUnits(Object.values(groupedUnits));
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingUnits(false));

      const fetchSales = h2iApi
        .get('/api/relvendas', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            tipo_venda: filter.type === 'all' ? '' : filter.type,
          },
        })
        .then(response => {
          if (!response.data.relvendas) {
            setFilteredSales([]);
            return;
          }
          const _sales = response.data.relvendas.map(item => {
            item.formattedTotal = moneyFormat(item.vl_total);
            item.formattedDiscount = moneyFormat(item.vl_desconto);
            item.formattedDiscountPercent = percentFormat(item.perc_desconto);
            return item;
          });
          const groupedSales: { [key: string]: GroupedSale } = {};

          _sales.forEach(sale => {
            if (!groupedSales[sale.pedido_por]) {
              groupedSales[sale.pedido_por] = {
                pedido_por: sale.pedido_por,
                vl_total: 0,
                spiff: 0,
                mc: 0,
                qtd: 0,
                ticket_medio: 0,
                percentual: 0,
                formattedMc: '',
                formattedSpiff: '',
                formattedTicketMedio: '',
                formattedPercentual: '',
                formattedTotal: '',
                vendas: [],
              };
            }

            groupedSales[sale.pedido_por].qtd += 1;
            groupedSales[sale.pedido_por].vl_total += sale.vl_total;
            groupedSales[sale.pedido_por].mc += sale.mc;
            groupedSales[sale.pedido_por].formattedTotal = moneyFormat(groupedSales[sale.pedido_por].vl_total);
          });

          setFilteredSales(Object.values(groupedSales));
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSales(false));

      const fetchBranches = h2iApi
        .get(`/api/relcurvaabcprodutos`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.branchId || '',
            tipo_venda: filter.type === 'all' ? '' : filter.type,
          },
        })
        .then(response => {
          if (!response.data[0].filial) {
            setFilteredBranches([]);
            return;
          }

          const _branches = response.data[0].filial.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);

            return item;
          });

          setFilteredBranches(_branches);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingBranches(false));

      Promise.all([fetchPayments, fetchSalesItems, fetchSales, fetchBranches]);
    },
    [h2iApi],
  );

  function handleSortPaymentMethods(index: string) {
    const f = sort(index, filteredPaymentMethods);
    setFilteredPaymentMethods(f);
  }

  function handleSortUnits(index: string) {
    const f = sort(index, filteredUnits);
    setFilteredUnits(f);
  }

  function handleSortSales(index: string) {
    const f = sort(index, filteredSales);
    setFilteredSales(f);
  }

  function handleSortBranches(index: string) {
    const f = sort(index, filteredBranches);
    setFilteredBranches(f);
  }

  return {
    filter,
    loadingPayments,
    handleChangeFilter,
    handleSortPaymentMethods,
    handleSortUnits,
    filteredPaymentMethods,
    handleSearch,
    filteredUnits,
    loadingUnits,
    filteredSales,
    loadingSales,
    handleSortSales,
    loadingBranches,
    filteredBranches,
    handleSortBranches,
  };
}
