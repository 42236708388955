import React from 'react';
import { styled, TextField } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { OrderBy } from 'types/orderBy';

interface Props {
  orderBy: OrderBy;
  handleChange(index: keyof OrderBy, value: any): void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  padding: 10,
});

const OrderByForm: React.FC<Props> = ({ orderBy, handleChange }) => {
  return (
    <Container>
      <SelectBranch
        label="Selecione a filial"
        viewAllBranches={false}
        branch_id={orderBy.id_filial}
        handleChange={branch => handleChange('id_filial', branch)}
      />
      <TextField
        value={orderBy.nome}
        onChange={e => handleChange('nome', e.target.value)}
        label="Nome"
        placeholder="Digite o nome"
      />
      <TextField
        value={orderBy.cpf}
        onChange={e => handleChange('cpf', e.target.value)}
        label="CPF"
        placeholder="Digite o CPF"
      />

      <button type="submit" style={{ display: 'none' }}></button>
    </Container>
  );
};

export default OrderByForm;
