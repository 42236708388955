import React from 'react';
import { darken, styled, Typography } from '@mui/material';
import { SalesReportTotal } from './hooks/useFetchFinancialCard';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { LineAxis } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  marginBottom: 10,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .third': {
    background: '#eeeff8',
    color: darken('#eeeff8', 0.5),
  },
  '& .fourth': {
    background: '#f8eef3',
    color: darken('#f8eef3', 0.5),
  },
  '& .fifth': {
    background: '#e3e3e3',
    color: darken('#e3e3e3', 0.5),
  },
  '& .sixth': {
    background: '#ffc8c8',
    color: darken('#ffc8c8', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface SalesCardReportTotalProps {
  formattedTotal: SalesReportTotal;
}

const SalesCardReportTotal: React.FC<SalesCardReportTotalProps> = ({ formattedTotal }) => {
  return (
    <Container>
      <Box>
        <Typography className="title first" variant="body2" fontSize={18}>
          Valor bruto
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.gross)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Valor recebido
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.received)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Valor desconto
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.discount)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
      <Box>
        <Typography className="title fourth" variant="body2" fontSize={18}>
          Taxa média
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {percentFormat(formattedTotal.tax)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Crédito/Parc
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.credit)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title sixth" variant="body2" fontSize={18}>
          Débito
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.debit)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default SalesCardReportTotal;
