import { TableTemplate } from 'types/tableTemplate';

export const commercialDashboardTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'Ações',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 300,
  },
  {
    id: 'formattedTotal',
    description: 'Valor',
    originalId: 'valor_total',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'Pedido_por',
    description: 'Pedido por',
    originalId: 'Pedido_por',
    width: 160,
  },
  {
    id: 'telefone',
    description: 'Telefone',
    originalId: 'telefone',
    width: 140,
  },
  {
    id: 'email',
    description: 'E-mail',
    originalId: 'email',
    width: 200,
  },
];
