import { IconButton, styled, Tooltip } from '@mui/material';
import React from 'react';
import { Add, Print } from '@mui/icons-material';
import { useRevenues } from './hooks/useRevenues';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { FaFileExcel } from 'react-icons/fa';
import { usePermissionRules } from 'hooks/permissionRules';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const RevenuesActions: React.FC = () => {
  const { setIsReportOpen, setIsNewRevenueOpen, revenues } = useRevenues();
  const { handleDownload } = useDefaultExcelExport(revenues);
  const { checkPermission } = usePermissionRules();

  return (
    <Container>
      <IconButton onClick={() => setIsNewRevenueOpen(true)} color="inherit">
        <Add />
      </IconButton>
      <IconButton color="inherit" onClick={() => setIsReportOpen(true)}>
        <Print />
      </IconButton>
      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <IconButton color="inherit" onClick={handleDownload}>
            <FaFileExcel />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
};

export default RevenuesActions;
