import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { BranchStockBalanceData } from 'types/reports/stockBalance';

type BranchItemModuleProps = {
  branch: BranchStockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
});

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{branch.filial}</Typography>

      <Grid className="bottom">
        <Typography variant="body2" color="textSecondary">
          Est Total:
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {branch.Reserva}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="body2" color="textSecondary">
          Reserva:
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {branch.Estoque}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="body2" color="textSecondary">
          Custo Total:
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {branch.formattedCustoTotal}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default BranchItemModule;
