import { moneyFormat } from 'helpers/numberFormat';
import { Replacement } from 'types/replacement';

export interface AgroupedProductReplacement {
  id_produto: number;
  produto: string;
  estoque: number;
  forecast: number;
  pedidos: number;
  investimento: number;
  formattedInvestimento: string;
  sugestao: number;
  custo: number;
  formattedCusto: string;
  itens: number;
  dias_estoque: number;
}

export interface AgroupedBranchReplacement {
  filial: string;
  investimento: number;
  formattedInvestimento: string;
  sugestao: number;
  custo: number;
  formattedCusto: string;
  itens: number;
}

export interface AgroupedSubgroupReplacement {
  subgrupo: string;
  investimento: number;
  formattedInvestimento: string;
  sugestao: number;
  custo: number;
  formattedCusto: string;
  itens: number;
}

export interface AgroupedBuyerReplacement {
  comprador: string;
  investimento: number;
  formattedInvestimento: string;
  sugestao: number;
  custo: number;
  formattedCusto: string;
  itens: number;
}

export function useFetchAnalysisReplacementDashboard(replacements: Replacement[]) {
  function groupByProduct(data: Replacement[]): AgroupedProductReplacement[] {
    const productMap = new Map();

    data.forEach(item => {
      if (!productMap.has(item.produto)) {
        productMap.set(item.produto, {
          produto: item.produto,
          investimento: 0,
          sugestao: 0,
          custo: 0,
          pedidos: 0,
          forecast: 0,
          estoque: 0,
          itens: 0,
          dias_estoque: 0,
        });
      }
      const entry: AgroupedProductReplacement = productMap.get(item.produto);
      entry.pedidos += item.pedidos;
      entry.id_produto = item.id_produto;
      entry.forecast += item.forecast;
      entry.estoque += item.estoque;
      entry.sugestao += item.sugestao < 0 ? 0 : item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao < 0 ? 0 : item.custo * item.sugestao;
      entry.dias_estoque = isFinite((entry.estoque / entry.forecast) * 30) ? (entry.estoque / entry.forecast) * 30 : 0;
      entry.formattedInvestimento = moneyFormat(entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
      entry.itens += 1;
    });

    return Array.from(productMap.values());
  }

  function groupByBranch(data: Replacement[]): AgroupedBranchReplacement[] {
    const branch = new Map();

    data.forEach(item => {
      if (!branch.has(item.filial)) {
        branch.set(item.filial, { filial: item.filial, investimento: 0, sugestao: 0, custo: 0, itens: 0 });
      }
      const entry: AgroupedBranchReplacement = branch.get(item.filial);
      entry.sugestao += item.sugestao < 0 ? 0 : item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao < 0 ? 0 : item.custo * item.sugestao;
      entry.formattedInvestimento = moneyFormat(entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
      entry.itens += 1;
    });

    return Array.from(branch.values());
  }

  function groupBySubgroup(data: Replacement[]): AgroupedSubgroupReplacement[] {
    const subgroupMap = new Map();

    data.forEach(item => {
      if (!subgroupMap.has(item.subgrupo)) {
        subgroupMap.set(item.subgrupo, { subgrupo: item.subgrupo, investimento: 0, sugestao: 0, custo: 0, itens: 0 });
      }
      const entry = subgroupMap.get(item.subgrupo);
      entry.sugestao += item.sugestao < 0 ? 0 : item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao < 0 ? 0 : item.custo * item.sugestao;
      entry.formattedInvestimento = moneyFormat(entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
      entry.itens += 1;
    });

    return Array.from(subgroupMap.values());
  }

  function groupByBuyer(data: Replacement[]): AgroupedBuyerReplacement[] {
    const buyerMap = new Map();

    data.forEach(item => {
      if (!buyerMap.has(item.comprador)) {
        buyerMap.set(item.comprador, { comprador: item.comprador, investimento: 0, sugestao: 0, custo: 0, itens: 0 });
      }
      const entry = buyerMap.get(item.comprador);
      entry.sugestao += item.sugestao < 0 ? 0 : item.sugestao;
      entry.custo += item.custo;
      entry.investimento += item.custo * item.sugestao < 0 ? 0 : item.custo * item.sugestao;
      entry.formattedInvestimento = moneyFormat(entry.investimento);
      entry.formattedCusto = moneyFormat(entry.custo);
      entry.itens += 1;
    });

    return Array.from(buyerMap.values());
  }

  const groupedProducts = groupByProduct(replacements).sort((a, b) => b.custo - a.custo);
  const groupedBranches = groupByBranch(replacements).sort((a, b) => b.custo - a.custo);
  const groupedSubgroups = groupBySubgroup(replacements).sort((a, b) => b.custo - a.custo);
  const groupedBuyers = groupByBuyer(replacements).sort((a, b) => b.custo - a.custo);

  return {
    groupedProducts,
    groupedBranches,
    groupedSubgroups,
    groupedBuyers,
  };
}
