import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { branchTableTemplate } from '../template/branchTableTemplate';
import { AbcProductBranch } from 'types/abcProduct';

type DailySummaryBranchItemTableProps = {
  branch: AbcProductBranch;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const DailySummaryBranchItemTable: React.FC<DailySummaryBranchItemTableProps> = ({ branch }) => {
  return (
    <>
      {branchTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item => (
          <div
            key={_item.id}
            className={
              _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
            }
          >
            <Tooltip title={branch[_item.id]}>
              <Description variant="body2" fontSize={12}>
                {branch[_item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default DailySummaryBranchItemTable;
