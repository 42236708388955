import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { ItemEntry } from 'types/itemEntry';
import { itemEntryTableTemplate } from '../../template/itemEntryTableTemplate';

type ItemEntryItemTableProps = {
  entry: ItemEntry;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const ItemEntryItemTable: React.FC<ItemEntryItemTableProps> = ({ entry }) => {
  return (
    <>
      {itemEntryTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
            <Tooltip title={entry[item.id]}>
              <Description style={{ fontSize: 10 }} variant="body2">
                {entry[item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default ItemEntryItemTable;
