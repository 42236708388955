import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type RmaTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const RmaTabs: React.FC<RmaTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="dashboard" />
        <Tab label="Relação de OS" value="items" />
        <Tab label="Produtos" value="product" />
      </Tabs>
    </Container>
  );
};

export default RmaTabs;
