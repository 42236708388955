import { AttachMoney, CalendarMonth, Group } from '@mui/icons-material';
import { Icon, styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexWrap: 'wrap',
});

const Box = styled('div')({
  flex: 1,
  padding: 15,
  minWidth: 150,
  background: '#eee',
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: 40,
  gap: 5,
});

const Title = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const DailySummaryTotal: React.FC = () => {
  return (
    <Container>
      <Box>
        <Title>
          <Icon color="success" fontSize="small">
            <AttachMoney color="success" fontSize="small" />
          </Icon>

          <Typography variant="subtitle1" fontWeight={600}>
            {moneyFormat(0)}
          </Typography>
        </Title>
        <Typography color="#2e7d32" variant="caption" fontWeight={600}>
          Total vendido
        </Typography>
      </Box>

      <Box>
        <Title>
          <Icon color="success" fontSize="small">
            <AttachMoney color="success" fontSize="small" />
          </Icon>

          <Typography variant="subtitle1" fontWeight={600}>
            {moneyFormat(0)}
          </Typography>
        </Title>
        <Typography color="#2e7d32" variant="caption" fontWeight={600}>
          Estoque
        </Typography>
      </Box>

      <Box>
        <Title>
          <Icon color="error" fontSize="small">
            <AttachMoney color="error" fontSize="small" />
          </Icon>

          <Typography variant="subtitle1" fontWeight={600}>
            {moneyFormat(0)}
          </Typography>
        </Title>
        <Typography color="error" variant="caption" fontWeight={600}>
          MC
        </Typography>
      </Box>

      <Box>
        <Title>
          <Icon color="primary" fontSize="small">
            <CalendarMonth color="primary" fontSize="small" />
          </Icon>

          <Typography variant="subtitle1" fontWeight={600}>
            {moneyFormat(0)}
          </Typography>
        </Title>
        <Typography color="primary" variant="caption" fontWeight={600}>
          Projetado
        </Typography>
      </Box>

      <Box>
        <Title>
          <Icon color="secondary" fontSize="small">
            <Group color="secondary" fontSize="small" />
          </Icon>

          <Typography variant="subtitle1" fontWeight={600}>
            {moneyFormat(0)}
          </Typography>
        </Title>
        <Typography color="secondary" variant="caption" fontWeight={600}>
          Clientes
        </Typography>
      </Box>
    </Container>
  );
};

export default DailySummaryTotal;
