import React, { useEffect, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Icon, styled, TextField, Typography } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import useTableOrder from 'hooks/tableOrder';
import { Delete } from '@mui/icons-material';

interface Props {
  onExited(): void;
  onSave(): void;
  suggestions: ShoppingSuggestion[];
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

const Form = styled('form')(({ theme }) => ({
  rowGap: 15,
  alignItems: 'center',
  columnGap: 10,
  display: 'grid',
  gridTemplateColumns: '100px 300px',
}));

const List = styled('ul')({
  padding: '0px 10px',
  display: 'grid',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  '& > .edit': {},
  '& > .input > div > fieldset': {
    textAlign: 'center',
  },
  '& > div > div > input': {
    textAlign: 'center',
    margin: 0,
    padding: '0px!important',
  },
  overflow: 'auto',
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '40px 120px 70px 500px 120px 50px 80px 90px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '40px 110px 60px 1fr 110px 50px 70px 80px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '40px 110px 60px 1fr 110px 50px 70px 80px',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const ReplacementForm: React.FC<Props> = ({ onExited, onSave, suggestions }) => {
  const [suggestion, setSuggestion] = useState({
    data: new Date(),
    comprador: suggestions.length > 0 ? suggestions[0].comprador : '',
  });
  const [filtered, setFiltered] = useState<ShoppingSuggestion[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(suggestions);
  }, [suggestions]);

  function handleSort(index: keyof ShoppingSuggestion) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChange(index: keyof typeof suggestion, value: any) {
    setSuggestion(prev => ({ ...prev, [index]: value }));
  }

  function handleChangeSuggestions(index: number, param: keyof ShoppingSuggestion, value: any) {
    setFiltered(prev => {
      const p: ShoppingSuggestion[] = [...prev];

      p[index][param as any] = value;
      return p;
    });
  }

  function handleRemove(index: number) {
    setFiltered(state => state.filter((item, _index) => _index !== index));
  }

  return (
    <Dialog maxWidth="xl" onExited={onExited} title="Montar reposição">
      <Container>
        <Form>
          <Typography variant="caption" fontWeight={600}>
            Comprador:
          </Typography>
          <TextField
            margin="none"
            onChange={e => handleChange('comprador', e.target.value)}
            placeholder="Digite o comprador"
            size="small"
            value={suggestion.comprador}
          />

          <Typography variant="caption" fontWeight={600}>
            Data:
          </Typography>
          <KeyboardDatePicker
            onChange={e => handleChange('data', e.target.value)}
            size="small"
            value={suggestion.data}
          />
        </Form>

        <List>
          <Row className="header">
            <Typography></Typography>
            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cod Prod
            </Typography>

            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>

            <Typography align="center" onClick={() => handleSort('comprador')} variant="caption" fontWeight={600}>
              Fornecedor
            </Typography>

            <Typography align="center" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
              Qtd
            </Typography>

            <Typography align="right" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
              Custo
            </Typography>

            <Typography align="right" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
              Total
            </Typography>
          </Row>

          {filtered.map((item, index) => (
            <Row className="item" key={index}>
              <Icon onClick={() => handleRemove(index)} fontSize="small" color="error">
                <Delete fontSize="small" />
              </Icon>
              <Typography variant="caption">{item.filial}</Typography>
              <Typography variant="caption">{item.id_produto}</Typography>
              <Typography className="item-name" variant="caption">
                {item.produto}
              </Typography>
              <TextField
                value={item.fornecedor}
                onChange={e => handleChangeSuggestions(index, 'fornecedor', e.target.value)}
                className="input"
              />
              <TextField
                value={item.sugestao}
                onChange={e => handleChangeSuggestions(index, 'sugestao', e.target.value)}
                className="input"
              />
              <Typography align="right" variant="caption"></Typography>
              <Typography align="right" variant="caption"></Typography>
            </Row>
          ))}
        </List>
      </Container>
    </Dialog>
  );
};

export default ReplacementForm;
