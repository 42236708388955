import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Extract } from 'types/extract';
import { BankStatementFilterProps } from '../BankStatement';
import { TotalizingAccount } from 'types/totalizingAccount';
import { TotalExtracts } from './useFetchExtracts';

export interface GroupedDataDate {
  total: number;
  result: Extract[];
  date: string;
  formattedDate: string;
}

export interface GroupedDataType {
  total: number;
  result: Extract[];
  type: string;
  formattedType: string;
}

interface ExtractContextValue {
  filter: BankStatementFilterProps;
  groupedDataByDate: GroupedDataDate[];
  groupedDataByType: GroupedDataType[];
  handleChangeFilter(index: keyof BankStatementFilterProps, value: any): void;
  setIsReportOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch(): void;
  loading: boolean;
  handleSelectAll(): void;
  selectAll: boolean;
  selectedExtracts: Extract[];
  handleToggle(extract: Extract): void;
  loadingTotalizingAccount: boolean;
  extracts: Extract[];
  total: TotalExtracts;
  showTotals: boolean;
  defaultView: 'type' | 'default' | 'date';
  setAppropriationDialog: Dispatch<SetStateAction<boolean>>;
  setPaymentDialog: Dispatch<SetStateAction<boolean>>;
  setShowTotals: Dispatch<SetStateAction<boolean>>;
  setExtract: Dispatch<SetStateAction<Extract | null>>;
  totalizingAccounts: TotalizingAccount[];
  setIsNewTransferOpen: Dispatch<SetStateAction<boolean>>;
  setIsNewExpense: Dispatch<SetStateAction<boolean>>;
  setDefaultView: Dispatch<SetStateAction<'type' | 'default' | 'date'>>;
  setInvoice: Dispatch<SetStateAction<{ id: number; tipo: string } | null>>;
}

const ExtractContext = createContext<ExtractContextValue>({} as ExtractContextValue);
export const ExtractConsumer = ExtractContext.Consumer;
export const ExtractProvider = ExtractContext.Provider;

export function useExtracts() {
  return useContext(ExtractContext);
}
