import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import React, { Dispatch, SetStateAction } from 'react';
import { Invoice } from 'types/invoices';
import InvoiceItem from './InvoiceItem';

interface InvoiceListProps {
  invoices: Invoice[];
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | null>>;
  selectedInvoice: null | Invoice;
}

const TableRow = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  borderRadius: 6,
  padding: 10,
  cursor: 'pointer',
  '&.selected': {
    borderColor: theme.palette.primary.main,
  },
}));

const TableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const InvoiceList: React.FC<InvoiceListProps> = ({ invoices, selectedInvoice, setSelectedInvoice }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <TableContainer>
      {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
        <TableRow
          onClick={() => setSelectedInvoice(invoice)}
          className={selectedInvoice?.id === invoice.id ? 'selected' : ''}
          key={invoice.id}
        >
          <InvoiceItem invoice={invoice} />
        </TableRow>
      ))}
    </TableContainer>
  );
};

export default InvoiceList;
