import { styled, Tab, Tabs } from '@mui/material';
import React from 'react';

interface Props {
  tab: string;
  onChangeTab(tab: string): void;
}

const Container = styled('div')({
  marginBottom: 10,
});

const StockBalanceTabs: React.FC<Props> = ({ onChangeTab, tab }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => onChangeTab(value)} variant="scrollable">
        <Tab value="branch" label="Visão geral" />
        <Tab value="items" label="Itens" />
        <Tab value="responsible" label="Responsável" />
        <Tab value="segment" label="Segmento" />
        <Tab value="unity" label="Unidade" />
        <Tab value="manufacturer" label="Fabricante" />
        <Tab value="subgroup" label="Subgrupo" />
        <Tab value="stoppedStock" label="Sem movimentação" />
      </Tabs>
    </Container>
  );
};

export default StockBalanceTabs;
