import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useDavReview } from '../hook/useDavReview';

type DavReviewListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const DavReviewListMenu: React.FC<DavReviewListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedDav } = useDavReview();

  function handlePrintClick() {
    window.open(`https://api.grupoh2i.com.br/api/dav?order_id=${selectedDav?.id}&branch_id=${selectedDav?.id_filial}`);
    setAnchorEl(null);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handlePrintClick}>Imprimir DAV</MenuItem>
    </Menu>
  );
};

export default DavReviewListMenu;
