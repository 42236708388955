import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchNoteEntry } from './hooks/useFetchNoteEntry';
import history from 'services/history';
import { NoteEntry } from 'types/noteEntry';
import NoteEntryActions from './NoteEntryActions';
import NoteEntryFilterBox from './NoteEntryFilterBox';
import NoteEntryListTable from './list/table/NoteEntryListTable';
import NoteEntryListModule from './list/module/NoteEntryListModule';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Pagination from 'components/pagination/Pagination';
import NoteEntryTabs from './tab/NoteEntryTabs';
import NoteEntryDashboard from './dashboard/NoteEntryDashboard';

export interface ItemsSoldFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const NoteEntryPage: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const { loading, notes, filter, handleChangeFilter, onSearch } = useFetchNoteEntry();
  const [orderedIndex, sort] = useTableOrder();
  const [tab, setTab] = useState<'notes' | 'dashboard'>('dashboard');
  const [filtered, setFiltered] = useState<NoteEntry[]>([]);

  useEffect(() => {
    setFiltered(notes);
  }, [notes]);

  function handleSort(index: string) {
    const filtered = sort(index, notes);
    setFiltered(filtered);
  }

  function handleReportClick() {
    const productId = parseInt(filter.product);

    history.push({
      pathname: '/reports/note_entry/report',
      search: `id_filial=${filter.branch_id || ''}&cliente=${filter.customer}&data_ini=${format(
        filter.initial_date,
        'P',
        {
          locale: ptBR,
        },
      )}&data_fim=${format(filter.final_date, 'P', { locale: ptBR })}&produto=${
        isNaN(productId) ? filter.product : filter.product.length === productId.toString().length ? '' : filter.product
      }&id_produto=${isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : ''}
      `,
    });
  }

  return (
    <>
      <Appbar title="Relação de compras" />
      <PageHeaderActions
        gutterBottom={false}
        title="Relação de compras"
        ActionComponent={<NoteEntryActions notes={notes} handleReportClick={handleReportClick} />}
      />

      <NoteEntryFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        loading={loading}
        onSearch={onSearch}
      />

      <NoteEntryTabs tab={tab} handleChange={value => setTab(value)} />
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : tab === 'notes' ? (
        filtered.length === 0 ? (
          <NoData message="Nenhum item para mostrar" />
        ) : (
          <PaginationProvider>
            <div>
              {displayMode === 'list' ? (
                <NoteEntryListTable notes={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <NoteEntryListModule notes={notes} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )
      ) : (
        <NoteEntryDashboard notes={filtered} />
      )}
    </>
  );
};

export default NoteEntryPage;
