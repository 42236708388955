import { TableTemplate } from 'types/tableTemplate';

export const bannersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: '',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'document_number',
    description: 'Número Doc',
    originalId: 'document_number',
    width: 120,
  },
  {
    id: 'number',
    description: 'Número',
    originalId: 'number',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'bank_billet_remittance_id',
    description: 'ID Remessa',
    originalId: 'bank_billet_remittance_id',
    width: 100,
  },
  {
    id: 'beneficiaryName',
    description: 'Beneficiário',
    originalId: 'beneficiaryName',
    width: 300,
  },
  {
    id: 'payerName',
    description: 'Cliente',
    originalId: 'payerName',
    width: 300,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'value',
    width: 100,
  },
  {
    id: 'formattedPayday',
    description: 'Vencimento',
    originalId: 'payday',
    width: 120,
  },
  {
    id: 'url',
    description: 'URL',
    originalId: 'url',
    width: 300,
  },
  {
    id: 'formattedCanceledAt',
    description: 'Cancelado em',
    originalId: 'canceled_at',
    width: 160,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Emissão',
    originalId: 'created_at',
    width: 150,
  },
];
