import React, { useEffect, useState } from 'react';
import useTableOrder from 'hooks/tableOrder';
import { darken, Icon, styled, Typography } from '@mui/material';
import { ItemEntry } from 'types/itemEntry';
import ItemEntryListNotes from 'pages/reports/item-entry/dialog/ItemEntryListNotes';
import { Print } from '@mui/icons-material';

interface Props {
  itemsEntry: ItemEntry[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '20vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f1f7ef',
  padding: 10,
  '& > p': {
    color: darken('#f1f7ef', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  overflow: 'auto',
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '30px 100px 1fr 90px 70px 50px 50px 50px 120px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '30px 1fr 1fr 0.5fr 0.4fr 0.4fr 0.4fr 0.4fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '30px 1fr 1fr 0.5fr 0.4fr 0.4fr 0.4fr 0.3fr 0.8fr',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  padding: '0px 10px',
  display: 'grid',
});

const CommercialAnalysisProductOrdersListDesktop: React.FC<Props> = ({ itemsEntry }) => {
  const [filtered, setFiltered] = useState<ItemEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<ItemEntry | null>(null);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(itemsEntry);
  }, [itemsEntry]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      {selectedEntry && <ItemEntryListNotes onExited={() => setSelectedEntry(null)} selectedEntry={selectedEntry} />}

      <Title>
        <Typography fontSize={13}>Histórico de compras</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography></Typography>
            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography onClick={() => handleSort('fornecedor')} variant="caption" fontWeight={600}>
              Fornecedor
            </Typography>

            <Typography onClick={() => handleSort('formattedDate')} variant="caption" fontWeight={600}>
              Data Entrada
            </Typography>

            <Typography onClick={() => handleSort('nf')} variant="caption" fontWeight={600}>
              NF
            </Typography>

            <Typography onClick={() => handleSort('cfop')} variant="caption" fontWeight={600}>
              CFOP
            </Typography>

            <Typography onClick={() => handleSort('cst')} variant="caption" fontWeight={600}>
              CST
            </Typography>

            <Typography onClick={() => handleSort('formattedQuantity')} variant="caption" fontWeight={600}>
              Qtd
            </Typography>

            <Typography align="right" onClick={() => handleSort('valor_unitario')} variant="caption" fontWeight={600}>
              Valor Unitário
            </Typography>
          </Row>

          {filtered.map((item, index) => (
            <Row onClick={() => setSelectedEntry(item)} className="item" key={index}>
              <Icon fontSize="small" color="primary">
                <Print fontSize="small" />
              </Icon>
              <Typography variant="caption">{item.filial}</Typography>
              <Typography className="item-name" variant="caption">
                {item.fornecedor}
              </Typography>
              <Typography variant="caption">{item.data_entrada}</Typography>
              <Typography variant="caption">{item.nf}</Typography>
              <Typography variant="caption">{item.cfop}</Typography>
              <Typography variant="caption">{item.cst}</Typography>
              <Typography variant="caption">{item.qtd}</Typography>
              <Typography align="right" variant="caption">
                {item.formattedUnitary}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductOrdersListDesktop;
