import React from 'react';
import SubgroupItemModule from './SubgroupItemModule';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';

type SubgroupListModuleProps = {
  subgroups: SubgroupStockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const SubgroupListModule: React.FC<SubgroupListModuleProps> = ({ subgroups }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {subgroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(subgroup => (
        <SubgroupItemModule key={subgroup.id_subgrupo} subgroup={subgroup} />
      ))}
    </ListStyled>
  );
};

export default SubgroupListModule;
