import React, { useState } from 'react';
import { useCommercialBi } from '../hooks/useCommercialBi';
import { IconButton, Typography, styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import { usePagination } from 'hooks/pagination';
import { OpenInNew } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import TopCustomersExpanded from './dialog/TopCustomersExpanded';

const Container = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
});

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f1f7ef',
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > button': {
    padding: 0,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 2,
  gridTemplateColumns: '1fr 70px',
  '& .sale-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 2,
});

const TopCustomersSales: React.FC = () => {
  const { sales } = useCommercialBi();
  const { rowsPerPage, page } = usePagination();
  const [open, setOpen] = useState(false);

  const salesReport = sales.sort((a, b) => {
    if (a.vl_total < b.vl_total) {
      return 1;
    }

    if (a.vl_total > b.vl_total) {
      return -1;
    }

    return 0;
  });

  return (
    <Container>
      {open && <TopCustomersExpanded onExited={() => setOpen(false)} sales={sales} />}
      <Title>
        <Typography fontSize={13}>Relação de clientes</Typography>

        <IconButton disabled={sales.length === 0} size="small" onClick={() => setOpen(true)}>
          <OpenInNew fontSize="small" />
        </IconButton>
      </Title>
      {sales.length > 0 ? (
        <Content>
          <List>
            <Row className="header">
              <Typography variant="caption" fontWeight={600}>
                Cliente
              </Typography>
              <Typography align="right" variant="caption" fontWeight={600}>
                Total
              </Typography>
            </Row>
            {salesReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => (
              <Row key={index}>
                <Typography className="sale-name" variant="caption">
                  {sale.cliente}
                </Typography>
                <Typography align="right" variant="caption">
                  {sale.formattedTotal}
                </Typography>
              </Row>
            ))}
          </List>
          <Pagination count={salesReport.length} />
        </Content>
      ) : (
        <NoData message="Nenhum registro encontrado" />
      )}
    </Container>
  );
};

export default TopCustomersSales;
