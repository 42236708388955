import { FilterList } from '@mui/icons-material';
import { IconButton, styled, Tooltip } from '@mui/material';
import { usePermissionRules } from 'hooks/permissionRules';
import React from 'react';
import { FaFileExcel } from 'react-icons/fa';

const Actions = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  display: 'flex',
}));

interface Props {
  handleOpenDialog(): void;
  handleOpenExcel(): void;
  disabled: boolean;
}

const AbcSegmentActions: React.FC<Props> = ({ handleOpenDialog, handleOpenExcel, disabled }) => {
  const { checkPermission } = usePermissionRules();
  return (
    <Actions>
      <Tooltip title="Outros filtros">
        <IconButton onClick={handleOpenDialog} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>

      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
            <FaFileExcel />
          </IconButton>
        </Tooltip>
      )}
    </Actions>
  );
};

export default AbcSegmentActions;
