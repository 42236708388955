import React from 'react';
import { Typography, ListItemButton, styled } from '@mui/material';
import { CommercialDashboardTotal } from 'types/commercialDashboard';

const ListItemButtonStyled = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  padding: 0,
});

const Flex = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 10,
  flex: 1,
  padding: '0px 10px',
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type CommercialDashboardItemModuleProps = {
  order: CommercialDashboardTotal;
};

const CommercialDashboardItemModule: React.FC<CommercialDashboardItemModuleProps> = ({ order }) => {
  return (
    <ListItemButtonStyled>
      <Flex className="bottom title">
        <Typography align="center" color="primary" fontSize={18} variant="h6">
          {order.Pedido_por}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Total:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.formattedTotal}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Qtd:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.qtd}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Percentual:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.formattedPercentual}
        </Typography>
      </Flex>
    </ListItemButtonStyled>
  );
};

export default CommercialDashboardItemModule;
