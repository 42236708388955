import { format, subDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { H2iBuyer } from 'types/h2iBuyer';
import { Rma } from 'types/rma';

export interface FilterRmaProps {
  initial_date: Date;
  type: string;
  final_date: Date;
  branch_id: number;
  product: string;
  buyer: string;
  situation: string;
}

export function useFetchRma() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingBuyers, setLoadingBuyers] = useState(false);
  const [buyers, setBuyers] = useState<H2iBuyer[]>([]);
  const [items, setItems] = useState<Rma[]>([]);
  const [filter, setFilter] = useState<FilterRmaProps>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    final_date: new Date(),
    initial_date: subDays(new Date(), 7),
    product: '',
    buyer: 'all',
    situation: 'all',
    type: 'all',
  });

  useEffect(() => {
    if (!h2iApi) return;

    setLoadingBuyers(true);
    h2iApi
      .get(`/api/getcompradores`)
      .then(response => {
        if (response.data.RESULT) {
          setBuyers([]);
          return;
        }
        const _buyers = response.data.compradores
          .filter(item => item.ativo === 'S')
          .sort((a: H2iBuyer, b: H2iBuyer) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });

        setBuyers(_buyers);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingBuyers(false);
      });
  }, [h2iApi]);

  function handleChangeFilter(index: keyof FilterRmaProps, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const onSearch = useCallback(
    (filter: FilterRmaProps) => {
      if (!h2iApi) return;
      if (!user?.branchList) return;

      setLoading(true);

      const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
      const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");
      const productId = parseInt(filter.product);

      h2iApi
        .get('/api/getrelrma', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            tipo: filter.type === 'all' ? '' : filter.type,
            id_comprador: filter.buyer === 'all' ? '' : filter.buyer,
            situacao: filter.situation === 'all' ? '' : filter.situation,
            produto: isNaN(productId)
              ? filter.product
              : filter.product.length === productId.toString().length
              ? ''
              : filter.product,
            id_produto: isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : '',
            id_filial: filter.branch_id || '',
          },
        })
        .then(response => {
          const _items: Rma[] = response.data.map((item: Rma) => {
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            item.formattedValue = moneyFormat(item.valor);
            item.formattedQuantity = parseInt(item.qtd || '0');

            return item;
          });

          setItems(_items);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, user],
  );

  return {
    loading,
    items,
    filter,
    loadingBuyers,
    buyers,
    handleChangeFilter,
    onSearch,
  };
}
