import React from 'react';
import ResponsibleItemModule from './ResponsibleItemModule';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';

type ResponsibleListModuleProps = {
  responsibles: ResponsibleStockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const ResponsibleListModule: React.FC<ResponsibleListModuleProps> = ({ responsibles }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {responsibles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(responsible => (
        <ResponsibleItemModule key={responsible.id_responsavel} responsible={responsible} />
      ))}
    </ListStyled>
  );
};

export default ResponsibleListModule;
