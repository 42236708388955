import { IconButton, ListItemButton, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { PurchaseOrderProduct } from 'types/purchaseOrder';

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const ListItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  flexDirection: 'column',
  '& .data': {
    display: 'grid',
    gridTemplateColumns: '70px 1fr',
  },
});

type ProductItemModuleProps = {
  disabled: boolean;
  product: PurchaseOrderProduct;
  handleClick(): void;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const ProductItemModule: React.FC<ProductItemModuleProps> = ({ product, disabled, setAnchorEl, handleClick }) => {
  const [collapse, setCollapse] = useState(false);

  function onClick() {
    setCollapse(!collapse);
    handleClick();
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleClick();
  }

  return (
    <>
      <ListItem disabled={disabled || product.cancelado === 'S'} onClick={onClick}>
        <div className="data">
          <Typography style={{ gridColumn: '1 / 3' }} color="primary" variant="caption" gutterBottom>
            ID {product.codigo_produto}
          </Typography>
          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Produto:
          </Typography>
          <Typography variant="body2">{product.produto}</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Valor Unitário:
          </Typography>
          <Typography variant="body2">{product.formattedValueUnitary}</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Valor Desconto:
          </Typography>
          <Typography variant="body2">{product.formattedValueDiscount}</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Valor Despesa:
          </Typography>
          <Typography variant="body2">{product.formattedValueExpense}</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Valor Frete:
          </Typography>
          <Typography variant="body2">{product.formattedValueTax}</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Qtd:
          </Typography>
          <Typography variant="body2">{product.quantidade}x</Typography>

          <Typography variant="body2" fontSize={12} fontWeight={500}>
            Total:
          </Typography>
          <Typography variant="body2">{moneyFormat(product.valor)}</Typography>
        </div>

        <IconButtonStyled onClick={e => handleMoreClick(e)}>
          <MoreVert />
        </IconButtonStyled>
      </ListItem>
    </>
  );
};

export default ProductItemModule;
