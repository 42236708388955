import { Typography } from '@mui/material';
import React from 'react';
import { Invoice } from 'types/invoices';

interface InvoiceItemProps {
  invoice: Invoice;
}

const InvoiceItem: React.FC<InvoiceItemProps> = ({ invoice }) => {
  return (
    <div>
      <Typography variant="subtitle2">Título: {invoice.titulo}</Typography>
      <Typography variant="subtitle2">{invoice.fornecedor}</Typography>
      <Typography variant="subtitle2">{invoice.filial}</Typography>
      <Typography variant="subtitle2">{invoice.formattedValue}</Typography>
    </div>
  );
};

export default InvoiceItem;
