import React from 'react';
import List from 'components/list/List';
import RetailTableItemModule from './RetailTableItemModule';
import { RetailTable } from 'types/retailTable';
import { Grid } from '@mui/material';
import { usePagination } from 'hooks/pagination';

type RetailTableListModuleProps = {
  tables: RetailTable[];
};

const RetailTableListModule: React.FC<RetailTableListModuleProps> = ({ tables }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {tables.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((table, index) => (
            <RetailTableItemModule key={index} table={table} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default RetailTableListModule;
