import React from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { useFinancialSummary } from './hooks/useFinancialSummary';
import SelectMultiOthersBranches from 'components/select-branches/SelectMultiOthersBranches';

const StyledFilter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 150px 1fr 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '190px 140px 1fr 150px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 120px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 3',
    gridRow: '1 / 2',
  },
}));

const DatepickerContainer = styled('div')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const FinancialSummaryFilterBox: React.FC = () => {
  const { filter, handleChangeFilter, handleSearch, loading } = useFinancialSummary();

  return (
    <FilterBox>
      <StyledFilter>
        <SelectMultiOthersBranches
          branchIds={filter.branchIds}
          handleChange={id => handleChangeFilter('branchIds', id)}
        />
        <TextField
          sx={{ minWidth: 150 }}
          placeholder="selecione o grupo"
          label="Grupo"
          value={filter.company}
          onChange={e => handleChangeFilter('company', e.target.value as string)}
          select
        >
          <MenuItem value="all">Todas</MenuItem>
          <MenuItem value="e">Eltecno</MenuItem>
          <MenuItem value="t">Terceiras</MenuItem>
        </TextField>
        <DatepickerContainer>
          <DesktopDatePicker
            label="Data inicial"
            value={filter.initial_date}
            maxDate={filter.final_date}
            onChange={date => handleChangeFilter('initial_date', date)}
          />
          <DesktopDatePicker
            label="Data Final"
            value={filter.final_date}
            minDate={filter.initial_date}
            onChange={date => handleChangeFilter('final_date', date)}
          />

          <Grid>
            <DesktopDatePicker
              label="Mês/Ano"
              value={filter.month_year}
              onChange={date => handleChangeFilter('month_year', date)}
              format="MMMM-yyyy"
              views={['month', 'year']}
            />
          </Grid>
        </DatepickerContainer>

        <Button
          disabled={loading}
          onClick={handleSearch}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </StyledFilter>
    </FilterBox>
  );
};

export default FinancialSummaryFilterBox;
