import React, { Dispatch, SetStateAction } from 'react';
import { Curriculum } from 'types/curriculum';
import CurriculumItemModule from './CurriculumItemModule';
import { styled } from '@mui/material';

interface CurriculumListModuleProps {
  resumes: Curriculum[];
  setAnchorEl: Dispatch<SetStateAction<Curriculum | null>>;
}

const List = styled('ul')(({ theme }) => ({
  display: 'grid',
  marginTop: 15,
  columnGap: 30,
  rowGap: 10,
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Container = styled('div')({
  flex: 1,
});

const CurriculumListModule: React.FC<CurriculumListModuleProps> = ({ resumes, setAnchorEl }) => {
  return (
    <Container>
      <List>
        {resumes.map(curriculum => (
          <CurriculumItemModule setAnchorEl={setAnchorEl} key={curriculum.id} curriculum={curriculum} />
        ))}
      </List>
    </Container>
  );
};

export default CurriculumListModule;
