import React, { MouseEvent, useEffect, useState } from 'react';
import { Icon, Tooltip, Typography, styled } from '@mui/material';
import { PaymentFlow } from 'types/paymentFlow';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import TopOrdersPayments from './dialog/TopOrdersPayments';
import { FaFileExcel } from 'react-icons/fa';
import { uuidv4 } from 'helpers/uuidv4';
import { download } from 'helpers/download';
import { api } from 'services/api';
import { usePermissionRules } from 'hooks/permissionRules';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#f9f7f2',
  overflow: 'auto',
  maxHeight: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: 5,
  paddingBottom: 6,
  gridTemplateColumns: '1fr 1fr 1fr',
  transition: 'all 0.3s ease',
  '&.content:hover': {
    backgroundColor: '#fff',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

interface TopPaymentsProps {
  data: PaymentFlow[];
}

const CustomIcon = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: 'green',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#ddd',
    borderRadius: 50,
  },
});

const Title = styled('div')({
  display: 'flex',
  gap: 5,
});

const TopPayments: React.FC<TopPaymentsProps> = ({ data }) => {
  const { checkPermission } = usePermissionRules();
  const [orderedIndex, sort] = useTableOrder();
  const [selectedPayment, setSelectedPayment] = useState<PaymentFlow | null>(null);
  const [filtered, setFiltered] = useState<PaymentFlow[]>([]);

  function handleClick(event: MouseEvent<HTMLSpanElement>) {
    event.stopPropagation();

    handleDownload('pagamentos-a-vencer', filtered);
  }

  async function handleDownload(title: string, data: any[]) {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `${title}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      {selectedPayment && <TopOrdersPayments onExited={() => setSelectedPayment(null)} payment={selectedPayment} />}
      <Title>
        <Typography variant="subtitle2" align="center">
          Pagamentos a vencer
        </Typography>
        {checkPermission('general.excel_export_button') && (
          <CustomIcon onClick={e => handleClick(e)}>
            <FaFileExcel style={{ height: 18, width: 18 }} />
          </CustomIcon>
        )}
      </Title>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('formattedDayOfWeek')} variant="subtitle2">
                Dia
              </Typography>
              {orderedIndex.index === 'formattedDayOfWeek' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedDueDate')} variant="subtitle2">
                Vencimento
              </Typography>
              {orderedIndex.index === 'formattedDueDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.map((item, index) => (
            <Row onClick={() => setSelectedPayment(item)} className="content" key={index}>
              <Tooltip title={item.formattedDayOfWeek}>
                <Typography className="item-name" variant="caption">
                  {item.formattedDayOfWeek}
                </Typography>
              </Tooltip>
              <Tooltip title={item.vencimento}>
                <Typography className="item-name" variant="caption">
                  {item.vencimento}
                </Typography>
              </Tooltip>
              <Typography className="numericData" align="right" variant="caption">
                {item.formattedValue}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopPayments;
