import React from 'react';
import SegmentItemModule from './SegmentItemModule';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';

type SegmentListModuleProps = {
  segments: SegmentStockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const SegmentListModule: React.FC<SegmentListModuleProps> = ({ segments }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(segment => (
        <SegmentItemModule key={segment.id_segmento} segment={segment} />
      ))}
    </ListStyled>
  );
};

export default SegmentListModule;
