import React from 'react';
import { AbcProductBranch } from 'types/abcProduct';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

type BranchItemModuleProps = {
  branch: AbcProductBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{branch.filial}</Typography>

      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Vl Total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.formattedTotal}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Custo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.formattedCoust}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Unitário
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.formattedUnitary}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Qtd
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {branch.qtd}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default BranchItemModule;
