import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import InvoicePayment from '../../dialog/InvoicePayment';
import { Invoice, InvoicePayment as InvoicePaymentInterface } from 'types/invoices';
import NoData from 'components/no-data/NoData';
import { moneyFormat } from 'helpers/numberFormat';

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'end',
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 20,
    overflow: 'scroll',
  },
}));

const Item = styled('li')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100px 1fr 1fr 1fr',
  gap: 5,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '100px 100px 150px 150px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '80px 70px 140px 1fr',
  },
  '&.header': {
    [theme.breakpoints.down('sm')]: {
      width: '130%',
    },
    backgroundColor: '#eee',
  },
}));

interface InvoicePaymentListProps {
  selectedInvoice: Invoice | null;
  fetch(): void;
  invoicePayments: InvoicePaymentInterface[];
}

const InvoicePaymentList: React.FC<InvoicePaymentListProps> = ({ fetch, selectedInvoice, invoicePayments }) => {
  const [paymentDialog, setPaymentDialog] = useState(false);

  function onSave() {
    fetch();
    onExited();
  }

  function onExited() {
    setPaymentDialog(false);
  }
  return (
    <div>
      {paymentDialog && selectedInvoice && (
        <InvoicePayment onSave={onSave} invoices={[selectedInvoice]} onExited={onExited} />
      )}
      <Header>
        <Button size="small" onClick={() => setPaymentDialog(true)} variant="contained">
          Adicionar pagamento
        </Button>
      </Header>

      <Content>
        <Typography variant="caption">Confira a lista de pagamentos:</Typography>

        {!invoicePayments ? (
          <NoData message="Nenhum pagamento informado" />
        ) : invoicePayments.length === 0 ? (
          <NoData message="Nenhum pagamento informado" />
        ) : (
          <List>
            <Item className="header">
              <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
                Pagamento
              </Typography>
              <Typography align="right" variant="subtitle1" fontWeight={600} fontSize={13}>
                Valor
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
                Operação feita em
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
                Usuário
              </Typography>
            </Item>
            {invoicePayments.map(item => (
              <Item key={item.id}>
                <Typography variant="caption">{item.data_pagamento}</Typography>
                <Typography align="right" variant="caption">
                  {moneyFormat(item.valor)}
                </Typography>
                <Typography variant="caption">{item.data_hora}</Typography>
                <Typography variant="caption">{item.usuario}</Typography>
              </Item>
            ))}
          </List>
        )}
      </Content>
    </div>
  );
};

export default InvoicePaymentList;
