import { TableTemplate } from 'types/tableTemplate';

export const saleTableTemplate: TableTemplate[] = [
  {
    id: 'pedido_por',
    description: 'Pedido por',
    originalId: 'pedido_por',
    width: 300,
  },
  {
    id: 'formattedTotal',
    description: 'Total',
    originalId: 'vl_total',
    width: 110,
    dataType: 'number',
  },
];
