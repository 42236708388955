import React from 'react';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import { ItemEntry } from 'types/itemEntry';
import { useFetchItemEntryDashboard } from './hooks/useFetchItemEntryDashboard';
import ItemEntryDashboardTotal from './ItemEntryDashboardTotal';
import ItemEntryDashboardSupplier from './supplier/ItemEntryDashboardSupplier';
import ItemEntryDashboardManufacturer from './manufacturer/ItemEntryDashboardManufacturer';
import ItemEntryDashboardSegment from './segment/ItemEntryDashboardSegment';
import ItemEntryDashboardBuyer from './buyer/ItemEntryDashboardBuyer';

interface ItemEntryDashboardProps {
  items: ItemEntry[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ItemEntryDashboard: React.FC<ItemEntryDashboardProps> = ({ items }) => {
  const { groupedManufacturers, groupedSuppliers, groupedSegments, groupedBuyers } = useFetchItemEntryDashboard(items);

  return (
    <Container>
      <ItemEntryDashboardTotal items={items} />

      {items.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <ItemEntryDashboardBuyer buyers={groupedBuyers} />

          <ItemEntryDashboardManufacturer manufacturers={groupedManufacturers} />

          <ItemEntryDashboardSegment segments={groupedSegments} />

          <PaginationProvider>
            <ItemEntryDashboardSupplier suppliers={groupedSuppliers} />
          </PaginationProvider>
        </Content>
      )}
    </Container>
  );
};

export default ItemEntryDashboard;
