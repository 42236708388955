import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcUnitsFilterParams } from './CurveAbcUnity';

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '160px 130px 1fr 1fr 100px 80px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.5fr 0.4fr 1fr 1fr 100px 80px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  '& > button': {
    width: 40,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CustomFormControl = styled(FormControl)({
  height: '100%',
});

const DateContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface AbcUnityFilterBoxProps {
  filter: AbcUnitsFilterParams;
  handleChangeFilter(index: keyof AbcUnitsFilterParams, value: any): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcUnityFilterBox: React.FC<AbcUnityFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenExcel,
  disabled,
}) => {
  const { checkPermission } = usePermissionRules();

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <CustomFormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </CustomFormControl>

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </DateContainer>
        <TextField
          label="Unidade"
          placeholder="Unidade"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />

        <Button variant="contained" type="submit" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
        <Actions>
          <Tooltip title="Outros filtros">
            <IconButton onClick={handleOpenDialogDesktop} color="inherit">
              <FilterList />
            </IconButton>
          </Tooltip>

          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <IconButton disabled={disabled} onClick={handleOpenExcel} style={{ color: disabled ? '#eee' : 'green' }}>
                <FaFileExcel />
              </IconButton>
            </Tooltip>
          )}
        </Actions>
      </Filter>
    </FilterBox>
  );
};

export default AbcUnityFilterBox;
