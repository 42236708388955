import React, { MouseEvent } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { reserveTableTemplate } from '../../template/reserveTableTemplate';
import { Reserve } from 'types/reserve';
import { Print } from '@mui/icons-material';

type ReserveAnalysisItemTableProps = {
  reserve: Reserve;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const ReserveAnalysisItemTable: React.FC<ReserveAnalysisItemTableProps> = ({ reserve }) => {
  function handleRedirect(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
  }
  return (
    <>
      {reserveTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item =>
          _item.id === 'action' ? (
            <Tooltip key={reserve.id} title="Imprimir">
              <IconButton size="small" color="primary" onClick={e => handleRedirect(e)}>
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div
              key={_item.id}
              className={
                _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Tooltip title={reserve[_item.id]}>
                <Description style={{ fontSize: 10 }} variant="body2">
                  {reserve[_item.id]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default ReserveAnalysisItemTable;
