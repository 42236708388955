import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import { WasteProvider } from '../hooks/useWaste';
import WasteForm from '../WasteForm';
import { useMessaging } from 'hooks/messaging';
import WasteProducts from '../products/WasteProducts';
import WasteTotals from '../totals/WasteTotals';
import PageHeader from 'components/page-header/PageHeader';
import WasteNewAppbarButtons from './WasteNewAppbarButtons';
import { useApp } from 'hooks/app';
import WasteSelectBranch from './steps/WasteSelectBranch';
import WasteSelectCustomer from './steps/WasteSelectCustomer';
import { useFetchWaste } from '../hooks/useFetchWaste';
import WasteProductsStep from './steps/WasteProducts';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  padding: '0px 60px',
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 200px)',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '0px 10px',
  },
}));

const WasteNew: React.FC = () => {
  const {
    loadingCustomer,
    loadingPostalCode,
    saving,
    formattedTotal,
    handleDocumentChange,
    handleChange,
    waste,
    disableButton,
    validation,
    dispatch,
    handlePostalCodeChange,
    handleValidation,
  } = useFetchWaste();
  const { windowWidth } = useApp();
  const { handleOpen } = useMessaging();
  const [step, setStep] = useState(1);
  const [allowProduct, setAllowProduct] = useState(false);

  function nextStep() {
    if (step === 1) {
      if (!waste.id_filial) {
        handleOpen('Ocorreu um erro na validação');
        return;
      }
    }
    if (step === 2) {
      setAllowProduct(true);
    }
    setStep(step + 1);
  }

  useEffect(() => {
    if (validation.id_filial) handleOpen(validation.id_filial);
  }, [validation, handleOpen]);

  return (
    <WasteProvider
      value={{
        waste,
        handleChange,
        dispatch,
        handleDocumentChange,
        loadingCustomer,
        loadingPostalCode,
        handlePostalCodeChange,
      }}
    >
      {saving && <Loading />}

      <Appbar
        title="R3E"
        ActionsComponent={<WasteNewAppbarButtons step={step} handleValidation={handleValidation} />}
      />

      <PageHeader title="Voltar" backUrl="/waste" />

      <Container>
        {windowWidth < 600 ? (
          <>
            {step === 1 ? (
              <WasteSelectBranch disableButton={!waste.id_filial} handleValidation={nextStep} />
            ) : step === 2 ? (
              <WasteSelectCustomer handleValidation={nextStep} validation={validation} disableButton={disableButton} />
            ) : (
              <WasteProductsStep />
            )}
          </>
        ) : (
          <>
            <WasteForm
              disableButton={disableButton}
              handleValidation={() => setAllowProduct(true)}
              validation={validation}
            />

            <WasteProducts allowProduct={allowProduct} />

            <WasteTotals formattedTotal={formattedTotal} />
          </>
        )}
      </Container>
    </WasteProvider>
  );
};

export default WasteNew;
