import { format, subDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { H2iBuyer } from 'types/h2iBuyer';
import { ItemEntry } from 'types/itemEntry';

export interface FilterItemEntry {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  product: string;
  customer: string;
  type: string;
  buyer: string;
}

export function useFetchItemEntry() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingBuyers, setLoadingBuyers] = useState(false);
  const [buyers, setBuyers] = useState<H2iBuyer[]>([]);
  const [entries, setEntries] = useState<ItemEntry[]>([]);
  const [filter, setFilter] = useState<FilterItemEntry>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
    final_date: new Date(),
    initial_date: subDays(new Date(), 7),
    customer: '',
    product: '',
    type: 'all',
    buyer: 'all',
  });

  useEffect(() => {
    if (!h2iApi) return;

    setLoadingBuyers(true);
    h2iApi
      .get(`/api/getcompradores`)
      .then(response => {
        if (response.data.RESULT) {
          setBuyers([]);
          return;
        }
        const _buyers = response.data.compradores
          .filter(item => item.ativo === 'S')
          .sort((a: H2iBuyer, b: H2iBuyer) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });

        setBuyers(_buyers);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingBuyers(false);
      });
  }, [h2iApi]);

  function handleChangeFilter(index: keyof FilterItemEntry, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const onSearch = useCallback(
    (filter: FilterItemEntry) => {
      if (!h2iApi) return;
      if (!user?.branchList) return;

      setLoading(true);

      const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
      const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");
      const productId = parseInt(filter.product);

      h2iApi
        .get('/api/getrelentradaitens', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_comprador: filter.buyer === 'all' ? '' : filter.buyer,
            produto: isNaN(productId)
              ? filter.product
              : filter.product.length === productId.toString().length
              ? ''
              : filter.product,
            tipo_cfop: filter.type === 'all' ? '' : filter.type,
            id_produto: isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : '',
            id_filial: filter.branch_id || '',
            cliente: filter.customer,
          },
        })
        .then(response => {
          const _entries: ItemEntry[] = response.data.map((item: ItemEntry) => {
            item.formattedUnitary = moneyFormat(item.valor_unitario);
            item.formattedCoustUnitaryTotal = moneyFormat(item.custo_total_unitario);
            item.formattedCoustTotal = moneyFormat(item.custo_total);
            item.formattedIpi = moneyFormat(item.valor_ipi);
            item.formattedDiscount = moneyFormat(item.valor_desconto);
            item.formattedShipment = moneyFormat(item.valor_desconto);
            item.formattedQuantity = parseFloat(item.qtd);

            return item;
          });

          setEntries(_entries);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, user],
  );

  return {
    loading,
    entries,
    filter,
    loadingBuyers,
    buyers,
    handleChangeFilter,
    onSearch,
  };
}
