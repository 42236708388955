import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { Vacancy } from 'types/vacancy';
import history from 'services/history';
import { MoreHoriz } from '@mui/icons-material';
import { useVacancies } from '../../hooks/useVacancies';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: 350,
});

type VacancyItemTableProps = {
  vacancy: Vacancy;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const VacancyItemTable: React.FC<VacancyItemTableProps> = ({ vacancy, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedVacancy } = useVacancies();

  function handleClick() {
    history.push(`/rh/vacancy/${vacancy.id}`);
  }

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setSelectedVacancy(vacancy);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'action' ? (
            <IconButton key={item.id} onClick={handleClickMore}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <NumericData
              onClick={handleClick}
              className={item.dataType === 'number' ? 'numericData' : undefined}
              key={item.id}
            >
              <Tooltip title={vacancy[item.id]}>
                <Description variant="caption">{vacancy[item.id]}</Description>
              </Tooltip>
            </NumericData>
          ),
        )}
    </>
  );
};

export default VacancyItemTable;
