import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import { useFinancial } from '../hooks/useFinancial';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { TotalizingAccount } from 'types/totalizingAccount';
import { TotalizingAccountProvider } from './hooks/useTotalizingAccount';
import { useFetchTotalizingAccount } from './hooks/useFetchTotalizingAccount';
import TotalizingAccountFilterBox from './TotalizingAccountFilterBox';
import PageHeaderButtons from './PageHeaderButtons';
import { accountsTableTemplate } from './accountsTableTemplate';
import TotalizingAccountReport from './report/TotalizingAccountReport';
import TotalizingListTable from './list/table/TotalizingListTable';
import TotalizingListModule from './list/module/TotalizingListModule';
import NewTotalizingAccount from './registration/new/NewTotalizingAccount';
import EditTotalizingAccount from './registration/edit/EditTotalizingAccount';
import { useApp } from 'hooks/app';
import TotalizingAccountActions from './TotalizingAccountActions';

export interface TotalizingAccountFilterProps {
  description: string;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const filterInitialState = {
  description: '',
  branchId: 1001,
};

const TotalizingAccountPage: React.FC = () => {
  const { handleBack } = useFinancial();
  const { windowWidth } = useApp();
  const [displayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<TotalizingAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<TotalizingAccount | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [filter, setFilter] = useState<TotalizingAccountFilterProps>(filterInitialState);
  const [isNewTotalizingAccount, setIsNewTotalizingAccount] = useState(false);
  const { accounts, loading, fetch } = useFetchTotalizingAccount(filter);
  const [isReportOpen, setIsReportOpen] = useState(false);

  useEffect(() => {
    setFiltered(accounts);
  }, [accounts]);

  function handleChangeFilter(index: keyof TotalizingAccountFilterProps, value: any) {
    setFilter(filter => ({
      ...filter,
      [index]: value,
    }));
  }

  function handleSearch() {
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSubmit() {
    setIsNewTotalizingAccount(false);
    setSelectedAccount(null);
    fetch();
  }

  return (
    <TotalizingAccountProvider
      value={{
        accounts,
        loading,
        setIsNewTotalizingAccount,
        setIsReportOpen,
        handleChangeFilter,
        handleSearch,
        setSelectedAccount,
        filter,
      }}
    >
      {selectedAccount && (
        <EditTotalizingAccount
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          onExited={() => setSelectedAccount(null)}
          onSubmit={onSubmit}
        />
      )}

      {isReportOpen && <TotalizingAccountReport onExited={() => setIsReportOpen(false)} />}

      {isNewTotalizingAccount && (
        <NewTotalizingAccount onSubmit={onSubmit} onExited={() => setIsNewTotalizingAccount(false)} />
      )}

      <Appbar title="Conta totalizadora" backAction={handleBack} ActionsComponent={<TotalizingAccountActions />} />

      <PageHeaderActions title="Conta totalizadora" ActionComponent={<PageHeaderButtons />} />

      {windowWidth < 600 && <PageHeaderButtons />}

      <TotalizingAccountFilterBox />

      <TableContainer tableTemplate={accountsTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma conta encontrada" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <TotalizingListTable accounts={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <TotalizingListModule accounts={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </TotalizingAccountProvider>
  );
};

export default TotalizingAccountPage;
