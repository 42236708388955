import { useCallback, useState } from 'react';
import { Waste, WasteOnList } from '../types/waste';
import { WasteQueryParams } from '../Wastes';
import { useWasteFormatter } from './useWasteFormatter';
import { useApp } from 'hooks/app';
import { format, subDays } from 'date-fns';

const queryParamsInitialValue: WasteQueryParams = {
  nome: '',
  id_filial: 0,
  data_ini: subDays(new Date(), 30),
  data_fim: new Date(),
};

export function useFetchWastes(_params?: string) {
  const { h2iApi } = useApp();
  const [queryParams, setQueryParams] = useState<WasteQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [wastes, setWastes] = useState<WasteOnList[]>([]);
  const formatter = useWasteFormatter();
  const [total, setTotal] = useState(0);
  const [error, setError] = useState('');

  const fetch = useCallback(() => {
    if (!h2iApi) {
      return;
    }
    const params = {
      ...queryParams,
      data_ini: format(queryParams.data_ini, "dd'/'MM'/'yyyy"),
      data_fim: format(queryParams.data_fim, "dd'/'MM'/'yyyy"),
      id_filial: queryParams.id_filial === 0 ? '' : queryParams.id_filial,
    };

    setLoading(true);

    h2iApi
      .get('/api/getreee', {
        params,
      })
      .then(_response => {
        const response: Waste[] = _response.data.reee;
        if (!response) {
          setWastes([]);
          setTotal(0);
          return;
        }
        setWastes(response.map(item => formatter(item)));
        setTotal(response.length);
      })
      .catch(error => {
        console.error(error);
        setError('Não foi possível recuperar as locações');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [formatter, queryParams, h2iApi]);

  return {
    fetch,
    loading,
    total,
    queryParams,
    setQueryParams,
    wastes,
    setWastes,
    error,
  };
}
