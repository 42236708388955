import { Theme } from '@mui/material';
import React from 'react';
import { useAbcProducts } from '../hooks/useAbcProducts';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import CustomerListTable from './list/table/CustomerListTable';
import Pagination from 'components/pagination/Pagination';
import CustomerListModule from './list/module/CustomerListModule';
import { makeStyles } from '@mui/styles';
import { arraySort } from 'helpers/sort';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  moreDetail: {},
  typography: {
    top: -12,
    left: 15,
    cursor: 'pointer',
    padding: '0px 5px',
    position: 'absolute',
    backgroundColor: '#fafafa',
  },
}));

const AbcProductCustomers: React.FC = () => {
  const classes = useStyles();
  const { displayMode, data } = useAbcProducts();

  const sorted = arraySort('cliente', data.customers, 'asc');

  return (
    <div className={classes.moreDetail}>
      <PaginationProvider>
        <div className={classes.container}>
          {data.customers.length === 0 ? (
            <NoData message="Nenhum cliente encontrado." />
          ) : displayMode === 'list' ? (
            <CustomerListTable customers={sorted} />
          ) : (
            <CustomerListModule customers={sorted} />
          )}
          <Pagination count={sorted.length} />
        </div>
      </PaginationProvider>
    </div>
  );
};

export default AbcProductCustomers;
