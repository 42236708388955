import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { H2iOrder } from 'types/h2iOrder';
import { MoreHoriz } from '@mui/icons-material';
import { useDavReview } from 'pages/dav-review/hook/useDavReview';

type DavReviewItemTableProps = {
  order: H2iOrder;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
});

const DavReviewItemTable: React.FC<DavReviewItemTableProps> = ({ order, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const { setSelectedDav } = useDavReview();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedDav(order);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleClick} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{order[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default DavReviewItemTable;
