import Appbar from 'components/appbar/Appbar';
import React, { FormEvent, useState } from 'react';
import history from 'services/history';
import { Vacancy } from 'types/vacancy';
import VacancyForm from '../VacancyForm';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import VacancyActions from '../VacancyActions';
import { useVacancyValidation } from '../validation/vacancyValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';
import { useSelector } from 'store/selector';

const initial_state: Vacancy = {
  benefits: '',
  department: 'administrative',
  created_at: new Date(),
  id: '',
  is_active: 1,
  local: '',
  observation: '',
  role: '',
  salary: '',
  updated_at: new Date(),
  work_experience: '',
  work_hours: '',
  work_model: '',
  image: null,
  image_id: null,
  user: {
    email: '',
    name: '',
  },
};

const VacancyNew: React.FC = () => {
  const user = useSelector(state => state.user);
  const [saving, setSaving] = useState(false);
  const [vacancy, setVacancy] = useState<Vacancy>(initial_state);
  const [validation, setValidation, validate] = useVacancyValidation();
  const { handleOpen } = useMessaging();

  function handleChange(index: keyof Vacancy, value: any) {
    setVacancy(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(vacancy)
      .then(handleSubmit)
      .catch(err => console.log(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/jobs', { ...vacancy, user_id: user?.id })
      .then(() => {
        setSaving(false);
        history.push('/rh/vacancies');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <InsideSaving />}
      <Appbar title="Nova vaga de emprego" backAction={() => history.push('/rh/vacancies')} />
      <PageHeaderActions
        title="Nova vaga de emprego"
        backUrl="/rh/vacancies"
        ActionComponent={<VacancyActions handleSubmit={handleValidation} />}
      />

      <form onSubmit={handleValidation}>
        <VacancyForm vacancy={vacancy} validation={validation} handleChange={handleChange} />
      </form>
    </>
  );
};

export default VacancyNew;
