import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

interface FinancialTicketTabsProps {
  value: string;
  handleChange(value: string): void;
}

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
});

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 30,
  '&.Mui-selected': {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

const FinancialTicketTabs: React.FC<FinancialTicketTabsProps> = ({ handleChange, value }) => {
  return (
    <CustomTabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <CustomTab value="dashboard" label="Dashboard" />
      <CustomTab value="list" label="Relação de boletos" />
    </CustomTabs>
  );
};

export default FinancialTicketTabs;
