import React, { createContext, FormEvent, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import { useParams } from 'react-router-dom';
import { productChange, setProduct } from '../reducer/actions';
import productReducer, { PRODUCT_INITIAL_STATE } from '../reducer/reducer';
import ProductTab, { ProductTabValues } from './ProductTab';
import * as yup from 'yup';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { ProductEdit, ProductGroup, ProductSubGroup } from 'types/product';
import ProductBasic from './steps/basic/ProductBasic';
import PageHeader from 'components/page-header/PageHeader';
import ProductPrices from './steps/price/ProductPrices';
import ProductTechnical from './steps/technical/ProductTechnical';
import ProductActions from './ProductActions';
import InsideLoading from 'components/loading/InsideLoading';
import { ProductActionsType } from '../reducer/types';
import history from 'services/history';
import { moneyFormat } from 'helpers/numberFormat';
import ProductWarranty from './steps/tax/ProductWarranty';
import Loading from 'components/loading/Loading';
import { usePermissionRules } from 'hooks/permissionRules';
import { useSelector } from 'store/selector';
import ProductImages from './steps/images/ProductImages';

import { makeStyles } from '@mui/styles';
import { Manufacturer } from 'types/manufacturer';

export type ProductValidation = {
  descricao?: string;
  custo?: string;
  margem_lucro?: string;
  peso?: string;
  largura?: string;
  profundidade?: string;
  caixa_master?: string;
  caracteristicas?: string;
  altura?: string;
  foto_principal?: string;
};

const basicForm = ['Produto', 'Codigo_barra'];
const pricesForm = ['Custo', 'Margem'];
const technicalForm = [
  'Peso',
  'Altura',
  'Profundidade',
  'caracteristicas',
  'Caixa_Master',
  'Largura',
  'foto_principal',
];

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  container: {
    flex: 1,
    marginBottom: 200,
    '& form': {},
  },
});

type ProductContextValue = {
  handleChange(index: keyof ProductEdit, value: any): void;
  product: ProductEdit;
  validation: ProductValidation;
  dispatch: React.Dispatch<ProductActionsType>;
  groups: ProductGroup[];
  subGroups: ProductSubGroup[];
  manufacturers: Manufacturer[];
  onSelect(manufacturer: Manufacturer | null): void;
  selectedManufacturer: Manufacturer | null;
  loading: boolean;
};

const ProductContext = createContext<ProductContextValue>({} as ProductContextValue);

export function useProduct(): ProductContextValue {
  const context = useContext(ProductContext);
  return context;
}

const Product: React.FC = () => {
  const classes = useStyles();
  const [selectedManufacturer, setSelectedManufacturer] = useState<Manufacturer | null>(null);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [product, dispatch] = useReducer(productReducer, PRODUCT_INITIAL_STATE);
  const [tab, setTab] = useState<ProductTabValues>('warranty');
  const { id } = useParams<{ id: string }>();
  const [validation, setValidation] = useState<ProductValidation>({});
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(true);
  const { handleOpen: handleMessageOpen } = useMessaging();
  const [subGroups, setSubGroups] = useState<ProductSubGroup[]>([]);
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [saving, setSaving] = useState(false);
  const { checkPermission } = usePermissionRules();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (!user) return;

    if (checkPermission('registrations.products.main')) {
      setTab('product');
      return;
    }

    if (checkPermission('registrations_product_price')) {
      setTab('prices');
      return;
    }

    if (checkPermission('registrations.products.technical_specifications')) setTab('technical');
  }, [checkPermission, user]);

  useEffect(() => {
    if (!h2iApi) return;

    async function loadProduct() {
      if (!h2iApi) return;

      const response = await h2iApi.get('/api/ProdutoID', { params: { id } });
      const p: ProductEdit = response.data.RESULT[0][0];
      dispatch(
        setProduct({
          ...p,
          main_image: p.foto_principal
            ? {
                id: new Date().getTime(),
                filename: 'foto_principal',
                imageThumbUrl: p.foto_principal,
                thumb: 'foto_principal_thum',
                imageUrl: p.foto_principal,
              }
            : null,
          image1: p.foto_1
            ? {
                id: new Date().getTime(),
                filename: 'foto_1',
                imageThumbUrl: p.foto_1,
                thumb: 'foto_1_thumb',
                imageUrl: p.foto_1,
              }
            : null,
          image2: p.foto_2
            ? {
                id: new Date().getTime(),
                filename: 'foto_2',
                imageThumbUrl: p.foto_2,
                thumb: 'foto_2_thumbb',
                imageUrl: p.foto_2,
              }
            : null,
          image3: p.foto_3
            ? {
                id: new Date().getTime(),
                filename: 'foto_3',
                imageThumbUrl: p.foto_3,
                thumb: 'foto_3_thumb',
                imageUrl: p.foto_3,
              }
            : null,
          precoquantidade: p.precoquantidade.map(item => {
            item.formattedValorPf = moneyFormat(item.VALOR_PF);
            item.formattedValorPj = moneyFormat(item.VALOR_PJ);
            return item;
          }),
        }),
      );
    }

    async function productSubGroup() {
      if (!h2iApi) return;

      const response = await h2iApi.get('/api/subgrupo');
      setSubGroups(response.data.RESULT[0]);
    }

    async function productGroup() {
      if (!h2iApi) return;

      const response = await h2iApi.get('/api/grupo');
      setGroups(response.data);
    }

    async function productManufacturers() {
      if (!h2iApi) return;

      const response = await h2iApi.get('/api/getfabricantes');
      setManufacturers(response.data);
    }

    Promise.all([loadProduct(), productSubGroup(), productGroup(), productManufacturers()])
      .catch(() => {
        handleMessageOpen('Não foi possível carregar o produto');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [h2iApi, id, handleMessageOpen]);

  useEffect(() => {
    if (!manufacturers && !product) {
      return;
    }

    manufacturers.filter(
      item => item.id_fabricante === parseInt(product.codigo_fabricante) && setSelectedManufacturer(item),
    );
  }, [manufacturers, product]);

  const handleChange = useCallback((index: keyof ProductEdit, value: any) => {
    setValidation({});
    dispatch(productChange(index, value));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      caixa_master: yup.number().typeError('A caixa master é obrigatória').required('A caixa master é obrigatória'),
      largura: yup.number().typeError('A largura é obrigatória').required('A largura é obrigatória'),
      altura: yup.number().typeError('A altura é obrigatória').required('A altura é obrigatória'),
      profundidade: yup.number().typeError('O comprimento é obrigatório').required('O comprimento é obrigatório'),
      peso: yup.string().required('O peso do produto é obrigatório'),
      margem_lucro: yup
        .number()
        .typeError('O valor da margem é obrigatório')
        .required('O valor da margem é obrigatório'),
      custo: yup.number().typeError('O custo do produto é obrigatório').required('O custo do produto é obrigatório'),
      descricao: yup.string().required('A descrição é obrigatória'),
      foto_principal: yup.string().required('A imagem é obrigatória'),
    });

    schema
      .validate(product, { abortEarly: true })
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err.path);
        if (err instanceof yup.ValidationError) {
          setValidation({
            [err.path as string]: err.message,
          });

          if (basicForm.includes(err.path as string)) setTab('product');
          if (pricesForm.includes(err.path as string)) setTab('prices');
          if (technicalForm.includes(err.path as string)) setTab('technical');
        }
        throw err;
      });
  }

  function handleSubmit() {
    setSaving(true);

    if (!h2iApi) return;

    h2iApi
      .post('/api/saveproduto', product)
      .then(() => {
        handleMessageOpen('Salvo');
        history.push('/products');
      })
      .catch(err => {
        if (err.response) handleMessageOpen(err.message);
        setSaving(false);
      });
  }

  const onSelect = (manufacturer: Manufacturer | null) => {
    setSelectedManufacturer(manufacturer);
    handleChange('codigo_fabricante', manufacturer?.id_fabricante || '');
  };

  return (
    <>
      {saving && <Loading />}
      <Appbar
        backAction={() => history.push('/products')}
        title="Produto"
        Tab={<ProductTab tab={tab} handleChange={tab => setTab(tab)} />}
        ActionsComponent={<ProductActions handleSubmit={handleValidation} />}
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <PageHeader title="Editar produto" description={product.produto} backUrl="/products" />
          <Grid container direction="column" className={classes.container} justifyContent="space-between">
            <ProductContext.Provider
              value={{
                handleChange,
                product,
                validation,
                dispatch,
                groups,
                subGroups,
                manufacturers,
                onSelect,
                selectedManufacturer,
                loading,
              }}
            >
              <form onSubmit={handleValidation}>
                {tab === 'product' ? (
                  <ProductBasic />
                ) : tab === 'prices' ? (
                  <ProductPrices />
                ) : tab === 'technical' ? (
                  <ProductTechnical />
                ) : tab === 'warranty' ? (
                  <ProductWarranty />
                ) : (
                  tab === 'images' && <ProductImages />
                )}
                <button type="submit" style={{ display: 'none' }} />
              </form>
            </ProductContext.Provider>
          </Grid>
        </>
      )}
    </>
  );
};

export default Product;
