import React from 'react';
import { Collapse, List } from '@mui/material';
import { Computer, Image, Mail } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { usePermissionRules } from 'hooks/permissionRules';
import { AiFillControl } from 'react-icons/ai';
import { useSidebar } from '../hook/useSidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import SidebarItemMore from '../SidebarItemMore';

const Settings: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible, setImageManager, setShownSite, setShownUserControl } = useSidebar();
  return (
    <>
      {checkPermission('settings') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('settings')}
            text="Configurações"
            opened={collapsible.settings}
            icon={<SettingsIcon />}
          />
          <Collapse in={collapsible.settings}>
            <List disablePadding>
              {checkPermission('settings.control_panel') && (
                <SidebarItem to="/settings" icon={<AiFillControl />} text="Painel de Controle" nested={5} />
              )}

              {checkPermission('settings.credit_analysis') && (
                <SidebarItem
                  to="/credit-analysis-questions"
                  icon={<AiFillControl />}
                  text="Questões para Análise de crédito"
                  nested={5}
                />
              )}

              {checkPermission('settings.user_control') && (
                <SidebarItemMore
                  icon={<Computer />}
                  handleClick={() => setShownUserControl(true)}
                  text="Controle de usuário"
                  nested={5}
                />
              )}

              {checkPermission('settings.email_templates') && (
                <SidebarItem to="/email-templates" icon={<Mail />} text="E-mails" nested={5} />
              )}

              {checkPermission('settings.site') && (
                <SidebarItemMore
                  icon={<Computer />}
                  handleClick={() => setShownSite(true)}
                  text="h2i.com.br"
                  nested={5}
                />
              )}

              {checkPermission('settings.site') && (
                <SidebarItem
                  text="Imagens"
                  icon={<Image />}
                  onClick={() => setImageManager(true)}
                  isLink={false}
                  nested={5}
                />
              )}

              {checkPermission('settings.test-area') && (
                <SidebarItem to="/dev-area" icon={<AiFillControl />} text="Área de testes" nested={5} />
              )}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};

export default Settings;
