import React from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { BillParams } from './Bills';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { BillSetting } from 'types/billSetting';

interface BillFilterMobileProps {
  onExited(): void;
  params: BillParams;
  loading: boolean;
  billets: BillSetting[];
  handleChangeParams(index: keyof BillParams, value: any): void;
}

const BillFilterMobile: React.FC<BillFilterMobileProps> = ({
  onExited,
  params,
  handleChangeParams,
  loading,
  billets,
}) => {
  return (
    <Dialog title="Filtro" onExited={onExited} maxWidth="sm">
      <div style={{ display: 'grid', gap: 15 }}>
        <TextField
          select
          label="Layout"
          placeholder="Informe a identificação do banco"
          fullWidth
          margin="normal"
          autoFocus
          value={params.bank_billet_setting_id}
          onChange={e => handleChangeParams('bank_billet_setting_id', e.target.value)}
        >
          {billets.map(bill => (
            <MenuItem key={bill.id} value={bill.id}>
              {bill.bank_name}
            </MenuItem>
          ))}
        </TextField>
        <KeyboardDatePicker
          label="Emissão inicial"
          value={params.initial_date}
          onChange={date => handleChangeParams('initial_date', date)}
          textFieldProps={{ margin: 'none' }}
        />
        <KeyboardDatePicker
          label="Emissão Final"
          value={params.final_date}
          onChange={date => handleChangeParams('final_date', date)}
          textFieldProps={{ margin: 'none' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              checked={params.sent}
              onChange={e => handleChangeParams('sent', e.target.checked)}
              color="primary"
            />
          }
          label="Não transmitidos"
        />
      </div>
    </Dialog>
  );
};

export default BillFilterMobile;
