import React from 'react';
import NoteEntryDashboardTotal from './NoteEntryDashboardTotal';
import { NoteEntry } from 'types/noteEntry';
import NoData from 'components/no-data/NoData';
import { useFetchNoteEntryDashboard } from './hooks/useFetchNoteEntryDashboard';
import PaginationProvider from 'hooks/pagination';
import NoteEntryDashboardBranches from './branch/NoteEntryDashboardBranches';
import NoteEntryDashboardSuppliers from './document/NoteEntryDashboardSuppliers';
import NoteEntryDashboardCfops from './cfop/NoteEntryDashboardCfops';
import { styled } from '@mui/material';

interface NoteEntryDashboardProps {
  notes: NoteEntry[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const NoteEntryDashboard: React.FC<NoteEntryDashboardProps> = ({ notes }) => {
  const { groupedBranches, groupedSuppliers, groupedCfops } = useFetchNoteEntryDashboard(notes);

  return (
    <Container>
      <NoteEntryDashboardTotal notes={notes} />

      {notes.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <NoteEntryDashboardBranches branches={groupedBranches} />

          <PaginationProvider>
            <NoteEntryDashboardSuppliers suppliers={groupedSuppliers} />
          </PaginationProvider>

          <NoteEntryDashboardCfops cfops={groupedCfops} />
        </Content>
      )}
    </Container>
  );
};

export default NoteEntryDashboard;
