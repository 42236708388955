import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { RevenuesProvider } from './hooks/useRevenues';
import PageHeaderButtons from './PageHeaderButtons';
import { Revenue } from 'types/revenue';
import { useFetchRevenues } from './hooks/useFetchRevenues';
import useTableOrder from 'hooks/tableOrder';
import { useSelector } from 'store/selector';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import { revenuesTableTemplate } from './revenuesTableTemplate';
import Pagination from 'components/pagination/Pagination';
import RevenuesFilterBox from './RevenuesFilterBox';
import RevenuesReport from './report/RevenuesReport';
import NewRevenue from './registration/new/NewRevenue';
import { useApp } from 'hooks/app';
import EditRevenue from './registration/edit/EditRevenue';
import RevenueListTable from './list/table/RevenueListTable';
import RevenueListModule from './list/module/RevenueListModule';
import { useFinancial } from '../hooks/useFinancial';
import RevenuesActions from './RevenuesActions';

export interface RevenuesFilterProps {
  description: string;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const filterInitialState = {
  description: '',
  branchId: 8,
};

const Revenues: React.FC = () => {
  const { handleBack } = useFinancial();
  const user = useSelector(state => state.user);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const { isMobile, windowWidth } = useApp();
  const [filtered, setFiltered] = useState<Revenue[]>([]);
  const [selectedRevenue, setSelectedRevenue] = useState<Revenue | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [filter, setFilter] = useState<RevenuesFilterProps>(filterInitialState);
  const [isNewRevenueOpen, setIsNewRevenueOpen] = useState(false);
  const [revenues, loading, fetch] = useFetchRevenues(filter);
  const [isReportOpen, setIsReportOpen] = useState(false);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFiltered(revenues);
  }, [revenues]);

  useEffect(() => {
    const branchId =
      user && user.branchList.length ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999;

    setFilter(state => ({
      ...state,
      branch_id: branchId,
    }));
  }, [user]);

  function handleChangeFilter(index: keyof RevenuesFilterProps, value: any) {
    setFilter(filter => ({
      ...filter,
      [index]: value,
    }));
  }

  function handleSearch() {
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSubmit() {
    setIsNewRevenueOpen(false);
    setSelectedRevenue(null);
    fetch();
  }

  return (
    <RevenuesProvider
      value={{
        revenues,
        loading,
        setIsNewRevenueOpen,
        setIsReportOpen,
        handleChangeFilter,
        handleSearch,
        setSelectedRevenue,
        filter,
      }}
    >
      {selectedRevenue && (
        <EditRevenue
          selectedRevenue={selectedRevenue}
          setSelectedRevenue={setSelectedRevenue}
          onExited={() => setSelectedRevenue(null)}
          onSubmit={onSubmit}
        />
      )}

      {isReportOpen && <RevenuesReport onExited={() => setIsReportOpen(false)} />}

      {isNewRevenueOpen && <NewRevenue onSubmit={onSubmit} onExited={() => setIsNewRevenueOpen(false)} />}

      <Appbar title="Classificação de receitas" backAction={handleBack} ActionsComponent={<RevenuesActions />} />

      <PageHeaderActions title="Classificação de receitas" ActionComponent={<PageHeaderButtons />} />

      {windowWidth < 600 && <PageHeaderButtons />}

      <RevenuesFilterBox />

      <TableContainer tableTemplate={revenuesTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma receita encontrada" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <RevenueListTable revenues={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <RevenueListModule revenues={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </RevenuesProvider>
  );
};

export default Revenues;
