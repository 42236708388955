import React from 'react';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Button, TextField, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import { usePurchaseOrder } from './hooks/PurchaseOrderProvider';
import FilterBox from 'components/filter-box/FilterBox';

type PurchaseOrderFilterProps = {
  showFilter: boolean;
};

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  gridTemplateColumns: '180px 1fr 120px 120px 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '180px 1fr 1fr 1fr 100px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const PurchaseOrderFilterPage: React.FC<PurchaseOrderFilterProps> = ({ showFilter }) => {
  const { filters, filter, handleChange, handleSearch } = usePurchaseOrder();
  return (
    <>
      {showFilter && (
        <FilterBox>
          <Filter onSubmit={event => handleSearch(filters, event)}>
            <SelectBranch branch_id={filter.branch_id} handleChange={e => handleChange('branch_id', e)} />
            <TextField
              label="Produto"
              placeholder="Digite sua pesquisa"
              autoFocus
              value={filter.product}
              onChange={e => handleChange('product', e.target.value)}
            />
            <TextField
              type="number"
              inputMode="decimal"
              label="AGING"
              placeholder="Pesquisar..."
              autoFocus
              value={filter.aging}
              onChange={e => handleChange('aging', e.target.value)}
            />
            <TextField
              type="number"
              inputMode="decimal"
              label="Forecast"
              placeholder="Pesquisar..."
              autoFocus
              value={filter.forecast}
              onChange={e => handleChange('forecast', e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
              Buscar
            </Button>
          </Filter>
        </FilterBox>
      )}
    </>
  );
};

export default PurchaseOrderFilterPage;
