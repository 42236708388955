import React, { MouseEvent } from 'react';
import { Icon, Tab, Tabs, styled } from '@mui/material';
import { FaFileExcel } from 'react-icons/fa';
import { usePermissionRules } from 'hooks/permissionRules';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';
import { download } from 'helpers/download';
import { usePurchaseOrder } from './hooks/PurchaseOrderProvider';

interface PurchaseOrderTabsProps {
  value: string;
  handleChange(value: string): void;
}

const CustomIcon = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#ddd',
    borderRadius: 50,
  },
});

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
});

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 30,
  '&.Mui-selected': {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

const PurchaseOrderTabs: React.FC<PurchaseOrderTabsProps> = ({ handleChange, value }) => {
  const { checkPermission } = usePermissionRules();
  const { transfers, ordersProducts, orders, agingList } = usePurchaseOrder();

  function handleClick(title: string, event: MouseEvent<HTMLSpanElement>) {
    event.stopPropagation();

    switch (title) {
      case 'dashboard':
        handleDownload(title, transfers);
        break;
      case 'registration':
        handleDownload(title, orders);
        break;
      case 'items':
        handleDownload(title, ordersProducts);
        break;
      case 'aging':
        handleDownload(title, agingList);
        break;
      case 'segments':
        handleDownload(title, transfers);
        break;
      case 'stockItems':
        handleDownload(title, transfers);
        break;
      case 'transferRequests':
        handleDownload(title, transfers);
        break;
      case 'shoppingSuggestion':
        handleDownload(title, transfers);
        break;
    }
  }

  async function handleDownload(title: string, data: any[]) {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `${title}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <CustomTabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <CustomTab value="dashboard" label="Dashboard" />
      {checkPermission('purchasing.order.registration') && (
        <CustomTab
          value="registration"
          label="Pedidos"
          icon={
            checkPermission('general.excel_export_button') ? (
              <CustomIcon onClick={e => handleClick('registration', e)}>
                <FaFileExcel style={{ height: 18, width: 18 }} />
              </CustomIcon>
            ) : (
              <></>
            )
          }
          iconPosition="end"
        />
      )}
      {checkPermission('purchasing.order.items') && (
        <CustomTab
          value="items"
          label="Itens dos pedidos"
          icon={
            checkPermission('general.excel_export_button') ? (
              <CustomIcon onClick={e => handleClick('items', e)}>
                <FaFileExcel style={{ height: 18, width: 18 }} />
              </CustomIcon>
            ) : (
              <></>
            )
          }
          iconPosition="end"
        />
      )}
      {checkPermission('purchasing.order.aging') && (
        <CustomTab
          value="aging"
          label="Aging"
          icon={
            checkPermission('general.excel_export_button') ? (
              <CustomIcon onClick={e => handleClick('aging', e)}>
                <FaFileExcel style={{ height: 18, width: 18 }} />
              </CustomIcon>
            ) : (
              <></>
            )
          }
          iconPosition="end"
        />
      )}
      {checkPermission('purchasing.order.segments') && <CustomTab value="segments" label="Segmentos" />}
      {checkPermission('purchasing.order.stockItems') && <CustomTab value="stockItems" label="Estoque crítico" />}
      {checkPermission('purchasing.order.transferRequests') && (
        <CustomTab
          value="transferRequests"
          label="Solicitações de Transf."
          icon={
            checkPermission('general.excel_export_button') ? (
              <CustomIcon onClick={e => handleClick('transferRequests', e)}>
                <FaFileExcel style={{ height: 18, width: 18 }} />
              </CustomIcon>
            ) : (
              <></>
            )
          }
          iconPosition="end"
        />
      )}
      {checkPermission('purchasing.order.flow') && <CustomTab value="flow" label="Fluxo" />}
      {checkPermission('purchasing.order.shoppingSuggestion') && (
        <CustomTab value="shoppingSuggestion" label="Sugestão de reposição" />
      )}
    </CustomTabs>
  );
};

export default PurchaseOrderTabs;
