import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { FinancialTicket } from 'types/financialReportList';
import { useFinancialTicket } from '../../hooks/useFinancialTicket';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

type TicketItemTableProps = {
  ticket: FinancialTicket;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const TicketItemTable: React.FC<TicketItemTableProps> = ({ ticket, setAnchorEl }) => {
  const { setSelectedTicket } = useFinancialTicket();
  const { tableTemplate } = useTable();

  function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTicket(ticket);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'acoes' ? (
            <CustomIconButton key={item.id} onClick={handleButtonClick}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Tooltip title={ticket[item.id]}>
                <Description variant="caption">{ticket[item.id]}</Description>
              </Tooltip>
            </NumericData>
          ),
        )}
    </>
  );
};

export default TicketItemTable;
