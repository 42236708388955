import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import ModuleLoading from 'components/loading/ModuleLoading';
import { styled } from '@mui/material';
import { useFetchResumes } from './hooks/useFetchResumes';
import PageHeader from 'components/page-header/PageHeader';
import CurriculumListModule from './list/CurriculumListModule';
import CurriculumFilter from './CurriculumFilter';
import ApiPagination from 'components/pagination/ApiPagination';
import { ResumesProvider } from './hooks/useResumes';
import { Curriculum } from 'types/curriculum';
import InsideSaving from 'components/loading/InsideSaving';
import DeleteCurriculum from './dialog/DeleteCurriculum';
import ViewCurriculum from './dialog/ViewCurriculum';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const CurriculumPage: React.FC = () => {
  const { fetch, loading, queryParams, handleChangeQueryParams, resumes, total } = useFetchResumes();
  const [selectedCurriculum, setSelectedCurriculum] = useState<Curriculum | null>(null);
  const [anchorEl, setAnchorEl] = useState<Curriculum | null>(null);
  const [saving, setSaving] = useState(false);

  function onDelete() {
    setAnchorEl(null);
    fetch(queryParams);
  }

  function onExited() {
    setAnchorEl(null);
    setSelectedCurriculum(null);
  }

  return (
    <ResumesProvider value={{ selectedCurriculum, setSaving, setSelectedCurriculum }}>
      <Appbar title="Currículos" />
      <PageHeader title="Currículos" description="Gestão dos currículos" />
      {saving && <InsideSaving />}

      {anchorEl && <DeleteCurriculum onDelete={onDelete} selectedCurriculum={anchorEl} onExited={onExited} />}

      {selectedCurriculum && <ViewCurriculum selectedCurriculum={selectedCurriculum} onExited={onExited} />}

      <CurriculumFilter filter={queryParams} handleChange={handleChangeQueryParams} handleSearch={fetch} />

      <PaginationProvider>
        {loading ? (
          <ModuleLoading />
        ) : resumes.length === 0 ? (
          <NoData message="Nenhum currículo cadastrado" />
        ) : (
          <Container>
            <CurriculumListModule setAnchorEl={setAnchorEl} resumes={resumes} />
            <ApiPagination
              onChangePage={value => handleChangeQueryParams('page', value)}
              onChangeRowsPerPage={value => handleChangeQueryParams('rows', value)}
              count={total}
            />
          </Container>
        )}
      </PaginationProvider>
    </ResumesProvider>
  );
};

export default CurriculumPage;
