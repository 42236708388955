import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState, useCallback } from 'react';
import { api } from 'services/api';
import BillsFilterBox from './BillsFilterBox';
import { bannersTableTemplate } from './billsTableTemplate';
import { BillsProvider } from './hooks/useBills';
import BillListModule from './list/module/BillListModule';
import BillListTable from './list/table/BillListTable';
import BillsActions from './BillsActions';
import { Bill } from 'types/bill';
import { moneyFormat } from 'helpers/numberFormat';
import BillRemittance from './remittance/BillRemittance';
import Remittances from './remittances/Remittances';
import BillFilterMobile from './BillFilterMobile';
import { BillSetting } from 'types/billSetting';
import { useFinancial } from 'pages/financial/hooks/useFinancial';
import BillTotal from './BillTotal';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export type BillParams = {
  sent: boolean;
  bank_billet_setting_id: number;
  initial_date: Date;
  final_date: Date;
};

const paramsInitialValue = {
  sent: true,
  initial_date: startOfMonth(new Date()),
  final_date: new Date(),
  bank_billet_setting_id: 1,
};

const Bills: React.FC = () => {
  const { handleBack } = useFinancial();
  const [bills, setBills] = useState<Bill[]>([]);
  const [billets, setBillets] = useState<BillSetting[]>([]);
  const [selectedBill, setSelectedBill] = useState<null | Bill>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Bill[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [dialogRemittance, setDialogRemittance] = useState(false);
  const [dialogRemittances, setDialogRemittances] = useState(false);
  const [dialogFilter, setDialogFilter] = useState(false);
  const [params, setParams] = useState<BillParams>(paramsInitialValue);

  useEffect(() => {
    setFiltered(bills);
  }, [bills]);

  useEffect(() => {
    api
      .get<BillSetting[]>('/bank-billet-settings')
      .then(response => setBillets(response.data))
      .catch(err => console.error(err));
  }, []);

  const loadBills = useCallback(() => {
    setLoading(true);
    api
      .get<Bill[]>('/bankBillets', { params: { ...params, sent: params.sent ? 0 : 1 } })
      .then(response => {
        setBills(
          response.data.map(item => {
            item.formattedCreatedAt = format(parseISO(item.created_at), 'PPp', { locale: ptBR });
            item.formattedCanceledAt = item.canceled_at
              ? format(parseISO(item.canceled_at), 'PPp', { locale: ptBR })
              : '';
            item.beneficiaryName = item.beneficiary.company_name;
            item.payerName = item.payer.company_name;
            item.formattedValue = moneyFormat(item.value);
            item.formattedPayday = format(parseISO(item.payday), 'PP', { locale: ptBR });
            return item;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    loadBills();
  }, [loadBills]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleCancel() {
    api.put(`/bankBillets/${selectedBill?.id}/cancel`).then(response => {
      setBills(state =>
        state.map(bill => {
          if (bill.id === selectedBill?.id) {
            bill.formattedCanceledAt = format(parseISO(response.data.canceled_at), 'PPp', { locale: ptBR });

            bill.canceled_at = response.data.canceled_at;
          }

          return bill;
        }),
      );
    });
  }

  function handleChangeParams(index: keyof BillParams, value: any) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <BillsProvider value={{ selectedBill, setSelectedBill, handleCancel, setBills, loading }}>
      {dialogFilter && (
        <BillFilterMobile
          billets={billets}
          loading={loading}
          params={params}
          handleChangeParams={handleChangeParams}
          onExited={() => setDialogFilter(false)}
        />
      )}
      {dialogRemittance && <BillRemittance onExited={() => setDialogRemittance(false)} />}
      {dialogRemittances && <Remittances onExited={() => setDialogRemittances(false)} />}
      <Appbar
        backAction={handleBack}
        title="Site"
        ActionsComponent={
          <BillsActions
            handleDialogRemittances={() => setDialogRemittances(true)}
            handleDialogRemittance={() => setDialogRemittance(true)}
            handleDialogFilter={() => setDialogFilter(true)}
          />
        }
      />
      <PageHeaderActions
        title="Boletos para remessa"
        description="Gestão dos boletos"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => setDialogRemittance(true)}>
              Criar remessa
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={() => setDialogRemittances(true)}>
              Remessas
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={bannersTableTemplate}>
        <BillsFilterBox
          billets={billets}
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          loadBills={loadBills}
          params={params}
          handleChangeParams={handleChangeParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum boleto para mostrar" />
        ) : (
          <PaginationProvider>
            <BillTotal bills={filtered} loading={loading} />
            <Container>
              {displayMode === 'list' ? (
                <BillListTable bills={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <BillListModule bills={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </BillsProvider>
  );
};

export default Bills;
