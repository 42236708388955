import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Pagination from 'components/pagination/Pagination';
import { Reserve } from 'types/reserve';
import PageHeader from 'components/page-header/PageHeader';
import { useFetchReserve } from './hooks/useFetchReserve';
import ReserveAnalysisFilterBox from './ReserveAnalysisFilterBox';
import ReserveAnalysisListTable from './list/table/ReserveAnalysisListTable';
import ReserveAnalysisListModule from './list/module/ReserveAnalysisListModule';
import ReserveAnalysisTab from './tab/ReserveTabs';
import ReserveDashboard from './dashboard/ReserveDashboard';

export interface ItemsSoldFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const ReserveAnalysis: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const [tab, setTab] = useState<'items' | 'dashboard'>('dashboard');
  const { loading, reserves, filter, subgroups, loadingSubgroups, handleChangeFilter, onSearch } = useFetchReserve();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<Reserve[]>([]);

  useEffect(() => {
    setFiltered(reserves);
  }, [reserves]);

  function handleSort(index: string) {
    const filtered = sort(index, reserves);
    setFiltered(filtered);
  }

  return (
    <>
      <Appbar title="Reserva" />
      <PageHeader title="Reserva" />

      <ReserveAnalysisFilterBox
        filter={filter}
        loadingSubgroups={loadingSubgroups}
        subgroups={subgroups}
        handleChangeFilter={handleChangeFilter}
        loading={loading}
        onSearch={onSearch}
      />

      <ReserveAnalysisTab tab={tab} handleChange={value => setTab(value)} />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum item para mostrar" />
      ) : tab === 'items' ? (
        <PaginationProvider>
          <div>
            {displayMode === 'list' ? (
              <ReserveAnalysisListTable reserves={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              <ReserveAnalysisListModule reserves={filtered} />
            )}
            <Pagination count={filtered.length} />
          </div>
        </PaginationProvider>
      ) : (
        <ReserveDashboard reserves={reserves} />
      )}
    </>
  );
};

export default ReserveAnalysis;
