import { Tabs, Tab } from '@mui/material';
import React from 'react';

interface EditInvoiceTabsProps {
  type: string;
  tab: string;
  onChange(value: string): void;
}

const EditInvoiceTabs: React.FC<EditInvoiceTabsProps> = ({ type, onChange, tab }) => {
  return (
    <Tabs value={tab} onChange={(e, value) => onChange(value)}>
      <Tab value="data" label="Geral" />
      {type === 'R' && <Tab value="payments" label="Pagamentos" />}
    </Tabs>
  );
};

export default EditInvoiceTabs;
