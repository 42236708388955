type Store<T> = {
  get: () => T;
  set: (value: T) => void;
  subscribe: (callback: () => void) => () => void;
};

function createStore<T>(key: string, initialValue: T): Store<T> {
  let value = initialValue;

  try {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue !== null) {
      value = JSON.parse(storedValue);
    }
  } catch (e) {
    console.error(e);
  }

  const listeners = new Set<() => void>();

  return {
    get: () => value,
    set: (newValue: T) => {
      value = newValue;
      sessionStorage.setItem(key, JSON.stringify(newValue));
      listeners.forEach(listener => listener());
    },
    subscribe: (listener: () => void) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
}

export default createStore;
