import { IconButton, styled, Tooltip } from '@mui/material';
import React from 'react';
import { Add, Print } from '@mui/icons-material';
import { useExpenses } from './hooks/useExpenses';
import { usePermissionRules } from 'hooks/permissionRules';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { FaFileExcel } from 'react-icons/fa';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const ExpensesActions: React.FC = () => {
  const { setIsReportOpen, setIsNewExpenseOpen, expenses } = useExpenses();
  const { handleDownload } = useDefaultExcelExport(expenses);
  const { checkPermission } = usePermissionRules();

  return (
    <Container>
      <IconButton onClick={() => setIsNewExpenseOpen(true)} color="inherit">
        <Add />
      </IconButton>
      <IconButton color="inherit" onClick={() => setIsReportOpen(true)}>
        <Print />
      </IconButton>
      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <IconButton color="inherit" onClick={handleDownload}>
            <FaFileExcel />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
};

export default ExpensesActions;
