import React from 'react';
import { darken, lighten, styled, Typography } from '@mui/material';
import { ProductSubGroup } from 'types/product';
import { LineAxis } from '@mui/icons-material';
import { CommercialAnalysisTotal } from './hooks/useFetchCommercialAnalysisSubgroup';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import { numberFormat } from 'helpers/numberFormat';

interface CommercialAnalysisSubgroupHeaderProps {
  subgroup: ProductSubGroup;
  total: CommercialAnalysisTotal;
  suggestions: ShoppingSuggestion[];
}

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridColumn: '1/3',
  gridTemplateColumns: '1.4fr 1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumn: '1/2',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '&.first': {
    gridRow: '1/2',
    gridColumn: '1/3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/4',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/3',
    },
  },
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    color: darken('#f1f7ef', 0.5),
    fontWeight: 600,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 15px',
  },
  '& .error': {
    color: '#fff',
    background: theme.palette.error.light,
  },
  '& .green': {
    background: lighten(theme.palette.success.light, 0.6),
    color: darken(theme.palette.success.main, 0.1),
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .third': {
    background: '#eeeff8',
    color: darken('#eeeff8', 0.5),
  },
  '& .fourth': {
    background: '#f8eef3',
    color: darken('#f8eef3', 0.5),
  },
  '& .fifth': {
    background: '#e3e3e3',
    color: darken('#e3e3e3', 0.5),
  },
  '& .sixth': {
    background: '#ffc8c8',
    color: darken('#ffc8c8', 0.5),
  },
  '& .seven': {
    background: '#898fbd',
    color: darken('#898fbd', 0.5),
  },
  '& .eight': {
    background: '#d0f7f7',
    color: darken('#d0f7f7', 0.5),
  },
  '& .nine': {
    background: '#bce9d6',
    color: darken('#bce9d6', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
  '&.last': {
    gridColumn: '1/3',
    gridRow: '2/3',
    [theme.breakpoints.down('lg')]: {
      gridColumn: '1/3',
    },
  },
}));

const CommercialAnalysisSubgroupHeader: React.FC<CommercialAnalysisSubgroupHeaderProps> = ({
  suggestions,
  subgroup,
  total,
}) => {
  const forecastTotal = suggestions.reduce((acc, curr) => acc + curr.forecast, 0);
  const suggestionTotal = suggestions.reduce((acc, curr) => acc + curr.sugestao, 0);
  const stockTotal = suggestions.reduce((acc, curr) => acc + curr.estoque, 0);
  const ordersTotal = suggestions.reduce((acc, curr) => acc + curr.pedidos, 0);

  const productInfo = {
    descricao: subgroup.NOME,
    comprador: suggestions[0]?.comprador,
    dias_estoque: numberFormat((stockTotal / forecastTotal) * 30, 0),
    sugestao: (forecastTotal / 25) * 30 - stockTotal - ordersTotal,
    sugestao_total: suggestionTotal,
    forecast_total: forecastTotal,
    estoque_total: stockTotal,
    pedidos_total: ordersTotal,
  };

  return (
    <Container>
      <Box className="first">
        <Typography className="title first" variant="body2" fontSize={18}>
          Subgrupo
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {productInfo.descricao}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title seven" variant="body2" fontSize={18}>
          Saldo de Estoque
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>

            <Typography className="value" variant="body2">
              {total.stock}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" fontSize={11}>
              Valor
            </Typography>

            <Typography className="value" variant="body2">
              {total.formattedStockValue}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Pedidos
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {total.orders}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title eight" variant="body2" fontSize={18}>
          Forecast 30d
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {productInfo.forecast_total}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className={productInfo.sugestao > 0 ? 'error title' : 'green title'} variant="body2" fontSize={18}>
          Sugestão
        </Typography>
        <div className="main">
          <Typography color={productInfo.sugestao > 0 ? 'error' : 'green'} className="value" variant="body2">
            {total.suggestion}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography
          className={parseInt(productInfo.dias_estoque) < 30 ? 'error title' : 'green title'}
          variant="body2"
          fontSize={18}
        >
          Dias Estoque
        </Typography>
        <div className="main">
          <Typography
            color={parseInt(productInfo.dias_estoque) < 30 ? 'error' : 'green'}
            className="value"
            variant="body2"
          >
            {productInfo.dias_estoque}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title nine" variant="body2" fontSize={18}>
          Comprador
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {productInfo.comprador}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Reservas
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {total.reserve}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Índice de RMA
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>
            <Typography className="value" variant="body2">
              {total.qtd}
            </Typography>
          </div>

          <div>
            <Typography variant="caption" fontSize={11}>
              Percentual
            </Typography>
            <Typography className="value" variant="body2">
              {total.formattedRma}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title" variant="body2" fontSize={18}>
          Média compras mês
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>
            <Typography className="value" variant="body2">
              {total.averagePurchases}
            </Typography>
          </div>

          <div>
            <Typography variant="caption" fontSize={11}>
              Investimento
            </Typography>
            <Typography className="value" variant="body2">
              {total.averagePurchasesValue}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title" variant="body2" fontSize={18}>
          Média vendas mês
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>
            <Typography className="value" variant="body2">
              {total.averageSales}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" fontSize={11}>
              Faturamento
            </Typography>
            <Typography className="value" variant="body2">
              {total.averageSalesValue}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default CommercialAnalysisSubgroupHeader;
