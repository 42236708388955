import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import { Email } from 'types/email';
import ExportEmailItemModule from './ExportEmailItemModule';
import { styled } from '@mui/material';

type ExportEmailListModuleProps = {
  emails: Email[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const ExportEmailListModule: React.FC<ExportEmailListModuleProps> = ({ emails }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <ListStyled>
      {emails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email, index) => (
        <ExportEmailItemModule key={index + 1} email={email} />
      ))}
    </ListStyled>
  );
};

export default ExportEmailListModule;
