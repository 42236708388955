import { styled, Typography } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { Curriculum } from 'types/curriculum';

interface ViewCurriculumProps {
  onExited(): void;
  selectedCurriculum: Curriculum;
}

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const ViewCurriculum: React.FC<ViewCurriculumProps> = ({ onExited, selectedCurriculum }) => {
  return (
    <Dialog maxWidth="sm" height="50vh" onExited={onExited} title={`Currículo de ${selectedCurriculum.name}`}>
      <Container>
        <Typography variant="subtitle2" fontSize={13}>
          <b>Nome:</b> {selectedCurriculum.name}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Área:</b> {selectedCurriculum.department}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Cargo:</b> {selectedCurriculum.role}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Escolaridade:</b> {selectedCurriculum.education_level}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Telefone:</b> {selectedCurriculum.phone}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Email:</b> {selectedCurriculum.email}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Cidade:</b> {selectedCurriculum.city}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Publicado:</b> {selectedCurriculum.formattedCreatedAt}
        </Typography>

        <Typography variant="subtitle2" fontSize={13}>
          <b>Biografia:</b> {selectedCurriculum.biography}
        </Typography>

        <a
          href={selectedCurriculum.document?.url}
          target="_blank"
          style={{ fontSize: 13, color: selectedCurriculum.document ? 'primary' : '#ff0000' }}
          rel="noreferrer"
        >
          {selectedCurriculum.document ? 'Visualizar CV' : 'Currículo não anexado'}
        </a>
      </Container>
    </Dialog>
  );
};

export default ViewCurriculum;
