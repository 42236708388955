import { Button, TextField, styled } from '@mui/material';
import React from 'react';

interface SerialNumberFormProps {
  serialNumber: string;
  onChange(value: string): void;
  onSubmit(): void;
  validation: string;
}

const Container = styled('div')({
  gap: 5,
  marginTop: 20,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '1fr 100px',
});

const SerialNumberForm: React.FC<SerialNumberFormProps> = ({ onChange, validation, serialNumber, onSubmit }) => {
  return (
    <Container>
      <TextField
        label="Informe o número de série"
        placeholder="Número de série"
        autoFocus
        value={serialNumber}
        error={!!validation}
        helperText={validation}
        onChange={e => onChange(e.target.value)}
      />

      <Button onClick={onSubmit} variant="contained">
        Adicionar
      </Button>
    </Container>
  );
};

export default SerialNumberForm;
