type Listener = () => void;

interface Store<T> {
  getState: () => T;
  subscribe: (listener: Listener) => () => void;
  setState: (state: T) => void;
}

const createFilterStore = (key: string, initialValue: string): Store<string> => {
  let listeners: Listener[] = [];

  const getState = () => {
    return localStorage.getItem(key) || initialValue;
  };

  const subscribe = (listener: Listener) => {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  };

  const setState = (state: string) => {
    localStorage.setItem(key, state);
    listeners.forEach(listener => listener());
  };

  return { getState, subscribe, setState };
};

const filterStore = createFilterStore('filters', '');

export { filterStore };
