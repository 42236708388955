import React, { useEffect, useState } from 'react';
import useTableOrder from 'hooks/tableOrder';
import { darken, styled, Typography } from '@mui/material';
import { PurchaseReview } from 'types/purchaseReview';

interface Props {
  purchases: PurchaseReview[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '20vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#fdf2cd',
  '& > p': {
    color: darken('#fdf2cd', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '100px 100px 100px 50px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 0.5fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  padding: '0px 10px',
  display: 'grid',
});

const CommercialAnalysisProductPurchasesListDesktop: React.FC<Props> = ({ purchases }) => {
  const [filtered, setFiltered] = useState<PurchaseReview[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(purchases);
  }, [purchases]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Retrospecto de compras mensal</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('formattedMonthYear')} variant="caption" fontWeight={600}>
              Mês/Ano
            </Typography>

            <Typography align="right" onClick={() => handleSort('custo_medio')} variant="caption" fontWeight={600}>
              Custo Médio
            </Typography>

            <Typography align="right" onClick={() => handleSort('custo_total')} variant="caption" fontWeight={600}>
              Custo Total
            </Typography>

            <Typography
              align="right"
              onClick={() => handleSort('formattedQuantity')}
              variant="caption"
              fontWeight={600}
            >
              Qtd
            </Typography>
          </Row>
          {filtered.map((item, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{item.mes_ano}</Typography>

              <Typography align="right" variant="caption">
                {item.formattedAverageCost}
              </Typography>
              <Typography align="right" variant="caption">
                {item.formattedTotalCost}
              </Typography>

              <Typography align="right" variant="caption">
                {item.qtd}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductPurchasesListDesktop;
