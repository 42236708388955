import Dialog from 'components/dialogs/Dialog';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import history from 'services/history';
import { useApp } from 'hooks/app';
import { Rma } from 'types/rma';
import ReportAppbarActions from 'pages/reports/item-entry/ReportAppbarActions';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  '& p': {
    fontSize: 13,
  },
  '& .signature': {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'end',
    textAlign: 'end',
    '& > p': {
      minWidth: 300,
      paddingTop: 5,
      borderTop: '1px solid #000',
    },
  },
});

const Row = styled('div')({
  display: 'grid',
  gap: 5,
  alignItems: 'center',
  '&.row1': {
    gridTemplateColumns: '130px 1fr 180px',
  },
  '&.row2': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

const CustomerContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 10,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const ProductContent = styled('div')({
  marginTop: 10,
  display: 'grid',
  gridTemplateColumns: '50px 1fr 120px 120px',
  gap: 5,
  padding: 10,
  border: '1px solid #eee',

  '& .table-header': {
    fontWeight: 'bold',
  },
  '& .numericData': {
    textAlign: 'right',
  },
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const DefectContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  rowGap: 5,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    columnGap: 20,
    '& > p': {
      textAlign: 'right',
    },
  },
});

const InfoContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  rowGap: 5,
  '& > .title': {
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const HeaderContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > p': {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const Flex = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  '& img': {
    width: 50,
    height: 50,
  },
  '& h3': {
    fontSize: 20,
  },
});

const RmaReport: React.FC = () => {
  const params = window.location.search;
  const [item, setItem] = useState<Rma | null>(null);
  const [loading, setLoading] = useState(false);
  const { h2iApi } = useApp();

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/getrelrma${params}`)
      .then(response => {
        const _item = response.data.map((item: Rma) => {
          item.formattedValue = moneyFormat(item.valor);

          return item;
        });

        setItem(_item[0]);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, h2iApi]);

  return (
    <Dialog
      fullScreen
      title={`OS Troca`}
      onExited={() => history.push('/reports/rma_analysis')}
      ComponentActions={<ReportAppbarActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : item ? (
        <Container>
          <div className="page">
            <div className="header" style={{ marginBottom: 10 }}>
              <Flex>
                <img
                  src="https://h2i.s3.sa-east-1.amazonaws.com/upload/images/1108222024100366fea556447ec.bmp"
                  alt="netsolution"
                />
                <h3>{item.titulo}</h3>
              </Flex>
            </div>

            <CustomerContent>
              <HeaderContent>
                <p>Número OS: {item.id}</p>
              </HeaderContent>

              <Row className="row1">
                <p>Cod Cli: {item.codigo_cliente}</p>
                <p>Cliente: {item.cliente}</p>
                <p>CPF: {item.cnpj_cpf}</p>
              </Row>
              <Row className="row2">
                <p>Abertura: {item.data}</p>
                <p style={{ gridColumn: '3/4' }}>Atendimento: {item.data_atendimento}</p>
              </Row>
              <Row className="row2">
                <p>Compra: {item.data_compra}</p>
                <p>Nº Venda: {item.numero_venda}</p>
                <p>Nota Fiscal: {item.numero_nota}</p>
              </Row>
            </CustomerContent>

            <ProductContent>
              <p className="table-header">Cód</p>
              <p className="table-header">Produto</p>
              <p className="table-header">Número Série</p>
              <p className="table-header numericData">Valor (R$)</p>

              <p>{item.id_produto}</p>
              <p className="product-name">{item.produto}</p>
              <p>{item.numero_serie}</p>
              <p className="numericData">{item.formattedValue}</p>
            </ProductContent>

            <DefectContent>
              <HeaderContent>
                <p>Defeito reclamado:</p>
              </HeaderContent>

              <p>{item.defeito_reclamado}</p>
            </DefectContent>

            <DefectContent>
              <HeaderContent>
                <p>Resolução defeito:</p>
              </HeaderContent>

              <p>{item.resolucao_defeito}</p>
            </DefectContent>
            <InfoContent>
              <p>{item.obs}</p>

              <p className="title">Termo de quitação</p>

              <p>
                Eu, {item.cliente}, inscrito sob CPF/CNPJ {item.cnpj_cpf}, declaro ter utilizado integralmente o crédito
                concedido através da OS TROCA Nº {item.id} para a aquisição dos produtos relacionados, conferindo plena
                quitação ao procedimento de troca realizado.
              </p>
            </InfoContent>

            <div className="signature">
              <p>{item.cliente}</p>
            </div>
          </div>
        </Container>
      ) : (
        <NoData message="OS não encontrada" />
      )}
    </Dialog>
  );
};

export default RmaReport;
