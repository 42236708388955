import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { Bill } from 'types/bill';

type BillItemModuleProps = {
  bill: Bill;
};

const ListItemStyled = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    width: '100%',
    alignItems: 'center',
    '& > a': {
      fontSize: 14,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
});

const BillItemModule: React.FC<BillItemModuleProps> = ({ bill }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">Documento {bill.document_number}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Beneficiário:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {bill.beneficiary.company_name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Cliente:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {bill.payerName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Valor:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {bill.formattedValue}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Vence em:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {bill.formattedPayday}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          URL:
        </Typography>
        <a target="_blank" href={bill.url} rel="noreferrer">
          {bill.url}
        </a>
      </div>
    </ListItemStyled>
  );
};

export default BillItemModule;
