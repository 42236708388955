import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import ProductItem from './ProductItem';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { stockTableTemplate } from '../stockTableTemplate';
import { StockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type ProductListProps = {
  products: StockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
};

const ProductStockListTable: React.FC<ProductListProps> = ({ products, handleSort, orderedIndex, isSubgroup }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <TableContainer tableTemplate={stockTableTemplate}>
        <TableContent>
          <TableHeader style={{ backgroundColor: isSubgroup ? '#d9f8e5' : '' }}>
            {stockTableTemplate.map(item => (
              <HeaderItem
                className={item.dataType === 'number' ? `numericData` : ''}
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight>
            {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
              <TableRow key={index}>
                <ProductItem product={product} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default ProductStockListTable;
