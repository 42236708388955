import { Done } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface Props {
  onClick(): void;
}

const UpdatePriceProtectionActions: React.FC<Props> = ({ onClick }) => {
  return (
    <div>
      <IconButton onClick={onClick} color="inherit">
        <Done />
      </IconButton>
    </div>
  );
};

export default UpdatePriceProtectionActions;
