import React, { MouseEvent } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { ResponsibleStockBalanceData } from 'types/reports/stockBalance';
import { responsibleStockTableTemplate } from '../responsibleStockTableTemplate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStockBalance } from '../../hooks/useStockBalance';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

type ResponsibleItemTableProps = {
  responsible: ResponsibleStockBalanceData;
  isSubgroup: boolean;
};

const ResponsibleItemTable: React.FC<ResponsibleItemTableProps> = ({ responsible, isSubgroup }) => {
  const { setSelectedResponsible, setMoreResponsible, moreResponsible } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedResponsible(responsible);
    setMoreResponsible(moreResponsible ? null : responsible);
  }

  return (
    <>
      {responsibleStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' && isSubgroup ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={
                  moreResponsible?.id_responsavel === responsible.id_responsavel
                    ? { transform: 'rotate(180deg)' }
                    : undefined
                }
              />
            </CustomIconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {responsible[item.id as keyof ResponsibleStockBalanceData]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ResponsibleItemTable;
