import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import TableContainer from 'components/table/TableContainer';
import { CommercialDashboardTotal } from 'types/commercialDashboard';
import CommercialDashboardItemTable from './CommercialDashboardItemTable';
import { commercialDashboardTotalTableTemplate } from '../template/commercialDashboardTotalTableTemplate';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
  },
});

interface CommercialDashboardListTableProps {
  orders: CommercialDashboardTotal[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const CommercialDashboardListTable: React.FC<CommercialDashboardListTableProps> = ({
  orders,
  handleSort,
  orderedIndex,
}) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={commercialDashboardTotalTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {commercialDashboardTotalTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={() => handleSort(item.originalId)}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>

              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={true} maxHeight={520}>
          {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
            <TableRow style={{ minHeight: 20 }} activeNthChild key={index}>
              <CommercialDashboardItemTable order={order} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default CommercialDashboardListTable;
