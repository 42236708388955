import { Add } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import React from 'react';

interface Props {
  onClick(): void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

const CustomerActions: React.FC<Props> = ({ onClick }) => {
  return (
    <Container>
      <IconButton color="inherit" onClick={onClick}>
        <Add />
      </IconButton>
    </Container>
  );
};

export default CustomerActions;
