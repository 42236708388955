import React from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  styled,
  useTheme,
} from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';
import Loading from 'components/loading/Loading';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { useExtracts } from './hooks/useExtracts';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';

const StyledFilter = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: 10,
});

const FirstRow = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  flex: 1,
  gap: 10,
  gridTemplateColumns: '1fr 1fr 170px 170px',
  '& > div': {
    flex: 1,
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SecondRow = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  flex: 1,
  gap: 10,
  gridTemplateColumns: '2fr 1fr 1fr 100px',
  '& label': {
    marginRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DatePickerContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'baseline',
  flex: 1,
  gap: 10,
  gridTemplateColumns: '90px 1fr 1fr 1fr',
  [theme.breakpoints.down('sm')]: {
    rowGap: 15,
    gridTemplateColumns: '1fr 1fr',
  },
}));

const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    gridColumn: '2/3',
    gridRow: '1/2',
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

function getStyles(name: number, personName: readonly number[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    backgroundColor: personName.indexOf(name) === -1 ? '#fff' : '#cfffe6',
  };
}

const BankStatementFilterBox: React.FC = () => {
  const theme = useTheme();
  const { filter, handleChangeFilter, handleSearch, loading, loadingTotalizingAccount, totalizingAccounts } =
    useExtracts();

  return (
    <FilterBox>
      <StyledFilter>
        <FirstRow>
          <SelectOthersBranch
            fullWidth
            label="Filiais"
            style={{ height: 'inherit' }}
            accounting={true}
            viewAllBranches={false}
            branch_id={filter.branchId}
            handleChange={id => handleChangeFilter('branchId', id)}
          />

          <FormControl>
            <InputLabel>Conta</InputLabel>
            <Select
              multiple
              fullWidth
              disabled={loadingTotalizingAccount}
              value={filter.accountId}
              placeholder="Selecione a conta"
              label="Conta"
              onChange={e => handleChangeFilter('accountId', e.target.value as number[])}
            >
              {!totalizingAccounts ? (
                <Loading />
              ) : (
                totalizingAccounts.length > 0 &&
                totalizingAccounts.map(item => (
                  <MenuItem style={getStyles(item.id, filter.accountId, theme)} key={item.id} value={item.id}>
                    {item.nome}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          <CustomTextField
            value={filter.type}
            onChange={e => handleChangeFilter('type', e.target.value)}
            select
            label="Tipo"
          >
            <MenuItem value="TT">Todos</MenuItem>
            <MenuItem value="D">Despesas</MenuItem>
            <MenuItem value="T">Títulos</MenuItem>
            <MenuItem value="F">Folha de Pagamento</MenuItem>
            <MenuItem value="DT">Títulos e Despesas</MenuItem>
            <MenuItem value="S">Transferência</MenuItem>
          </CustomTextField>

          <TextField
            label="Apropriados"
            fullWidth
            margin="none"
            size="small"
            value={filter.appropriate}
            onChange={e => handleChangeFilter('appropriate', e.target.value)}
            select
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="S">Sim</MenuItem>
            <MenuItem value="N">Não</MenuItem>
          </TextField>
        </FirstRow>

        <SecondRow>
          <DatePickerContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.ignorePeriod === 'S'}
                  onChange={e => handleChangeFilter('ignorePeriod', e.target.checked ? 'S' : 'N')}
                />
              }
              label="Ignorar"
            />

            <DesktopDatePicker
              disabled={!!filter.month_year || filter.ignorePeriod === 'S'}
              label="Data inicial"
              value={filter.initial_date}
              onChange={date => handleChangeFilter('initial_date', date)}
            />

            <DesktopDatePicker
              disabled={!!filter.month_year || filter.ignorePeriod === 'S'}
              label="Data final"
              value={filter.final_date}
              onChange={date => handleChangeFilter('final_date', date)}
            />

            <CustomDesktopDatePicker
              disabled={filter.ignorePeriod === 'S'}
              label="Mês/Ano"
              value={filter.month_year}
              onChange={date => handleChangeFilter('month_year', date)}
              format="MMMM-yyyy"
              views={['month', 'year']}
              localeText={{
                clearButtonLabel: 'Limpar',
              }}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
            />
          </DatePickerContainer>

          <CustomTextField
            value={filter.movimentation}
            onChange={e => handleChangeFilter('movimentation', e.target.value)}
            placeholder="Pesquise pela descrição financeira"
            label="Descrição financeira"
          />

          <CustomTextField
            value={filter.targetAccount}
            onChange={e => handleChangeFilter('targetAccount', e.target.value)}
            placeholder="Pesquise pela conta destino"
            label="Conta destino"
          />

          <Button
            disabled={loading}
            onClick={handleSearch}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Search />}
          >
            Buscar
          </Button>
        </SecondRow>
      </StyledFilter>
    </FilterBox>
  );
};

export default BankStatementFilterBox;
