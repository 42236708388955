import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { SubgroupStockBalanceData } from 'types/reports/stockBalance';

type SubgroupItemModuleProps = {
  subgroup: SubgroupStockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

const SubgroupItemModule: React.FC<SubgroupItemModuleProps> = ({ subgroup }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{subgroup.subgrupo}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Est total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.EstTotal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subgroup.formattedCustoTotal}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default SubgroupItemModule;
