import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { TotalizingAccount } from 'types/totalizingAccount';
import { useTotalizingAccounts } from '../../hooks/useTotalizingAccount';

const StyledListItem = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '110px 1fr',
    alignItems: 'center',
  },
});

interface TotalizingItemModuleProps {
  account: TotalizingAccount;
}

const TotalizingItemModule: React.FC<TotalizingItemModuleProps> = ({ account }) => {
  const { setSelectedAccount } = useTotalizingAccounts();

  return (
    <StyledListItem onClick={() => setSelectedAccount(account)}>
      <Typography variant="body1">{account.nome}</Typography>

      <div className="content">
        <Typography color="textSecondary" variant="body2">
          Filial:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {account.filial}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          Saldo:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {account.formattedSaldo}
        </Typography>
      </div>
    </StyledListItem>
  );
};

export default TotalizingItemModule;
