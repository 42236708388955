import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useTransferAccounts } from '../hooks/useTransferAccount';

type TransferAccountListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
  selectedId: number | null;
};

const TransferAccountListMenu: React.FC<TransferAccountListMenuProps> = ({
  anchorEl,
  setAnchorEl,
  selectedId,
  setSelectedId,
}) => {
  const { handleSelectTitle } = useTransferAccounts();

  function handleClick() {
    setAnchorEl(null);

    if (selectedId) {
      handleSelectTitle(selectedId);
      setSelectedId(null);
    }
  }

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleClick}>Referenciar título</MenuItem>
    </Menu>
  );
};

export default TransferAccountListMenu;
