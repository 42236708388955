import React, { FormEvent, useCallback, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { Button, TextField, styled } from '@mui/material';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Invoice } from 'types/invoices';
import { useApp } from 'hooks/app';
import { invoiceTypes } from 'pages/moviment/query/Invoices';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import InvoiceList from './list/InvoiceList';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

interface SelectedTitleProps {
  id: number;
  type: string;
  onExited(): void;
  onSave(): void;
}

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Form = styled('form')({
  gap: 10,
  padding: '10px 0',
  display: 'flex',
  flexDirection: 'column',
});

const Filter = styled('div')({
  rowGap: 15,
  columnGap: 10,
  display: 'grid',
  gridTemplateColumns: '145px 145px 1fr 1fr 100px',
});

const SelectedTitle: React.FC<SelectedTitleProps> = ({ id, type, onExited, onSave }) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [loading, setLoading] = useState(false);
  const { financialApi } = useApp();
  const [filter, setFilter] = useState({
    title: '',
    supplier: '',
    initial_date: subDays(new Date(), 90),
    final_date: new Date(),
  });

  function handleChange(index: string, value: any) {
    setFilter(values => ({
      ...values,
      [index]: value,
    }));
  }

  const fetch = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!financialApi) {
        return;
      }

      setLoading(true);

      const formattedInitialDate = format(filter.initial_date, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.final_date, 'P', { locale: ptBR });
      financialApi
        .get('/getTitulos', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            fornecedor: filter.supplier,
            id_filial: '',
            tipo: "'T'",
            apropriado: 'N',
            titulo: filter.title,
            mes_ano: '',
            numero_nf: '',
            ct_informado: '',
            id_conta_totalizadora: '',
            data_apropriacao: '',
          },
        })
        .then(response => {
          if (!response.data?.titulos) {
            setInvoices([]);
            return;
          }

          setInvoices(
            response.data.titulos.map(invoice => ({
              ...invoice,
              formattedType: invoiceTypes[invoice.tipo],
              formattedValue: moneyFormat(invoice.valor),
              formattedPaidValue: moneyFormat(invoice.valor_pago),
              formattedmissingValue: moneyFormat(invoice.valor_falta),
            })),
          );
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [filter, financialApi],
  );

  return (
    <Dialog maxWidth="md" title="Selecione o título" onExited={onExited}>
      <Form onSubmit={fetch}>
        <Filter>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date}
            onChange={date => handleChange('initial_date', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.final_date}
            onChange={date => handleChange('final_date', date)}
          />
          <TextField
            value={filter.supplier}
            onChange={e => handleChange('supplier', e.target.value)}
            label="Pesquisar"
            placeholder={type === 'F' ? 'Funcionário' : type === 'T' ? 'Fornecedor' : 'Classificação'}
          />

          <TextField
            value={filter.title}
            onChange={e => handleChange('title', e.target.value)}
            label="Pesquisar"
            placeholder="Descrição do lancaçamento"
          />

          <Button type="submit" variant="contained">
            Pesquisar
          </Button>
        </Filter>

        {loading ? (
          <InsideLoading />
        ) : invoices.length === 0 ? (
          <NoData message="Nenhum título para mostrar" />
        ) : (
          <PaginationProvider>
            <Content>
              <InvoiceList
                setSelectedInvoice={setSelectedInvoice}
                selectedInvoice={selectedInvoice}
                invoices={invoices}
              />
              <Pagination count={invoices.length} />
            </Content>
          </PaginationProvider>
        )}
      </Form>
    </Dialog>
  );
};

export default SelectedTitle;
