import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import { useFetchCommercialAnalysisProduct } from './hooks/useFetchCommercialAnalysisProduct';
import CommercialAnalysisProductFilterBox from './CommercialAnalysisProductFilterBox';
import CommercialAnalysisProductHeader from './CommercialAnalysisProductHeader';
import { styled } from '@mui/material';
import CommercialAnalysisProductSales from './sales/CommercialAnalysisProductSales';
import CommercialAnalysisProductOrdersListDesktop from './purchase/CommercialAnalysisProductOrdersListDesktop';
import CommercialAnalysisProductPurchasesListDesktop from './purchase/CommercialAnalysisProductPurchasesListDesktop';
import CommercialAnalysisProductOrderItemsListDesktop from './purchase/CommercialAnalysisProductOrderItemsListDesktop';
import CommercialAnalysisProductLoading from './loading/CommercialAnalysisProductLoading';
import CommercialAnalysisProductSuggestion from './suggestion/CommercialAnalysisProductSuggestion';
import CommercialAnalysisProductTabs from './tab/CommercialAnalysisProductTabs';
import CommercialAnalysisProductTransfer from './transfer/CommercialAnalysisProductTransfer';
import CommercialAnalysisProductReserve from './reserve/CommercialAnalysisProductReserve';

const Container = styled('div')(({ theme }) => ({
  zoom: '90%',
  [theme.breakpoints.down('lg')]: {
    zoom: '80%',
  },
  [theme.breakpoints.down('md')]: {},
}));

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '2fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const CommercialAnalysisProduct: React.FC = () => {
  const {
    product,
    handleSearchSubmit,
    loading,
    orderItems,
    itemsEntry,
    handleChangeFilter,
    suggestions,
    filter,
    sales,
    total,
    purchases,
    reserves,
  } = useFetchCommercialAnalysisProduct();
  const [tab, setTab] = useState('main');

  return (
    <>
      <Appbar title="Análise de produto" />

      <CommercialAnalysisProductFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <CommercialAnalysisProductTabs tab={tab} handleChange={value => setTab(value)} />
      {loading ? (
        <CommercialAnalysisProductLoading />
      ) : !product ? (
        <NoData message="Produto não encontrado" />
      ) : (
        <Container>
          {tab === 'main' ? (
            <Content>
              <CommercialAnalysisProductHeader suggestions={suggestions} total={total} product={product} />

              <CommercialAnalysisProductOrdersListDesktop itemsEntry={itemsEntry} />

              <CommercialAnalysisProductPurchasesListDesktop purchases={purchases} />

              <CommercialAnalysisProductSales sales={sales} />

              <CommercialAnalysisProductOrderItemsListDesktop orderItems={orderItems} />
            </Content>
          ) : tab === 'suggestion' ? (
            <CommercialAnalysisProductSuggestion suggestions={suggestions} />
          ) : tab === 'transfer' ? (
            <CommercialAnalysisProductTransfer suggestions={suggestions.filter(item => item.forecast > 0)} />
          ) : (
            <CommercialAnalysisProductReserve reserves={reserves} />
          )}
        </Container>
      )}
    </>
  );
};

export default CommercialAnalysisProduct;
