import React, { MouseEvent } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { noteEntryTableTemplate } from '../../template/noteEntryTableTemplate';
import { NoteEntry } from 'types/noteEntry';
import { useMessaging } from 'hooks/messaging';
import { OpenInNew, PictureAsPdf } from '@mui/icons-material';

type NoteEntryItemTableProps = {
  note: NoteEntry;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const NoteEntryItemTable: React.FC<NoteEntryItemTableProps> = ({ note }) => {
  const { handleOpen } = useMessaging();

  function onClick(e: MouseEvent<HTMLSpanElement>, index: string) {
    e.stopPropagation();
    if (index === 'chave') {
      navigator.clipboard.writeText(note.chave);
      handleOpen('Chave copiada para a área de transferência');
    }
  }

  function openExternalSite(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(note.chave);
    window.open(`https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx`, '_blank');
  }

  function openExternalSiteXML(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(note.chave);
    window.open(`https://consultadanfe.com/?chave=${note.chave}`, '_blank');
  }

  return (
    <>
      {noteEntryTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'action' ? (
            <Tooltip key={item.id} title="Clique aqui para abrir o site da SEFAZ">
              <IconButton color="primary" size="small" onClick={openExternalSite}>
                <OpenInNew fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : item.id === 'pdf' ? (
            <Tooltip key={item.id} title="Clique aqui para visualizar a DANFE/XML">
              <IconButton color="error" size="small" onClick={openExternalSiteXML}>
                <PictureAsPdf fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? 'numericData' : ''}>
              <Tooltip title={item.id === 'chave' ? 'Clique aqui para copiar a chave' : note[item.id]}>
                <Description
                  onClick={e => onClick(e, item.id)}
                  color={item.id === 'chave' ? 'primary' : ''}
                  style={{ fontSize: 10, fontWeight: item.id === 'chave' ? 600 : 0 }}
                  variant="body2"
                >
                  {note[item.id]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default NoteEntryItemTable;
