import React, { Dispatch, SetStateAction, useState } from 'react';
import CommercialDashboardItemModule from './CommercialDashboardItemModule';
import CommercialDashboardListMenu from '../../CommercialDashboardListMenu';
import { CommercialDashboard } from 'types/commercialDashboard';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';

type CommercialDashboardListModuleProps = {
  orders: CommercialDashboard[];
  setCustomerContacted: Dispatch<SetStateAction<boolean>>;
};

const List = styled('div')({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr',
});

const CommercialDashboardListModule: React.FC<CommercialDashboardListModuleProps> = ({
  orders,
  setCustomerContacted,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedOrder, setSelectedOrder] = useState<null | CommercialDashboard>(null);
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <CommercialDashboardListMenu
        setCustomerContacted={setCustomerContacted}
        selectedOrder={selectedOrder}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <List>
        {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
          <CommercialDashboardItemModule
            setSelectedOrder={setSelectedOrder}
            setAnchorEl={setAnchorEl}
            key={order.id}
            order={order}
          />
        ))}
      </List>
    </>
  );
};

export default CommercialDashboardListModule;
