import { KeyboardArrowRight, Payments } from '@mui/icons-material';
import { Icon, lighten, styled, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import React from 'react';
import { PaymentMethod } from 'types/salesReport';
import DailySummaryPaymentListTable from './table/DailySummaryPaymentListTable';
import NoData from 'components/no-data/NoData';
import DailySummaryLoading from '../loading/DailySummaryLoading';

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const Content = styled('div')({
  maxWidth: 600,
  padding: 15,
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  gap: 10,
});

const Totalizer = styled('div')({
  display: 'flex',
  maxWidth: 600,
  gap: 10,
});

const Box = styled('div')(({ theme }) => ({
  padding: 6,
  flex: 1,
  background: lighten(theme.palette.primary.light, 0.3),
  color: theme.palette.primary.contrastText,
  borderRadius: 6,
  gap: 5,
  display: 'flex',
  justifyContent: 'space-between',
}));

const Flex = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
});

interface Props {
  loading: boolean;
  paymentMethods: PaymentMethod[];
  handleSortPaymentMethods(index: string): void;
}

const DailySummaryPayment: React.FC<Props> = ({ paymentMethods, loading, handleSortPaymentMethods }) => {
  return (
    <Container>
      <Title>
        <Icon color="primary" fontSize="medium">
          <Payments color="primary" fontSize="medium" />
        </Icon>

        <Typography color="primary" fontWeight={600} variant="subtitle1">
          Formas de pagamento
        </Typography>
      </Title>

      {loading ? (
        <DailySummaryLoading />
      ) : (
        <>
          <Totalizer>
            <Box>
              <Typography variant="caption" fontWeight={600}>
                Valor total:
              </Typography>

              <Flex>
                <Typography variant="caption" fontWeight={600}>
                  {moneyFormat(paymentMethods.reduce((acc, item) => acc + item.valor, 0))}
                </Typography>

                <Icon color="inherit" fontSize="small">
                  <KeyboardArrowRight fontSize="small" color="inherit" />
                </Icon>
              </Flex>
            </Box>

            <Box>
              <Typography variant="caption" fontWeight={600}>
                Qtd:
              </Typography>

              <Flex>
                <Typography variant="caption" fontWeight={600}>
                  {paymentMethods.length}
                </Typography>

                <Icon color="inherit" fontSize="small">
                  <KeyboardArrowRight fontSize="small" color="inherit" />
                </Icon>
              </Flex>
            </Box>
          </Totalizer>

          {paymentMethods.length === 0 ? (
            <Content>
              <NoData message="Nenhuma forma de pagamento encontrada" />
            </Content>
          ) : (
            <>
              <Content>
                <DailySummaryPaymentListTable handleSort={handleSortPaymentMethods} paymentMethods={paymentMethods} />
              </Content>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DailySummaryPayment;
