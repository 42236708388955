import React, { useEffect, useState } from 'react';
import { styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import { AgroupedProductReplacement } from '../hooks/useFetchAnalysisReplacementDashboard';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { numberFormat } from 'helpers/numberFormat';

interface AnalysisReplacementDashboardProductProps {
  products: AgroupedProductReplacement[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1/4',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#f8eef3',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '60px 1fr 100px 100px 100px 100px 100px 100px',
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const AnalysisReplacementDashboardProduct: React.FC<AnalysisReplacementDashboardProductProps> = ({ products }) => {
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<AgroupedProductReplacement[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  function handleSort(index: keyof AgroupedProductReplacement) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por produto</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cod Prod
            </Typography>
            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>
            <Typography onClick={() => handleSort('estoque')} align="center" variant="caption" fontWeight={600}>
              Estoque
            </Typography>
            <Typography onClick={() => handleSort('pedidos')} align="center" variant="caption" fontWeight={600}>
              Pedidos
            </Typography>
            <Typography onClick={() => handleSort('forecast')} align="center" variant="caption" fontWeight={600}>
              Forecast
            </Typography>
            <Typography onClick={() => handleSort('investimento')} align="right" variant="caption" fontWeight={600}>
              Investimento
            </Typography>
            <Typography onClick={() => handleSort('sugestao')} align="center" variant="caption" fontWeight={600}>
              Sugestão
            </Typography>
            <Typography onClick={() => handleSort('dias_estoque')} align="center" variant="caption" fontWeight={600}>
              Dias Estoque
            </Typography>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{product.id_produto}</Typography>
              <Typography className="product-name" variant="caption">
                {product.produto}
              </Typography>
              <Typography align="center" variant="caption">
                {product.estoque}
              </Typography>
              <Typography align="center" variant="caption">
                {product.pedidos}
              </Typography>
              <Typography align="center" variant="caption">
                {product.forecast}
              </Typography>
              <Typography align="right" variant="caption">
                {product.formattedInvestimento}
              </Typography>
              <Typography align="center" variant="caption">
                {product.sugestao}
              </Typography>
              <Typography align="center" variant="caption">
                {numberFormat(product.dias_estoque, 0)}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>

      <Pagination count={filtered.length} />
    </Container>
  );
};

export default AnalysisReplacementDashboardProduct;
