import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { DetailPayment } from 'types/paymentFlow';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import { moneyFormat } from 'helpers/numberFormat';

const Container = styled('div')({
  gap: 10,
  gridColumn: '1/3',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  overflow: 'auto',
  maxHeight: '25vw',
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  padding: '0px 5px',
  gridTemplateColumns: '90px 60px 1fr 90px 90px 90px',
  transition: 'all 0.3s ease',
  '&.content': {
    borderBottom: 'none',
  },
  '&.content:hover': {
    backgroundColor: '#fff',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& > span': {
    gap: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const Column = styled('ul')({
  display: 'flex',
  border: '1px solid #eee',
  flexDirection: 'column',
});

interface TopPaymentsDetailsProps {
  data: DetailPayment[];
}

const TopPaymentsDetails: React.FC<TopPaymentsDetailsProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<DetailPayment[]>([]);

  interface DataItem {
    valor: number;
    vencimento: string;
    fornecedor: string;
    data_lancamento: string;
    id_pedido: string;
    previsao_chegada: string;
  }

  const groupByDate = (data: DataItem[], dateField: keyof DataItem) => {
    return data.reduce((acc, item) => {
      const date = item[dateField];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {} as Record<string, DataItem[]>);
  };

  const groupedData = groupByDate(filtered, 'vencimento');

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      {filtered.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography onClick={() => handleSort('formattedDueDate')} align="right" variant="subtitle2">
                Vencimento
              </Typography>
              {orderedIndex.index === 'formattedDueDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('id_pedido')} variant="subtitle2">
                Pedido
              </Typography>
              {orderedIndex.index === 'id_pedido' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('fornecedor')} align="right" variant="subtitle2">
                Fornecedor
              </Typography>
              {orderedIndex.index === 'fornecedor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedReleaseDate')} variant="subtitle2">
                Lançamento
              </Typography>
              {orderedIndex.index === 'formattedReleaseDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span>
              <Typography onClick={() => handleSort('formattedArrivalDate')} variant="subtitle2">
                Previsão
              </Typography>
              {orderedIndex.index === 'formattedArrivalDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography onClick={() => handleSort('valor')} align="right" variant="subtitle2">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>

          {Object.entries(groupedData).map(([date, items]) => (
            <Column key={date}>
              {items.map(item => (
                <Row className="content" key={item.id_pedido}>
                  <Typography className="item-name" variant="caption">
                    {item.vencimento}
                  </Typography>
                  <Typography className="item-name" variant="caption">
                    {item.id_pedido}
                  </Typography>
                  <Tooltip title={item.fornecedor}>
                    <Typography className="item-name" variant="caption">
                      {item.fornecedor}
                    </Typography>
                  </Tooltip>
                  <Typography className="item-name" variant="caption">
                    {item.data_lancamento}
                  </Typography>
                  <Typography className="item-name" variant="caption">
                    {item.previsao_chegada}
                  </Typography>
                  <Typography className="numericData" align="right" variant="caption">
                    {moneyFormat(item.valor)}
                  </Typography>
                </Row>
              ))}

              <Typography variant="caption" align="right">
                Total: <strong>{moneyFormat(items.reduce((sum, item) => sum + item.valor, 0))}</strong>
              </Typography>
            </Column>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopPaymentsDetails;
