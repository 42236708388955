import { Done } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface PurchaseOrderConfirmReceiptActionProps {
  onClick(): void;
}

const PurchaseOrderConfirmReceiptAction: React.FC<PurchaseOrderConfirmReceiptActionProps> = ({ onClick }) => {
  return (
    <Tooltip title="Confirmar">
      <IconButton onClick={onClick} color="inherit" size="medium">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default PurchaseOrderConfirmReceiptAction;
