import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { Invoice, InvoicePayment } from 'types/invoices';

type UseFetchInvoice = [Invoice | null, boolean, InvoicePayment[], () => void];

export function useFetchInvoice(invoiceId?: number): UseFetchInvoice {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const { financialApi } = useApp();
  const [invoicePayments, setInvoicePayments] = useState<InvoicePayment[]>([]);

  const fetch = useCallback(() => {
    if (!financialApi) {
      return;
    }

    if (!invoiceId) {
      return;
    }

    setLoading(true);

    const titles = financialApi
      .get('/getTitulos', {
        params: {
          id: invoiceId,
        },
      })
      .then(response => {
        if (!response.data?.titulos) {
          setInvoice(null);
          return;
        }

        setInvoice(response.data.titulos[0]);
      });

    const payments = financialApi
      .get('/getpagamentofinanceiro', {
        params: {
          id: invoiceId,
        },
      })
      .then(response => {
        if (!response.data.pagamentos) {
          setInvoicePayments([]);
          return;
        }

        setInvoicePayments(response.data.pagamentos);
      });

    Promise.all([titles, payments])
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [invoiceId, financialApi]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [invoice, loading, invoicePayments, fetch];
}
