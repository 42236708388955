import React from 'react';
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { invoicesTableTemplate } from '../../invoicesTableTemplate';
import { Invoice } from 'types/invoices';
import { useInvoices } from '../../hooks/useInvoices';

type InvoiceTableItemProps = {
  invoice: Invoice;
};

interface IsPaidColumnProps {
  paid: boolean;
}

const IsPaidColumn = styled('div')<IsPaidColumnProps>(props => ({
  backgroundColor: props.paid ? props.theme.palette.success.light : 'transparent',
  padding: '4px 10px',
  textAlign: 'center',
  '& > p': {
    color: '#fff',
  },
}));

const IsPaidTypography = styled(Typography)(props => ({
  color: props.theme.palette.success.main,
}));

const InvoiceTableItem: React.FC<InvoiceTableItemProps> = ({ invoice }) => {
  const { selectedInvoices, handleToggle, setSelectedInvoice } = useInvoices();
  const isChecked = selectedInvoices.some(selectedInvoice => selectedInvoice.id === invoice.id);

  function handleClick(invoice: Invoice) {
    setSelectedInvoice(invoice);
  }

  return (
    <>
      {invoicesTableTemplate.map(item =>
        item.id === 'select' ? (
          <FormControlLabel
            style={{ justifyContent: 'center', marginRight: 0 }}
            label=""
            key={item.id}
            control={<Checkbox checked={isChecked} onChange={() => handleToggle(invoice)} />}
          />
        ) : item.id === 'data_pagamento' ? (
          <IsPaidColumn onClick={() => handleClick(invoice)} key={item.id} paid={!!invoice.data_pagamento}>
            <Typography fontSize={12} variant="body2">
              {invoice.data_pagamento}
            </Typography>
          </IsPaidColumn>
        ) : (
          <div
            className={item.dataType === 'number' ? 'numericData' : ''}
            key={item.id}
            onClick={() => handleClick(invoice)}
          >
            {invoice.apropriado === 'S' ? (
              <IsPaidTypography fontSize={12} variant="body2">
                {invoice[item.id]}
              </IsPaidTypography>
            ) : (
              <Typography fontSize={12} variant="body2">
                {invoice[item.id]}
              </Typography>
            )}
          </div>
        ),
      )}
    </>
  );
};

export default InvoiceTableItem;
