import React, { useMemo } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { useSelector } from 'store/selector';
import { useBranches } from 'providers/BranchesProviders';
import { Branch } from 'types/branch';

interface SelectBranchProps {
  branch_id: number;
  handleChange(value: number): void;
  viewAllBranches?: boolean;
  loading?: boolean;
  label?: string;
  onSelect?(): void;
  setBranch?(branch: Branch): void;
  selectStyle?: React.CSSProperties;
  error?: boolean;
  helperText?: string;
  inputRef?: React.RefObject<HTMLSelectElement>;
}

const CustomFormControl = styled(FormControl)({
  minWidth: 150,
  height: '100%',
  '& > p': {
    marginTop: 0,
  },
});

const SelectBranch: React.FC<SelectBranchProps> = ({
  branch_id,
  handleChange,
  loading = false,
  setBranch,
  onSelect,
  selectStyle,
  viewAllBranches = true,
  label = 'Filiais',
  error = false,
  helperText,
  inputRef,
}) => {
  const user = useSelector(state => state.user);
  const { userBranches, branches } = useBranches();

  const userBranchesQuantity = useMemo(() => user?.branches.split(',').length ?? 0, [user]);

  return (
    <CustomFormControl>
      <InputLabel>{label}</InputLabel>

      <Select
        inputRef={inputRef}
        style={selectStyle}
        label={label}
        onSelect={onSelect}
        value={branch_id}
        disabled={loading}
        error={error}
        onChange={e => handleChange(e.target.value as number)}
      >
        {userBranchesQuantity >= branches.length && viewAllBranches && <MenuItem value={0}>TODAS AS FILIAIS</MenuItem>}

        {userBranches.map(branch => (
          <MenuItem onClick={() => (setBranch ? setBranch(branch) : undefined)} key={branch.id} value={branch.id}>
            {branch.nome}
          </MenuItem>
        ))}
        {!viewAllBranches && branch_id === 0 && <MenuItem value={0}>Nenhuma filial selecionada</MenuItem>}
        {branch_id === 99 && <MenuItem value={99}>Sem filial cadastrada</MenuItem>}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </CustomFormControl>
  );
};

export default SelectBranch;
