import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export type VacancyValidation = {
  role?: string;
  benefits?: string;
  salary?: string;
  work_hours?: string;
  work_model?: string;
  work_experience?: string;
  local?: string;
  observation?: string;
};

type UseVacancyValidation = [
  VacancyValidation,
  Dispatch<SetStateAction<VacancyValidation>>,
  (vacancy: VacancyValidation) => Promise<void>,
];

export function useVacancyValidation(): UseVacancyValidation {
  async function handleValidation(vacancy: VacancyValidation) {
    const schema = yup.object().shape({
      salary: yup.string().required('Salário é obrigatório'),
      observation: yup.string().required('Observação é obrigatório'),
      work_model: yup.string().required('Modelo de trabalho é obrigatório'),
      work_hours: yup.string().required('Horas de trabalho é obrigatória'),
      work_experience: yup.string().required('Experiência para vaga é obrigatória'),
      benefits: yup.string().required('Benefício é obrigatório'),
      local: yup.string().required('Localidade da vaga é obrigatória'),
      role: yup.string().required('O título da vaga é obrigatório'),
    });

    try {
      await schema.validate(vacancy);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<VacancyValidation>({});
  return [validation, setValidation, handleValidation];
}
