import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { QueryParams } from './SliderBanners';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

const Filter = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '220px 1fr',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [props.theme.breakpoints.down('md')]: {
    flex: 1,
  },
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
  '& > .search-params': {
    display: 'grid',
    columnGap: 10,
    gridTemplateColumns: '1fr 1fr 1fr',
    [props.theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

type PlansFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
};

const SiteBannersFilterBox: React.FC<PlansFilterBoxProps> = ({ queryParams, handleQueryParamsChange }) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      <Filter>
        <TextField
          label="Nome do banner"
          placeholder="Digite o nome do banner"
          value={queryParams.name}
          onChange={e => handleQueryParamsChange('name', e.target.value)}
          autoFocus
        />
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date || null}
            onChange={date => handleQueryParamsChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date || null}
            onChange={date => handleQueryParamsChange('final_date', date)}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={queryParams.active}
                onChange={e => handleQueryParamsChange('active', e.target.checked)}
                color="primary"
              />
            }
            label="Ativos"
          />
        </div>
      </Filter>
    </FilterBox>
  );
};

export default SiteBannersFilterBox;
