import { useEffect, useState, FormEvent, useCallback } from 'react';
import { Sale } from '../../commercial/abc/product/modal/branch/SelectedBranchListMenu';
import { useApp } from 'hooks/app';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Sales, SalesItem } from 'types/reports/sales';
import { SalesFilter, SalesReportTotal } from '../SalesReport';
import { ActivityMethod, CityMethod, PaymentMethod, SalesMethod } from 'types/salesReport';

export function useFetchSales() {
  const user = useSelector(state => state.user);
  const branches = useSelector(state => state.branches);
  const { h2iApi, handleOpenMenu, isOpenedMenu } = useApp();
  const [loading, setLoading] = useState(false);
  const [loadingSale, setLoadingSale] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<any>(0);
  const [sales, setSales] = useState<Sales[]>([]);
  const [salesItems, setSalesItems] = useState<SalesItem[]>([]);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [paymentsMethods, setPaymentsMethods] = useState<PaymentMethod[]>([]);
  const [salesMethods, setSalesMethods] = useState<SalesMethod[]>([]);
  const [activitiesMethods, setActivitiesMethods] = useState<ActivityMethod[]>([]);
  const [citiesMethods, setCitiesMethods] = useState<CityMethod[]>([]);
  const [filter, setFilter] = useState<SalesFilter>({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: endOfDay(new Date()),
    typeSale: 'V',
    order_by: '',
    product: '',
    branchId:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
  });
  const [formattedTotal, setFormattedTotal] = useState<SalesReportTotal>({
    pj: '',
    pf: '',
    mc: '',
    total: '',
    discount: '',
    percentDiscount: '',
    customers: 0,
  });

  function handleSelectSale(sale: Sales) {
    if (!h2iApi) {
      return;
    }

    setLoadingSale(true);

    h2iApi
      .get(`/api/imprimevenda`, {
        params: {
          id_filial: sale.id_filial,
          numero_venda: sale.numero_venda,
        },
      })
      .then(response => setSelectedSale(response.data[0].venda[0]))
      .catch(err => console.error(err))
      .finally(() => setLoadingSale(false));
  }

  function handleChangeFilter(index: keyof SalesFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch(searchText);
  }

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!h2iApi) return;

      if (isOpenedMenu) {
        handleOpenMenu();
      }

      if (!filter.finalDate || !filter.initialDate) {
        return;
      }

      const formattedInitialDate = format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.finalDate, 'P', { locale: ptBR });

      setLoading(true);

      const fetchSales = h2iApi.get(`/api/relvendas`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      const fetchSalesItems = h2iApi.get(`/api/getrelvendasitem`, {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      const fetchMethods = h2iApi.get('/api/getrelvendasmodalidade', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      const fetchActivities = h2iApi.get('/api/getrelvendasatividadecliente', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      const fetchCities = h2iApi.get('/api/getrelvendascidade', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      const fetchPayments = h2iApi.get('/api/getrelvendasformapag', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branchId || '',
          tipo_venda: filter.typeSale,
          filtro: searchValue,
          pedido_por: filter.order_by.toUpperCase(),
          produto: filter.product.toUpperCase(),
        },
      });

      Promise.all([fetchMethods, fetchActivities, fetchCities, fetchSales, fetchSalesItems, fetchPayments])
        .then(
          ([
            methodsReponse,
            activitiesReponse,
            citiesResponse,
            salesResponse,
            salesItemsResponse,
            paymentsResponse,
          ]) => {
            if (salesResponse.data.MESSAGE === 'OK') {
              setSales([]);
              setSalesMethods([]);
              setActivitiesMethods([]);
              setPaymentsMethods([]);
              setCitiesMethods([]);
              setSalesItems([]);
            }

            setPaymentsMethods(
              paymentsResponse.data.relpag
                .map(item => ({
                  ...item,
                  formattedValue: moneyFormat(item.valor),
                }))
                .sort((a, b) => b.valor - a.valor),
            );

            setSalesItems(
              salesItemsResponse.data.relitens.map((item: SalesItem) => {
                item.formattedTotal = moneyFormat(item.total);
                item.formattedSpiff = numberFormat(item.spiff);
                item.formattedUnitario = moneyFormat(item.unitario);
                item.formattedCusto = moneyFormat(item.custo);

                return item;
              }),
            );
            setSalesMethods(
              methodsReponse.data.relvendasmodalidade
                .map(item => ({
                  ...item,
                  formattedValue: moneyFormat(item.valor),
                }))
                .sort((a, b) => b.valor - a.valor),
            );
            setActivitiesMethods(
              activitiesReponse.data.relvendasatividade
                .map(item => ({
                  ...item,
                  formattedValue: moneyFormat(item.valor),
                }))
                .sort((a, b) => b.valor - a.valor),
            );
            setCitiesMethods(
              citiesResponse.data.relvendasmodalidade
                .map(item => ({
                  ...item,
                  formattedValue: moneyFormat(item.valor),
                }))
                .sort((a, b) => b.valor - a.valor),
            );

            const _sales = salesResponse.data.relvendas.map(item => {
              item.formattedTotal = moneyFormat(item.vl_total);
              item.formattedSpiff = numberFormat(item.spiff);
              item.formattedDiscount = moneyFormat(item.vl_desconto);
              item.formattedDiscountPercent = percentFormat(item.perc_desconto);
              item.filial = branches.find(branch => branch.id === item.id_filial)?.nome || '';
              return item;
            });

            setSales(_sales);
          },
        )
        .finally(() => setLoading(false));
    },
    [h2iApi, filter, isOpenedMenu, handleOpenMenu, branches],
  );

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  useEffect(() => {
    const mc = sales.reduce((sum, item) => sum + item.mc, 0);
    const total = sales.reduce((sum, item) => sum + item.vl_total, 0);
    const discount = sales.reduce((sum, item) => sum + item.vl_desconto, 0);
    const percentDiscount = sales.reduce((sum, item) => sum + item.perc_desconto, 0);
    const salesCnpj = sales
      .filter(sale => sale.tipo_pessoa === 'Jurídica')
      .reduce((sum, item) => sum + item.vl_total, 0);
    const salesCpf = sales.filter(sale => sale.tipo_pessoa === 'Física').reduce((sum, item) => sum + item.vl_total, 0);
    const customers = Array.from(new Set(sales));

    setFormattedTotal({
      pj: moneyFormat(salesCnpj),
      pf: moneyFormat(salesCpf),
      mc: moneyFormat(mc),
      total: moneyFormat(total),
      discount: moneyFormat(discount),
      percentDiscount: percentFormat(percentDiscount / sales.length),
      customers: customers.length,
    });
  }, [sales]);

  return {
    sales,
    salesItems,
    loading,
    filter,
    selectedSale,
    paymentsMethods,
    loadingSale,
    selectedBranch,
    formattedTotal,
    salesMethods,
    activitiesMethods,
    citiesMethods,
    handleSelectSale,
    setSelectedBranch,
    setSelectedSale,
    handleChangeFilter,
    searchText,
    handleSearchInputChange,
    handleSearchSubmit,
  };
}
