import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/selector';
import { styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import history from 'services/history';
import { useApp } from 'hooks/app';
import { useSearchParams } from 'react-router-dom';
import { NoteEntry } from 'types/noteEntry';
import ReportAppbarActions from 'pages/reports/item-entry/ReportAppbarActions';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
    marginBottom: 7,
  },
});

const Row = styled('div')({
  display: 'grid',
  gap: 5,
  gridTemplateColumns: '100px 120px 1fr 50px 60px 50px 110px 80px 80px 80px 80px',
  alignItems: 'center',
  '& > p': {
    fontSize: 12,
  },
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const ProductsContent = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 20,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
      fontSize: 13,
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

const HeaderContent = styled('div')({
  backgroundColor: '#eee',
  margin: -20,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginBottom: 10,
  '& > p': {
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const NoteEntryReport: React.FC = () => {
  const user = useSelector(state => state.user);
  const params = window.location.search;
  const [notes, setNotes] = useState<NoteEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const { h2iApi } = useApp();
  const [searchParams] = useSearchParams();
  const initial_date = searchParams.get('data_ini');
  const final_date = searchParams.get('data_fim');

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/getrelentradanf${params}`)
      .then(response => {
        const _notes = response.data.map((item: NoteEntry) => {
          item.formattedAddition = moneyFormat(item.valor_acrescimo);
          item.formattedTotal = moneyFormat(item.valor_total);
          item.formattedIpi = moneyFormat(item.valor_ipi);
          item.formattedDiscount = moneyFormat(item.valor_desconto);
          item.formattedShipment = moneyFormat(item.valor_frete);

          return item;
        });

        setNotes(_notes);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, h2iApi]);

  const formattedTotal = useMemo(() => {
    if (!notes.length) {
      return moneyFormat(0);
    }
    return moneyFormat(notes.reduce((sum, item) => sum + item.valor_total, 0));
  }, [notes]);

  return (
    <Dialog
      fullScreen
      title={`Relatório de compras`}
      onExited={() => history.push('/reports/note_entry')}
      ComponentActions={<ReportAppbarActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <div className="land-scape">
            <div className="header">
              <div>
                <h3>
                  Relatório de {initial_date} até {final_date}
                </h3>
              </div>
              <HeaderRightContent>
                <p>{user?.name}</p>
                <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
              </HeaderRightContent>
            </div>

            <ProductsContent>
              <HeaderContent>
                <p>Informações</p>
              </HeaderContent>

              <Row className="table-header">
                <p>Filial</p>
                <p>CNPJ</p>
                <p>Fornecedor</p>
                <p>NF</p>
                <p>CFOP</p>
                <p>Data Entrada</p>
                <p className="right">Valor Total</p>
                <p className="right">IPI</p>
                <p className="right">Valor frete</p>
                <p className="right">Valor desconto</p>
                <p className="right">Valor acréscimo</p>
              </Row>
              {notes.map((entry, index) => (
                <Row key={index}>
                  <p>{entry.filial}</p>
                  <p>{entry.cnpj}</p>
                  <p>{entry.fornecedor}</p>
                  <p>{entry.nf}</p>
                  <p>{entry.cfop}</p>
                  <p>{entry.data_entrada}</p>
                  <p className="right">{entry.formattedTotal}</p>
                  <p className="right">{entry.formattedIpi}</p>
                  <p className="right">{entry.formattedShipment}</p>
                  <p className="right">{entry.formattedDiscount}</p>
                  <p className="right">{entry.formattedAddition}</p>
                </Row>
              ))}
            </ProductsContent>

            <TotalContent>
              <p className="label">
                <strong>Totais</strong>
              </p>
              <div className="grid">
                <p>Total</p>
                <p className="value">{formattedTotal}</p>
              </div>
            </TotalContent>
          </div>
        </Container>
      )}
    </Dialog>
  );
};

export default NoteEntryReport;
