import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import BranchItemTable from './BranchItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import { BranchStockBalanceData } from 'types/reports/stockBalance';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { branchStockTableTemplate } from '../branchStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import BranchMoreMenu from '../BranchMoreMenu';

type BranchListTableProps = {
  branches: BranchStockBalanceData[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  isSubgroup: boolean;
  stoppedStock: boolean;
};

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const BranchListTable: React.FC<BranchListTableProps> = ({
  branches,
  handleSort,
  orderedIndex,
  isSubgroup,
  stoppedStock,
}) => {
  const { rowsPerPage, page } = usePagination();
  const { moreBranch } = useStockBalance();

  return (
    <Grid container>
      <TableContainer tableTemplate={branchStockTableTemplate}>
        <TableContent>
          <TableHeader>
            {branchStockTableTemplate.map(item => (
              <HeaderItem
                className={
                  item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
                }
                key={item.id}
                onClick={() => handleSort(item.originalId)}
              >
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight>
            {branches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((branch, index) => (
              <>
                <TableRow key={index}>
                  <BranchItemTable isSubgroup={isSubgroup} branch={branch} />
                </TableRow>
                {moreBranch?.id_filial === branch.id_filial && (
                  <BranchMoreMenu stoppedStock={stoppedStock} key={branch.id_filial} branchId={branch.id_filial} />
                )}
              </>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </Grid>
  );
};

export default BranchListTable;
