import React, { MouseEvent } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';
import { segmentTableTemplate } from '../segmentTableTemplate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStockBalance } from '../../hooks/useStockBalance';

type SegmentItemTableProps = {
  segment: SegmentStockBalanceData;
};

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const SegmentItemTable: React.FC<SegmentItemTableProps> = ({ segment }) => {
  const { moreSegment, setMoreSegment } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMoreSegment(moreSegment ? null : segment);
  }

  return (
    <>
      {segmentTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreSegment?.id_segmento === segment.id_segmento ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {segment[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SegmentItemTable;
