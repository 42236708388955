import React, { useEffect, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { PaymentFlow } from 'types/paymentFlow';
import { styled } from '@mui/material';
import { PurchaseOrder } from 'types/purchaseOrder';
import PurchaseOrderListTable from '../../registration/table/PurchaseOrderListTable';
import useTableOrder from 'hooks/tableOrder';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'hooks/app';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { usePurchaseOrder } from 'pages/purchase/order/hooks/PurchaseOrderProvider';
import Loading from 'components/loading/Loading';

interface TopOrdersPaymentsProps {
  onExited(): void;
  payment: PaymentFlow;
}

const Container = styled('div')({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const TopOrdersPayments: React.FC<TopOrdersPaymentsProps> = ({ onExited, payment }) => {
  const [filtered, setFiltered] = useState<PurchaseOrder[]>([]);
  const { h2iApi } = useApp();
  const { filter, filters } = usePurchaseOrder();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    if (!h2iApi) return;
    const params = {
      id_filial: filter.branch_id || '',
      produto: filter.product,
      aging: filter.aging,
      forecast: filter.forecast,
      vencimento: payment.vencimento,
    };
    setLoading(true);
    h2iApi
      .get(`/api/getpedidocompras?${filters}`, {
        params,
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setFiltered([]);
          return;
        }
        setFiltered(
          response.data.map(item => ({
            ...item,
            formattedActualDate: new Date().toISOString(),
            formattedForecast: item.previsao && parsePtBRDate(item.previsao).toISOString(),
            formattedNewForecast: parsePtBRDate(item.nova_previsao).toISOString(),
            formattedDate: item.data && parsePtBRDate(item.data).toISOString(),
            formattedDateIssue: item.data_emissao_nfe && parsePtBRDate(item.data_emissao_nfe).toISOString(),
            formattedTotal: moneyFormat(item.valor_total_pedido),
            formattedShipment: moneyFormat(item.valor_frete),
            formattedIpi: moneyFormat(item.valor_ipi),
            formattedSt: moneyFormat(item.valor_st),
            formattedExpense: moneyFormat(item.valor_despesa),
            formattedDiscount: moneyFormat(item.valor_desconto),
          })),
        );
      })
      .catch(() => setFiltered([]))
      .finally(() => setLoading(false));
  }, [h2iApi, filter, filters, payment]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Dialog onExited={onExited} title={`Confira os pedidos com vencimento em ${payment.vencimento}`} fullScreen>
      <PaginationProvider>
        <Container>
          {loading && <Loading />}
          <PurchaseOrderListTable
            useMaxHeight={false}
            orders={filtered}
            handleSort={handleSort}
            orderedIndex={orderedIndex}
          />
          <Pagination count={filtered.length} />
        </Container>
      </PaginationProvider>
    </Dialog>
  );
};

export default TopOrdersPayments;
