import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useProduct } from '../../Product';

const ProductTax: React.FC = () => {
  const { product, handleChange } = useProduct();

  return (
    <Grid gap={2} display={'grid'} paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Garantia H2i"
            placeholder="Informe a quantidade de dias da garantia H2i"
            fullWidth
            value={product.garantia_h2i}
            onChange={e => handleChange('garantia_h2i', e.target.value)}
            autoFocus
            type="number"
            inputProps={{ inputMode: 'numeric', step: 1 }}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Garantia total"
            placeholder="Informe a quantidade de dias da garantia total"
            fullWidth
            value={product.garantia_total}
            onChange={e => handleChange('garantia_total', e.target.value)}
            type="number"
            inputProps={{ inputMode: 'numeric', step: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Garantia fabricante"
            placeholder="Informe a quantidade de dias da garantia do fabricante"
            fullWidth
            value={product.garantia_total - product.garantia_h2i}
            onChange={e => handleChange('garantia_fabricante', e.target.value)}
            type="number"
            inputProps={{ inputMode: 'numeric', step: 1 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Site do fabricante"
            placeholder="Informe o site do fabricante"
            fullWidth
            value={product.site_fabricante}
            onChange={e => handleChange('site_fabricante', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <TextField
            label="Site para suporte"
            placeholder="Informe o site para suporte"
            fullWidth
            value={product.site_suporte}
            onChange={e => handleChange('site_suporte', e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
          <TextField
            multiline
            rows={5}
            label="Observações para troca"
            placeholder="Informe as observações para troca"
            fullWidth
            value={product.obs_troca}
            onChange={e => handleChange('obs_troca', e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
          <TextField
            multiline
            rows={5}
            label="Procedimento para troca"
            placeholder="Informe o procedimento para troca"
            fullWidth
            value={product.procedimento_troca}
            onChange={e => handleChange('procedimento_troca', e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductTax;
