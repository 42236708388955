import { TableTemplate } from 'types/tableTemplate';

export const ticketTableTemplate: TableTemplate[] = [
  { id: 'acoes', description: 'AÇÕES', originalId: 'acoes', notFilterable: true, notSearchable: true, width: 70 },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 80,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 140,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 350,
  },
  {
    id: 'data_lanc',
    description: 'Data lanc',
    originalId: 'formattedReleaseDate',
    width: 120,
  },
  {
    id: 'vencimento',
    description: 'Data venc',
    originalId: 'formattedDueDate',
    width: 120,
  },
  {
    id: 'data_pag',
    description: 'Data pag',
    originalId: 'formattedPayDate',
    width: 120,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 120,
  },
  {
    id: 'formattedFees',
    description: 'Juros',
    originalId: 'juros',
    width: 80,
    dataType: 'number',
  },
  {
    id: 'formattedTrafficTicket',
    description: 'Multa',
    originalId: 'multa',
    width: 80,
    dataType: 'number',
  },
  {
    id: 'formattedTotal',
    description: 'Valor total',
    originalId: 'valor_total',
    width: 150,
    dataType: 'number',
  },
];
