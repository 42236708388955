import { TableTemplate } from 'types/tableTemplate';

export const itemEntryTableTemplate: TableTemplate[] = [
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 100,
  },
  {
    id: 'comprador',
    description: 'Comprador',
    originalId: 'comprador',
    width: 100,
  },
  {
    id: 'fornecedor',
    description: 'Fornecedor',
    originalId: 'fornecedor',
    width: 260,
  },
  {
    id: 'fabricante',
    description: 'Fabricante',
    originalId: 'fabricante',
    width: 120,
  },
  {
    id: 'nf',
    description: 'NF',
    originalId: 'nf',
    width: 65,
  },
  {
    id: 'id_produto',
    description: 'Cod Prod',
    originalId: 'id_produto',
    width: 85,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'cst',
    description: 'CST',
    originalId: 'cst',
    width: 60,
  },
  {
    id: 'cfop',
    description: 'CFOP',
    originalId: 'cfop',
    width: 60,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 60,
  },
  {
    id: 'data_entrada',
    description: 'Data Entrada',
    originalId: 'data_entrada',
    width: 110,
  },
  {
    id: 'formattedUnitary',
    description: 'Vl Unitário',
    originalId: 'valor_unitario',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedCoustUnitaryTotal',
    description: 'Custo Unitário',
    originalId: 'custo_total_unitario',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedCoustTotal',
    description: 'Custo Total',
    originalId: 'custo_total',
    width: 100,
    dataType: 'number',
  },
];
