import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React from 'react';
import OrderByActions from './OrderByActions';
import Appbar from 'components/appbar/Appbar';

const OrderBy: React.FC = () => {
  return (
    <>
      <Appbar title="Pedido por" />

      <PageHeaderActions title="Confira a lista de pedido por" ActionComponent={<OrderByActions />} />
    </>
  );
};

export default OrderBy;
