import React, { useState } from 'react';
import { H2iOrder } from 'types/h2iOrder';
import { Typography, styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { useTable } from 'components/table/hook/useTable';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableBody from 'components/table/TableBody';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import DavReviewItemTable from './DavReviewItemTable';
import history from 'services/history';
import DavReviewListMenu from '../DavReviewListMenu';

interface DavReviewListTableProps {
  orders: H2iOrder[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const DavReviewListTable: React.FC<DavReviewListTableProps> = ({ orders, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <TableContent>
      <TableHeader>
        {anchorEl && <DavReviewListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
        {tableTemplate.map(item => (
          <HeaderItem key={item.id} onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}>
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </HeaderItem>
        ))}
      </TableHeader>
      <TableBody>
        {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
          <TableRow onClick={() => history.push(`/review/dav/${order.id}/${order.id_filial}`)} key={index}>
            <DavReviewItemTable setAnchorEl={setAnchorEl} order={order} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default DavReviewListTable;
