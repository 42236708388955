import { Article, Delete, Phone, Psychology, QueryBuilder } from '@mui/icons-material';
import { Icon, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Curriculum } from 'types/curriculum';
import { useResumes } from '../hooks/useResumes';

interface CurriculumItemModuleProps {
  curriculum: Curriculum;
  setAnchorEl: Dispatch<SetStateAction<Curriculum | null>>;
}

const Container = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  height: '100%',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  padding: 15,
  minWidth: 400,
  boxShadow: '0px 0px 6px 0px #c1c1c1',
  borderRadius: 6,
  [theme.breakpoints.down('sm')]: {
    minWidth: 250,
  },
}));

const Header = styled('div')({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
});

const InitialName = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 40,
  width: 40,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
}));

const HeaderInfos = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const HeaderActions = styled('div')({
  display: 'flex',
  gap: 5,
  alignSelf: 'start',
});

const Divider = styled('div')({
  height: 1,
  backgroundColor: '#c1c1c1',
  width: '100%',
});

const Footer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 5,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Description = styled('div')({
  alignItems: 'center',
  display: 'flex',
  gap: 5,
});

const CurriculumItemModule: React.FC<CurriculumItemModuleProps> = ({ curriculum, setAnchorEl }) => {
  const { setSelectedCurriculum } = useResumes();

  function handleClickDeleteButton(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(curriculum);
  }

  function handleClick() {
    setSelectedCurriculum(curriculum);
  }

  return (
    <Container onClick={handleClick}>
      <Header>
        <InitialName>
          <Typography variant="h6" fontWeight={600}>
            {curriculum.name[0].toUpperCase()}
          </Typography>
        </InitialName>

        <HeaderInfos>
          <Typography variant="h6" color="primary" fontWeight={500}>
            {curriculum.name}
          </Typography>

          <Typography variant="caption">{curriculum.city}</Typography>
        </HeaderInfos>

        <HeaderActions>
          {curriculum.document && (
            <Tooltip title="Baixar CV">
              <IconButton href={curriculum.document.url} target="_blank" color="success">
                <Article fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Excluir CV">
            <IconButton onClick={handleClickDeleteButton} color="error">
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        </HeaderActions>
      </Header>

      <Divider />

      <Footer>
        <Description>
          <Icon fontSize="small">
            <Psychology fontSize="small" />
          </Icon>
          <Typography variant="subtitle2" fontSize={13}>
            {curriculum.role}
          </Typography>
        </Description>

        <Description>
          <Icon fontSize="small">
            <Phone fontSize="small" />
          </Icon>
          <Typography variant="subtitle2" fontSize={13}>
            {curriculum.phone}
          </Typography>
        </Description>

        <Description>
          <Icon fontSize="small">
            <QueryBuilder fontSize="small" />
          </Icon>
          <Typography variant="subtitle2" fontSize={13}>
            {curriculum.formattedCreatedAt}
          </Typography>
        </Description>
      </Footer>
    </Container>
  );
};

export default CurriculumItemModule;
