import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Curriculum } from 'types/curriculum';

interface ResumesContext {
  selectedCurriculum: Curriculum | null;
  setSelectedCurriculum: Dispatch<SetStateAction<Curriculum | null>>;
  setSaving: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ResumesContext>({} as ResumesContext);
export const ResumesProvider = Context.Provider;

export function useResumes(): ResumesContext {
  return useContext(Context);
}
