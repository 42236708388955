import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { Revenue } from 'types/revenue';
import { useRevenues } from '../../hooks/useRevenues';

const StyledListItem = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '110px 1fr',
    alignItems: 'center',
  },
});

interface RevenueItemModuleProps {
  revenue: Revenue;
}

const RevenueItemModule: React.FC<RevenueItemModuleProps> = ({ revenue }) => {
  const { setSelectedRevenue } = useRevenues();

  return (
    <StyledListItem onClick={() => setSelectedRevenue(revenue)}>
      <Typography variant="body1">{revenue.nome}</Typography>

      <div className="content">
        <Typography color="textSecondary" variant="body2">
          Filial:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {revenue.id_filial}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          Tipo:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {revenue.formattedType}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          Cód Contábil:
        </Typography>

        <Typography color="textSecondary" variant="body2">
          {revenue.conta_contabil}
        </Typography>
      </div>
    </StyledListItem>
  );
};

export default RevenueItemModule;
