import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import BillItemTable from './BillItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Bill } from 'types/bill';
import BillListMenu from '../BillsListMenu';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type BillListTableProps = {
  bills: Bill[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const BillListTable: React.FC<BillListTableProps> = ({ bills, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <BillListMenu setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={() => handleSort(item.originalId)}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {bills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(bill => (
            <TableRow
              key={bill.id}
              style={{
                backgroundColor: bill.canceled_at
                  ? 'rgba(255, 0, 0, 0.2)'
                  : bill.bank_billet_remittance_id
                  ? 'rgba(0, 255, 0, 0.2)'
                  : '',
              }}
            >
              <BillItemTable bill={bill} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default BillListTable;
