import React, { useMemo } from 'react';
import { TablePagination as DefaultTablePagination, styled, Pagination as PaginationLab } from '@mui/material';
import { usePagination } from 'hooks/pagination';

type PaginationProps = {
  count: number;
};

const CustomPaginationLab = styled(PaginationLab)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const TablePagination = styled(DefaultTablePagination)(({ theme }) => ({
  '&.selectRoot': {
    marginRight: 10,
  },
  '&.toolbar': {
    paddingLeft: '0!important',
  },
  '&.actions': {
    marginLeft: 0,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  '&.MuiTablePagination-root': {
    border: 'none',
    padding: 0,
  },
}));

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zoom: '90%',
});

const Pagination: React.FC<PaginationProps> = ({ count }) => {
  const { rowsPerPage, page, handleSetPage, handleSetRowsPerPage, rowsPerPageOption } = usePagination();

  const pages = useMemo(() => Math.ceil(count / rowsPerPage), [rowsPerPage, count]);

  return (
    <Container>
      <TablePagination
        labelRowsPerPage="Registros"
        rowsPerPageOptions={rowsPerPageOption}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onPageChange={(e, page) => handleSetPage(page)}
        onRowsPerPageChange={e => handleSetRowsPerPage(parseFloat(e.target.value))}
      />
      <CustomPaginationLab
        count={pages}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={(e, page) => handleSetPage(page - 1)}
        color="primary"
      />
    </Container>
  );
};

export default Pagination;
