import React from 'react';
import { AbcProductManufacturer } from 'types/abcProduct';
import { Typography, styled, ListItem } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type ManufacturerItemModuleProps = {
  manufacturer: AbcProductManufacturer;
};

const ManufacturerItemModule: React.FC<ManufacturerItemModuleProps> = ({ manufacturer }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{manufacturer.fabricante}</Typography>

      <Grid className="bottom">
        <Typography variant="caption">Vl Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {manufacturer.formattedTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Vl Custo:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {manufacturer.formattedCoust}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {manufacturer.formattedTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Vl Unitário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {manufacturer.formattedUnitary}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Qtd:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {manufacturer.qtd}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default ManufacturerItemModule;
