import React from 'react';
import { MonthlyBranch } from 'types/monthlyComparison';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

type BranchItemModuleProps = {
  branch: MonthlyBranch;
};

const BranchItemModule: React.FC<BranchItemModuleProps> = ({ branch }) => {
  return (
    <ListItemStyled>
      <Typography variant="body2">{branch.nome}</Typography>
      <Typography variant="body2">{branch.cidade}</Typography>
    </ListItemStyled>
  );
};

export default BranchItemModule;
