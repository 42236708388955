import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, styled } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import FilterMoreForm from 'components/filter-more/FilterMoreForm';
import InsideLoading from 'components/loading/InsideLoading';
import { AbcProductFilterParams } from './AbcProduct';
import { useFetchMoreFilters } from 'components/filter-more/hooks/useFetchMoreFilters';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  flex: 1,
}));

const Form = styled('form')(({ theme }) => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
}));

type AbcProductDateFilterProps = {
  onExited(): void;
  onSearch(): void;
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
};

const AbcProductDateFilter: React.FC<AbcProductDateFilterProps> = ({
  onExited,
  onSearch,
  filter,
  handleChangeFilter,
}) => {
  const { units, segments, subCategories, categories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <Container>
        {loading ? (
          <InsideLoading />
        ) : (
          <Form>
            <FilterMoreForm
              showCategories
              showSegments
              showUnits
              showMadeBy
              manufacturers={manufacturers}
              subCategories={subCategories}
              subGroups={subGroups}
              categories={categories}
              segments={segments}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              units={units}
            />

            <KeyboardDatePicker
              label="Data inicial"
              value={filter.initialDate}
              onChange={date => handleChangeFilter('initialDate', date)}
            />

            <KeyboardDatePicker
              label="Data Final"
              value={filter.finalDate}
              onChange={date => handleChangeFilter('finalDate', date)}
            />

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </Form>
        )}
      </Container>
    </DialogInput>
  );
};

export default AbcProductDateFilter;
