import React, { FormEvent } from 'react';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import { VacancyQueryParams } from './hooks/useFetchVacancy';

type JustificationFilterProps = {
  handleChange(index: keyof VacancyQueryParams, value: any): void;
  filter: VacancyQueryParams;
  handleSearch(queryParams: VacancyQueryParams, e?: FormEvent<HTMLFormElement>): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 2fr 1fr 100px',
  columnGap: 10,
  flex: 1,
  '& label': {
    marginRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    rowGap: 15,
  },
  '& > .search-params': {
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const VacancyFilter: React.FC<JustificationFilterProps> = ({
  filter,
  setDisplayMode,
  displayMode,
  handleSearch,
  handleChange,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={e => handleSearch(filter, e)}>
        <TextField
          label="Título da vaga"
          placeholder="Digite o título da vaga"
          autoFocus
          value={filter.role}
          onChange={e => handleChange('role', e.target.value)}
        />
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date}
            onChange={date => handleChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date}
            onChange={date => handleChange('final_date', date)}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={filter.is_active}
              onChange={e => handleChange('is_active', !!e.target.checked)}
              color="primary"
            />
          }
          label="Ativas"
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default VacancyFilter;
