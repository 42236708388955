import React from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import TableBody from 'components/table/TableBody';
import TableContainer from 'components/table/TableContainer';
import TableContent from 'components/table/TableContent';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import { retailTableTemplate } from 'pages/retail-table/template/retailTableTemplate';
import { RetailTable } from 'types/retailTable';
import RetailTableItemTable from './RetailTableItemTable';

interface Props {
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  filteredTables: RetailTable[];
}

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

const RetailTableListTable: React.FC<Props> = ({ filteredTables, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <TableContainer tableTemplate={retailTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {retailTableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? `numericData` : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={10} variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight maxHeight={110}>
          {filteredTables.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((table, index) => (
            <TableRow key={index}>
              <RetailTableItemTable table={table} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default RetailTableListTable;
