import { Print } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import React from 'react';
import { ItemEntry } from 'types/itemEntry';

interface ItemEntryActionsProps {
  handleReportClick(): void;
  entries: ItemEntry[];
}

const Actions = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  gap: 10,
});

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const ItemEntryActions: React.FC<ItemEntryActionsProps> = ({ entries, handleReportClick }) => {
  const { handleDownload } = useDefaultExcelExport(entries);

  return (
    <Actions>
      <ExcelButtonDownload handleDownload={handleDownload} size="small" marginTop={0} disabled={!entries.length} />
      <CustomButton
        disabled={!entries.length}
        startIcon={<Print />}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleReportClick}
      >
        Imprimir
      </CustomButton>
    </Actions>
  );
};

export default ItemEntryActions;
