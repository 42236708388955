import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItemButton, styled, IconButton } from '@mui/material';
import { Vacancy } from 'types/vacancy';
import history from 'services/history';
import { MoreHoriz } from '@mui/icons-material';
import { useVacancies } from '../../hooks/useVacancies';

const ListItemButtonStyled = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Flex = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type VacancyItemModuleProps = {
  vacancy: Vacancy;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const VacancyItemModule: React.FC<VacancyItemModuleProps> = ({ vacancy, setAnchorEl }) => {
  const { setSelectedVacancy } = useVacancies();

  function handleClick() {
    history.push(`/rh/vacancy/${vacancy.id}`);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setSelectedVacancy(vacancy);
  }

  return (
    <ListItemButtonStyled onClick={handleClick}>
      <Flex>
        <Typography variant="caption" color="primary" gutterBottom>
          Vaga #{vacancy.id}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Criado em:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.formattedCreatedAt}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Local:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.local}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Vaga:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.role}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Salário:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.salary}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Publicada por:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.username}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">Obs:</Typography>

        <Typography align="left" variant="caption">
          {vacancy.observation}
        </Typography>
      </Flex>

      <IconButton size="small" onClick={e => handleMoreClick(e)}>
        <MoreHoriz />
      </IconButton>
    </ListItemButtonStyled>
  );
};

export default VacancyItemModule;
