import Dialog from 'components/dialogs/Dialog';
import React, { useState } from 'react';
import InvoiceActions from './BankStatementPaymentActions';
import { MenuItem, TextField, Typography, styled } from '@mui/material';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { moneyFormat } from 'helpers/numberFormat';
import { Receipt, RequestQuote } from '@mui/icons-material';
import { useBankStatementPaymentValidation } from './validation/bankStatementPaymentValidation';
import { Extract } from 'types/extract';
import { TotalizingAccount } from 'types/totalizingAccount';

interface BankStatementPaymentProps {
  onExited(): void;
  selectedExtracts: Extract[];
  accounts: TotalizingAccount[];
}

const Content = styled('div')({
  display: 'grid',
  gap: 15,
  gridTemplateColumns: '1fr 1fr',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  gridColumn: '1/3',
});

const Box = styled('div')(({ theme }) => ({
  minWidth: 225,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
  alignItems: 'center',
  gap: 15,
  border: `1px solid ${theme.palette.primary.dark}`,
  '& .value': {
    fontSize: 24,
    textAlign: 'end',
    paddingRight: 5,
  },
  '& svg': {
    paddingLeft: 5,
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
  },
  '& .description': {
    fontWeight: 600,
    padding: '10px 5px',
    gridColumn: '1/3',
    textAlign: 'end',
    borderTop: `1px solid ${theme.palette.primary.dark}`,
  },
}));

const Header = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxHeight: 225,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    maxHeight: 430,
  },
}));

const Item = styled('li')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 60px 90px',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '100px 100px 100px 60px 90px',
  },
  '&.header': {
    backgroundColor: '#eee',
  },
}));

const List = styled('ul')(({ theme }) => ({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 20,
    overflow: 'scroll',
  },
}));

const BankStatementPayment: React.FC<BankStatementPaymentProps> = ({ onExited, selectedExtracts, accounts }) => {
  const [validation, setValidation, validate] = useBankStatementPaymentValidation();
  const [totalizingAccountId, setTotalizingAccountId] = useState(0);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const total = selectedExtracts.reduce((sum, item) => sum + item.valor, 0);
  const [totals, setTotals] = useState(selectedExtracts.reduce((sum, item) => sum + item.valor, 0).toString());
  const length = selectedExtracts.length;

  function handleValidation() {
    setValidation({});

    validate({ totalizingAccountId })
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {}

  return (
    <Dialog
      height="70vh"
      maxWidth="sm"
      onExited={onExited}
      ComponentActions={<InvoiceActions handleSubmit={handleValidation} />}
      title="Pagamento"
    >
      <Header>
        <Box>
          <Receipt />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {length}
          </Typography>
          <Typography className="description" variant="caption">
            Quantidade de títulos
          </Typography>
        </Box>

        <Box>
          <RequestQuote />
          <Typography className="value" fontSize={12} variant="subtitle2">
            {moneyFormat(total)}
          </Typography>
          <Typography className="description" variant="caption">
            (R$) Total
          </Typography>
        </Box>
      </Header>
      <div style={{ margin: '20px 0' }}>
        <Typography variant="caption">
          * Ao selecionar mais de um registro, o valor pago não pode ser alterado
        </Typography>
      </div>

      <form>
        <Content>
          {length === 1 && (
            <Column>
              <TextField
                label="Valor pago (R$)"
                autoFocus
                value={totals}
                onChange={e => setTotals(e.target.value)}
                inputMode="decimal"
              />
            </Column>
          )}
          <TextField
            select
            error={!!validation.totalizingAccountId}
            helperText={validation.totalizingAccountId}
            value={totalizingAccountId}
            placeholder="Selecione a conta totalizadora"
            label="Conta totalizadora"
            onChange={e => setTotalizingAccountId(parseInt(e.target.value))}
          >
            <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>

            {accounts.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>

          <DesktopDatePicker label="Data de pagamento" value={paymentDate} onChange={date => setPaymentDate(date)} />
        </Content>
        <List>
          <Typography variant="subtitle2">Faturas selecionadas:</Typography>
          <Item className="header">
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              Descrição
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              Origem
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              Destino
            </Typography>
            <Typography variant="subtitle1" fontWeight={600} fontSize={13}>
              Tipo
            </Typography>
            <Typography align="right" variant="subtitle1" fontWeight={600} fontSize={13}>
              Valor
            </Typography>
          </Item>
          {selectedExtracts.map((item, index) => (
            <Item key={index}>
              <Typography variant="caption">{item.descricao}</Typography>
              <Typography variant="caption">{item.conta}</Typography>
              <Typography variant="caption">{item.conta_destino}</Typography>
              <Typography variant="caption">{item.formattedType}</Typography>
              <Typography variant="caption" align="right">
                {item.formattedValue}
              </Typography>
            </Item>
          ))}
        </List>
      </form>
    </Dialog>
  );
};

export default BankStatementPayment;
