import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { PaymentMethod } from 'types/salesReport';
import { paymentTableTemplate } from '../template/paymentTableTemplate';

type DailySummaryPaymentItemTableProps = {
  method: PaymentMethod;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const DailySummaryPaymentItemTable: React.FC<DailySummaryPaymentItemTableProps> = ({ method }) => {
  return (
    <>
      {paymentTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item => (
          <div
            key={_item.id}
            className={
              _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
            }
          >
            <Tooltip title={method[_item.id]}>
              <Description variant="body2" fontSize={12}>
                {method[_item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default DailySummaryPaymentItemTable;
