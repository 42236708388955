import React, { MouseEvent } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { rmaTableTemplate } from '../../template/rmaTableTemplate';
import { Rma } from 'types/rma';
import { Print } from '@mui/icons-material';
import history from 'services/history';

type RmaItemTableProps = {
  item: Rma;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const RmaItemTable: React.FC<RmaItemTableProps> = ({ item }) => {
  function handleRedirect(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    history.push(`/reports/rma_analysis/print?id_filial=${item.id_filial}&id=${item.id}`);
  }
  return (
    <>
      {rmaTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item =>
          _item.id === 'action' ? (
            <Tooltip key={item.id} title="Imprimir">
              <IconButton size="small" color="primary" onClick={e => handleRedirect(e)}>
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div
              key={_item.id}
              className={
                _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Tooltip title={item[_item.id]}>
                <Description style={{ fontSize: 10 }} variant="body2">
                  {item[_item.id]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default RmaItemTable;
