import React, { useState } from 'react';
import List from 'components/list/List';
import { Vacancy } from 'types/vacancy';
import VacancyItemModule from './VacancyItemModule';
import VacancyListMenu from '../VacancyListMenu';

type VacancyListModuleProps = {
  vacancies: Vacancy[];
};

const VacancyListModule: React.FC<VacancyListModuleProps> = ({ vacancies }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <VacancyListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {vacancies.map(vacancy => (
          <VacancyItemModule setAnchorEl={setAnchorEl} key={vacancy.id} vacancy={vacancy} />
        ))}
      </List>
    </>
  );
};

export default VacancyListModule;
