import React from 'react';
import { Typography, styled } from '@mui/material';

const Container = styled('div')<{ background: string }>(({ background }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  backgroundColor: background,
}));

type NoDataProps = {
  message: string;
  background?: string;
};

const NoData: React.FC<NoDataProps> = ({ message, background = 'transparent' }) => {
  return (
    <Container background={background}>
      <Typography color="textSecondary">{message}</Typography>
    </Container>
  );
};

export default NoData;
