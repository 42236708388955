import { useApp } from 'hooks/app';
import React, { useCallback, useContext, useState, ReactNode, FormEvent, useSyncExternalStore, useEffect } from 'react';
import { useSelector } from 'store/selector';
import { OrderTotals, PurchaseOrder } from 'types/purchaseOrder';
import { PurchaseOrderFilter } from '../PurchaseOrder';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import {
  PurchaseOrderBuyer,
  PurchaseOrderBuyerWithoutSale,
  PurchaseOrderItem,
  PurchaseOrderSegment,
} from 'types/purchaseOrderItem';
import { Aging, AgingBuyer } from 'types/aging';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { TransferRequest } from 'types/transferRequest';
import { StockItemList } from 'types/stockItem';
import { OverduePayment, PaymentFlow, BranchPayment, DetailPayment } from 'types/paymentFlow';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ShoppingSuggestion } from 'types/shoppingSuggestion';
import { filterStore } from './useFilterStore';
import createStore from './useStore';

const initial_total: OrderTotals = {
  estoque: 0,
  pedido: 0,
  valor_total: 0,
  aging: 0,
  compras: 0,
  transferencias: 0,
  valor_estoque: 0,
  formattedStockValue: 'R$ 0,00',
  formattedTransfers: 'R$ 0,00',
  formattedShopping: 'R$ 0,00',
  formattedTotal: 'R$ 0,00',
  formattedAging: 'R$ 0,00',
};

export interface Loading {
  buyersWithoutSales: boolean;
  items: boolean;
  registration: boolean;
  dashboard: boolean;
  aging: boolean;
  segments: boolean;
  transferRequests: boolean;
  stockItems: boolean;
  shoppingSuggestion: boolean;
  totals: boolean;
  paymentFlow: boolean;
}

interface PurchaseOrderContextData {
  handleSearch(query: string, event: FormEvent<HTMLFormElement>): void;
  handleChange(index: keyof PurchaseOrderFilter, value: any): void;
  orders: PurchaseOrder[];
  ordersProducts: PurchaseOrderItem[];
  agingList: Aging[];
  agingBuyers: AgingBuyer[];
  buyersWithoutSales: PurchaseOrderBuyerWithoutSale[];
  ordersSegments: PurchaseOrderSegment[];
  ordersBuyers: PurchaseOrderBuyer[];
  loading: Loading;
  filter: PurchaseOrderFilter;
  totals: OrderTotals;
  onFilterClick(query: string): void;
  stockItemsGrouped: StockItemList[];
  stockItemsUngrouped: StockItemList[];
  transfers: TransferRequest[];
  filters: string;
  payments: PaymentFlow[];
  suggestions: ShoppingSuggestion[];
  overduePayments: OverduePayment[];
  branchesPayments: BranchPayment[];
  detailsPayments: DetailPayment[];
}

const PurchaseOrderContext = React.createContext({} as PurchaseOrderContextData);
interface PurchaseOrderProviderProps {
  children: ReactNode;
}

const useFilterStore = () => {
  return useSyncExternalStore(filterStore.subscribe, filterStore.getState);
};

const totalsStore = createStore<OrderTotals>('totals', initial_total);
const paymentsStore = createStore<PaymentFlow[]>('payments', []);
const detailsPaymentsStore = createStore<DetailPayment[]>('detailsPayments', []);
const overduePaymentsStore = createStore<OverduePayment[]>('overduePayments', []);
const buyersWithoutSalesStore = createStore<PurchaseOrderBuyerWithoutSale[]>('buyersWithoutSales', []);
const ordersBuyersStore = createStore<PurchaseOrderBuyer[]>('ordersBuyers', []);
const agingListStore = createStore<Aging[]>('agingList', []);
const stockItemsGroupedStore = createStore<StockItemList[]>('stockItemsGrouped', []);
const stockItemsUngroupedStore = createStore<StockItemList[]>('stockItemsUngrouped', []);
const agingBuyersStore = createStore<AgingBuyer[]>('agingBuyers', []);
const transfersStore = createStore<TransferRequest[]>('transfers', []);
const ordersSegmentsStore = createStore<PurchaseOrderSegment[]>('ordersSegments', []);
const ordersProductsStore = createStore<PurchaseOrderItem[]>('ordersProducts', []);
const ordersStore = createStore<PurchaseOrder[]>('orders', []);
const suggestionsStore = createStore<ShoppingSuggestion[]>('suggestions', []);

const PurchaseOrderProvider: React.FC<PurchaseOrderProviderProps> = ({ children }) => {
  const user = useSelector(state => state.user);
  const filters = useFilterStore();
  const [localFilters, setLocalFilters] = useState(filters);

  const totals = useSyncExternalStore(totalsStore.subscribe, totalsStore.get, totalsStore.get);
  const payments = useSyncExternalStore(paymentsStore.subscribe, paymentsStore.get, paymentsStore.get);
  const detailsPayments = useSyncExternalStore(
    detailsPaymentsStore.subscribe,
    detailsPaymentsStore.get,
    detailsPaymentsStore.get,
  );
  const overduePayments = useSyncExternalStore(
    overduePaymentsStore.subscribe,
    overduePaymentsStore.get,
    overduePaymentsStore.get,
  );
  const buyersWithoutSales = useSyncExternalStore(
    buyersWithoutSalesStore.subscribe,
    buyersWithoutSalesStore.get,
    buyersWithoutSalesStore.get,
  );
  const ordersBuyers = useSyncExternalStore(ordersBuyersStore.subscribe, ordersBuyersStore.get, ordersBuyersStore.get);
  const agingList = useSyncExternalStore(agingListStore.subscribe, agingListStore.get, agingListStore.get);
  const stockItemsGrouped = useSyncExternalStore(
    stockItemsGroupedStore.subscribe,
    stockItemsGroupedStore.get,
    stockItemsGroupedStore.get,
  );
  const stockItemsUngrouped = useSyncExternalStore(
    stockItemsUngroupedStore.subscribe,
    stockItemsUngroupedStore.get,
    stockItemsUngroupedStore.get,
  );
  const agingBuyers = useSyncExternalStore(agingBuyersStore.subscribe, agingBuyersStore.get, agingBuyersStore.get);
  const transfers = useSyncExternalStore(transfersStore.subscribe, transfersStore.get, transfersStore.get);
  const ordersSegments = useSyncExternalStore(
    ordersSegmentsStore.subscribe,
    ordersSegmentsStore.get,
    ordersSegmentsStore.get,
  );
  const ordersProducts = useSyncExternalStore(
    ordersProductsStore.subscribe,
    ordersProductsStore.get,
    ordersProductsStore.get,
  );
  const orders = useSyncExternalStore(ordersStore.subscribe, ordersStore.get, ordersStore.get);
  const suggestions = useSyncExternalStore(suggestionsStore.subscribe, suggestionsStore.get, suggestionsStore.get);

  const [loading, setLoading] = useState<Loading>({
    buyersWithoutSales: false,
    items: false,
    registration: false,
    dashboard: false,
    aging: false,
    segments: false,
    transferRequests: false,
    stockItems: false,
    shoppingSuggestion: false,
    paymentFlow: false,
    totals: false,
  });
  const { h2iApi, handleOpenMenu, isOpenedMenu } = useApp();
  const [filter, setFilter] = useState<PurchaseOrderFilter>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 12 ? '0' : user.branchList[0]) : 999,
    buyer: '',
    product: '',
    aging: '30',
    forecast: '30',
  });

  useEffect(() => {
    setFilter(state => ({
      ...state,
      branch_id: user?.branchList?.length === 12 ? 0 : parseInt(user?.branchList[0] || '99'),
    }));
  }, [user]);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleSearch = useCallback(
    async (query: string, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!h2iApi) return;
      if (isOpenedMenu) {
        handleOpenMenu();
      }
      setLoading({
        aging: true,
        buyersWithoutSales: true,
        dashboard: true,
        registration: true,
        items: true,
        segments: true,
        transferRequests: true,
        stockItems: true,
        paymentFlow: true,
        shoppingSuggestion: true,
        totals: true,
      });

      const productId = parseInt(filter.product);

      const params = {
        id_filial: filter.branch_id || '',
        produto: productId.toString().length !== filter.product.length ? filter.product : '',
        id_produto: productId.toString().length !== filter.product.length ? '' : productId,
        aging: filter.aging,
        forecast: filter.forecast,
      };

      h2iApi
        .get(`/api/getsugestaotransferencia?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            transfersStore.set([]);
            return;
          }
          transfersStore.set(
            response.data.map(item => ({
              ...item,
              custo_total: parseFloat(item.custo_total),
              formattedReleaseDate: parsePtBRDate(item.data_lanc).toISOString(),
              formattedQuantity: parseInt(item.qtd),
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedTotalWeight: parseFloat(item.peso_total),
            })),
          );
        })
        .catch(() => transfersStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            transferRequests: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidototais?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            totalsStore.set(initial_total);

            return;
          }
          totalsStore.set({
            ...response.data[0],
            formattedTotal: moneyFormat(response.data[0].valor_total),
            formattedStockValue: moneyFormat(response.data[0].valor_estoque),
            formattedShopping: moneyFormat(response.data[0].compras),
            formattedTransfers: moneyFormat(response.data[0].transferencias),
          });
        })
        .catch(() => totalsStore.set(initial_total))
        .finally(() =>
          setLoading(state => ({
            ...state,
            totals: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidocompras?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            ordersStore.set([]);
            return;
          }
          ordersStore.set(
            response.data.map(item => ({
              ...item,
              formattedActualDate: new Date().toISOString(),
              formattedForecast: item.previsao && parsePtBRDate(item.previsao).toISOString(),
              formattedNewForecast: parsePtBRDate(item.nova_previsao).toISOString(),
              formattedDate: item.data && parsePtBRDate(item.data).toISOString(),
              formattedDateIssue: item.data_emissao_nfe && parsePtBRDate(item.data_emissao_nfe).toISOString(),
              formattedTotal: moneyFormat(item.valor_total_pedido),
              formattedShipment: moneyFormat(item.valor_frete),
              formattedIpi: moneyFormat(item.valor_ipi),
              formattedSt: moneyFormat(item.valor_st),
              formattedExpense: moneyFormat(item.valor_despesa),
              formattedDiscount: moneyFormat(item.valor_desconto),
            })),
          );
        })
        .catch(() => ordersStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            registration: false,
          })),
        );

      h2iApi
        .get(`/api/getestoquexforecast?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            suggestionsStore.set([]);
            return;
          }
          suggestionsStore.set(
            response.data.map(item => ({
              ...item,
              pedidos: parseFloat(item.pedidos),
              estoque: parseFloat(item.estoque),
              forecast: parseFloat(item.forecast),
              id_produto: parseFloat(item.id_produto),
              formattedArrivalForecast: item.previsao_chegada && parsePtBRDate(item.previsao_chegada).toISOString(),
            })),
          );
        })
        .catch(() => suggestionsStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            shoppingSuggestion: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidoitens?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            ordersProductsStore.set([]);
            return;
          }
          ordersProductsStore.set(
            response.data.map(item => ({
              ...item,
              formattedPercent: percentFormat(item.perc_comprou_caro),
              formattedCoust: moneyFormat(item.custo),
              formattedNegotiatedValue: moneyFormat(item.valor_negociado),
              formattedValueTotal: moneyFormat(item.valor_total),
              formattedReleaseDate: item.data_lancamento && parsePtBRDate(item.data_lancamento).toISOString(),
              formattedMinForecast: parsePtBRDate(item.previsaomin).toISOString(),
              formattedMaxForecast: parsePtBRDate(item.previsaomax).toISOString(),
            })),
          );
        })
        .catch(() => ordersProductsStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            items: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidosegmentos?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            ordersSegmentsStore.set([]);
            return;
          }
          ordersSegmentsStore.set(response.data);
        })
        .catch(() => ordersSegmentsStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            segments: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidocomprador?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            ordersBuyersStore.set([]);

            return;
          }
          ordersBuyersStore.set(response.data);
        })
        .catch(() => ordersBuyersStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            dashboard: false,
          })),
        );

      h2iApi
        .get(`/api/getpedidosemvendas?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            buyersWithoutSalesStore.set([]);
            return;
          }
          buyersWithoutSalesStore.set(
            response.data.map(item => ({
              ...item,
              formattedValue: moneyFormat(item.valor),
              formattedQuantity: parseInt(item.qtd_itens),
            })),
          );
        })
        .catch(() => buyersWithoutSalesStore.set([]))
        .finally(() =>
          setLoading(state => ({
            ...state,
            buyersWithoutSales: false,
          })),
        );

      const fetchGroupedProductsSoldOutStock = h2iApi
        .get(`/api/getprodutosvendeuzerou?agrupado=S&${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            stockItemsGroupedStore.set([]);

            return;
          }
          stockItemsGroupedStore.set(
            response.data.map(item => ({
              ...item,
              formattedExpectedArrivalDate: item.PrevChega && parsePtBRDate(item.PrevChegada).toISOString(),
              pedidos: item.pedidos && parseInt(item.pedidos),
            })),
          );
        })
        .catch(() => stockItemsGroupedStore.set([]));

      const fetchUngroupedProductsSoldOutStock = h2iApi
        .get(`/api/getprodutosvendeuzerou?agrupado=N&${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            stockItemsUngroupedStore.set([]);

            return;
          }
          stockItemsUngroupedStore.set(
            response.data.map(item => ({
              ...item,
              formattedExpectedArrivalDate: item.PrevChega && parsePtBRDate(item.PrevChegada).toISOString(),
              pedidos: item.pedidos && parseInt(item.pedidos),
            })),
          );
        })
        .catch(() => stockItemsUngroupedStore.set([]));

      const fetchAgingList = h2iApi.get(`/api/getpedidoaging?${query}`, {
        params,
      });

      const fetchAgingBuyer = h2iApi.get(`/api/getpedidoagingcomprador?${query}`, {
        params,
      });

      const fetchPayments = h2iApi
        .get(`/api/getpedidopagamento?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            paymentsStore.set([]);

            return;
          }
          paymentsStore.set(
            response.data.map(item => ({
              ...item,
              formattedDayOfWeek: item.vencimento && format(parsePtBRDate(item.vencimento), 'EEEE', { locale: ptBR }),
              formattedDueDate: item.vencimento && parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => paymentsStore.set([]));

      const fetchOverduePayments = h2iApi
        .get(`/api/getpedidopagamentovencido?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            overduePaymentsStore.set([]);

            return;
          }
          overduePaymentsStore.set(
            response.data.map(item => ({
              ...item,
              formattedReleaseDate: parsePtBRDate(item.data_lancamento).toISOString(),
              formattedArrivalDate: parsePtBRDate(item.previsao_chegada).toISOString(),
              formattedDueDate: parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => overduePaymentsStore.set([]));

      const fetchDetailPayments = h2iApi
        .get(`/api/getpedidovencimento?${query}`, {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            detailsPaymentsStore.set([]);
            return;
          }
          detailsPaymentsStore.set(
            response.data.map(item => ({
              ...item,
              formattedReleaseDate: parsePtBRDate(item.data_lancamento).toISOString(),
              formattedArrivalDate: parsePtBRDate(item.previsao_chegada).toISOString(),
              formattedDueDate: parsePtBRDate(item.vencimento).toISOString(),
              formattedValue: moneyFormat(item.valor),
            })),
          );
        })
        .catch(() => detailsPaymentsStore.set([]));

      Promise.all([fetchPayments, fetchOverduePayments, fetchDetailPayments]).finally(() =>
        setLoading(state => ({
          ...state,
          paymentFlow: false,
        })),
      );

      Promise.all([fetchUngroupedProductsSoldOutStock, fetchGroupedProductsSoldOutStock]).finally(() =>
        setLoading(state => ({
          ...state,
          stockItems: false,
        })),
      );

      Promise.all([fetchAgingList, fetchAgingBuyer])
        .then(([agingResponse, agingBuyerResponse]) => {
          if (agingResponse.data.MESSAGE) {
            agingListStore.set([]);
            agingBuyersStore.set([]);

            return;
          }

          const _agingList: Aging[] = agingResponse.data;
          const _agingBuyer: AgingBuyer[] = agingBuyerResponse.data;

          totalsStore.set({
            ...totalsStore.get(),
            aging: _agingList.reduce((sum, item) => sum + item.custo_total, 0),
            formattedAging: moneyFormat(_agingList.reduce((sum, item) => sum + item.custo_total, 0)),
          });

          agingBuyersStore.set(
            _agingBuyer.map(item => ({
              ...item,
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedStock: parseInt(item.estoque),
            })),
          );

          agingListStore.set(
            _agingList.map(item => ({
              ...item,
              formattedCoust: moneyFormat(item.custo),
              formattedTotalCoust: moneyFormat(item.custo_total),
              formattedStock: parseInt(item.estoque),
            })),
          );
        })
        .catch(() => {
          agingListStore.set([]);
          agingBuyersStore.set([]);
        })
        .finally(() =>
          setLoading(state => ({
            ...state,
            aging: false,
          })),
        );
    },
    [filter.aging, filter.branch_id, filter.forecast, filter.product, h2iApi, handleOpenMenu, isOpenedMenu],
  );

  function handleChange(index: keyof PurchaseOrderFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function onFilterClick(query: string) {
    handleSearch(query);
    setLocalFilters(query);
    filterStore.setState(query);
  }

  return (
    <PurchaseOrderContext.Provider
      value={{
        handleSearch,
        handleChange,
        agingList,
        agingBuyers,
        buyersWithoutSales,
        ordersSegments,
        ordersBuyers,
        loading,
        filter,
        totals,
        onFilterClick,
        stockItemsGrouped,
        stockItemsUngrouped,
        transfers,
        filters: localFilters,
        ordersProducts,
        orders,
        payments,
        overduePayments,
        branchesPayments: [],
        detailsPayments,
        suggestions,
      }}
    >
      {children}
    </PurchaseOrderContext.Provider>
  );
};

export function usePurchaseOrder(): PurchaseOrderContextData {
  const context = useContext(PurchaseOrderContext);

  if (!context) throw new Error('This hook must be in PurchaseOrder Context Component');

  return context;
}

export default PurchaseOrderProvider;
