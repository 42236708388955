import React from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';

const TestArea: React.FC = () => {
  return (
    <>
      <Appbar title="Área de desenvolvimento" />
      <PageHeader title="Área de desenvolvimento" description="Teste aqui seus códigos em desenvolvimento" />
    </>
  );
};

export default TestArea;
