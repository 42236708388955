import React, { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import { Assessment } from '@mui/icons-material';
import NoData from 'components/no-data/NoData';
import { usePagination } from 'hooks/pagination';
import { AggregatedProduct } from './ShoppingSuggestion';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  zoom: '90%',
});

const TR = styled('tr')(({ theme }) => ({
  cursor: 'pointer',
  display: 'grid',
  borderBottom: '1px solid #eee',
  gridTemplateColumns: '50px 360px 70px 70px 70px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 70px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns:
      '50px 1fr 70px 70px 70px 70px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 70px',
  },
  '&.header': {
    '& > span': {
      cursor: 'pointer',
      gap: 5,
      display: 'grid',
      alignItems: 'center',
      fontSize: 12,
      '&.center': {
        justifyContent: 'center',
      },
    },
  },
  '& > h6': {
    gap: 5,
    padding: 5,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    '&.center': {
      justifyContent: 'center',
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #eee',
    },
  },
  '& > span': {
    '& > h6': {
      padding: 5,
      gap: 5,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      '&.center': {
        justifyContent: 'center',
      },
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #eee',
    },
  },
  '& .product-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('table')({
  display: 'grid',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  '& > span, h6': {
    fontSize: 12,
  },
});

const Row = styled('span')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '&:nth-child(even)': {
    backgroundColor: '#eeeff8',
  },
  '& > h6': {
    fontWeight: 600,
  },
}));

interface TopShoppingSuggestionAggregatedProps {
  data: AggregatedProduct[];
  branches: string[];
  setSelectedProduct: Dispatch<SetStateAction<AggregatedProduct | null>>;
}

const TopShoppingSuggestionAggregated: React.FC<TopShoppingSuggestionAggregatedProps> = ({
  data,
  branches,
  setSelectedProduct,
}) => {
  const [, sort] = useTableOrder();
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<AggregatedProduct[]>([]);

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  function handleRedirect(e: MouseEvent<HTMLButtonElement>, product: AggregatedProduct) {
    e.stopPropagation();

    window.open(`/commercial-bi/analysis-product/${product.id_produto}`, '_blank');
  }

  return (
    <Container>
      {filtered.length > 0 ? (
        <List>
          <TR className="header">
            <span>
              <Column onClick={() => handleSort('id_produto')}>
                <Typography align="center" variant="subtitle2">
                  Cod
                </Typography>
                <Typography align="center" variant="subtitle2">
                  Prod
                </Typography>
              </Column>
            </span>

            <span>
              <Typography onClick={() => handleSort('produto')} variant="subtitle2">
                Produto
              </Typography>
            </span>

            <span className="center">
              <Column onClick={() => handleSort('estoque_total')}>
                <Typography color="primary" align="center" variant="subtitle2">
                  Est
                </Typography>
                <Typography color="primary" align="center" variant="subtitle2">
                  Total
                </Typography>
              </Column>
            </span>

            <span className="center">
              <Column onClick={() => handleSort('forecast_total')}>
                <Typography color="primary" align="center" variant="subtitle2">
                  For
                </Typography>
                <Typography color="primary" align="center" variant="subtitle2">
                  Total
                </Typography>
              </Column>
            </span>

            <span className="center">
              <Column onClick={() => handleSort('sugestao_total')}>
                <Typography color="primary" align="center" variant="subtitle2">
                  Sug
                </Typography>
                <Typography color="primary" align="center" variant="subtitle2">
                  Total
                </Typography>
              </Column>
            </span>

            {branches.map(branch => (
              <Row key={branch}>
                <Column onClick={() => handleSort(`est_${branch.toLowerCase()}`)}>
                  <Typography align="center" variant="subtitle2">
                    Est
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    {branch}
                  </Typography>
                </Column>

                <Column onClick={() => handleSort(`sug_${branch.toLowerCase()}`)}>
                  <Typography color="error" align="center" variant="subtitle2">
                    Sug
                  </Typography>
                  <Typography color="error" align="center" variant="subtitle2">
                    {branch}
                  </Typography>
                </Column>
              </Row>
            ))}

            <span className="header"></span>
          </TR>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
            <TR key={index} onClick={() => setSelectedProduct(product)}>
              <Typography align="center" variant="subtitle2">
                {product.id_produto}
              </Typography>
              <Tooltip title={product.produto}>
                <Typography className="product-name" variant="subtitle2">
                  {product.produto}
                </Typography>
              </Tooltip>

              <Typography color="primary" variant="subtitle2" className="center">
                {product.estoque_total}
              </Typography>
              <Typography color="primary" variant="subtitle2" className="center">
                {product.forecast_total}
              </Typography>
              <Typography color="primary" variant="subtitle2" className="center">
                {product.sugestao_total}
              </Typography>
              {branches.map(branch => (
                <Row key={branch}>
                  <Typography align="center" className="center" variant="subtitle2">
                    {product[`est_${branch.toLowerCase()}`]}
                  </Typography>
                  <Typography color="error" className="center" variant="subtitle2">
                    {product[`sug_${branch.toLowerCase()}`]}
                  </Typography>
                </Row>
              ))}

              <span className="center">
                <Tooltip title="Análise de produto">
                  <IconButton size="small" color="primary" onClick={e => handleRedirect(e, product)}>
                    <Assessment fontSize="small" />
                  </IconButton>
                </Tooltip>
              </span>
            </TR>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
    </Container>
  );
};

export default TopShoppingSuggestionAggregated;
