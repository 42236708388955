import React from 'react';
import { Button, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { Search } from '@mui/icons-material';
import SelectOthersBranch from 'components/select-branches/SelectOthersBranch';
import { useTotalizingAccounts } from './hooks/useTotalizingAccount';

const StyledFilter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '200px 300px 100px',
  flex: 1,
  gap: 10,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '150px 1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'end',
}));

const TotalizingAccountFilterBox: React.FC = () => {
  const { filter, handleChangeFilter, handleSearch, loading } = useTotalizingAccounts();

  return (
    <FilterBox>
      <StyledFilter>
        <SelectOthersBranch branch_id={filter.branchId} handleChange={value => handleChangeFilter('branchId', value)} />
        <TextField
          value={filter.description}
          onChange={e => handleChangeFilter('description', e.target.value)}
          placeholder="Filtro"
          label="Nome"
        />
        <Actions>
          <Button
            disabled={loading}
            onClick={handleSearch}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Search />}
          >
            Buscar
          </Button>
        </Actions>
      </StyledFilter>
    </FilterBox>
  );
};

export default TotalizingAccountFilterBox;
