import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import { Tooltip, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import { useParams } from 'react-router-dom';
import { useFetchPurchaseOrder } from '../registration/hook/useFetchPurchaseOrder';
import history from 'services/history';
import PurchaseOrderAppbarButtons from './PurchaseOrderAppbarButtons';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
  },
});

const Row = styled('table')({
  display: 'grid',
  gridTemplateColumns: '50px 1fr 70px 35px 70px 70px 70px 70px 70px 100px',
  alignItems: 'center',
  '&.payment': {
    borderSpacing: 0,
    borderCollapse: 'collapse',
    boxSizing: 'border-box',
    gridTemplateColumns: '0.8fr 0.7fr 0.8fr 0.7fr 0.8fr 0.7fr 0.8fr 0.7fr',
    '& > p': {
      padding: 5,
      border: '1px solid hsla(0,0%,50.2%,.5019607843)',
    },
  },
  '& > .title': {
    fontWeight: 'bold',
  },
  '& > p': {
    fontSize: 12,
  },
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const ContentColor = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 10,
  border: '1px solid #eee',
  position: 'relative',
  paddingTop: 32,
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

const Content = styled('div')({
  border: '1px solid #eee',
  padding: 10,
  display: 'grid',
  gridTemplateColumns: '0.8fr 1fr 1fr 1fr',
  position: 'relative',
  gap: 4,
  marginTop: 10,
  paddingTop: 32,
  '& > p, span': {
    fontSize: 12,
  },
  '& > p > span': {
    fontWeight: 'bold',
  },
});

const HeaderContent = styled('div')({
  backgroundColor: '#eee',
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  padding: 5,
  '& > p': {
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const Description = styled('p')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  width: '100%',
  WebkitBoxOrient: 'vertical',
});

const CustomToolTip = styled(Tooltip)({
  gridColumn: '2/4',
});

const PurchaseOrderReport: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { redirect_to } = useParams<{ redirect_to: string }>();
  const user = useSelector(state => state.user);
  const { order, loading } = useFetchPurchaseOrder(id);
  const branches = useSelector(state => state.branches);

  const formattedTotal = useMemo(() => {
    return moneyFormat(order.valor_total_pedido);
  }, [order]);

  const formattedOrderTotal = useMemo(() => {
    return moneyFormat(order.valor_produtos);
  }, [order]);

  const branch = useMemo(() => {
    if (!order.id_filial) return null;
    return branches.filter(item => item.id === order.id_filial)[0];
  }, [branches, order]);

  function onExited() {
    if (redirect_to) {
      history.push(`/${redirect_to.replaceAll('_', '/')}`);
      return;
    }
    history.push('/purchasing/registration');
  }

  return (
    <Dialog
      fullScreen
      title={`Pedido #${order.codigo_pedido}`}
      onExited={onExited}
      ComponentActions={<PurchaseOrderAppbarButtons />}
    >
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <div className="page">
            <div className="header">
              <div>
                <h3>Pedido de compras: {order.codigo_pedido}</h3>
              </div>
              <HeaderRightContent>
                <p>{user?.name}</p>
                <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
              </HeaderRightContent>
            </div>

            <Content>
              <HeaderContent>
                <p>Informações do destinatário</p>
              </HeaderContent>
              <Tooltip title={order.filial}>
                <p>
                  <b>Unidade:</b> {order.filial}
                </p>
              </Tooltip>
              <Tooltip title={branch?.cnpj}>
                <p>
                  <b>CNPJ:</b> {branch?.cnpj}
                </p>
              </Tooltip>
              <Tooltip title={branch?.cidade}>
                <p>
                  <b>Cidade:</b> {branch?.cidade}
                </p>
              </Tooltip>
            </Content>

            <Content>
              <HeaderContent>
                <p>Informações do remetente</p>
              </HeaderContent>

              <Tooltip title={order.codigo_pedido}>
                <p>
                  <b>Pedido:</b> {order.codigo_pedido}
                </p>
              </Tooltip>

              <Tooltip title={order.data}>
                <p>
                  <b>Dt Cadastro:</b> {order.data}
                </p>
              </Tooltip>

              <Tooltip title={order.previsao}>
                <p>
                  <b>Previsão:</b> {order.previsao}
                </p>
              </Tooltip>

              <Tooltip title={order.nova_previsao}>
                <p>
                  <b>Nova previsão:</b> {order.nova_previsao}
                </p>
              </Tooltip>

              <Tooltip title={order.codigo_fornecedor}>
                <p>
                  <b>Cód:</b> {order.codigo_fornecedor}
                </p>
              </Tooltip>

              <CustomToolTip title={order.cliente}>
                <p>
                  <b>Fornecedor:</b> {order.cliente}
                </p>
              </CustomToolTip>
              <Tooltip title={order.cnpj}>
                <p>
                  <b>CNPJ:</b> {order.cnpj}
                </p>
              </Tooltip>

              <Tooltip title={order.notafiscal}>
                <p>
                  <b>NF:</b> {order.notafiscal}
                </p>
              </Tooltip>

              <Tooltip title={order.serie_nfe}>
                <p>
                  <b>Série:</b> {order.serie_nfe}
                </p>
              </Tooltip>

              <Tooltip title={order.cfop}>
                <p>
                  <b>CFOP:</b> {order.cfop}
                </p>
              </Tooltip>

              <Tooltip title={order.descricao_cfop}>
                <p>
                  <b>NatOp:</b> {order.descricao_cfop}
                </p>
              </Tooltip>

              <Tooltip title={moneyFormat(order.valor_frete)}>
                <p>
                  <b>Vl Frete:</b> {moneyFormat(order.valor_frete)}
                </p>
              </Tooltip>

              <Tooltip title={moneyFormat(order.valor_ipi)}>
                <p>
                  <b>Vl IPI:</b> {moneyFormat(order.valor_ipi)}
                </p>
              </Tooltip>

              <Tooltip title={moneyFormat(order.valor_st)}>
                <p>
                  <b>Vl ST:</b> {moneyFormat(order.valor_st)}
                </p>
              </Tooltip>

              <Tooltip title={moneyFormat(order.valor_desconto)}>
                <p>
                  <b>Vl Desc:</b> {moneyFormat(order.valor_desconto)}
                </p>
              </Tooltip>

              <Tooltip title={moneyFormat(order.valor_total_pedido)}>
                <p>
                  <b>Total:</b> {moneyFormat(order.valor_total_pedido)}
                </p>
              </Tooltip>
            </Content>

            <ContentColor>
              <HeaderContent>
                <p>Pagamentos</p>
              </HeaderContent>

              <Row className="payment">
                <p className="title">Vencimento</p>
                <p className="right title">Valor</p>
                <p className="title">Vencimento</p>
                <p className="right title">Valor</p>
                <p className="title">Vencimento</p>
                <p className="right title">Valor</p>
                <p className="title">Vencimento</p>
                <p className="right title">Valor</p>
                {order.pagamentos.map((payment, index) => (
                  <>
                    <p>{payment.vencimento}</p>
                    <p className="right">{payment.formattedValue}</p>
                  </>
                ))}
              </Row>
            </ContentColor>

            <ContentColor>
              <HeaderContent>
                <p>Produtos</p>
              </HeaderContent>

              <Row className="table-header">
                <p>Código</p>
                <p>Descrição</p>
                <p className="right">Vl Unit</p>
                <p className="right">Qtd</p>
                <p className="right">Vl Acr</p>
                <p className="right">VL ST</p>
                <p className="right">VL IPI</p>
                <p className="right">VL Frete</p>
                <p className="right">VL Desc.</p>
                <p className="right">VL Total</p>
              </Row>
              {order.itens.map((product, index) => (
                <Row key={index}>
                  <p>{product.codigo_produto}</p>
                  <Tooltip title={product.produto}>
                    <Description>{product.produto}</Description>
                  </Tooltip>
                  <p className="right">{product.formattedValueUnitary}</p>
                  <p className="right">{product.quantidade}</p>
                  <p className="right">{moneyFormat(product.vl_acr_unitario)}</p>
                  <p className="right">{moneyFormat(product.vl_st_unitario)}</p>
                  <p className="right">{moneyFormat(product.vl_ipi_unitario)}</p>
                  <p className="right">{moneyFormat(product.vl_frete_unitario)}</p>
                  <p className="right">{moneyFormat(product.vl_desc_unitario)}</p>
                  <p className="right">{product.formattedValue}</p>
                </Row>
              ))}
            </ContentColor>

            <TotalContent>
              <p className="label">
                <strong>Totais</strong>
              </p>
              <div className="grid">
                <p>Produtos</p>
                <p className="value">{formattedTotal}</p>
                <p>Pedido</p>
                <p className="value">{formattedOrderTotal}</p>
              </div>
            </TotalContent>
          </div>
        </Container>
      )}
    </Dialog>
  );
};

export default PurchaseOrderReport;
