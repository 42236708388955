import React from 'react';
import { Button, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { Add, DisplaySettings, Print } from '@mui/icons-material';
import { useExtracts } from './hooks/useExtracts';

const PageHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  gap: 10,
});

const Actions = styled('div')(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  gap: 10,
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PageHeaderButtons: React.FC = () => {
  const { extracts, setIsReportOpen, setIsNewTransferOpen, setIsNewExpense, defaultView, setDefaultView } =
    useExtracts();

  const { handleDownload } = useDefaultExcelExport(extracts);

  function handleReportClick() {
    setIsReportOpen(true);
  }

  return (
    <PageHeader>
      <Actions>
        <div>
          <Button
            startIcon={<Add />}
            variant="contained"
            color="secondary"
            size="small"
            sx={{ color: '#fff', minWidth: 160 }}
            onClick={() => setIsNewTransferOpen(true)}
          >
            Transferência
          </Button>

          <Button
            startIcon={<Add />}
            variant="contained"
            color="error"
            size="small"
            sx={{ color: '#fff', minWidth: 160 }}
            onClick={() => setIsNewExpense(true)}
          >
            Despesas
          </Button>
        </div>

        <div>
          <CustomButton
            disabled={!extracts.length}
            startIcon={<DisplaySettings />}
            variant="contained"
            color="primary"
            size="small"
            sx={{ color: '#fff', minWidth: 160 }}
            onClick={() =>
              setDefaultView(defaultView === 'type' ? 'default' : defaultView === 'default' ? 'date' : 'type')
            }
          >
            Visualização
          </CustomButton>

          <ExcelButtonDownload handleDownload={handleDownload} size="small" marginTop={0} disabled={!extracts.length} />

          <Button
            disabled={!extracts.length}
            startIcon={<Print />}
            variant="contained"
            color="primary"
            size="small"
            sx={{ color: '#fff', minWidth: 160 }}
            onClick={handleReportClick}
          >
            Imprimir
          </Button>
        </div>
      </Actions>
    </PageHeader>
  );
};

export default PageHeaderButtons;
