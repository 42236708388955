import React from 'react';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import PriceProtectionDashboardTotal from './PriceProtectionDashboardTotal';
import { PriceProtection } from 'types/priceProtection';
import PriceProtectionDashboardManufacturer from './manufacturer/PriceProtectionDashboardManufacturer';
import PriceProtectionDashboardProducts from './products/PriceProtectionDashboardProducts';
import { useFetchPriceProtectionDashboard } from './hooks/useFetchPriceProtectionDashboard';

interface PriceProtectionDashboardProps {
  datas: PriceProtection[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 2fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const PriceProtectionDashboard: React.FC<PriceProtectionDashboardProps> = ({ datas }) => {
  const { groupedManufacturers, groupedProducts } = useFetchPriceProtectionDashboard(datas);

  return (
    <Container>
      <PriceProtectionDashboardTotal datas={datas} />

      {datas.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <PaginationProvider>
            <PriceProtectionDashboardManufacturer manufacturers={groupedManufacturers} />
          </PaginationProvider>

          <PaginationProvider>
            <PriceProtectionDashboardProducts products={groupedProducts} />
          </PaginationProvider>
        </Content>
      )}
    </Container>
  );
};

export default PriceProtectionDashboard;
