import React, { useState } from 'react';
import { IconButton, styled, TextField, Tooltip } from '@mui/material';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import { NewCreditLimitRequestDocument } from 'types/creditLimitRequest';
import { NewResellerDocument } from 'types/reseller';
import Dialog from 'components/dialogs/Dialog';
import { Done } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

interface ModaNewDocumentProps {
  onExited(): void;
  handleAddDocument(document: NewResellerDocument): void;
}

const ModalNewDocument: React.FC<ModaNewDocumentProps> = ({ onExited, handleAddDocument }) => {
  const [resellerDocument, setResellerDocument] = useState<NewResellerDocument>({
    description: '',
    document: null,
  });

  function handleChange(index: keyof NewCreditLimitRequestDocument, value: any) {
    setResellerDocument(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleConfirm() {
    onExited();
    handleAddDocument(resellerDocument);
  }

  return (
    <Dialog
      ComponentActions={
        <Tooltip title="Adicionar documento" arrow>
          <IconButton
            disabled={!resellerDocument.document || !resellerDocument.description}
            color="inherit"
            onClick={handleConfirm}
          >
            <Done />
          </IconButton>
        </Tooltip>
      }
      title="Adicionar documento"
      onExited={onExited}
      maxWidth="sm"
    >
      <Container>
        <TextField
          label="Descrição"
          placeholder="Descrição do arquivo"
          margin="normal"
          value={resellerDocument.description}
          onChange={e => handleChange('description', e.target.value)}
          fullWidth
        />
        <DocumentUpload
          handleRemoveImage={() => handleChange('document', null)}
          label="Novo documento"
          document={resellerDocument.document}
          handleSetImage={document => handleChange('document', document)}
        />
      </Container>
    </Dialog>
  );
};

export default ModalNewDocument;
