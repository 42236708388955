import React from 'react';
import { TextField, Typography, styled } from '@mui/material';
import { useReseller } from '../hooks/useReseller';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import ResellerApprovalSelfie from './ResellerApprovalSelfie';
import CpfInput from 'components/masked-input/CpfInput';
import PhoneInput from 'components/masked-input/PhoneInput';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import InputWhatsAppButton from 'components/input-whatsapp-button/InputWhatsAppButton';

const Row = styled('div')({
  display: 'flex',
  gap: 10,
});

const DocsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: 10,
  rowGap: 20,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const docUpload = {
  width: '100%',
  height: 150,
};

const Location = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
});

const RowTwoColumns = styled('div')({
  display: 'grid',
  gridTemplateColumns: '130px 1fr',
  columnGap: '10px',
});

const Row3 = styled('div')({
  display: 'grid',
  gridTemplateColumns: '80px 1fr 1fr 80px',
  columnGap: '10px',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
});

const ResellerApprovalPartner: React.FC = () => {
  const { handleChange, handlePartnerAddressChange } = useReseller();
  const { reseller } = useReseller();

  return (
    <Container>
      <Row>
        <TextField
          label="CPF"
          placeholder="Informe o CPF do sócio"
          value={reseller?.partner_document || ''}
          onChange={e => handleChange('partner_document', e.target.value)}
          fullWidth
          InputProps={{
            inputComponent: CpfInput as any,
          }}
        />
        <TextField
          label="RG"
          placeholder="Informe o RG do sócio"
          value={reseller?.partner_rg || ''}
          onChange={e => handleChange('partner_rg', e.target.value)}
          fullWidth
        />
      </Row>

      <Row>
        <TextField
          label="Nome"
          placeholder="Informe o nome do sócio"
          value={reseller?.partner_name || ''}
          onChange={e => handleChange('partner_name', e.target.value)}
          fullWidth
        />
      </Row>

      <Row>
        <TextField
          label="Telefone"
          placeholder="Informe o telefone do sócio"
          value={reseller?.partner_phone || ''}
          onChange={e => handleChange('partner_phone', e.target.value)}
          fullWidth
          InputProps={{
            inputComponent: PhoneInput as any,
            endAdornment: <InputWhatsAppButton phone={reseller?.phone || ''} />,
          }}
        />
      </Row>

      <Location>
        <Typography variant="caption" fontWeight={600}>
          Endereço do sócio
        </Typography>
        <RowTwoColumns>
          <TextField
            label="CEP"
            placeholder="Informe o CEP"
            value={reseller?.partner_address?.postal_code || ''}
            onChange={e => handlePartnerAddressChange('postal_code', e.target.value)}
            fullWidth
            InputProps={{
              inputComponent: PostalCodeInput as any,
            }}
          />
          <TextField
            label="Logradouro"
            placeholder="Informou o logradouro"
            value={reseller?.partner_address?.street || ''}
            onChange={e => handlePartnerAddressChange('street', e.target.value)}
            fullWidth
          />
        </RowTwoColumns>
        <Row3>
          <TextField
            label="Número"
            placeholder="Informe o número da rua"
            value={reseller?.partner_address?.street_number || ''}
            onChange={e => handlePartnerAddressChange('street_number', e.target.value)}
            fullWidth
          />
          <TextField
            label="Bairro"
            placeholder="Informe o bairro"
            value={reseller?.partner_address?.neighborhood || ''}
            onChange={e => handlePartnerAddressChange('neighborhood', e.target.value)}
            fullWidth
          />
          <TextField
            label="Cidade"
            placeholder="Informe a cidade"
            value={reseller?.partner_address?.city || ''}
            onChange={e => handlePartnerAddressChange('city', e.target.value)}
            fullWidth
          />
          <TextField
            label="Estado"
            placeholder="Informe o estado"
            value={reseller?.partner_address?.state || ''}
            onChange={e => handlePartnerAddressChange('state', e.target.value)}
            fullWidth
          />
        </Row3>
      </Location>

      <DocsContainer>
        <DocumentUpload
          document={reseller?.partner_document_copy || null}
          label="Documento com foto"
          handleRemoveImage={() => handleChange('partner_document_copy', null)}
          handleSetImage={doc => handleChange('partner_document_copy', doc)}
          style={docUpload}
        />

        <DocumentUpload
          document={reseller?.partner_proof_residence_copy || null}
          label="Comprovante de residência"
          handleRemoveImage={() => handleChange('partner_proof_residence_copy', null)}
          handleSetImage={doc => handleChange('partner_proof_residence_copy', doc)}
          style={docUpload}
        />

        <ResellerApprovalSelfie
          image={reseller?.partner_selfie || null}
          label="Selfie com documento"
          handleRemoveImage={() => handleChange('partner_selfie', null)}
          handleSetImage={doc => handleChange('partner_selfie', doc)}
          style={docUpload}
        />
      </DocsContainer>
    </Container>
  );
};

export default ResellerApprovalPartner;
