import { MenuItem, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useTable } from 'components/table/hook/useTable';
import React, { useEffect, useRef } from 'react';
import { useCreditLimitRequests } from './hooks/useCreditLimitRequests';

const Filter = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '180px 200px 1fr',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 2fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
  '& > .search-params': {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '150px 150px',
    columnGap: 10,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

type ResellersFilterBoxProps = {
  setIndexToSearch(index: string): void;
  indexToSearch: string;
  setSearchValue(value: string): void;
  searchValue: string;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const ResellersFilterBox: React.FC<ResellersFilterBoxProps> = ({
  setIndexToSearch,
  indexToSearch,
  setSearchValue,
  searchValue,
}) => {
  const { tableTemplate } = useTable();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { handleChangeFilter, filter } = useCreditLimitRequests();

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [indexToSearch]);

  return (
    <FilterBox>
      <Filter>
        <TextField value={indexToSearch} onChange={e => setIndexToSearch(e.target.value)} select label="Pesquisar em">
          {tableTemplate
            .filter(col => !col.notSearchable)
            .map(item => (
              <MenuItem key={item.id} value={item.originalId}>
                {item.description}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
      </Filter>
    </FilterBox>
  );
};

export default ResellersFilterBox;
