import React from 'react';
import DialogInput from 'components/dialogs/DialogInput';
import { Button, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import InsideLoading from 'components/loading/InsideLoading';
import { useFetchMoreFilters } from './hooks/useFetchMoreFilters';
import FilterMoreForm from './FilterMoreForm';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  flex: 1,
}));

const Form = styled('form')(({ theme }) => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'column',
}));

export interface FilterMore {
  madeBy: string;
  unitIds: number[];
  segmentIds: number[];
  categoryIds: number[];
  subCategoryIds: number[];
  subGroupIds: number[];
  manufacturerIds: number[];
}

type FilterMoreProps = {
  responsible?: boolean;
  showMadeBy?: boolean;
  showUnits?: boolean;
  showSegments?: boolean;
  showCategories?: boolean;
  onExited(): void;
  onSearch(): void;
  filter: FilterMore;
  handleChangeFilter(index: keyof FilterMore, value: any): void;
};

const FilterMorePage: React.FC<FilterMoreProps> = ({
  onExited,
  onSearch,
  handleChangeFilter,
  filter,
  showMadeBy = true,
  showUnits = true,
  showSegments = true,
  showCategories = true,
  responsible = true,
}) => {
  const { units, segments, subCategories, categories, subGroups, manufacturers, loading } = useFetchMoreFilters();

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <Container>
        {loading ? (
          <InsideLoading />
        ) : (
          <Form>
            <FilterMoreForm
              showUnits={showUnits}
              showSegments={showSegments}
              showCategories={showCategories}
              showMadeBy={showMadeBy}
              manufacturers={manufacturers}
              categories={categories}
              handleChangeFilter={handleChangeFilter}
              units={units}
              subGroups={subGroups}
              segments={segments}
              filter={filter}
              subCategories={subCategories}
            />

            <div>
              <Button
                endIcon={<Search fontSize="small" />}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onSearch}
                fullWidth
              >
                Pesquisar
              </Button>
            </div>
          </Form>
        )}
      </Container>
    </DialogInput>
  );
};

export default FilterMorePage;
