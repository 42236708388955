import React from 'react';
import UnityItemModule from './UnityItemModule';
import List from 'components/list/List';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import { UnityStockBalanceData } from 'types/reports/stockBalance';

type UnityListModuleProps = {
  units: UnityStockBalanceData[];
};

const ListStyled = styled(List)({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr',
  columnGap: 100,
  flex: 1,
});

const UnityListModule: React.FC<UnityListModuleProps> = ({ units }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <ListStyled>
      {units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(unity => (
        <UnityItemModule key={unity.id_unidade} unity={unity} />
      ))}
    </ListStyled>
  );
};

export default UnityListModule;
