import FilterBox from 'components/filter-box/FilterBox';
import React, { FormEvent } from 'react';
import { Button, Checkbox, FormControlLabel, styled, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ProductsSearchParams } from './Products';

interface ProductsFilterBoxProps {
  handleSearch: (e?: FormEvent) => void;
  loading: boolean;
  searchParams: ProductsSearchParams;
  search: string;
  setSearch: (value: string) => void;
  handleSetSearchParams: (index: keyof ProductsSearchParams, value: any) => void;
}

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '400px 150px 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '2fr 1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ProductsFilterBox: React.FC<ProductsFilterBoxProps> = ({
  handleSearch,
  loading,
  searchParams,
  search,
  setSearch,
  handleSetSearchParams,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearch}>
        <TextField
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              checked={searchParams.stock}
              onChange={e => handleSetSearchParams('stock', e.target.checked)}
              color="primary"
            />
          }
          label="Estoque"
        />
        <Button startIcon={<Search />} size="small" disabled={loading} variant="contained" type="submit">
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default ProductsFilterBox;
