import React, { MouseEvent, useEffect, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import PaginationProvider from 'hooks/pagination';
import { useApp } from 'hooks/app';
import { ItemEntry } from 'types/itemEntry';
import { NoteEntry } from 'types/noteEntry';
import { moneyFormat } from 'helpers/numberFormat';
import { IconButton, ListItem, styled, Tooltip, Typography } from '@mui/material';
import { useDisplayMode } from 'hooks/useDisplayMode';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import { OpenInNew, PictureAsPdf } from '@mui/icons-material';
import { useMessaging } from 'hooks/messaging';

interface ItemEntryListNotesProps {
  onExited(): void;
  selectedEntry: ItemEntry;
}

const Container = styled('div')({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '100px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

const Actions = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  gap: 10,
  marginTop: 10,
});

const ItemEntryListNotes: React.FC<ItemEntryListNotesProps> = ({ onExited, selectedEntry }) => {
  const [note, setNote] = useState<NoteEntry>({} as NoteEntry);
  const { h2iApi } = useApp();
  const [displayMode] = useDisplayMode();
  const { handleOpen } = useMessaging();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!h2iApi) return;

    const params = {
      id_entrada: selectedEntry.id_entrada,
      id_filial: selectedEntry.id_filial,
    };

    setLoading(true);
    h2iApi
      .get('/api/getrelentradanf', {
        params,
      })
      .then(response => {
        const _notes = response.data.map((item: NoteEntry) => {
          item.formattedAddition = moneyFormat(item.valor_acrescimo);
          item.formattedTotal = moneyFormat(item.valor_total);
          item.formattedIpi = moneyFormat(item.valor_ipi);
          item.formattedDiscount = moneyFormat(item.valor_desconto);
          item.formattedShipment = moneyFormat(item.valor_desconto);

          return item;
        });

        setNote(_notes[0]);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [h2iApi, selectedEntry]);

  function openExternalSite(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(note.chave);
    window.open(`https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx`, '_blank');
    handleOpen('Chave copiada para a área de transferência');
  }

  function openExternalSiteXML(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    navigator.clipboard.writeText(note.chave);
    window.open(`https://consultadanfe.com/?chave=${note.chave}`, '_blank');
  }

  return (
    <Dialog onExited={onExited} height={500} maxWidth="sm" title={`Confira a entrada da nota ${selectedEntry.nf}`}>
      <PaginationProvider>
        <Container>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : !note ? (
            <NoData message="Nenhuma nota encontrada" />
          ) : (
            <ListItemStyled>
              <Grid className="bottom">
                <Typography variant="caption">Filial:</Typography>

                <Typography align="left" variant="caption" fontWeight={600}>
                  {note.filial}
                </Typography>
              </Grid>

              <Grid className="bottom">
                <Typography variant="caption">Fornecedor:</Typography>

                <Typography align="left" variant="caption" fontWeight={600}>
                  {note.fornecedor}
                </Typography>
              </Grid>

              <Grid className="bottom">
                <Typography variant="caption">NF:</Typography>

                <Typography align="left" variant="caption" fontWeight={600}>
                  {note.nf}
                </Typography>
              </Grid>

              <Grid className="bottom">
                <Typography variant="caption">Chave:</Typography>

                <Typography align="left" variant="caption" fontWeight={600}>
                  {note.chave}
                </Typography>
              </Grid>

              <Actions>
                <Tooltip title="Clique aqui para abrir o site da SEFAZ">
                  <IconButton color="primary" size="small" onClick={e => openExternalSite(e)}>
                    <OpenInNew />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Clique aqui para visualizar a DANFE/XML">
                  <IconButton color="error" size="small" onClick={e => openExternalSiteXML(e)}>
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
              </Actions>
            </ListItemStyled>
          )}
        </Container>
      </PaginationProvider>
    </Dialog>
  );
};

export default ItemEntryListNotes;
