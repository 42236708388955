import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { useTable } from 'components/table/hook/useTable';
import { IconButton, Typography, styled } from '@mui/material';
import { CommercialDashboard } from 'types/commercialDashboard';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type CommercialDashboardItemTableProps = {
  order: CommercialDashboard;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setSelectedOrder: Dispatch<SetStateAction<CommercialDashboard | null>>;
};

const CommercialDashboardItemTable: React.FC<CommercialDashboardItemTableProps> = ({
  order,
  setAnchorEl,
  setSelectedOrder,
}) => {
  const { tableTemplate } = useTable();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);

    setSelectedOrder(order);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <CustomIconButton onClick={handleClickMore} key={item.id}>
              <MoreHoriz />
            </CustomIconButton>
          ) : (
            <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
              <Typography style={{ fontSize: 11 }} variant="body2">
                {order[item.id as keyof CommercialDashboard]}
              </Typography>
            </NumericData>
          ),
        )}
    </>
  );
};

export default CommercialDashboardItemTable;
