import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { SegmentStockBalanceData } from 'types/reports/stockBalance';

type SegmentItemModuleProps = {
  segment: SegmentStockBalanceData;
};

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

const SegmentItemModule: React.FC<SegmentItemModuleProps> = ({ segment }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{segment.segmento}</Typography>
      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Estoque
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.Estoque}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Est total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.EstTotal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Custo total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {segment.formattedCustoTotal}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default SegmentItemModule;
