import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import { styled } from '@mui/material';
import CommercialAnalysisSubgroupFilterBox from './CommercialAnalysisSubgroupFilterBox';
import { useFetchCommercialAnalysisSubgroup } from './hooks/useFetchCommercialAnalysisSubgroup';
import CommercialAnalysisProductLoading from '../analysis-product/loading/CommercialAnalysisProductLoading';
import CommercialAnalysisSubgroupTabs from './tab/CommercialAnalysisSubgroupTabs';
import CommercialAnalysisSubgroupHeader from './CommercialAnalysisProductHeader';
import CommercialAnalysisProductTransfer from '../analysis-product/transfer/CommercialAnalysisProductTransfer';
import CommercialAnalysisSubgroupProducts from './products/CommercialAnalysisSubgroupProducts';
import CommercialAnalysisSubgroupSuggestion from './suggestion/CommercialAnalysisSubgroupSuggestion';
import CommercialAnalysisProductReserve from '../analysis-product/reserve/CommercialAnalysisProductReserve';
import CommercialAnalysisSubgroupReserve from './reserve/CommercialAnalysisSubgroupReserve';

const Container = styled('div')(({ theme }) => ({}));

const Content = styled('div')(({ theme }) => ({
  zoom: '90%',
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '2fr 1fr',
  [theme.breakpoints.down('lg')]: {
    zoom: '80%',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ContainerTransfer = styled('div')(({ theme }) => ({
  gridColumn: '1/3',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/2',
  },
}));

const CommercialAnalysisSubgroup: React.FC = () => {
  const {
    buyers,
    subgroup,
    loadingBuyers,
    loadingSubgroups,
    subgroups,
    handleSearchSubmit,
    loading,
    handleChangeFilter,
    suggestions,
    filter,
    total,
    reserves,
  } = useFetchCommercialAnalysisSubgroup();
  const [tab, setTab] = useState('main');

  return (
    <>
      <Appbar title="Análise de subgrupo" />

      <CommercialAnalysisSubgroupFilterBox
        buyers={buyers}
        loadingBuyers={loadingBuyers}
        loadingSubgroups={loadingSubgroups}
        subgroups={subgroups}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <CommercialAnalysisSubgroupTabs tab={tab} handleChange={value => setTab(value)} />
      {loading ? (
        <CommercialAnalysisProductLoading />
      ) : !subgroup ? (
        <NoData message="Subgrupo não encontrado" />
      ) : (
        <Container>
          {tab === 'main' ? (
            <Content>
              <CommercialAnalysisSubgroupHeader suggestions={suggestions} total={total} subgroup={subgroup} />

              <CommercialAnalysisSubgroupProducts suggestions={suggestions} />

              <CommercialAnalysisSubgroupReserve reserves={reserves} />

              <ContainerTransfer>
                <CommercialAnalysisProductTransfer suggestions={suggestions.filter(item => item.forecast > 0)} />
              </ContainerTransfer>
            </Content>
          ) : tab === 'suggestion' ? (
            <CommercialAnalysisSubgroupSuggestion subgroupId={subgroup.CODIGO_SUBGRUPO} suggestions={suggestions} />
          ) : (
            <CommercialAnalysisProductReserve reserves={reserves} />
          )}
        </Container>
      )}
    </>
  );
};

export default CommercialAnalysisSubgroup;
