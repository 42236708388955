import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import history from 'services/history';
import SiteBannersFilterBox from './SiteBannersFilterBox';
import { siteBannersTableTemplate } from './siteBannersTableTemplate';
import { SiteBannersProvider } from './hooks/useSiteBanners';
import SiteBannerListModule from './list/module/SiteBannerListModule';
import SiteBannerListTable from './list/table/SiteBannerListTable';
import { SliderBanner } from 'types/sliderBanner';
import SiteBannersActions from './SiteBannersActions';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  active: boolean;
  name: string;
}

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 365),
  final_date: new Date(),
  active: true,
  name: '',
};

let timer: NodeJS.Timeout;

const SliderBanners: React.FC = () => {
  const [banners, setBanners] = useState<SliderBanner[]>([]);
  const [selectedBanner, setSelectedBanner] = useState<null | SliderBanner>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<SliderBanner[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);

  const fetchBanners = useCallback((query?: QueryParams) => {
    setLoading(true);
    api
      .get<SliderBanner[]>('/site-banners', {
        params: { ...query, active: query?.active ? 1 : 0 },
      })
      .then(response => {
        setBanners(
          response.data.map(item => {
            item.formattedCreatedAt = format(parseISO(item.created_at), 'PPp', { locale: ptBR });
            item.formattedActive = item.active ? 'Sim' : 'Não';
            return item;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFiltered(banners);
  }, [banners]);

  useEffect(() => {
    fetchBanners(queryParamsInitialValue);
  }, [fetchBanners]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'name') {
      fetchBanners(query);
      return;
    }

    if (index === 'name' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchBanners(query), 500);
  }

  return (
    <SiteBannersProvider value={{ selectedBanner, setSelectedBanner }}>
      <Appbar title="Site" ActionsComponent={<SiteBannersActions />} />
      <PageHeaderActions
        title="Slider"
        description="Gestão das imagens do slider"
        ActionComponent={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push('/site/slider-banner')}
            >
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={siteBannersTableTemplate}>
        <SiteBannersFilterBox handleQueryParamsChange={handleQueryParamsChange} queryParams={queryParams} />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum banner para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SiteBannerListTable banners={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <SiteBannerListModule banners={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </SiteBannersProvider>
  );
};

export default SliderBanners;
