import React from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { DailySummaryFilterProps } from './hooks/useFetchDailySummary';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';

interface Props {
  handleSearch(filter: DailySummaryFilterProps): void;
  filter: DailySummaryFilterProps;
  handleChangeFilter(index: keyof DailySummaryFilterProps, value: any): void;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '190px 320px 100px 100px',
  alignItems: 'center',
  rowGap: 15,
  columnGap: 10,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
});

const DailySummaryFilterBox: React.FC<Props> = ({ filter, handleSearch, handleChangeFilter }) => {
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    handleSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch branch_id={filter.branchId} handleChange={id => handleChangeFilter('branchId', id)} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate || null}
            onChange={date => handleChangeFilter('initialDate', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.finalDate || null}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </DateContainer>

        <TextField label="Tipo" value={filter.type} onChange={e => handleChangeFilter('type', e.target.value)} select>
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </TextField>

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default DailySummaryFilterBox;
