import { TableTemplate } from 'types/tableTemplate';

export const reserveTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'id',
    description: 'Reserva',
    originalId: 'id',
    width: 70,
  },
  {
    id: 'id_pedido',
    description: 'Pedido',
    originalId: 'id_pedido',
    width: 70,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 100,
  },
  {
    id: 'data',
    description: 'Data',
    originalId: 'formattedDate',
    width: 140,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 220,
  },
  {
    id: 'id_produto',
    description: 'Cod Prod',
    originalId: 'id_produto',
    width: 85,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'estoque',
    description: 'Estoque',
    originalId: 'estoque',
    width: 70,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'qtd',
    width: 60,
  },
  {
    id: 'formattedUnitValue',
    description: 'Valor Unitário',
    originalId: 'valor_unitario',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedTotalValue',
    description: 'Valor Total',
    originalId: 'valor_total',
    width: 110,
    dataType: 'number',
  },
];
