import React from 'react';
import { Typography, styled, ListItem } from '@mui/material';
import { RetailTable } from 'types/retailTable';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '110px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type RetailTableItemModuleProps = {
  table: RetailTable;
};

const RetailTableItemModule: React.FC<RetailTableItemModuleProps> = ({ table }) => {
  return (
    <ListItemStyled>
      <Grid className="bottom">
        <Typography variant="caption">ID:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.id}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Código Intelbras:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.codigo_intelbras}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Produto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Subgrupo:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.subgrupo}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Estoque:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.estoque}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">PMD:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.pmd}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Preço 2:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {table.formattedPrice2}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default RetailTableItemModule;
