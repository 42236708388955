import { Print } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import history from 'services/history';
import { WasteQueryParams } from './Wastes';
import { format } from 'date-fns';

interface WastesHeaderButtonsParams {
  queryParams: WasteQueryParams;
}

const WastesHeaderButtons: React.FC<WastesHeaderButtonsParams> = ({ queryParams }) => {
  const params = {
    ...queryParams,
    data_ini: format(queryParams.data_ini, "dd'/'MM'/'yyyy"),
    data_fim: format(queryParams.data_fim, "dd'/'MM'/'yyyy"),
    id_filial: queryParams.id_filial === 0 ? '' : queryParams.id_filial,
  };

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <Button
        onClick={() =>
          history.push({
            pathname: '/waste/print',
            search: `id_filial=${params.id_filial}&data_ini=${params.data_ini}&data_fim=${params.data_fim}&nome=${params.nome}`,
          })
        }
        style={{ textTransform: 'none' }}
        variant="outlined"
        startIcon={<Print />}
      >
        Imprimir
      </Button>
      <Button onClick={() => history.push('/waste/new')} variant="contained" size="small" color="primary">
        Novo
      </Button>
    </div>
  );
};

export default WastesHeaderButtons;
