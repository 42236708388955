import React from 'react';
import { ListItemButton, Typography, styled } from '@mui/material';
import { Expense } from 'types/expense';
import { useExpenses } from '../../hooks/useExpenses';

const StyledListItem = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '110px 1fr',
    alignItems: 'center',
  },
});

interface ExpenseItemModuleProps {
  expense: Expense;
}

const ExpenseItemModule: React.FC<ExpenseItemModuleProps> = ({ expense }) => {
  const { setSelectedExpense } = useExpenses();

  return (
    <StyledListItem onClick={() => setSelectedExpense(expense)}>
      <Typography variant="body1">{expense.nome}</Typography>

      <div className="content">
        <Typography color="textSecondary" variant="body2">
          Filial:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {expense.id_filial}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          Agrupamento:
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {expense.id_agrupamento}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          Cód Contábil:
        </Typography>

        <Typography color="textSecondary" variant="body2">
          {expense.conta_contabil}
        </Typography>
      </div>
    </StyledListItem>
  );
};

export default ExpenseItemModule;
