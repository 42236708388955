import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useFetchViews } from './hooks/useFetchViews';
import { styled } from '@mui/material';
import { useAbcCustomers } from './hooks/useAbcCustomers';
import Pagination from 'components/pagination/Pagination';
import { AbcCustomerProvider } from './hooks/useAbcCustomer';
import AbcCustomerFilterBox from './AbcCustomerFilterBox';
import AbcCustomerTotal from './AbcCustomerTotal';
import BranchListTable from './branch/list/table/BranchListTable';
import BranchListModule from './branch/list/module/BranchListModule';
import AbcProductListModule from './product/list/module/AbcProductListModule';
import AbcProductListTable from './product/list/table/AbcProductListTable';
import UnityListModule from './unity/list/module/UnityListModule';
import UnityListTable from './unity/list/table/UnityListTable';
import SegmentListModule from './segment/list/module/SegmentListModule';
import SegmentListTable from './segment/list/table/SegmentListTable';
import SubgroupListTable from './subgroup/list/table/SubgroupListTable';
import SubgroupListModule from './subgroup/list/module/SubgroupListModule';
import ManufacturerListTable from './manufacturer/list/table/ManufacturerListTable';
import ManufacturerListModule from './manufacturer/list/module/ManufacturerListModule';
import SellerListModule from './seller/list/module/SellerListModule';
import SellerListTable from './seller/list/table/SellerListTable';
import CustomerListTable from './list/table/CustomerListTable';
import CustomerListModule from './list/module/CustomerListModule';
import AbcCustomerDateFilter from './AbcCustomerDateFilter';
import SelectedSale from 'components/sale/SelectedSale';
import { AbcCustomersFilterParams } from './CurveAbcCustomer';
import FilterMore from 'components/filter-more/FilterMore';
import AbcProductExport from './export/AbcProductExport';
import AbcCustomerTabs from './AbcCustomerTabs';
import InsideLoading from 'components/loading/InsideLoading';
import AbcCustomerActions from './actions/AbcCustomerActions';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface AbcCustomerPageProps {
  filter: AbcCustomersFilterParams;
  handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any): void;
}

const AbcCustomerPage: React.FC<AbcCustomerPageProps> = ({ filter, handleChangeFilter }) => {
  const [tab, setTab] = useState('branch');
  const {
    filteredSegments,
    handleSortSegment,
    filteredProducts,
    handleSort,
    filteredUnits,
    handleSortUnity,
    filteredBranches,
    handleSortBranch,
    filteredSubgroups,
    handleSortSubgroup,
    filteredManufacturers,
    handleSortManufacturer,
    filteredSellers,
    handleSortSeller,
    filteredCustomers,
    handleSortCustomer,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    formattedTotal,
    orderedIndex,
    selectedSale,
    setSelectedSale,
  } = useAbcCustomers();

  const [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    dialogDateDesktop,
    setExcelExport,
    setDialogDate,
    setDialogDateDesktop,
  ] = useFetchViews(handleSearch, searchText);

  return (
    <AbcCustomerProvider value={{ filter, handleChangeFilter, searchValue: searchText }}>
      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}

      {dialogDate && (
        <AbcCustomerDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
        />
      )}

      {dialogDateDesktop && (
        <FilterMore
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDateDesktop(false)}
        />
      )}

      {excelExport && (
        <AbcProductExport
          products={filteredProducts}
          branches={filteredBranches}
          segments={filteredSegments}
          subgroups={filteredSubgroups}
          units={filteredUnits}
          customers={filteredCustomers}
          sellers={filteredSellers}
          manufacturers={filteredManufacturers}
          onExited={() => setExcelExport(false)}
        />
      )}

      <Appbar
        title="Curva Abc de Cliente"
        ActionsComponent={
          <AbcCustomerActions
            handleOpenExcel={() => setExcelExport(true)}
            disabled={loading}
            handleOpenDialog={() => setDialogDate(true)}
          />
        }
      />

      <AbcCustomerFilterBox
        filter={filter}
        disabled={filteredProducts.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <AbcCustomerTabs tab={tab} onChange={tab => setTab(tab)} />

      <AbcCustomerTotal formattedTotal={formattedTotal} />

      {loading ? (
        <InsideLoading />
      ) : filteredProducts.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <>
          {tab === 'branch' ? (
            <PaginationProvider>
              <Container>
                {filteredBranches.length === 0 ? (
                  <NoData message="Nenhuma filial encontrada." />
                ) : displayMode === 'list' ? (
                  <BranchListTable
                    isSubgroup
                    branches={filteredBranches}
                    handleSort={handleSortBranch}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <BranchListModule branches={filteredBranches} />
                )}
                <Pagination count={filteredBranches.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'unity' ? (
            <PaginationProvider>
              <Container>
                {filteredUnits.length === 0 ? (
                  <NoData message="Nenhuma unidade encontrada." />
                ) : displayMode === 'list' ? (
                  <UnityListTable
                    isSubgroup
                    units={filteredUnits}
                    handleSort={handleSortUnity}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <UnityListModule units={filteredUnits} />
                )}
                <Pagination count={filteredUnits.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'segment' ? (
            <PaginationProvider>
              <Container>
                {filteredSegments.length === 0 ? (
                  <NoData message="Nenhum segmento encontrado." />
                ) : displayMode === 'list' ? (
                  <SegmentListTable
                    isSubgroup
                    segments={filteredSegments}
                    handleSort={handleSortSegment}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <SegmentListModule segments={filteredSegments} />
                )}
                <Pagination count={filteredSegments.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'subgroup' ? (
            <PaginationProvider>
              <Container>
                {filteredSubgroups.length === 0 ? (
                  <NoData message="Nenhum subgrupo encontrado." />
                ) : displayMode === 'list' ? (
                  <SubgroupListTable
                    isSubgroup
                    subgroups={filteredSubgroups}
                    handleSort={handleSortSubgroup}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <SubgroupListModule subgroups={filteredSubgroups} />
                )}
                <Pagination count={filteredSubgroups.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'manufacturer' ? (
            <PaginationProvider>
              <Container>
                {filteredManufacturers.length === 0 ? (
                  <NoData message="Nenhum fabricante encontrado." />
                ) : displayMode === 'list' ? (
                  <ManufacturerListTable
                    isSubgroup
                    manufacturers={filteredManufacturers}
                    handleSort={handleSortManufacturer}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <ManufacturerListModule manufacturers={filteredManufacturers} />
                )}
                <Pagination count={filteredManufacturers.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'order_by' ? (
            <PaginationProvider>
              <Container>
                {filteredSellers.length === 0 ? (
                  <NoData message="Nenhum vendedor encontrado." />
                ) : displayMode === 'list' ? (
                  <SellerListTable
                    isSubgroup
                    sellers={filteredSellers}
                    handleSort={handleSortSeller}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <SellerListModule sellers={filteredSellers} />
                )}
                <Pagination count={filteredSellers.length} />
              </Container>
            </PaginationProvider>
          ) : tab === 'customer' ? (
            <PaginationProvider>
              <Container>
                {filteredCustomers.length === 0 ? (
                  <NoData message="Nenhum cliente encontrado." />
                ) : displayMode === 'list' ? (
                  <CustomerListTable
                    isSubgroup
                    customers={filteredCustomers}
                    handleSort={handleSortCustomer}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <CustomerListModule customers={filteredCustomers} />
                )}
                <Pagination count={filteredCustomers.length} />
              </Container>
            </PaginationProvider>
          ) : (
            <PaginationProvider>
              <Container>
                {displayMode === 'list' ? (
                  <AbcProductListTable
                    isSubgroup
                    products={filteredProducts}
                    handleSort={handleSort}
                    orderedIndex={orderedIndex}
                  />
                ) : (
                  <AbcProductListModule products={filteredProducts} />
                )}
                <Pagination count={filteredProducts.length} />
              </Container>
            </PaginationProvider>
          )}
        </>
      )}
    </AbcCustomerProvider>
  );
};

export default AbcCustomerPage;
