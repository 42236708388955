import React, { useState } from 'react';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Vacancy } from 'types/vacancy';
import VacancyItemTable from './VacancyItemTable';
import VacancyListMenu from '../VacancyListMenu';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
  '&.headerItemNumeric': {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

type VacancyListTableProps = {
  vacancies: Vacancy[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const VacancyListTable: React.FC<VacancyListTableProps> = ({ vacancies, handleSort, orderedIndex }) => {
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <VacancyListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <HeaderItem
              className={item.dataType === 'number' ? 'headerItemNumeric' : ''}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody>
          {vacancies.map(vacancy => (
            <TableRow key={vacancy.id}>
              <VacancyItemTable setAnchorEl={setAnchorEl} vacancy={vacancy} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default VacancyListTable;
