import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import NoData from 'components/no-data/NoData';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import { Button, styled } from '@mui/material';
import history from 'services/history';
import { useDisplayMode } from 'hooks/useDisplayMode';
import useTableOrder from 'hooks/tableOrder';
import { useFetchVacancy } from './hooks/useFetchVacancy';
import { Vacancy } from 'types/vacancy';
import VacancyListTable from './list/table/VacancyListTable';
import VacancyListModule from './list/module/VacancyListModule';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import { vacancyTableTemplate } from './template/vacancyTableTemplate';
import { VacanciesProvider } from './hooks/useVacancies';
import InsideSaving from 'components/loading/InsideSaving';
import VacancyFilter from './VacancyFilter';
import ApiPagination from 'components/pagination/ApiPagination';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const VacancyPage: React.FC = () => {
  const { fetch, loading, queryParams, handleChangeQueryParams, vacancies, total } = useFetchVacancy();
  const [filtered, setFiltered] = useState<Vacancy[]>([]);
  const [selectedVacancy, setSelectedVacancy] = useState<Vacancy | null>(null);
  const [saving, setSaving] = useState(false);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(vacancies);
  }, [vacancies]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onInactiveVacancy() {
    fetch(queryParams);
  }

  return (
    <VacanciesProvider value={{ onInactiveVacancy, selectedVacancy, setSaving, setSelectedVacancy }}>
      {saving && <InsideSaving />}
      <Appbar title="Vagas de emprego" />
      <PageHeaderActions
        title="Vagas de emprego"
        description="Gestão das vagas de emprego"
        ActionComponent={
          <Button variant="contained" onClick={() => history.push('/rh/vacancy/new')}>
            Novo
          </Button>
        }
      />

      <VacancyFilter
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        filter={queryParams}
        handleChange={handleChangeQueryParams}
        handleSearch={fetch}
      />

      <TableContainer tableTemplate={vacancyTableTemplate}>
        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma vaga cadastrada" />
          ) : (
            <Container>
              {displayMode === 'list' ? (
                <VacancyListTable vacancies={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <VacancyListModule vacancies={filtered} />
              )}
              <ApiPagination
                onChangePage={value => handleChangeQueryParams('page', value)}
                onChangeRowsPerPage={value => handleChangeQueryParams('rows', value)}
                count={total}
              />
            </Container>
          )}
        </PaginationProvider>
      </TableContainer>
    </VacanciesProvider>
  );
};

export default VacancyPage;
