import { Waste, WasteProduct } from '../types/waste';
import { WasteActions } from './types';
import { useReducer, Dispatch } from 'react';
import { moneyFormat } from 'helpers/numberFormat';

export const wasteInitialState: Waste = {
  bairro: '',
  cep: '',
  cidade: '',
  nome: '',
  id_filial: 0,
  filial: '',
  cnpj_cpf: '',
  endereco: '',
  uf: '',
  complemento: '',
  produtos: [],
  id: 0,
  valor_total: 0,
  pago: 'N',
  data_pagamento: '',
};

export function wasteReducer(state: Waste, action: WasteActions): Waste {
  switch (action.type) {
    case 'SET_WASTE': {
      return {
        ...action.waste,
        produtos: action.waste.produtos.map(product => ({
          ...product,
          formattedValue: moneyFormat(product.valor_unitario),
          formattedTotal: moneyFormat(product.valor_unitario * product.qtd),
        })),
      };
    }

    case 'CHANGE_WASTE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_WASTE_PRODUCT': {
      const products: WasteProduct[] = [
        ...state.produtos,
        {
          id: action.product.id,
          id_produto: action.product.productId,
          produto: action.product.product,
          valor_unitario: parseFloat(action.product.value),
          numeros_serie: action.product.serial_numbers,
          qtd: action.product.quantity,
          formattedValue: moneyFormat(action.product.value),
          valor_total: action.product.quantity * parseFloat(action.product.value),
          formattedTotal: moneyFormat(action.product.quantity * parseFloat(action.product.value)),
        },
      ];
      const total = products.reduce((carry, product) => carry + product.qtd * product.valor_unitario, 0);

      return {
        ...state,
        produtos: products,
        valor_total: total,
      };
    }

    case 'REMOVE_WASTE_PRODUCT': {
      const products = state.produtos.filter(product => product.id !== action.id);
      const total = products.reduce((carry, product) => carry + product.qtd * product.valor_unitario, 0);

      return {
        ...state,
        produtos: products,
        valor_total: total,
      };
    }

    default: {
      return state;
    }
  }
}

type UseWasteReducer = [Waste, Dispatch<WasteActions>];

export function useWasteReducer(): UseWasteReducer {
  const [waste, dispatch] = useReducer(wasteReducer, wasteInitialState);

  return [waste, dispatch];
}
