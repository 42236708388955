import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  styled,
  Button,
} from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcCustomersFilterParams } from './CurveAbcCustomer';

const Filter = styled('form')(({ theme }) => ({
  gap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.4fr 0.4fr 1fr 0.8fr 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 10,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface AbcCustomerFilterBoxProps {
  filter: AbcCustomersFilterParams;
  handleChangeFilter(index: keyof AbcCustomersFilterParams, value: any): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcCustomerFilterBox: React.FC<AbcCustomerFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenExcel,
  disabled,
}) => {
  const { checkPermission } = usePermissionRules();

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <FormControl style={{ height: '100%' }}>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </FormControl>

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </DateContainer>
        <TextField
          label="Cliente"
          placeholder="Cliente"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
      <Tooltip title="Outros filtros">
        <CustomIconButton onClick={handleOpenDialogDesktop} color="inherit">
          <FilterList />
        </CustomIconButton>
      </Tooltip>

      {checkPermission('general.excel_export_button') && (
        <Tooltip title="Exportar excel">
          <CustomIconButton
            disabled={disabled}
            onClick={handleOpenExcel}
            style={{ color: disabled ? '#eee' : 'green' }}
          >
            <FaFileExcel />
          </CustomIconButton>
        </Tooltip>
      )}
    </FilterBox>
  );
};

export default AbcCustomerFilterBox;
