import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useCallback, useState } from 'react';
import {
  BranchStockBalanceData,
  ManufacturerStockBalanceData,
  ResponsibleStockBalanceData,
  SegmentStockBalanceData,
  StockBalanceData,
  SubgroupStockBalanceData,
  UnityStockBalanceData,
} from 'types/reports/stockBalance';
import { StockFilter } from 'types/reports/stockFilter';
import { StockBalanceTotalProps } from '../list/hooks/useStockBalance';
import useTableOrder from 'hooks/tableOrder';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export function useFetchStockBalance() {
  const { h2iApi } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loadingResponsibles, setLoadingResponsibles] = useState(false);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingManufacturers, setLoadingManufacturers] = useState(false);
  const [loadingStoppedStock, setLoadingStoppedStock] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [dataItems, setDataItems] = useState<StockBalanceData[]>([]);
  const [dataSubgroups, setDataSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [dataBranches, setDataBranches] = useState<BranchStockBalanceData[]>([]);
  const [dataResponsibles, setDataResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const [filteredResponsibles, setFilteredResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const [filteredItems, setFilteredItems] = useState<StockBalanceData[]>([]);
  const [filteredSubgroups, setFilteredSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<BranchStockBalanceData[]>([]);
  const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreResponsible, setMoreResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreBranch, setMoreBranch] = useState<BranchStockBalanceData | null>(null);
  const [moreUnity, setMoreUnity] = useState<UnityStockBalanceData | null>(null);
  const [dataUnits, setDataUnits] = useState<UnityStockBalanceData[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<UnityStockBalanceData[]>([]);
  const [moreSegment, setMoreSegment] = useState<SegmentStockBalanceData | null>(null);
  const [dataSegments, setDataSegments] = useState<SegmentStockBalanceData[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<SegmentStockBalanceData[]>([]);
  const [moreStoppedStock, setMoreStoppedStock] = useState<BranchStockBalanceData | null>(null);
  const [dataStoppedStocks, setDataStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [filteredStoppedStocks, setFilteredStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [moreManufacturer, setMoreManufacturer] = useState<ManufacturerStockBalanceData | null>(null);
  const [dataManufacturers, setDataManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [filter, setFilter] = useState<StockFilter>({
    branchId: [],
    manufacturerId: [],
    subgroupId: [],
    indexToSearch: 'Produto',
    searchValue: '',
    categoryId: [],
    segmentId: [],
    subcategoryId: [],
    unityId: [],
    made_by: '',
  });
  const [formattedTotal, setFormattedTotal] = useState<StockBalanceTotalProps>({
    coustTotal: 0,
    coustStoppedStock: 0,
    order: 0,
    reserve: 0,
    stock: 0,
    stoppedStock: 0,
    forecast: 0,
    index: 0,
  });

  const handleSearch = useCallback(
    async (filter: StockFilter, isOpenedMenu?: boolean, handleOpenMenu?: () => void) => {
      if (!h2iApi) {
        return;
      }

      if (isOpenedMenu && handleOpenMenu) {
        handleOpenMenu();
      }

      setLoadingResponsibles(true);
      setLoadingSubgroups(true);
      setLoadingUnits(true);
      setLoadingSegments(true);
      setLoadingItems(true);
      setLoadingManufacturers(true);
      setLoadingStoppedStock(true);
      setLoadingBranches(true);

      const fetchResponsibles = h2iApi
        ?.get(`/api/relestresponsavel`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredResponsibles([]);
            return;
          }
          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });
          setDataResponsibles(_data);
          setFilteredResponsibles(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingResponsibles(false));

      const fetchSubgroups = h2iApi
        ?.get(`/api/relestsubgrupo`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredSubgroups([]);
            return;
          }

          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSubgroups(_data);
          setFilteredSubgroups(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSubgroups(false));

      const fetchUnits = h2iApi
        ?.get(`/api/relestunidade`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataUnits(_data);
          setFilteredUnits(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingUnits(false));

      const fetchSegments = h2iApi
        ?.get(`/api/relestsegmento`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSegments(_data);
          setFilteredSegments(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSegments(false));

      const fetchItems = h2iApi
        .get(`/api/saldoestoque`, {
          params: {
            id_filial: filter.branchId.join(','),
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data[0].result) {
            setFilteredItems([]);
            setDataItems([]);
            setFormattedTotal(item => ({
              ...item,
              coustTotal: 0,
              index: 0,
            }));
            return;
          }

          const coustTotal = response.data.reduce((sum, item) => sum + item.CustoTotal, 0);
          const index = response.data.reduce((sum, item) => sum + item.indice, 0);
          setFormattedTotal(item => ({
            ...item,
            coustTotal,
            index,
          }));

          const _data: StockBalanceData[] = response.data.map((item: StockBalanceData) => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedIndex = numberFormat(item.indice);
            return item;
          });

          const forecast = response.data.reduce((sum, item) => sum + item.forecast, 0);
          setFormattedTotal(item => ({
            ...item,
            forecast,
          }));
          setDataItems(_data);
          setFilteredItems(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingItems(false));

      const fetchManufacturers = h2iApi
        .get(`/api/relestfabricante`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.filiais.map(item => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = item.ultima_venda ? parsePtBRDate(item.ultima_venda).toISOString() : '';
            item.formattedLastEntry = item.ultima_entrada ? parsePtBRDate(item.ultima_entrada).toISOString() : '';
            return item;
          });
          setDataManufacturers(_data);
          setFilteredManufacturers(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingManufacturers(false));

      const fetchBranchesStoppedStock = h2iApi
        .get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            sem_movimentacao: 'S',
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setDataStoppedStocks([]);
            setFilteredStoppedStocks([]);
            setFormattedTotal(item => ({
              ...item,
              coustStoppedStock: 0,
              stoppedStock: 0,
            }));
            return;
          }

          const coustStoppedStock = response.data.filiais.reduce((sum, item) => sum + item.CustoTotal, 0);
          const stoppedStock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);
          setFormattedTotal(item => ({
            ...item,
            coustStoppedStock,
            stoppedStock,
          }));

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = item.ultima_venda ? parsePtBRDate(item.ultima_venda).toISOString() : '';
            item.formattedLastEntry = item.ultima_entrada ? parsePtBRDate(item.ultima_entrada).toISOString() : '';
            return item;
          });
          setDataStoppedStocks(_data);
          setFilteredStoppedStocks(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingStoppedStock(false));

      const fetchBranches = h2iApi
        ?.get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setDataBranches([]);
            setFilteredBranches([]);
            setFormattedTotal(item => ({
              ...item,
              order: 0,
              reserve: 0,
              stock: 0,
            }));
            return;
          }

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = item.ultima_venda ? parsePtBRDate(item.ultima_venda).toISOString() : '';
            item.formattedLastEntry = item.ultima_entrada ? parsePtBRDate(item.ultima_entrada).toISOString() : '';
            return item;
          });

          const reserve = response.data.filiais.reduce((sum, item) => sum + item.valor_reserva, 0);
          const order = response.data.filiais.reduce((sum, item) => sum + item.valor_pedido, 0);
          const stock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);

          setFormattedTotal(item => ({
            ...item,
            order,
            reserve,
            stock,
          }));

          setDataBranches(_data);
          setFilteredBranches(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingBranches(false));

      Promise.all([
        fetchResponsibles,
        fetchSubgroups,
        fetchUnits,
        fetchSegments,
        fetchItems,
        fetchManufacturers,
        fetchBranchesStoppedStock,
        fetchBranches,
      ]);
    },
    [h2iApi],
  );

  function handleChange(index: keyof StockFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSortManufacturer(index: string) {
    const p = sort(index, dataManufacturers);
    setFilteredManufacturers(p);
  }

  function handleSortResponsible(index: string) {
    const p = sort(index, dataResponsibles);
    setFilteredResponsibles(p);
  }

  function handleSortBranch(index: string) {
    const p = sort(index, dataBranches);
    setFilteredBranches(p);
  }

  function handleSortStoppedStock(index: string) {
    const p = sort(index, dataStoppedStocks);
    setFilteredStoppedStocks(p);
  }

  function handleSortSubgroup(index: string) {
    const p = sort(index, dataSubgroups);
    setFilteredSubgroups(p);
  }

  function handleSortUnity(index: string) {
    const p = sort(index, dataUnits);
    setFilteredUnits(p);
  }

  function handleSortSegment(index: string) {
    const p = sort(index, dataSegments);
    setFilteredSegments(p);
  }

  function handleSortItem(index: string) {
    const p = sort(index, dataItems);
    setFilteredItems(p);
  }

  return {
    handleSearch,
    handleChange,
    handleSortManufacturer,
    handleSortResponsible,
    handleSortBranch,
    handleSortStoppedStock,
    handleSortSubgroup,
    handleSortUnity,
    handleSortSegment,
    handleSortItem,
    filter,
    loadingResponsibles,
    loadingSubgroups,
    loadingUnits,
    loadingSegments,
    loadingItems,
    loadingManufacturers,
    loadingStoppedStock,
    loadingBranches,
    filteredResponsibles,
    filteredSubgroups,
    filteredUnits,
    filteredSegments,
    filteredItems,
    filteredBranches,
    selectedResponsible,
    setSelectedResponsible,
    moreResponsible,
    setMoreResponsible,
    moreBranch,
    setMoreBranch,
    moreUnity,
    setMoreUnity,
    moreSegment,
    setMoreSegment,
    moreStoppedStock,
    setMoreStoppedStock,
    moreManufacturer,
    setMoreManufacturer,
    filteredStoppedStocks,
    filteredManufacturers,
    formattedTotal,
    orderedIndex,
  };
}
