import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import { useFinancial } from '../hooks/useFinancial';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { TransferAccount } from 'types/transferAccount';
import PageHeaderButtons from './PageHeaderButtons';
import { accountsTableTemplate } from './accountsTableTemplate';
import TransferAccountReport from './report/TransferAccountReport';
import TransferAccountListTable from './list/table/TransferAccountListTable';
import TransferAccountListModule from './list/module/TransferAccountListModule';
import { useFetchTransferAccount } from './hooks/useFetchTransferAccount';
import { TransferAccountProvider } from './hooks/useTransferAccount';
import TransferAccountFilterBox from './TransferAccountFilterBox';
import NewTransferAccount from './registration/new/NewTransferAccount';
import { useApp } from 'hooks/app';
import TransferAccountActions from './TransferAccountActions';
import EditTransferAccount from './registration/edit/EditTransferAccount';
import { startOfMonth } from 'date-fns';
import SelectedTitle from './dialog/SelectedTitle';

export interface TransferAccountFilterProps {
  observation: string;
  originAccountId: number;
  targetAccountId: number;
  initial_date: Date;
  final_date: Date;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const filterInitialState: TransferAccountFilterProps = {
  observation: '',
  branchId: 1001,
  initial_date: startOfMonth(new Date()),
  final_date: new Date(),
  originAccountId: 0,
  targetAccountId: 0,
};

const TransferAccountPage: React.FC = () => {
  const { handleBack } = useFinancial();
  const { windowWidth } = useApp();
  const [selectedTransferAccount, setSelectedTransferAccount] = useState<TransferAccount | null>(null);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<TransferAccount[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [filter, setFilter] = useState<TransferAccountFilterProps>(filterInitialState);
  const [isNewTransferAccount, setIsNewTransferAccount] = useState(false);
  const { accounts, totalizingAccounts, loading, fetch } = useFetchTransferAccount(filter);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [selectedTitleId, setSelectedTitleId] = useState<number | null>(null);

  useEffect(() => {
    setFiltered(accounts);
  }, [accounts]);

  function handleChangeFilter(index: keyof TransferAccountFilterProps, value: any) {
    setFilter(filter => ({
      ...filter,
      [index]: value,
    }));
  }

  function handleSearch() {
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSubmit() {
    setIsNewTransferAccount(false);
    fetch();
  }

  function onSave() {
    setSelectedTransferAccount(null);
    fetch();
  }

  function handleSelectTitle(id: number) {
    setSelectedTitleId(id);
  }

  function onSaveSelectedTitle() {
    setSelectedTitleId(null);
    fetch();
  }

  return (
    <TransferAccountProvider
      value={{
        totalizingAccounts,
        accounts,
        loading,
        setIsNewTransferAccount,
        setIsReportOpen,
        handleChangeFilter,
        handleSearch,
        setSelectedTransferAccount,
        handleSelectTitle,
        filter,
      }}
    >
      {selectedTitleId && (
        <SelectedTitle
          type={'T'}
          id={selectedTitleId}
          onExited={() => setSelectedTitleId(null)}
          onSave={onSaveSelectedTitle}
        />
      )}
      {selectedTransferAccount && (
        <EditTransferAccount
          onSave={onSave}
          onExited={() => setSelectedTransferAccount(null)}
          selectedTransferAccount={selectedTransferAccount}
        />
      )}
      {isReportOpen && <TransferAccountReport onExited={() => setIsReportOpen(false)} />}

      {isNewTransferAccount && (
        <NewTransferAccount
          branchId={filter.branchId}
          onSubmit={onSubmit}
          onExited={() => setIsNewTransferAccount(false)}
        />
      )}

      <Appbar
        title="Transferência entre contas"
        backAction={handleBack}
        ActionsComponent={<TransferAccountActions />}
      />

      <PageHeaderActions title="Transferência entre contas" ActionComponent={<PageHeaderButtons />} />

      {windowWidth < 600 && <PageHeaderButtons />}

      <TransferAccountFilterBox displayMode={displayMode} setDisplayMode={setDisplayMode} />

      <TableContainer tableTemplate={accountsTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma transferência entre contas encontrada" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <TransferAccountListTable accounts={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <TransferAccountListModule accounts={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </TransferAccountProvider>
  );
};

export default TransferAccountPage;
