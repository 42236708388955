import { Search } from '@mui/icons-material';
import { Button, TextField, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import React, { FormEvent } from 'react';

interface BuyerFilterBoxProps {
  onSubmit(e?: FormEvent<HTMLFormElement>);
  handleChangeText(value: string): void;
  searchText: string;
}

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '300px 100px',
  columnGap: 10,
  rowGap: 15,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    flex: 1,
  },
}));

const BuyerFilterBox: React.FC<BuyerFilterBoxProps> = ({ onSubmit, handleChangeText, searchText }) => {
  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <TextField
          label="Pesquisar"
          placeholder="Pesquisar"
          autoFocus
          value={searchText}
          onChange={e => handleChangeText(e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default BuyerFilterBox;
