import { Icon, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ArrowDropDown, Clear, TaskAlt } from '@mui/icons-material';
import { AgroupedBranchReplacement } from './hooks/useFetchAnalysisReplacementBranch';
import AnalysisReplacementListTable from '../list/table/AnalysisReplacementListTable';
import { Replacement } from 'types/replacement';
import { OrderIndexData } from 'hooks/tableOrder';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';

interface Props {
  branchesOpen: number[];
  onClickButton: (index: number) => void;
  filteredBranches: AgroupedBranchReplacement[];
  handleSort(index: keyof AgroupedBranchReplacement): void;
  replacements: Replacement[];
  handleSortReplacements(index: string): void;
  orderedIndex: OrderIndexData;
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1/4',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#eeeff8',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
  },
  '& > span': {
    padding: 5,
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  '& > span:not(:last-child)': {
    borderRight: '1px solid #eee',
  },
  '& > button': {
    padding: 0,
  },
  display: 'grid',
  borderBottom: '1px solid #eee',
  alignItems: 'center',
  gridTemplateColumns: '40px 140px 100px 100px 90px 100px 110px 100px 100px 90px',
  '& .branch-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '&.open': {
    transform: 'rotate(180deg)',
  },
  transition: 'transform 0.3s ease',
  color: theme.palette.primary.main,
}));

const List = styled('div')({});

const AnalysisReplacementBranch: React.FC<Props> = ({
  onClickButton,
  replacements,
  orderedIndex,
  handleSortReplacements,
  branchesOpen,
  filteredBranches,
  handleSort,
}) => {
  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por filial</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography></Typography>

            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>
            <Typography align="right" onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
              Estoque Total
            </Typography>
            <Typography align="right" onClick={() => handleSort('pedidos')} variant="caption" fontWeight={600}>
              Pedidos
            </Typography>
            <Typography align="right" onClick={() => handleSort('reserva')} variant="caption" fontWeight={600}>
              Reserva
            </Typography>
            <Typography align="right" onClick={() => handleSort('investimento')} variant="caption" fontWeight={600}>
              Investimento
            </Typography>

            <Typography align="center" onClick={() => handleSort('visto')} variant="caption" fontWeight={600}>
              Visualizado
            </Typography>
          </Row>

          {filteredBranches.length === 0 ? (
            <NoData message="Sem filial com estoque excedente" />
          ) : (
            filteredBranches.map((branch, index) => (
              <div key={index}>
                <Row className="item">
                  <Tooltip title="Visualizar itens da filial">
                    <CustomIconButton
                      onClick={() => onClickButton(index)}
                      className={branchesOpen.includes(index) ? 'open' : ''}
                      size="small"
                    >
                      <ArrowDropDown fontSize="small" />
                    </CustomIconButton>
                  </Tooltip>

                  <Typography variant="caption">{branch.filial}</Typography>

                  <Typography align="right" variant="caption">
                    {branch.estoque}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {branch.pedidos}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {branch.reserva}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {branch.formattedInvestimento}
                  </Typography>
                  {branch.visto ? (
                    <Icon style={{ width: '100%', padding: 0 }} fontSize="small" color={'success'}>
                      <TaskAlt fontSize="small" />
                    </Icon>
                  ) : (
                    <Icon style={{ width: '100%', padding: 0 }} fontSize="small" color={'error'}>
                      <Clear fontSize="small" />
                    </Icon>
                  )}
                </Row>

                {branchesOpen.includes(index) && (
                  <PaginationProvider>
                    <div style={{ border: '1px solid #eee', padding: 20 }}>
                      <AnalysisReplacementListTable
                        handleSort={handleSortReplacements}
                        orderedIndex={orderedIndex}
                        replacements={replacements
                          .filter(item => item.id_filial === branch.id_filial)
                          .filter(item => item.dias_estoque > 60)}
                      />

                      <div style={{ zoom: '90%' }}>
                        <Pagination
                          count={
                            replacements
                              .filter(item => item.id_filial === branch.id_filial)
                              .filter(item => item.dias_estoque > 60).length
                          }
                        />
                      </div>
                    </div>
                  </PaginationProvider>
                )}
              </div>
            ))
          )}
        </List>
      </Content>
    </Container>
  );
};

export default AnalysisReplacementBranch;
