import React from 'react';
import { styled, Tooltip, Typography } from '@mui/material';
import { priceProtectionTableTemplate } from 'pages/price-protection/priceProtectionTableTemplate';
import { PriceProtection } from 'types/priceProtection';

type PriceProtectionItemTableProps = {
  data: PriceProtection;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const PriceProtectionItemTable: React.FC<PriceProtectionItemTableProps> = ({ data }) => {
  return (
    <>
      {priceProtectionTableTemplate.map(product => (
        <div key={product.id} className={product.dataType === 'number' ? 'numericData' : ''}>
          <Tooltip title={product[product.id]}>
            <Description style={{ fontSize: 11 }} variant="body2">
              {data[product.id]}
            </Description>
          </Tooltip>
        </div>
      ))}
    </>
  );
};

export default PriceProtectionItemTable;
