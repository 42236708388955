import React from 'react';
import { Typography } from '@mui/material';
import { StockBalanceData } from 'types/reports/stockBalance';
import { stockTableTemplate } from '../stockTableTemplate';

type ProductItemProps = {
  product: StockBalanceData;
};

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  return (
    <>
      {stockTableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div
            key={item.id}
            className={item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''}
          >
            <Typography style={{ fontSize: 11 }} variant="body2">
              {product[item.id as keyof StockBalanceData]}
            </Typography>
          </div>
        ))}
    </>
  );
};

export default ProductItem;
