import { Dispatch, useReducer } from 'react';
import { NewInvoice, NewInvoiceActions } from './types';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

const INITIAL_STATE: NewInvoice = {
  branchId: null,
  costCenterId: 999,
  totalizingAccountId: 999,
  monthYear: new Date(),
  type: 'T',
  value: 0,
  payday: null,
  apropriatedAt: null,
  invoiceDescription: '',
  invoiceNumber: '',
  paidAt: null,
  validatedInvoice: false,
  rmaCredit: false,
  favoredId: null,
  isShippingCompany: false,
  noBankBillet: false,
  invoiceKey: null,
  id: 0,
};

function invoiceReducer(state = INITIAL_STATE, action: NewInvoiceActions): NewInvoice {
  switch (action.type) {
    case 'INVOICE_CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_INVOICE': {
      return {
        totalizingAccountId: parseInt(`${action.invoice.id_conta_totalizadora}`),
        costCenterId: parseInt(`${action.invoice.id_centro_custo}`),
        branchId: parseInt(`${action.invoice.id_filial}`),
        monthYear: new Date(`20${action.invoice.mes_ano.slice(2, 4)}-${action.invoice.mes_ano.slice(0, 2)}-03`),
        type: action.invoice.tipo,
        value: action.invoice.valor,
        payday: parsePtBRDate(action.invoice.vencimento),
        apropriatedAt: action.invoice.data_apropriacao ? parsePtBRDate(action.invoice.data_apropriacao) : null,
        invoiceDescription: action.invoice.titulo,
        invoiceNumber: action.invoice.nota_fiscal,
        paidAt: action.invoice.data_pagamento ? parsePtBRDate(action.invoice.data_pagamento) : null,
        validatedInvoice: action.invoice.validada === 'S',
        rmaCredit: action.invoice.credito_rma === 'S',
        favoredId: action.invoice.id_favorecido,
        isShippingCompany: action.invoice.transportadora === 'S',
        noBankBillet: action.invoice.boleto === 'S',
        invoiceKey: action.invoice.chave_nfe,
        id: action.invoice.id,
      };
    }

    default: {
      return state;
    }
  }
}

type UseInvoiceReducer = [NewInvoice, Dispatch<NewInvoiceActions>];

export function useInvoiceReducer(): UseInvoiceReducer {
  const [invoice, dispatch] = useReducer(invoiceReducer, INITIAL_STATE);

  return [invoice, dispatch];
}
