import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Pagination from 'components/pagination/Pagination';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { Sales } from 'types/reports/sales';
import SalesReportData from './SalesReportData';
import { SalesProvider } from './hooks/useSales';
import SalesReportFilter from './SalesReportFilter';
import SalesReportTotalPage from './SalesReportTotal';
import Loading from 'components/loading/Loading';
import SelectedSale from 'components/sale/SelectedSale';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import { useDisplayMode } from 'hooks/useDisplayMode';
import SalesReportActions from './SalesReportActions';
import SalesReportFilterBox from './SalesReportFilterBox';
import SalesReportTabs from './SalesReportTabs';
import SalesReportDashboard from './dashboard/SalesReportDashboard';
import { useFetchSales } from './hooks/useFetchSales';
import SalesReportListTable from './report/list/table/SalesListTable';
import SalesReportListModule from './report/list/module/SalesListModule';

export interface SalesFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
  typeSale: string;
  product: string;
  order_by: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export type SalesReportTotal = {
  pj: string;
  pf: string;
  customers: number;
  mc: string;
  total: string;
  discount: string;
  percentDiscount: string;
};

const SalesReport: React.FC = () => {
  const [filterdSales, setFilteredSales] = useState<Sales[]>([]);
  const { handleDownload, isDownloading } = useDefaultExcelExport(filterdSales);
  const [orderedIndex, sort] = useTableOrder();
  const [tab, setTab] = useState('report');
  const [displayMode] = useDisplayMode();
  const {
    sales,
    salesItems,
    loading,
    filter,
    searchText,
    selectedSale,
    loadingSale,
    selectedBranch,
    formattedTotal,
    salesMethods,
    paymentsMethods,
    activitiesMethods,
    citiesMethods,
    setSelectedBranch,
    setSelectedSale,
    handleChangeFilter,
    handleSearchInputChange,
    handleSelectSale,
    handleSearchSubmit,
  } = useFetchSales();
  const [dialogDate, setDialogDate] = useState(false);
  const [shownReport, setShownReport] = useState(false);

  useEffect(() => {
    setFilteredSales(sales);
  }, [sales]);

  function handleSort(index: string) {
    const filtered = sort(index, filterdSales);
    setFilteredSales(filtered);
  }

  return (
    <SalesProvider
      value={{
        filter,
        handleChangeFilter,
        searchText,
      }}
    >
      <Appbar
        title="Itens vendidos"
        ActionsComponent={
          <SalesReportActions
            filteredSales={filterdSales}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            setDialogDate={setDialogDate}
            setShownReport={setShownReport}
          />
        }
      />

      {selectedSale && <SelectedSale onExited={() => setSelectedSale(null)} selectedSale={selectedSale} />}

      {dialogDate && (
        <SalesReportFilter handleSearchSubmit={handleSearchSubmit} onExited={() => setDialogDate(false)} />
      )}

      {loadingSale && <Loading />}

      {shownReport && <SalesReportData data={sales} branch={selectedBranch} onExited={() => setShownReport(false)} />}

      <SalesReportFilterBox
        loading={loading}
        searchText={searchText}
        setSelectedBranch={setSelectedBranch}
        handleSearchInputChange={handleSearchInputChange}
        handleSearchSubmit={handleSearchSubmit}
      />

      <SalesReportTabs disabled={loading} tab={tab} onChange={tab => setTab(tab)} />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : tab === 'report' ? (
        filterdSales.length === 0 ? (
          <NoData message="Nenhum item para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              <SalesReportTotalPage
                citiesMethods={citiesMethods}
                salesMethods={salesMethods}
                formattedTotal={formattedTotal}
              />
              {displayMode === 'list' ? (
                <SalesReportListTable
                  handleSelectSale={sale => handleSelectSale(sale)}
                  sales={filterdSales}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SalesReportListModule handleSelectSale={sale => handleSelectSale(sale)} sales={filterdSales} />
              )}
              <Pagination count={filterdSales.length} />
            </Container>
          </PaginationProvider>
        )
      ) : (
        <SalesReportDashboard
          sales={sales}
          loading={loading}
          paymentsMethods={paymentsMethods}
          salesItems={salesItems}
          salesMethods={salesMethods}
          activitiesMethods={activitiesMethods}
          citiesMethods={citiesMethods}
        />
      )}
    </SalesProvider>
  );
};

export default SalesReport;
