import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { IconButton, MenuItem, TextField, Tooltip, Button, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { FaFileExcel } from 'react-icons/fa';
import { FilterList, Print, Search } from '@mui/icons-material';
import { StockFilter } from 'types/reports/stockFilter';
import { usePermissionRules } from 'hooks/permissionRules';
import SelectMultiBranches from 'components/select-branches/SelectMultiBranches';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.7fr 0.7fr 1fr 0.7fr 100px 0.3fr',
  rowGap: 15,
  columnGap: 10,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    display: 'flex',
  },
}));

interface StockIndicatorFilterProps {
  onSearch(filter: StockFilter): void;
  handleChange(index: keyof StockFilter, value: any): void;
  openReport(): void;
  openMoreFilter(): void;
  filter: StockFilter;
  setExcelExport: Dispatch<SetStateAction<boolean>>;
}

const StockIndicatorFilter: React.FC<StockIndicatorFilterProps> = ({
  handleChange,
  onSearch,
  openReport,
  openMoreFilter,
  setExcelExport,
  filter,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { checkPermission } = usePermissionRules();

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [filter.indexToSearch]);

  function handleValidation(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={e => handleValidation(e)}>
        <SelectMultiBranches branchIds={filter.branchId} handleChange={e => handleChange('branchId', e)} />

        <TextField
          value={filter.indexToSearch}
          onChange={e => handleChange('indexToSearch', e.target.value)}
          select
          label="Pesquisar em"
        >
          <MenuItem value="Produto">Descrição</MenuItem>
          <MenuItem value="CodProd">ID</MenuItem>
        </TextField>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.searchValue}
          onChange={e => handleChange('searchValue', e.target.value)}
        />
        <TextField
          value={filter.parameter}
          onChange={e => handleChange('parameter', e.target.value)}
          select
          label="Parâmetro"
        >
          <MenuItem value="T">Todos</MenuItem>
          <MenuItem value="P">Positivos</MenuItem>
          <MenuItem value="N">Negativos</MenuItem>
        </TextField>

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
        <div className="search-params">
          {checkPermission('general.excel_export_button') && (
            <div>
              <Tooltip title="Exportar excel">
                <IconButton color="inherit" onClick={() => setExcelExport(true)}>
                  <FaFileExcel />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div>
            <Tooltip title="Imprimir">
              <IconButton color="inherit" onClick={openReport}>
                <Print />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Outros filtros">
              <IconButton color="inherit" onClick={openMoreFilter}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Filter>
    </FilterBox>
  );
};

export default StockIndicatorFilter;
