import React from 'react';
import { Typography, styled } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import { unityTableTemplate } from '../template/unityTableTemplate';
import DailySummaryUnityItemTable from './DailySummaryUnityItemTable';
import { GroupedUnity } from 'pages/reports/orders-list/dashboard/list/unity/table/GroupedUnityListTable';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

type DailySummaryUnityListTableProps = {
  units: GroupedUnity[];
  handleSort(index: string): void;
};

const DailySummaryUnityListTable: React.FC<DailySummaryUnityListTableProps> = ({ units, handleSort }) => {
  return (
    <TableContainer tableTemplate={unityTableTemplate}>
      <TableContent useMinHeight={false}>
        <TableHeader>
          {unityTableTemplate.map(item => (
            <HeaderItem
              className={
                item.dataType === 'number' ? `numericData` : item.dataType === 'quantity' ? `quantityData` : ''
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography fontSize={12} variant="caption" color="primary">
                {item.description}
              </Typography>
            </HeaderItem>
          ))}
        </TableHeader>
        <TableBody useMaxHeight={false}>
          {units.map((unity, index) => (
            <TableRow style={{ minHeight: 30 }} key={index}>
              <DailySummaryUnityItemTable unity={unity} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default DailySummaryUnityListTable;
