import { TableTemplate } from 'types/tableTemplate';

export const invoicesTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 50,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 300,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    dataType: 'number',
    width: 120,
  },
];
