import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type AnalysisReplacementTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const AnalysisReplacementTabs: React.FC<AnalysisReplacementTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="secondary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="dashboard" />
        <Tab label="Relação de reposição" value="replacements" />
        <Tab label="Reserva" value="reserve" />
        <Tab label="Produto" value="product" />
        <Tab label="Subgrupo" value="subgroup" />
        <Tab label="Estoque excedente" value="surplus_stock" />
      </Tabs>
    </Container>
  );
};

export default AnalysisReplacementTabs;
