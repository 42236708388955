import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import React, { useState } from 'react';
import { FinancialTicket } from 'types/financialReportList';

interface FinancialCustomerTicketsDialogProps {
  onExited(): void;
  tickets: FinancialTicket[];
}

const Container = styled('div')({
  gap: 5,
  display: 'flex',
  flexDirection: 'column',
});

const Infos = styled('div')(({ theme }) => ({
  gap: 5,
  display: 'grid',
  gridTemplateColumns: '130px 1fr',
  '& > span': {
    fontSize: 12,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const List = styled('div')({
  gap: 5,
  overflow: 'auto',
  display: 'flex',
  padding: '10px 0',
  flexDirection: 'column',
});

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 110px 110px 100px 70px 80px 80px 100px',
  '& > div': {
    gap: 5,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '& > svg': {
      fontSize: 11,
    },
    '&.numericData': {
      justifyContent: 'end',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '120px 110px 110px 100px 70px 80px 80px 100px',
  },
}));

const FinancialCustomerTicketsDialog: React.FC<FinancialCustomerTicketsDialogProps> = ({ onExited, tickets }) => {
  const total = tickets.reduce((sum, item) => sum + item.valor_total, 0);
  const [filtered, setFiltered] = useState<FinancialTicket[]>(tickets);
  const [orderedIndex, sort] = useTableOrder();

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Dialog title={tickets[0].cliente} onExited={onExited}>
      <Container>
        <Infos>
          <Typography variant="subtitle2">Qtd de boletos:</Typography>
          <Typography variant="subtitle2" fontWeight={600}>
            {tickets.length}
          </Typography>

          <Typography variant="subtitle2">Cliente:</Typography>
          <Typography variant="subtitle2" fontWeight={600}>
            {tickets[0].cliente}
          </Typography>

          <Typography variant="subtitle2">CNPJ:</Typography>
          <Typography variant="subtitle2" fontWeight={600}>
            {tickets[0].cnpj_cpf}
          </Typography>

          <Typography variant="subtitle2">Total de boletos:</Typography>
          <Typography variant="subtitle2" fontWeight={600}>
            {moneyFormat(total)}
          </Typography>
        </Infos>

        <Typography variant="h6">Lista:</Typography>

        <List>
          <Grid className="header">
            <div>
              <Typography onClick={() => handleSort('filial')} variant="h6" fontSize={14}>
                Filial
              </Typography>
              {orderedIndex.index === 'filial' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div>
              <Typography onClick={() => handleSort('formattedReleaseDate')} variant="h6" fontSize={14}>
                Lançamento
              </Typography>
              {orderedIndex.index === 'formattedReleaseDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div>
              <Typography onClick={() => handleSort('formattedPayDate')} variant="h6" fontSize={14}>
                Pagamento
              </Typography>
              {orderedIndex.index === 'formattedPayDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div>
              <Typography onClick={() => handleSort('formattedDueDate')} variant="h6" fontSize={14}>
                Vencimento
              </Typography>
              {orderedIndex.index === 'formattedDueDate' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div className="numericData">
              <Typography onClick={() => handleSort('valor')} variant="h6" fontSize={14} align="right">
                Valor
              </Typography>
              {orderedIndex.index === 'valor' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div className="numericData">
              <Typography onClick={() => handleSort('juros')} variant="h6" fontSize={14} align="right">
                Juros
              </Typography>
              {orderedIndex.index === 'juros' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div className="numericData">
              <Typography onClick={() => handleSort('multa')} variant="h6" fontSize={14} align="right">
                Multa
              </Typography>
              {orderedIndex.index === 'multa' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
            <div className="numericData">
              <Typography onClick={() => handleSort('valor_total')} variant="h6" fontSize={14} align="right">
                Total
              </Typography>
              {orderedIndex.index === 'valor_total' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward fontSize="small" color="primary" />
                  ) : (
                    <ArrowDownward fontSize="small" color="primary" />
                  )}
                </>
              )}
            </div>
          </Grid>
          {filtered.map(ticket => (
            <Grid key={ticket.id}>
              <Typography variant="caption">{ticket.filial}</Typography>
              <Typography variant="caption">{ticket.data_lanc}</Typography>
              <Typography variant="caption">{ticket.data_pag}</Typography>
              <Typography variant="caption">{ticket.vencimento}</Typography>
              <Typography variant="caption" align="right">
                {ticket.formattedValue}
              </Typography>
              <Typography variant="caption" align="right">
                {ticket.formattedFees}
              </Typography>
              <Typography variant="caption" align="right">
                {ticket.formattedTrafficTicket}
              </Typography>
              <Typography variant="caption" align="right">
                {ticket.formattedTotal}
              </Typography>
            </Grid>
          ))}
        </List>
      </Container>
    </Dialog>
  );
};

export default FinancialCustomerTicketsDialog;
