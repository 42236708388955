import { IconButton, InputAdornment, TextField, Tooltip, Typography, styled } from '@mui/material';
import { Launch } from '@mui/icons-material';
import DocumentUpload from 'components/document-upload/DocumentUpload';
import InputWhatsAppButton from 'components/input-whatsapp-button/InputWhatsAppButton';
import PhoneInput from 'components/masked-input/PhoneInput';
import React, { useEffect, useRef } from 'react';
import { Reseller } from 'types/reseller';
import { useReseller } from '../hooks/useReseller';
import { ResellerValidation } from './validations/resellerValidation';
import MaskedInput from 'react-text-mask';

const Row = styled('div')({
  display: 'flex',
  gap: 10,
});

const Location = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const DocsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 10,
  rowGap: 15,
  margin: '20px 0',
});

const docUploadContainer = {
  width: '100%',
  height: '150px!important',
  minHeight: 150,
};

const docUpload = {
  width: 200,
  height: '150px!important',
  minHeight: 150,
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
});

interface ResellerApprovalCompanyProps {
  reseller: Reseller | null;
  validation: ResellerValidation;
}

const ResellerApprovalCompany: React.FC<ResellerApprovalCompanyProps> = ({ reseller, validation }) => {
  const { handleChange, sintegraBaRequest, isSelfEmployed } = useReseller();
  const inputs = {
    ie: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<MaskedInput>(null),
    company_name: useRef<HTMLInputElement>(null),
    company_trade_name: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(inputs) as [keyof typeof inputs];

    if (!key) return;

    if (key === 'phone') {
      inputs.phone.current?.inputElement.focus();
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <Row>
        <TextField
          label={isSelfEmployed ? 'CPF' : 'CNPJ'}
          placeholder="Informe o CNPJ da empresa"
          defaultValue={reseller?.document}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Consultar cartão CNPJ">
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp?cnpj=${reseller?.document}`,
                      )
                    }
                  >
                    <Launch />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          inputRef={inputs.ie}
          error={!!validation.ie}
          helperText={validation.ie}
          label={isSelfEmployed ? 'RG' : 'IE'}
          placeholder={isSelfEmployed ? 'Informe o RG' : 'Informe a inscrição estadual'}
          value={reseller?.ie}
          onChange={e => handleChange('ie', e.target.value)}
          InputProps={
            !isSelfEmployed
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Consultar Sintegra Bahia">
                        <IconButton onClick={sintegraBaRequest}>
                          <Launch />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
      </Row>
      <Row>
        <TextField
          inputRef={inputs.company_name}
          error={!!validation.company_name}
          helperText={validation.company_name}
          label={isSelfEmployed ? 'Nome' : 'Razão social'}
          placeholder={isSelfEmployed ? 'Informe o nome' : 'Informe a razão social'}
          value={reseller?.company_name}
          onChange={e => handleChange('company_name', e.target.value)}
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          inputRef={inputs.company_trade_name}
          error={!!validation.company_trade_name}
          helperText={validation.company_trade_name}
          label={isSelfEmployed ? 'Nome' : 'Nome fantasia'}
          placeholder={isSelfEmployed ? 'Informe o nome social' : 'Informe o nome fantasia'}
          value={reseller?.company_trade_name}
          onChange={e => handleChange('company_trade_name', e.target.value)}
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          inputRef={inputs.phone}
          error={!!validation.phone}
          helperText={validation.phone}
          label="Telefone"
          placeholder="Informe o telefone"
          value={reseller?.phone}
          onChange={e => handleChange('phone', e.target.value)}
          fullWidth
          InputProps={{
            inputComponent: PhoneInput as any,
            endAdornment: <InputWhatsAppButton phone={reseller?.phone || ''} />,
          }}
        />

        <TextField
          inputRef={inputs.email}
          error={!!validation.email}
          helperText={validation.email}
          label="E-mail"
          placeholder="Informe o e-mail"
          value={reseller?.email}
          onChange={e => handleChange('email', e.target.value)}
          fullWidth
        />
      </Row>
      <Location>
        <Typography variant="caption" color="textSecondary">
          Localização
        </Typography>
        <Typography variant="body2">
          {reseller?.address.street}, {reseller?.address.street_number}
        </Typography>
        <Typography variant="body2">{reseller?.address.neighborhood}</Typography>
        <Typography variant="body2">
          {reseller?.address.city}, {reseller?.address.postal_code}
        </Typography>
      </Location>
      <DocsContainer>
        {isSelfEmployed ? (
          <DocumentUpload
            document={reseller?.self_employed_declaration || null}
            label="Declaração de autônomo"
            handleRemoveImage={() => handleChange('self_employed_declaration', null)}
            handleSetImage={doc => handleChange('self_employed_declaration', doc)}
            containerClassname={docUploadContainer}
            style={docUpload}
          />
        ) : (
          <DocumentUpload
            document={reseller?.social_contract_copy || null}
            label="Contrato social"
            handleRemoveImage={() => handleChange('social_contract_copy', null)}
            handleSetImage={doc => handleChange('social_contract_copy', doc)}
            containerClassname={docUploadContainer}
            style={docUpload}
          />
        )}
        <DocumentUpload
          document={reseller?.letter_of_attorney || null}
          label="Procuração"
          handleRemoveImage={() => handleChange('letter_of_attorney', null)}
          handleSetImage={doc => handleChange('letter_of_attorney', doc)}
          containerClassname={docUploadContainer}
          style={docUpload}
        />
      </DocsContainer>
    </Container>
  );
};

export default ResellerApprovalCompany;
