import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

type MonthlyComparisonTabsProps = {
  handleChange(value: string): void;
  tab: string;
};

const MonthlyComparisonTabs: React.FC<MonthlyComparisonTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Período" value="dashboard" />
        <Tab label="Filial" value="branch" />
        <Tab label="Categoria" value="category" />
        <Tab label="Fabricante" value="manufacturer" />
      </Tabs>
    </Container>
  );
};

export default MonthlyComparisonTabs;
