import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Add, FilterList, FilterListOff, Visibility, VisibilityOff } from '@mui/icons-material';
import history from 'services/history';

type PurchaseOrderActionsProps = {
  tab: string;
  handleShowFilter(): void;
  handleShowTotals(): void;
  showFilter: boolean;
  showTotals: boolean;
};

const PurchaseOrderActions: React.FC<PurchaseOrderActionsProps> = ({
  tab,
  showTotals,
  handleShowTotals,
  showFilter,
  handleShowFilter,
}) => {
  return (
    <>
      <Tooltip title="Esconder filtros">
        <IconButton onClick={handleShowFilter} color="inherit">
          {showFilter ? <FilterList /> : <FilterListOff />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Esconder totalizador">
        <IconButton onClick={handleShowTotals} color="inherit">
          {showTotals ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </Tooltip>
      {tab === 'registration' && (
        <IconButton color="inherit" onClick={() => history.push('/purchasing/order/new')}>
          <Tooltip title="Novo">
            <Add />
          </Tooltip>
        </IconButton>
      )}
    </>
  );
};

export default PurchaseOrderActions;
