import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { BranchFinancialTicket } from 'types/financialReportList';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';

const Column = styled('div')({
  gap: 10,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  height: 550,
});

const Row = styled('li')(({ theme }) => ({
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '110px 95px 110px 90px 90px',
  '& > span': {
    gap: 5,
    fontSize: 11,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  },
}));

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
  width: '100%',
});

interface TopBranchesTicketProps {
  data: BranchFinancialTicket[];
}

const TopBranchesTicket: React.FC<TopBranchesTicketProps> = ({ data }) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<BranchFinancialTicket[]>([]);
  const { rowsPerPage, page } = usePagination();

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Column>
      <Typography fontSize={13} variant="subtitle2" align="center">
        Por filial
      </Typography>
      {data.length > 0 ? (
        <List>
          <Row>
            <span>
              <Typography fontSize={13} onClick={() => handleSort('nome')} variant="subtitle2">
                Filial
              </Typography>
              {orderedIndex.index === 'nome' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('valor_total')} align="right" variant="subtitle2">
                Total
              </Typography>
              {orderedIndex.index === 'valor_total' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('pagos')} variant="subtitle2" align="right">
                Recebidos
              </Typography>
              {orderedIndex.index === 'pagos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('vencidos')} variant="subtitle2" align="right">
                Inadimplentes
              </Typography>
              {orderedIndex.index === 'vencidos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('a_receber')} variant="subtitle2" align="right">
                A receber
              </Typography>
              {orderedIndex.index === 'a_receber' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <Row key={index}>
              <Tooltip title={item.filial}>
                <Typography className="item-name" variant="caption">
                  {item.filial}
                </Typography>
              </Tooltip>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.valor_total)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.pagos)}
              </Typography>
              <Typography className="numericData" color="error" align="right" variant="caption">
                {moneyFormat(item.vencidos)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.a_receber)}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
      <Pagination count={data.length} />
    </Column>
  );
};

export default TopBranchesTicket;
