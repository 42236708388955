import React from 'react';
import { MonthlyCategory } from 'types/monthlyComparison';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

type CategoryItemModuleProps = {
  category: MonthlyCategory;
};

const CategoryItemModule: React.FC<CategoryItemModuleProps> = ({ category }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{category.categoria}</Typography>
    </ListItemStyled>
  );
};

export default CategoryItemModule;
