import { styled } from '@mui/material';
import DialogInput from 'components/dialogs/DialogInput';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import SalesListTable from 'pages/reports/orders-list/report/list/table/SalesListTable';
import React, { useEffect, useState } from 'react';
import { Sales } from 'types/reports/sales';

interface TopCustomersExpandedProps {
  onExited(): void;
  sales: Sales[];
}

const Content = styled('div')({
  display: 'flex',
  maxWidth: '100%',
  overflow: 'auto',
  flexDirection: 'column',
});

const TopCustomersExpanded: React.FC<TopCustomersExpandedProps> = ({ sales, onExited }) => {
  const [filtered, setFiltered] = useState<Sales[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(sales);
  }, [sales]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <DialogInput maxWidth="lg" onExited={onExited}>
      <PaginationProvider>
        <Content>
          <SalesListTable
            handleSelectSale={sale => console.log(sale)}
            sales={filtered}
            handleSort={handleSort}
            orderedIndex={orderedIndex}
          />
          <Pagination count={filtered.length} />
        </Content>
      </PaginationProvider>
    </DialogInput>
  );
};

export default TopCustomersExpanded;
