import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ResumeQueryParams } from './hooks/useFetchResumes';

type CurriculumFilterProps = {
  handleChange(index: keyof ResumeQueryParams, value: any): void;
  filter: ResumeQueryParams;
  handleSearch(queryParams: ResumeQueryParams, e?: FormEvent<HTMLFormElement>): void;
};

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr 2fr 100px',
  columnGap: 10,
  flex: 1,
  '& label': {
    marginRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    rowGap: 15,
  },
  '& > .search-params': {
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const CurriculumFilter: React.FC<CurriculumFilterProps> = ({ filter, handleSearch, handleChange }) => {
  return (
    <FilterBox>
      <Filter onSubmit={e => handleSearch(filter, e)}>
        <TextField
          label="Nome do candidato"
          placeholder="Digite o nome do candidato"
          autoFocus
          value={filter.term}
          onChange={e => handleChange('term', e.target.value)}
        />

        <TextField
          label="Cidade do candidato"
          placeholder="Digite a cidade do candidato"
          autoFocus
          value={filter.city}
          onChange={e => handleChange('city', e.target.value)}
        />

        <TextField
          select
          label="Área de interesse"
          placeholder="Informe a área de interesse"
          autoFocus
          value={filter.department}
          onChange={e => handleChange('department', e.target.value)}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="administrative">Administrativo</MenuItem>
          <MenuItem value="box">Caixa</MenuItem>
          <MenuItem value="shopping">Compras</MenuItem>
          <MenuItem value="commercial_sales">Comercial / Vendas</MenuItem>
          <MenuItem value="accounting_tax">Contábil / Fiscal</MenuItem>
          <MenuItem value="personal_department">Departamento Pessoal</MenuItem>
          <MenuItem value="stock_shipping">Estoque / Expedição</MenuItem>
          <MenuItem value="financial">Financeiro</MenuItem>
          <MenuItem value="it">Informática / TI</MenuItem>
          <MenuItem value="logistics">Logística</MenuItem>
          <MenuItem value="marketing">Marketing</MenuItem>
          <MenuItem value="driver">Motorista</MenuItem>
          <MenuItem value="human_resources">Recursos Humanos</MenuItem>
          <MenuItem value="general_services">Serviços Gerais</MenuItem>
        </TextField>
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date}
            onChange={date => handleChange('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date}
            onChange={date => handleChange('final_date', date)}
          />
        </div>

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CurriculumFilter;
