import { Print } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import ExcelButtonDownload from 'components/excel-button-download/ExcelButtonDownload';
import { useDefaultExcelExport } from 'hooks/useDefaultExcelExport';
import React from 'react';
import { NoteEntry } from 'types/noteEntry';

interface NoteEntryActionsProps {
  handleReportClick(): void;
  notes: NoteEntry[];
}

const Actions = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  gap: 10,
});

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const NoteEntryActions: React.FC<NoteEntryActionsProps> = ({ notes, handleReportClick }) => {
  const { handleDownload } = useDefaultExcelExport(notes);

  return (
    <Actions>
      <ExcelButtonDownload handleDownload={handleDownload} size="small" marginTop={0} disabled={!notes.length} />
      <CustomButton
        disabled={!notes.length}
        startIcon={<Print />}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleReportClick}
      >
        Imprimir
      </CustomButton>
    </Actions>
  );
};

export default NoteEntryActions;
