import { Button } from '@mui/material';
import React from 'react';
import history from 'services/history';

const OrderByActions: React.FC = () => {
  return (
    <Button onClick={() => history.push('/order-by/new')} variant="contained" color="primary">
      Novo
    </Button>
  );
};

export default OrderByActions;
