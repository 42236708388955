import React from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { Typography, darken, styled } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import StockBalanceTotalLoading from 'pages/reports/stock/loading/StockBalanceTotalLoading';
import { Bill } from 'types/bill';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  marginBottom: 10,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface ReportBillTotalProps {
  bills: Bill[];
  loading: boolean;
}

const BillTotal: React.FC<ReportBillTotalProps> = ({ bills, loading }) => {
  return loading ? (
    <StockBalanceTotalLoading />
  ) : (
    <Container>
      <Box>
        <Typography className="title first" variant="body2" fontSize={18}>
          Quantidade de boletos
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {bills.length}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Total de boletos
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(bills.reduce((sum, item) => sum + item.value, 0))}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default BillTotal;
