import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useVacancies } from '../hooks/useVacancies';
import { api } from 'services/api';
import { Vacancy } from 'types/vacancy';

type VacancyListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const VacancyListMenu: React.FC<VacancyListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedVacancy, setSelectedVacancy, setSaving, onInactiveVacancy } = useVacancies();

  function handleInactivateVacancy(vacancy: Vacancy) {
    setSaving(true);
    api
      .patch(`/jobs/${vacancy.id}/status`, { ...vacancy, is_active: false })
      .then(() => {
        setSaving(false);
        setSelectedVacancy(null);
        onInactiveVacancy();
      })
      .finally(() => {
        setSaving(false);

        setAnchorEl(null);
      });
  }

  function handleClickInactivateVacancyButton() {
    if (!selectedVacancy) {
      return;
    }

    handleInactivateVacancy(selectedVacancy);
  }

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleClickInactivateVacancyButton}>Inativar vaga</MenuItem>
    </Menu>
  );
};

export default VacancyListMenu;
