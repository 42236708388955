import React, { FormEvent } from 'react';
import { Button, TextField, alpha, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { useCommercialBi } from './hooks/useCommercialBi';

const Container = styled('div')(props => ({
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 15,
  background: alpha(props.theme.palette.primary.main, 0.08),
  padding: 25,
}));

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '250px 1fr 100px',
  columnGap: 10,
  flex: 1,
  '& > div': {
    height: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    gap: 10,
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const CommercialBiMainFilter: React.FC = () => {
  const { mainFilter, handleMainFilterChange, fetch } = useCommercialBi();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    fetch(mainFilter);
  }

  return (
    <Container>
      <Filter onSubmit={handleSubmit}>
        <SelectBranch branch_id={mainFilter.branchId} handleChange={e => handleMainFilterChange('branchId', e)} />

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={mainFilter.initialDate}
            onChange={date => handleMainFilterChange('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={mainFilter.finalDate}
            onChange={date => handleMainFilterChange('finalDate', date)}
          />

          <TextField
            label="Produto"
            placeholder="Digite sua pesquisa"
            autoFocus
            value={mainFilter.product}
            onChange={e => handleMainFilterChange('product', e.target.value)}
          />

          <TextField
            label="Cliente"
            placeholder="Digite sua pesquisa"
            autoFocus
            value={mainFilter.customer}
            onChange={e => handleMainFilterChange('customer', e.target.value)}
          />
        </div>

        <Button variant="contained" type="submit" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </Container>
  );
};

export default CommercialBiMainFilter;
