import { Typography, styled } from '@mui/material';
import React from 'react';
import { moneyFormat } from 'helpers/numberFormat';
import { useFinancialSummary } from './hooks/useFinancialSummary';
import { Favorite, ThumbDown, ThumbUp } from '@mui/icons-material';

interface MakeStylesProps {
  result: number;
  showTotals: boolean;
}

const Container = styled('div')<MakeStylesProps>(props => ({
  display: 'grid',
  marginBottom: 15,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  transition: 'all 0.2s ease',
  height: props.showTotals ? 110 : 0,
  opacity: props.showTotals ? 1 : 0,
  columnGap: 10,
  rowGap: 5,
  [props.theme.breakpoints.down('lg')]: {
    height: props.showTotals ? 110 : 0,
  },
  [props.theme.breakpoints.down('md')]: {
    height: props.showTotals ? 220 : 0,
    gridTemplateColumns: '1fr 1fr',
  },
  '& .item': {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    paddingTop: 5,
    alignItems: 'center',
    gap: 5,
    border: '1px solid #000!important',
    '&.revenue': {
      color: props.theme.palette.success.main,
    },
    '&.expense': {
      color: props.theme.palette.error.main,
    },
    '&.personnel': {
      color: props.theme.palette.primary.main,
    },
    '&.invoice': {
      color: props.theme.palette.warning.main,
    },
    '&.result': {
      transition: 'all ease 0.3s',
      color: props.result < 0 ? props.theme.palette.error.main : props.theme.palette.success.main,
    },
    '& .value': {
      fontSize: 16,
      textAlign: 'end',
      paddingRight: 5,
    },
    '& svg': {
      paddingLeft: 5,
      transition: 'all ease 0.3s',
      '&.result': {
        marginLeft: 5,
        transform: props.result < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
      },
    },
    '& .description': {
      fontWeight: 600,
      padding: 5,
      gridColumn: '1/3',
      borderTop: '1px solid #000!important',
      textAlign: 'end',
    },
  },
}));

const FinancialSummaryTotal: React.FC = () => {
  const { invoicesTotal, showTotals } = useFinancialSummary();
  const result = invoicesTotal.revenue - invoicesTotal.expense - invoicesTotal.personnel - invoicesTotal.invoice;
  const formattedExpense = moneyFormat(invoicesTotal.expense);
  const formattedRevenue = moneyFormat(invoicesTotal.revenue);
  const formattedPersonnel = moneyFormat(invoicesTotal.personnel);
  const formattedInvoice = moneyFormat(invoicesTotal.invoice);

  return (
    <Container showTotals={showTotals} result={result}>
      <div className="item revenue">
        <Favorite fontSize="large" />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedRevenue}
        </Typography>
        <Typography className="description revenue" variant="caption">
          Receita (R$)
        </Typography>
      </div>

      <div className="item expense">
        <ThumbDown fontSize="large" />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedExpense}
        </Typography>
        <Typography className="description expense" variant="caption">
          Despesa (R$)
        </Typography>
      </div>

      <div className="item personnel">
        <ThumbDown fontSize="large" />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedPersonnel}
        </Typography>
        <Typography className="description personnel" variant="caption">
          Folha de Pagamento (R$)
        </Typography>
      </div>

      <div className="item invoice">
        <ThumbDown fontSize="large" />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {formattedInvoice}
        </Typography>
        <Typography className="description invoice" variant="caption">
          Título (R$)
        </Typography>
      </div>

      <div className="item result">
        <ThumbUp className="result" fontSize="large" />
        <Typography className="value" fontSize={12} variant="subtitle2">
          {moneyFormat(result)}
        </Typography>
        <Typography className="description result" variant="caption">
          Resultado (R$)
        </Typography>
      </div>
    </Container>
  );
};

export default FinancialSummaryTotal;
