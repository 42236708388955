import Appbar from 'components/appbar/Appbar';
import React, { FormEvent, useEffect, useState } from 'react';
import history from 'services/history';
import { Vacancy } from 'types/vacancy';
import VacancyForm from '../VacancyForm';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import VacancyActions from '../VacancyActions';
import { useVacancyValidation } from '../validation/vacancyValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading/Loading';

const initial_state: Vacancy = {
  benefits: '',
  department: 'administrative',
  created_at: new Date(),
  id: '',
  is_active: 1,
  local: '',
  observation: '',
  role: '',
  salary: '',
  updated_at: new Date(),
  work_experience: '',
  work_hours: '',
  work_model: '',
  image: null,
  image_id: null,
  user: {
    email: '',
    name: '',
  },
};

const VacancyUpdate: React.FC = () => {
  const { job_id } = useParams<{ job_id: string }>();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [vacancy, setVacancy] = useState<Vacancy>(initial_state);
  const [validation, setValidation, validate] = useVacancyValidation();
  const { handleOpen } = useMessaging();

  useEffect(() => {
    setLoading(true);

    api
      .get(`/jobs/${job_id}`)
      .then(response => {
        setVacancy(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [job_id]);

  function handleChange(index: keyof Vacancy, value: any) {
    setVacancy(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(vacancy)
      .then(handleSubmit)
      .catch(err => console.log(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/jobs/${vacancy.id}`, vacancy)
      .then(() => {
        setSaving(false);
        history.push('/rh/vacancies');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {loading && <Loading />}
      {saving && <InsideSaving />}
      <Appbar title="Atualizar vaga de emprego" backAction={() => history.push('/rh/vacancies')} />
      <PageHeaderActions
        title="Atualizar vaga de emprego"
        backUrl="/rh/vacancies"
        ActionComponent={<VacancyActions handleSubmit={handleValidation} />}
      />

      <form onSubmit={handleValidation}>
        <VacancyForm vacancy={vacancy} validation={validation} handleChange={handleChange} />
      </form>
    </>
  );
};

export default VacancyUpdate;
