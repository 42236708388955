import React, { MouseEvent } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { UnityStockBalanceData } from 'types/reports/stockBalance';
import { unityStockTableTemplate } from '../unityStockTableTemplate';
import { useStockBalance } from '../../hooks/useStockBalance';
import { KeyboardArrowDown } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)({
  justifySelf: 'baseline',
  padding: 5,
  '& > span > svg': {
    transition: 'transform ease-in 0.3s',
  },
});

type UnityItemTableProps = {
  unity: UnityStockBalanceData;
};

const UnityItemTable: React.FC<UnityItemTableProps> = ({ unity }) => {
  const { moreUnity, setMoreUnity } = useStockBalance();

  function handleClickMore(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setMoreUnity(moreUnity ? null : unity);
  }

  return (
    <>
      {unityStockTableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'more' ? (
            <CustomIconButton key={item.id} onClick={handleClickMore}>
              <KeyboardArrowDown
                style={moreUnity?.id_unidade === unity.id_unidade ? { transform: 'rotate(180deg)' } : undefined}
              />
            </CustomIconButton>
          ) : (
            <div
              key={item.id}
              className={
                item.dataType === 'number' ? 'numericData' : item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Typography style={{ fontSize: 11 }} variant="body2">
                {unity[item.id]}
              </Typography>
            </div>
          ),
        )}
    </>
  );
};

export default UnityItemTable;
