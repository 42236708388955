import { Grid, TextField } from '@mui/material';
import ImageUpload from 'components/image-upload/ImageUpload';
import React from 'react';
import { useProduct } from '../../Product';

const ProductImages: React.FC = () => {
  const { product, validation, handleChange } = useProduct();

  return (
    <Grid gap={2} display={'grid'} paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} lg={4} md={6} sm={12}>
          <TextField
            label="Link Youtube"
            placeholder="Informe o link do video no YouTube para incorporação"
            fullWidth
            onChange={e => handleChange('link_youtube', e.target.value)}
            value={product.link_youtube}
            helperText="Copiar o endereço da barra de endereço do navegador"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <ImageUpload
            validationError={validation.foto_principal}
            label="Foto principal"
            handleSetImage={image => handleChange('main_image', image)}
            handleRemoveImage={() => handleChange('main_image', null)}
            image={product.main_image}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <ImageUpload
            label="Foto 1"
            handleSetImage={image => handleChange('image1', image)}
            handleRemoveImage={() => handleChange('image1', null)}
            image={product.image1}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <ImageUpload
            validationError={validation.foto_principal}
            label="Foto 2"
            handleSetImage={image => handleChange('image2', image)}
            handleRemoveImage={() => handleChange('image2', null)}
            image={product.image2}
          />
        </Grid>
        <Grid item xs={12} xl={2} lg={2} md={3} sm={6}>
          <ImageUpload
            validationError={validation.foto_principal}
            label="Foto 3"
            handleSetImage={image => handleChange('image3', image)}
            handleRemoveImage={() => handleChange('image3', null)}
            image={product.image3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductImages;
