import React from 'react';
import { Typography, styled, ListItem } from '@mui/material';
import { Rma } from 'types/rma';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type RmaItemModuleProps = {
  item: Rma;
};

const RmaItemModule: React.FC<RmaItemModuleProps> = ({ item }) => {
  return (
    <ListItemStyled>
      <Grid className="bottom">
        <Typography variant="caption">OS:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.id}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Filial:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.filial}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Comprador:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.comprador}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Produto:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.produto}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Fabricante:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.fabricante}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Data:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.data}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Qtd:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.formattedQuantity}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Valor:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.formattedValue}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Situação:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {item.situacao}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default RmaItemModule;
