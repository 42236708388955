import React, { useEffect, useState } from 'react';
import Dialog from 'components/dialogs/Dialog';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import { ItemEntry } from 'types/itemEntry';
import { NoteEntry } from 'types/noteEntry';
import { moneyFormat } from 'helpers/numberFormat';
import { styled } from '@mui/material';
import ItemEntryListTable from 'pages/reports/item-entry/list/table/ItemEntryListTable';
import { useDisplayMode } from 'hooks/useDisplayMode';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import ItemEntryListModule from 'pages/reports/item-entry/list/module/ItemEntryListModule';

interface NoteEntryListItemsProps {
  onExited(): void;
  selectedNote: NoteEntry;
}

const Container = styled('div')({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const NoteEntryListItems: React.FC<NoteEntryListItemsProps> = ({ onExited, selectedNote }) => {
  const [items, setItems] = useState<ItemEntry[]>([]);
  const [filtered, setFiltered] = useState<ItemEntry[]>([]);
  const { h2iApi } = useApp();
  const [displayMode] = useDisplayMode();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    if (!h2iApi) return;

    const params = {
      id_entrada: selectedNote.id,
      id_filial: selectedNote.id_filial,
    };

    setLoading(true);
    h2iApi
      .get(`/api/getrelentradaitens`, {
        params,
      })
      .then(response => {
        const _entries = response.data.map((item: ItemEntry) => {
          item.formattedUnitary = moneyFormat(item.valor_unitario);
          item.formattedCoustUnitaryTotal = moneyFormat(item.custo_total_unitario);
          item.formattedCoustTotal = moneyFormat(item.custo_total);
          item.formattedIpi = moneyFormat(item.valor_ipi);
          item.formattedDiscount = moneyFormat(item.valor_desconto);
          item.formattedShipment = moneyFormat(item.valor_desconto);

          return item;
        });

        setItems(_entries);
      })
      .catch(() => setItems([]))
      .finally(() => setLoading(false));
  }, [h2iApi, selectedNote]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  useEffect(() => {
    setFiltered(items);
  }, [items]);

  return (
    <Dialog onExited={onExited} maxWidth="lg" title={`Confira os itens da nota ${selectedNote.nf}`}>
      <PaginationProvider>
        <Container>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum item encontrado" />
          ) : displayMode === 'list' ? (
            <ItemEntryListTable entries={items} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ItemEntryListModule entries={items} />
          )}
        </Container>
      </PaginationProvider>
    </Dialog>
  );
};

export default NoteEntryListItems;
