import { useCallback, useState } from 'react';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { PaymentMethod } from 'types/salesReport';
import { Sales, SalesItem } from 'types/reports/sales';
import { CommercialBiMainFilterProps } from '../CommercialBi';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { StockBalanceData } from 'types/reports/stockBalance';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { PurchaseOrderItem } from 'types/purchaseOrderItem';

export function useFetchCommercialBi() {
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const { h2iApi } = useApp();
  const [salesItems, setSalesItems] = useState<SalesItem[]>([]);
  const [sales, setSales] = useState<Sales[]>([]);
  const [stockItems, setStockItems] = useState<StockBalanceData[]>([]);
  const [orders, setOrders] = useState<PurchaseOrderItem[]>([]);

  const fetch = useCallback(
    (filter: CommercialBiMainFilterProps) => {
      setLoading(true);
      if (!h2iApi) return;
      const formattedInitialDate = format(filter.initialDate, 'P', { locale: ptBR });
      const formattedFinalDate = format(filter.finalDate, 'P', { locale: ptBR });
      const query = window.location.search;
      const fetchStocks = () => {
        h2iApi
          .get(`/api/saldoestoque${query}`, {
            params: {
              id_filial: filter.branchId || '',
            },
          })
          .then(response => {
            if (response.data[0].result) {
              setStockItems([]);
              return;
            }

            const _data = response.data.map((item: StockBalanceData) => {
              item.formattedEstoque = numberFormat(item.Estoque);
              item.formattedCusto = moneyFormat(item.Custo);
              item.formattedCustoTotal = moneyFormat(item.CustoTotal);
              item.formattedValueReserve = moneyFormat(item.valor_reserva);
              item.formattedValueOrder = moneyFormat(item.valor_pedido);
              item.formattedIndex = numberFormat(item.indice);
              return item;
            });
            setStockItems(_data);
          });
      };

      const fetchSales = () => {
        h2iApi
          .get(`/api/relvendas${query}`, {
            params: {
              data_ini: formattedInitialDate,
              data_fim: formattedFinalDate,
              id_filial: filter.branchId || '',
              tipo_venda: 'V',
              produto: filter.product.toUpperCase(),
              filtro: filter.customer,
            },
          })
          .then(response => {
            if (!response.data.relvendas) {
              setSales([]);
              return;
            }

            setSales(
              response.data.relvendas.map(item => {
                item.formattedTotal = moneyFormat(item.vl_total);
                item.formattedDiscount = moneyFormat(item.vl_desconto);

                return item;
              }),
            );
          })
          .catch(err => console.error(err));
      };

      const fetchSalesItems = () => {
        h2iApi
          .get(`/api/getrelvendasitem${query}`, {
            params: {
              data_ini: formattedInitialDate,
              data_fim: formattedFinalDate,
              id_filial: filter.branchId || '',
              tipo_venda: 'V',
              produto: filter.product.toUpperCase(),
              filtro: filter.customer,
            },
          })
          .then(response => {
            if (!response.data.relitens) {
              setSalesItems([]);
              return;
            }

            setSalesItems(
              response.data.relitens.map((item: SalesItem) => {
                item.formattedTotal = moneyFormat(item.total);
                item.formattedUnitario = moneyFormat(item.unitario);
                item.formattedCusto = moneyFormat(item.custo);

                return item;
              }),
            );
          })
          .catch(err => console.error(err));
      };
      const fetchPaymentMethods = () => {
        h2iApi
          .get(`/api/getrelvendasformapag${query}`, {
            params: {
              data_ini: formattedInitialDate,
              data_fim: formattedFinalDate,
              id_filial: filter.branchId || '',
              tipo_venda: 'V',
              produto: filter.product.toUpperCase(),
              filtro: filter.customer,
            },
          })
          .then(response => {
            if (!response.data.relpag) {
              setPaymentMethods([]);
              return;
            }

            setPaymentMethods(
              response.data.relpag.map(item => ({
                ...item,
                formattedValue: moneyFormat(item.valor),
                formattedPercentual: percentFormat(
                  (item.valor / response.data.relpag.reduce((acc, curr) => acc + curr.valor, 0)) * 100,
                ),
              })),
            );
          })
          .catch(err => console.error(err));
      };

      const fetchOrdersSales = () => {
        h2iApi
          .get(`/api/getpedidoitens${query}`, {
            params: {
              id_filial: filter.branchId || '',
              produto: filter.product.toUpperCase(),
              aging: 30,
              forecast: 30,
            },
          })
          .then(response => {
            if (response.data.MESSAGE) {
              setOrders([]);
              return;
            }
            setOrders(
              response.data.map(item => ({
                ...item,
                formattedPercent: percentFormat(item.perc_comprou_caro),
                formattedCoust: moneyFormat(item.custo),
                formattedNegotiatedValue: moneyFormat(item.valor_negociado),
                formattedValueTotal: moneyFormat(item.valor_total),
                formattedMinForecast: parsePtBRDate(item.previsaomin).toISOString(),
                formattedMaxForecast: parsePtBRDate(item.previsaomax).toISOString(),
              })),
            );
          });
      };

      Promise.all([fetchOrdersSales(), fetchStocks(), fetchPaymentMethods(), fetchSalesItems(), fetchSales()]).finally(
        () => {
          setLoading(false);
        },
      );
    },
    [h2iApi],
  );
  return {
    paymentMethods,
    loading,
    fetch,
    sales,
    salesItems,
    stockItems,
    orders,
  };
}
