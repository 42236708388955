import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { useFetchWaste } from 'pages/material-residues/waste/hooks/useFetchWaste';
import { setWasteAction } from 'pages/material-residues/waste/reducer/actions';
import { WasteProvider } from '../hooks/useWaste';
import WasteForm from '../WasteForm';
import WasteProducts from '../products/WasteProducts';
import WasteTotals from '../totals/WasteTotals';
import { useFetchWaste as useFetchWasteRequests } from '../hooks/useFetchWaste';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 40,
  flex: 1,
});

const WasteUpdate: React.FC = () => {
  const {
    loadingCustomer,
    loadingPostalCode,
    formattedTotal,
    handleDocumentChange,
    handleChange,
    waste,
    disableButton,
    dispatch,
    handlePostalCodeChange,
  } = useFetchWasteRequests();
  const { id } = useParams<{ id: string }>();
  const [loaded, loading] = useFetchWaste(id);
  const [allowProduct, setAllowProduct] = useState(true);

  useEffect(() => {
    if (disableButton === true) {
      setAllowProduct(false);
    }
  }, [disableButton]);

  useEffect(() => {
    dispatch(setWasteAction(loaded));
  }, [loaded, dispatch]);

  return (
    <WasteProvider
      value={{
        waste,
        handleChange,
        dispatch,
        handleDocumentChange,
        loadingCustomer,
        loadingPostalCode,
        handlePostalCodeChange,
      }}
    >
      <Appbar title="R3E" />

      <PageHeader title="Editar R3E" backUrl="/waste" />
      <Container>
        {loading ? (
          <InsideLoading />
        ) : (
          <>
            <WasteForm disableButton={disableButton} handleValidation={() => setAllowProduct(true)} />

            <WasteProducts allowProduct={allowProduct} />

            <WasteTotals formattedTotal={formattedTotal} />
          </>
        )}
      </Container>
    </WasteProvider>
  );
};

export default WasteUpdate;
