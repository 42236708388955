import React, { useEffect, useState } from 'react';
import useTableOrder from 'hooks/tableOrder';
import { darken, styled, Typography } from '@mui/material';
import { SaleReview } from 'types/saleReview';

interface Props {
  sales: SaleReview[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#eeeff8',
  '& > p': {
    color: darken('#eeeff8', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '80px 80px 100px 50px 100px 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '90px 90px 90px 40px 80px 90px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 90px 100px 50px 80px 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '45px 90px 1fr 0.6fr 0.8fr 1fr',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  padding: '0px 10px',
  display: 'grid',
});

const CommercialAnalysisProductSales: React.FC<Props> = ({ sales }) => {
  const [filtered, setFiltered] = useState<SaleReview[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(sales);
  }, [sales]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Retrospecto de vendas</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('formattedMonthYear')} variant="caption" fontWeight={600}>
              Mês/Ano
            </Typography>

            <Typography align="right" onClick={() => handleSort('preco_medio')} variant="caption" fontWeight={600}>
              Preço Médio
            </Typography>

            <Typography align="right" onClick={() => handleSort('venda_total')} variant="caption" fontWeight={600}>
              Venda Total
            </Typography>

            <Typography
              align="right"
              onClick={() => handleSort('formattedQuantity')}
              variant="caption"
              fontWeight={600}
            >
              Qtd
            </Typography>

            <Typography align="right" onClick={() => handleSort('cmv')} variant="caption" fontWeight={600}>
              CMV
            </Typography>

            <Typography align="right" onClick={() => handleSort('mc')} variant="caption" fontWeight={600}>
              MC
            </Typography>
          </Row>
          {filtered.map((item, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{item.mes_ano}</Typography>
              <Typography align="right" variant="caption">
                {item.formattedAveragePrice}
              </Typography>

              <Typography align="right" variant="caption">
                {item.formattedTotalSale}
              </Typography>

              <Typography variant="caption" align="right">
                {item.qtd}
              </Typography>

              <Typography align="right" variant="caption">
                {item.formattedCmv}
              </Typography>

              <Typography align="right" variant="caption">
                {item.formattedMc}
              </Typography>
            </Row>
          ))}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductSales;
