import { Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'store/selector';
import { CommercialDashboard } from 'types/commercialDashboard';

type CommercialDashboardListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
  selectedOrder: CommercialDashboard | null;
  setCustomerContacted: Dispatch<SetStateAction<boolean>>;
};

const CommercialDashboardListMenu: React.FC<CommercialDashboardListMenuProps> = ({
  anchorEl,
  setAnchorEl,
  selectedOrder,
  setCustomerContacted,
}) => {
  const user = useSelector(state => state.user);
  function handleClick() {
    setAnchorEl(null);
    window.open(
      `https://api.grupoh2i.com.br/api/dav?order_id=${selectedOrder?.id}&branch_id=${selectedOrder?.id_filial}`,
    );
  }

  function handleClickWhatsapp() {
    const message = `https://wa.me/55${selectedOrder?.telefone.replace(/\D/g, '')}?text=Oi%2C%20${
      selectedOrder?.cliente
    }.%0A%0AAqui%20%C3%A9%20o%20${
      user?.name
    }%20da%20H2i.%20Notei%20que%20voc%C3%AA%20fez%20um%20or%C3%A7amento%20conosco%20recentemente%20e%20gostaria%20de%20saber%20como%20podemos%20ajudar%20a%20finalizar%20seu%20pedido.%20Existe%20algo%20mais%20que%20voc%C3%AA%20precise%20ou%20alguma%20d%C3%BAvida%20que%20eu%20possa%20esclarecer%3F%0A%0AFico%20%C3%A0%20disposi%C3%A7%C3%A3o%20para%20te%20ajudar%20no%20que%20for%20necess%C3%A1rio.%0A%0AAbra%C3%A7o%2C%0A${
      user?.name
    }%0AH2i`;

    window.open(message);
  }

  function handleClickCustomer() {
    setAnchorEl(null);

    setCustomerContacted(true);
  }

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleClick}>Imprimir</MenuItem>

      <MenuItem onClick={handleClickWhatsapp}>WhatsApp</MenuItem>

      <MenuItem onClick={handleClickCustomer}>Contatado</MenuItem>
    </Menu>
  );
};

export default CommercialDashboardListMenu;
