import { MenuItem, styled, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { UpdatePriceProctectionFormProps } from './UpdatePriceProtectionModal';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface Props {
  form: UpdatePriceProctectionFormProps;
  setForm: Dispatch<SetStateAction<UpdatePriceProctectionFormProps>>;
}

const UpdatePriceProtectionForm: React.FC<Props> = ({ form, setForm }) => {
  function handleChange(value: string, key: keyof UpdatePriceProctectionFormProps) {
    setForm(state => ({ ...state, [key]: value }));
  }

  return (
    <Container>
      <TextField
        autoFocus
        label="Valor total a receber (R$)"
        placeholder="Valor total a receber"
        margin="normal"
        type="number"
        value={form.valor || ''}
        disabled
      />

      <TextField
        select
        label="Pago"
        placeholder="Pago"
        margin="normal"
        type="number"
        value={form.pago}
        onChange={e => handleChange(e.target.value, 'pago')}
      >
        <MenuItem value="S">Sim</MenuItem>
        <MenuItem value="N">Não</MenuItem>
      </TextField>

      <TextField
        label="Número título"
        placeholder="Número título"
        margin="normal"
        value={form.numero_titulo}
        onChange={e => handleChange(e.target.value, 'numero_titulo')}
        fullWidth
      />

      <TextField
        rows={4}
        multiline
        label="Observação"
        placeholder="Observação"
        margin="normal"
        type="number"
        value={form.obs}
        onChange={e => handleChange(e.target.value, 'obs')}
        fullWidth
      />
    </Container>
  );
};

export default UpdatePriceProtectionForm;
