import React from 'react';
import { Typography, ListItem, styled } from '@mui/material';
import { Email } from 'types/email';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 60,
});

type ExportEmailItemModuleProps = {
  email: Email;
};

const ExportEmailItemModule: React.FC<ExportEmailItemModuleProps> = ({ email }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">{email.nome}</Typography>
      <Typography variant="body2" color="textSecondary">
        {email.email}
      </Typography>
    </ListItemStyled>
  );
};

export default ExportEmailItemModule;
