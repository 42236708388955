import { useState } from 'react';
import { useApp } from 'hooks/app';
import { StockBalanceData } from 'types/reports/stockBalance';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { StockBalanceTotalProps } from 'pages/reports/stock/stock-balance/list/hooks/useStockBalance';
import { StockFilter } from 'types/reports/stockFilter';

type UseFetchWasteStock = {
  stockBalance: StockBalanceData[];
  formattedTotal: StockBalanceTotalProps;
  fetchStockBalance(filter: StockFilter): void;
  loading: boolean;
};

export function useFetchWasteStock(): UseFetchWasteStock {
  const { h2iApi } = useApp();
  const [loading, setLoading] = useState(false);
  const [formattedTotal, setFormattedTotal] = useState<StockBalanceTotalProps>({
    coustTotal: 0,
    coustStoppedStock: 0,
    order: 0,
    reserve: 0,
    stock: 0,
    stoppedStock: 0,
    forecast: 0,
    index: 0,
  });
  const [stockBalance, setStockBalance] = useState<StockBalanceData[]>([]);

  function fetchStockBalance(filter: StockFilter) {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/saldoestoque`, {
        params: {
          reee: 'S',
          id_filial: filter.branchId.join(','),
          id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
          filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
          id_categoria: filter.categoryId.join(','),
          id_subcategoria: filter.subcategoryId.join(','),
          id_segmento: filter.segmentId.join(','),
          id_unidade: filter.unityId.join(','),
          id_fabricante: filter.manufacturerId.join(','),
          id_subgrupo: filter.subgroupId.join(','),
          responsavel: filter.made_by,
        },
      })
      .then(response => {
        if (response.data[0].result) {
          setFormattedTotal(item => ({
            ...item,
            coustTotal: 0,
            index: 0,
          }));
          setStockBalance([]);
          return;
        }

        const coustTotal = response.data.reduce((sum, item) => sum + item.CustoTotal, 0);
        const index = response.data.reduce((sum, item) => sum + item.indice, 0);
        const stock = response.data.reduce((sum, item) => sum + item.EstTotal, 0);
        const forecast = response.data.reduce((sum, item) => sum + item.forecast, 0);
        const reserve = response.data.reduce((sum, item) => sum + item.Reserva, 0);
        setFormattedTotal(item => ({
          ...item,
          coustTotal,
          index,
          forecast,
          reserve,
          stock,
        }));

        const _data: StockBalanceData[] = response.data.map((item: StockBalanceData) => {
          item.formattedEstoque = numberFormat(item.Estoque);
          item.formattedCusto = moneyFormat(item.Custo);
          item.formattedCustoTotal = moneyFormat(item.CustoTotal);
          item.formattedValueReserve = moneyFormat(item.valor_reserva);
          item.formattedValueOrder = moneyFormat(item.valor_pedido);
          item.formattedIndex = numberFormat(item.indice);
          return item;
        });

        setStockBalance(_data);
      })
      .finally(() => setLoading(false));
  }

  return { stockBalance, formattedTotal, fetchStockBalance, loading };
}
