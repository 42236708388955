import React from 'react';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { Typography, darken, styled } from '@mui/material';
import { StockBalanceTotalProps } from './stock-balance/list/hooks/useStockBalance';
import { LineAxis } from '@mui/icons-material';
import StockBalanceTotalLoading from './loading/StockBalanceTotalLoading';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  marginBottom: 10,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .third': {
    background: '#eeeff8',
    color: darken('#eeeff8', 0.5),
  },
  '& .fourth': {
    background: '#f8eef3',
    color: darken('#f8eef3', 0.5),
  },
  '& .fifth': {
    background: '#e3e3e3',
    color: darken('#e3e3e3', 0.5),
  },
  '& .sixth': {
    background: '#ffc8c8',
    color: darken('#ffc8c8', 0.5),
  },
  '& .seventh': {
    background: '#898fbd',
    color: darken('#898fbd', 0.5),
  },
  '& .eighth': {
    background: '#d0f7f7',
    color: darken('#d0f7f7', 0.5),
  },
  '& .nine': {
    background: '#bce9d6',
    color: darken('#bce9d6', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface ReportStockBalanceTotalProps {
  formattedTotal: StockBalanceTotalProps;
  loading: boolean;
}

const StockBalanceTotal: React.FC<ReportStockBalanceTotalProps> = ({ formattedTotal, loading }) => {
  return loading ? (
    <StockBalanceTotalLoading />
  ) : (
    <Container>
      <Box>
        <Typography className="title first" variant="body2" fontSize={18}>
          Custo Total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.coustTotal)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Custo Sem Movimentação
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.coustStoppedStock)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Valor Reserva
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.reserve)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fourth" variant="body2" fontSize={18}>
          Pedidos em transferência
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {moneyFormat(formattedTotal.order)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Estoque
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.stock}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title sixth" variant="body2" fontSize={18}>
          Estoque Sem Movimentação
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.stoppedStock}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title seventh" variant="body2" fontSize={18}>
          Forecast
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.forecast}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title eighth" variant="body2" fontSize={18}>
          IGE
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {numberFormat(formattedTotal.index)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default StockBalanceTotal;
