import React from 'react';
import List from 'components/list/List';
import { Reserve } from 'types/reserve';
import ReserveAnalysisItemModule from './ReserveAnalysisItemModule';
import { usePagination } from 'hooks/pagination';

type ReserveAnalysisListModuleProps = {
  reserves: Reserve[];
};

const ReserveAnalysisListModule: React.FC<ReserveAnalysisListModuleProps> = ({ reserves }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <List>
        {reserves.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((reserve, index) => (
          <ReserveAnalysisItemModule key={index} reserve={reserve} />
        ))}
      </List>
    </>
  );
};

export default ReserveAnalysisListModule;
