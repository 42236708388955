import React, { useEffect, useState } from 'react';
import { AgroupedSuppliersNotesEntry } from '../hooks/useFetchNoteEntryDashboard';
import { styled, Typography } from '@mui/material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import useTableOrder from 'hooks/tableOrder';

interface NoteEntryDashboardSuppliersProps {
  suppliers: AgroupedSuppliersNotesEntry[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '40vh',
  minHeight: '10vh',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    gridColumn: '1/3',
    gridRow: '2/3',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#fdf2cd',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    padding: '5px 0',
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  padding: '2px 0',
  gridTemplateColumns: '1fr 100px 100px',
  '& .supplier-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')({
  display: 'grid',
});

const NoteEntryDashboardSuppliers: React.FC<NoteEntryDashboardSuppliersProps> = ({ suppliers }) => {
  const { rowsPerPage, page } = usePagination();
  const [filtered, setFiltered] = useState<AgroupedSuppliersNotesEntry[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(suppliers);
  }, [suppliers]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Por fornecedor</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('fornecedor')} variant="caption" fontWeight={600}>
              Fornecedor
            </Typography>
            <Typography onClick={() => handleSort('percentual')} align="right" variant="caption" fontWeight={600}>
              Percentual
            </Typography>
            <Typography onClick={() => handleSort('valor_total')} align="right" variant="caption" fontWeight={600}>
              Valor Total
            </Typography>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((supplier, index) => (
            <Row className="item" key={index}>
              <Typography variant="caption">{supplier.fornecedor}</Typography>
              <Typography align="right" variant="caption">
                {supplier.formattedPercentual}
              </Typography>
              <Typography align="right" variant="caption">
                {supplier.formattedTotal}
              </Typography>
            </Row>
          ))}
        </List>
        <Pagination count={suppliers.length} />
      </Content>
    </Container>
  );
};

export default NoteEntryDashboardSuppliers;
