import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { Typography, styled } from '@mui/material';
import { CommercialDashboardTotal } from 'types/commercialDashboard';

const NumericData = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

type CommercialDashboardItemTableProps = {
  order: CommercialDashboardTotal;
};

const CommercialDashboardItemTable: React.FC<CommercialDashboardItemTableProps> = ({ order }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <NumericData key={item.id} className={item.dataType === 'number' ? 'numericData' : undefined}>
            <Typography style={{ fontSize: 11 }} variant="body2">
              {order[item.id as keyof CommercialDashboardTotal]}
            </Typography>
          </NumericData>
        ))}
    </>
  );
};

export default CommercialDashboardItemTable;
