import React, { useState, useEffect } from 'react';
import useTableOrder from 'hooks/tableOrder';
import ProductList from '../product/table/ProductList';
import { StockBalanceData } from 'types/reports/stockBalance';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { styled } from '@mui/material';
import { useStockBalance } from '../hooks/useStockBalance';
import { useApp } from 'hooks/app';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/no-data/NoData';

interface UnityMoreMenuProps {
  unityId: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    margin: 0,
    minHeight: 0,
    overflow: 'visible',
  },
});

const UnityMoreMenu: React.FC<UnityMoreMenuProps> = ({ unityId }) => {
  const { h2iApi } = useApp();
  const { filter } = useStockBalance();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<StockBalanceData[]>([]);

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/saldoestoque`, {
        params: {
          id_filial: filter.branchId.join(',') || '',
          id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
          filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
          id_categoria: filter.categoryId.join(',') || '',
          id_subcategoria: filter.subcategoryId.join(',') || '',
          id_segmento: filter.segmentId.join(',') || '',
          id_unidade: unityId,
          sem_movimentacao: '',
          id_fabricante: filter.manufacturerId.join(','),
          id_subgrupo: filter.subgroupId.join(','),
          responsavel: filter.made_by,
        },
      })
      .then(response => {
        if (response.data[0].result) {
          setFiltered([]);
          return;
        }

        const _data = response.data.map((item: StockBalanceData) => {
          item.formattedEstoque = numberFormat(item.Estoque);
          item.formattedCusto = moneyFormat(item.Custo);
          item.formattedCustoTotal = moneyFormat(item.CustoTotal);
          item.formattedValueReserve = moneyFormat(item.valor_reserva);
          item.formattedValueOrder = moneyFormat(item.valor_pedido);
          item.formattedIndex = numberFormat(item.indice);
          return item;
        });
        setFiltered(_data);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, unityId]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  return (
    <Container>
      {loading ? (
        <InsideLoading />
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum produto encontrado." />
      ) : (
        <ProductList isSubgroup={true} products={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
      )}
    </Container>
  );
};

export default UnityMoreMenu;
