import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, ListItemButton, styled, IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { CommercialDashboard } from 'types/commercialDashboard';

const ListItemButtonStyled = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Flex = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type CommercialDashboardItemModuleProps = {
  order: CommercialDashboard;
  setSelectedOrder: Dispatch<SetStateAction<CommercialDashboard | null>>;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>;
};

const CommercialDashboardItemModule: React.FC<CommercialDashboardItemModuleProps> = ({
  order,
  setSelectedOrder,
  setAnchorEl,
}) => {
  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    setSelectedOrder(order);
  }

  return (
    <ListItemButtonStyled>
      <Flex className="bottom">
        <Typography variant="caption">
          <b>Data:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.Data}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Cliente:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.cliente}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>CNPJ:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.Cnpj}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Pedido por:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.Pedido_por}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Total:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.formattedTotal}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>E-mail:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.email}
        </Typography>
      </Flex>

      <Flex className="bottom">
        <Typography variant="caption">
          <b>Telefone:</b>
        </Typography>

        <Typography align="left" variant="caption">
          {order.telefone}
        </Typography>
      </Flex>

      <IconButton size="small" onClick={e => handleMoreClick(e)}>
        <MoreHoriz />
      </IconButton>
    </ListItemButtonStyled>
  );
};

export default CommercialDashboardItemModule;
