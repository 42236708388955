import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, styled, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

interface MonthlyFilterBoxProps {
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
}

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '500px 100px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    '& > button': {
      width: 100,
      justifySelf: 'end',
    },
  },
}));

const MonthlyFilterBox: React.FC<MonthlyFilterBoxProps> = ({
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <TextField
          label="Pesquisar por produto"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default MonthlyFilterBox;
