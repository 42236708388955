import { TableTemplate } from 'types/tableTemplate';

export const rmaTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 40,
  },
  {
    id: 'id',
    description: 'OS',
    originalId: 'id',
    width: 70,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 100,
  },
  {
    id: 'comprador',
    description: 'Comprador',
    originalId: 'comprador',
    width: 100,
  },
  {
    id: 'cliente',
    description: 'Cliente',
    originalId: 'cliente',
    width: 220,
  },
  {
    id: 'id_produto',
    description: 'Cod Prod',
    originalId: 'id_produto',
    width: 85,
  },
  {
    id: 'produto',
    description: 'Produto',
    originalId: 'produto',
    width: 220,
  },
  {
    id: 'data',
    description: 'Data',
    originalId: 'formattedDate',
    width: 100,
  },
  {
    id: 'qtd',
    description: 'Qtd',
    originalId: 'formattedQuantity',
    width: 60,
  },
  {
    id: 'formattedValue',
    description: 'Valor',
    originalId: 'valor',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'situacao',
    description: 'Situação',
    originalId: 'situacao',
    width: 80,
  },
  {
    id: 'troca_24',
    description: 'Troca 24h',
    originalId: 'troca_24',
    width: 110,
  },
  {
    id: 'vale_usado',
    description: 'Vale Usado',
    originalId: 'vale_usado',
    width: 110,
  },
];
