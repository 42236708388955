import Dialog from 'components/dialogs/Dialog';
import React, { useState } from 'react';
import UpdatePriceProtectionForm from './UpdatePriceProtectionForm';
import { usePriceProtection } from '../hooks/usePriceProtection';
import UpdatePriceProtectionActions from './UpdatePriceProtectionActions';
import { useApp } from 'hooks/app';
import InsideSaving from 'components/loading/InsideSaving';

interface Props {
  onExited(): void;
  onSave(): void;
}

export type UpdatePriceProctectionFormProps = {
  valor: number;
  pago: 'N' | 'S';
  numero_titulo: string;
  obs: string;
  id: number;
};

const UpdatePriceProtectionModal: React.FC<Props> = ({ onExited, onSave }) => {
  const { selected } = usePriceProtection();
  const { h2iApi } = useApp();
  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState<UpdatePriceProctectionFormProps>({
    valor: selected?.valor || 0,
    pago: selected?.pago || 'N',
    numero_titulo: selected?.numero_titulo || '',
    obs: selected?.obs || '',
    id: selected?.id || 0,
  });

  function handleSubmit() {
    if (!h2iApi) {
      return;
    }

    setSaving(true);

    h2iApi
      .post('/api/updatepriceprotection', {
        ...form,
      })
      .then(() => {
        setSaving(false);
        onSave();
      })
      .catch(err => {
        console.error(err);
        setSaving(false);
      });
  }

  return (
    <Dialog
      ComponentActions={<UpdatePriceProtectionActions onClick={handleSubmit} />}
      maxWidth="sm"
      height="80vh"
      onExited={onExited}
      title="Atualizar price protection"
    >
      {saving && <InsideSaving />}
      <UpdatePriceProtectionForm form={form} setForm={setForm} />
    </Dialog>
  );
};

export default UpdatePriceProtectionModal;
