import React, { useState, useEffect } from 'react';
import { useSelector } from 'store/selector';
import { styled } from '@mui/material';
import { H2iOrder } from 'types/h2iOrder';
import Appbar from 'components/appbar/Appbar';
import TableContainer from 'components/table/TableContainer';
import { davReviewTableTemplate } from './template/davReviewTableTemplate';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import Pagination from 'components/pagination/Pagination';
import DavReviewListTable from './list/table/DavReviewListTable';
import DavReviewListModule from './list/module/DavReviewListModule';
import DavReviewFilterBox from './DavReviewFilterBox';
import { useFetchDavReview } from './hook/useFetchDavReview';
import { subDays } from 'date-fns';
import { useDisplayMode } from 'hooks/useDisplayMode';
import NoData from 'components/no-data/NoData';
import InsideLoading from 'components/loading/InsideLoading';
import { DavReviewProvider } from './hook/useDavReview';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export interface DavReviewPaymentParams {
  installmentQuantity: number;
  discountValue: string;
  methodPayment: number;
}

export interface DavReviewFilterProps {
  davName: string;
  davNumber: number;
  initial_date: Date;
  final_date: Date;
  branch_id: number;
}

const DavReviewPage: React.FC = () => {
  const [selectedDav, setSelectedDav] = useState<H2iOrder | null>(null);
  const user = useSelector(state => state.user);
  const [filter, setFilter] = useState<DavReviewFilterProps>({
    davName: '',
    davNumber: 0,
    initial_date: subDays(new Date(), 7),
    final_date: new Date(),
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999,
  });
  const { handleSearch, loading, orders } = useFetchDavReview(filter);
  const [orderedIndex, sort] = useTableOrder();
  const [displayMode] = useDisplayMode();
  const [filtered, setFiltered] = useState<H2iOrder[]>([]);

  useEffect(() => {
    setFiltered(orders);
  }, [orders]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChangeFilter(index: keyof DavReviewFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <DavReviewProvider value={{ setSelectedDav, selectedDav }}>
      <Appbar title="DAV Review" />

      <DavReviewFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        handleSearch={handleSearch}
        loading={loading}
      />

      {loading ? (
        <InsideLoading />
      ) : filtered.length > 0 ? (
        <TableContainer tableTemplate={davReviewTableTemplate}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <DavReviewListTable orders={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <DavReviewListModule orders={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        </TableContainer>
      ) : (
        <NoData message="Nenhum pedido para mostrar" />
      )}
    </DavReviewProvider>
  );
};

export default DavReviewPage;
