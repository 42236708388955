import React from 'react';
import List from 'components/list/List';
import RmaItemModule from './RmaItemModule';
import { Rma } from 'types/rma';

type RmaListModuleProps = {
  items: Rma[];
};

const RmaListModule: React.FC<RmaListModuleProps> = ({ items }) => {
  return (
    <>
      <List>
        {items.map((item, index) => (
          <RmaItemModule key={index} item={item} />
        ))}
      </List>
    </>
  );
};

export default RmaListModule;
