import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { Button, IconButton, MenuItem, TextField, Tooltip, styled } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import { FaFileExcel } from 'react-icons/fa';
import { FilterList, Print, Search } from '@mui/icons-material';
import { StockFilter } from 'types/reports/stockFilter';
import { usePermissionRules } from 'hooks/permissionRules';
import SelectMultiBranches from 'components/select-branches/SelectMultiBranches';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.7fr 1fr 1fr 100px 120px',
  columnGap: 10,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 15,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 15,
  },
  '& > .search-params': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface StockBalanceFilterProps {
  onSearch(filter: StockFilter): void;
  handleChange(index: keyof StockFilter, value: any): void;
  displayMode: 'list' | 'module';
  openReport(): void;
  openMoreFilter(): void;
  filter: StockFilter;
  setExcelExport: Dispatch<SetStateAction<boolean>>;
}

const StockBalanceFilter: React.FC<StockBalanceFilterProps> = ({
  handleChange,
  onSearch,
  openReport,
  openMoreFilter,
  setExcelExport,
  filter,
}) => {
  const { checkPermission } = usePermissionRules();

  function handleValidation(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={handleValidation}>
        <SelectMultiBranches branchIds={filter.branchId} handleChange={e => handleChange('branchId', e)} />

        <TextField
          value={filter.indexToSearch}
          onChange={e => handleChange('indexToSearch', e.target.value)}
          select
          label="Pesquisar em"
        >
          <MenuItem value="Produto">Descrição</MenuItem>
          <MenuItem value="CodProd">ID</MenuItem>
        </TextField>
        <TextField
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.searchValue}
          onChange={e => handleChange('searchValue', e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>

        <div className="search-params">
          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <IconButton color="inherit" onClick={() => setExcelExport(true)}>
                <FaFileExcel />
              </IconButton>
            </Tooltip>
          )}
          <div>
            <Tooltip title="Imprimir">
              <IconButton color="inherit" onClick={openReport}>
                <Print />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Outros filtros">
              <IconButton color="inherit" onClick={openMoreFilter}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Filter>
    </FilterBox>
  );
};

export default StockBalanceFilter;
