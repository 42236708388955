import { FilterSidebar } from 'components/sidebar-filters/hooks/useSidebarFilters';
import { useApp } from 'hooks/app';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useEffect, useState } from 'react';

type UseFetchSidebarFilters = [FilterSidebar[], boolean];

export function useFetchSidebarFilters(params?: any): UseFetchSidebarFilters {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterSidebar[]>([]);
  const { h2iApi } = useApp();
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    const fetchFilters = h2iApi.get('/api/getrelatoriofiltros', { params });

    Promise.all([fetchFilters])
      .then(([filtersResponse]) => {
        const data: FilterSidebar[] = filtersResponse.data;
        if (filtersResponse.data.MESSAGE) {
          return;
        }
        setFilters(
          data.map(item => ({
            ...item,
            opcoes: item.opcoes.map(option => ({
              ...option,
              id: option.id ? option.id.toString() : option.nome,
            })),
          })),
        );
      })
      .catch(([buyersError, filtersError]) =>
        showErrorDialog({
          error: `${buyersError} ${filtersError}`,
          message: 'Não foi possível carregar os filtros',
        }),
      )
      .finally(() => setLoading(false));
  }, [showErrorDialog, h2iApi, params]);

  return [filters, loading];
}
