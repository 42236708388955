import React from 'react';
import CommercialDashboardItemModule from './CommercialDashboardItemModule';
import { CommercialDashboardTotal } from 'types/commercialDashboard';
import { styled } from '@mui/material';
import { usePagination } from 'hooks/pagination';

type CommercialDashboardListModuleProps = {
  orders: CommercialDashboardTotal[];
};

const List = styled('div')({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr',
});

const CommercialDashboardListModule: React.FC<CommercialDashboardListModuleProps> = ({ orders }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <List>
        {orders
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .sort((a, b) => (a.valor_total < b.valor_total ? 1 : a.valor_total > b.valor_total ? -1 : 0))
          .map(order => (
            <CommercialDashboardItemModule key={order.Pedido_por} order={order} />
          ))}
      </List>
    </>
  );
};

export default CommercialDashboardListModule;
