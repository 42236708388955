import React, { Dispatch, SetStateAction } from 'react';
import { Button, styled, TextField } from '@mui/material';
import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Search } from '@mui/icons-material';

interface ReprintSaleFilterBoxProps {
  branchId: number;
  setBranchId: Dispatch<SetStateAction<number>>;
  setSaleId: Dispatch<SetStateAction<number | null>>;
  saleId: number | null;
  handleSearchSubmit(): void;
}

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '180px 200px 100px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    '& > button': {
      width: 100,
      justifySelf: 'end',
    },
  },
}));

const ReprintSaleFilterBox: React.FC<ReprintSaleFilterBoxProps> = ({
  branchId,
  saleId,
  setBranchId,
  setSaleId,
  handleSearchSubmit,
}) => {
  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch branch_id={branchId} handleChange={e => setBranchId(e)} />

        <TextField
          label="Número da venda"
          placeholder="Digite o nº da venda"
          autoFocus
          value={saleId}
          onChange={e => setSaleId(parseInt(e.target.value))}
          inputProps={{
            inputMode: 'numeric',
            step: 1,
          }}
          type="number"
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default ReprintSaleFilterBox;
