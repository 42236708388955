import { TableTemplate } from 'types/tableTemplate';

export const noteEntryTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 40,
    notFilterable: true,
  },
  {
    id: 'pdf',
    description: '',
    originalId: 'pdf',
    width: 40,
    notFilterable: true,
  },
  {
    id: 'filial',
    description: 'Filial',
    originalId: 'filial',
    width: 100,
  },
  {
    id: 'cnpj',
    description: 'CNPJ',
    originalId: 'cnpj',
    width: 130,
  },
  {
    id: 'fornecedor',
    description: 'Fornecedor',
    originalId: 'fornecedor',
    width: 260,
  },
  {
    id: 'nf',
    description: 'NF',
    originalId: 'nf',
    width: 65,
  },
  {
    id: 'chave',
    description: 'Chave',
    originalId: 'chave',
    width: 60,
  },
  {
    id: 'cfop',
    description: 'CFOP',
    originalId: 'cfop',
    width: 60,
  },
  {
    id: 'data_entrada',
    description: 'Data Entrada',
    originalId: 'data_entrada',
    width: 110,
  },
  {
    id: 'formattedTotal',
    description: 'Vl Total',
    originalId: 'valor_total',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedDiscount',
    description: 'Vl Desconto',
    originalId: 'valor_desconto',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedAddition',
    description: 'Vl Acréscimo',
    originalId: 'valor_acrescimo',
    width: 110,
    dataType: 'number',
  },
  {
    id: 'formattedIpi',
    description: 'Vl IPI',
    originalId: 'valor_ipi',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedShipment',
    description: 'Vl Frete',
    originalId: 'valor_frete',
    width: 100,
    dataType: 'number',
  },
];
