import { Box, Skeleton } from '@mui/material';
import React from 'react';

const DailySummaryLoading: React.FC = () => {
  return (
    <Box display={'flex'} gap={1} maxWidth={600} flexDirection={'column'}>
      <Box display={'flex'} gap={1}>
        <Skeleton variant="text" width={'100%'} height={42} />
        <Skeleton variant="text" width={'100%'} height={42} />
      </Box>

      <Skeleton variant="rectangular" width={'100%'} height={40} />
      <Skeleton variant="rectangular" width={'100%'} height={40} />
      <Skeleton variant="rectangular" width={'100%'} height={40} />
    </Box>
  );
};

export default DailySummaryLoading;
