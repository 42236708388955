import Appbar from 'components/appbar/Appbar';
import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import { styled } from '@mui/material';
import { useApp } from 'hooks/app';
import { ProductList } from 'types/product';
import ModuleProductList from './list/module/ProductList';
import TableProductList from './list/table/ProductList';
import useTableOrder from 'hooks/tableOrder';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/numberFormat';
import ImagePreview from 'components/image-preview/ImagePreview';
import { ProductContextProvider, ProductContextValue } from './productContext';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { useProductSearch } from 'providers/ProductSearchProvider';
import PageHeader from 'components/page-header/PageHeader';
import ProductsFilterBox from './ProductsFilterBox';
import NoData from 'components/no-data/NoData';
import { useDisplayMode } from 'hooks/useDisplayMode';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

let timer: any;

export type ProductsSearchParams = {
  ean: string;
  stock: boolean;
};

const Products: React.FC = () => {
  const { h2iApi } = useApp();
  const [searchParams, setSearchParams] = useState<ProductsSearchParams>({
    ean: '',
    stock: false,
  });
  const [displayMode] = useDisplayMode();
  const [products, setProducts] = useState<ProductList[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductList[]>([]);
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductList | null>(null);
  const { search, setSearch } = useProductSearch();

  const contextValue: ProductContextValue = {
    handleSelectProduct: value => setSelectedProduct(value),
    openImagePreview: () => setImagePreview(true),
  };

  const handleSearch = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();

      if (!h2iApi) return;

      setLoading(true);

      h2iApi
        .get(`/api/produtoTodos?produto=${search.toUpperCase()}${searchParams.stock ? '&ESTOQUE=1' : '&ESTOQUE=0'}`)
        .then(response => {
          const p = response.data.map((item: ProductList) => {
            item.formattedCusto = moneyFormat(item.custo);
            item.formattedMC = numberFormat(item.mc);
            item.formattedPercentualMC = percentFormat(item['mc%']);
            item.formattedMargem = percentFormat(item.margem);
            item.formattedP2PJ = numberFormat(item.p2pj);
            item.formattedP2BR = numberFormat(item.p2br);
            item.formattedPromoPJ = item.promopj ? 'Sim' : 'Não';
            item.formattedDestaque = item.destaque ? 'Sim' : 'Não';
            item.formattedPmc = numberFormat(item.pmc);
            item.formattedPmd = numberFormat(item.pmd);
            item.formattedPp = numberFormat(item.pp);
            return item;
          });

          setProducts(p);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [h2iApi, search, searchParams.stock],
  );

  useEffect(() => {
    clearTimeout(timer);

    if (search.length <= 2) return;

    timer = setTimeout(handleSearch, 500);
  }, [search, handleSearch]);

  useEffect(() => setFilteredProducts(products), [products]);

  function handleSetSearchParams(index: string, value: any) {
    setSearchParams({
      ...searchParams,
      [index]: value,
    });
  }

  function handleSort(index: string) {
    const p = sort(index, filteredProducts);
    setFilteredProducts(p);
  }

  return (
    <>
      {imagePreview && selectedProduct && (
        <ImagePreview
          src={selectedProduct.foto_principal}
          description={selectedProduct.produto}
          onExited={() => setImagePreview(false)}
        />
      )}
      <Appbar title="Cadastros" />
      <PageHeader title="Produtos" description="Gerencie os produtos" />

      <ProductsFilterBox
        handleSearch={handleSearch}
        handleSetSearchParams={handleSetSearchParams}
        loading={loading}
        search={search}
        searchParams={searchParams}
        setSearch={setSearch}
      />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredProducts.length === 0 ? (
        <NoData message="Nenhum produto para mostrar" />
      ) : (
        <ProductContextProvider value={contextValue}>
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <TableProductList products={filteredProducts} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <ModuleProductList products={filteredProducts} />
              )}
              <Pagination count={filteredProducts.length} />
            </Container>
          </PaginationProvider>
        </ProductContextProvider>
      )}
    </>
  );
};

export default Products;
