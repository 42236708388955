import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';

const Container = styled('div')({
  backgroundColor: '#fff',
  marginBottom: 10,
});

export type NoteEntryTabsOptions = 'notes' | 'dashboard';

type NoteEntryTabsProps = {
  handleChange(value: NoteEntryTabsOptions): void;
  tab: NoteEntryTabsOptions;
};

const NoteEntryTabs: React.FC<NoteEntryTabsProps> = ({ tab, handleChange }) => {
  return (
    <Container>
      <Tabs indicatorColor="primary" value={tab} onChange={(e, value) => handleChange(value)} variant="scrollable">
        <Tab label="Visão geral" value="dashboard" />
        <Tab label="Relação de notas" value="notes" />
      </Tabs>
    </Container>
  );
};

export default NoteEntryTabs;
