import { FormEvent, useCallback, useState } from 'react';
import { subDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Vacancy } from 'types/vacancy';
import { api } from 'services/api';
import { Paginated } from 'types/paginated';
import { moneyFormat } from 'helpers/numberFormat';

export interface VacancyQueryParams {
  initial_date: Date;
  final_date: Date;
  rows: number;
  page: number;
  role: string;
  is_active: boolean;
}

const queryParamsInitialValue: VacancyQueryParams = {
  final_date: new Date(),
  initial_date: subDays(new Date(), 30),
  page: 0,
  rows: 20,
  role: '',
  is_active: true,
};

export function useFetchVacancy() {
  const [queryParams, setQueryParams] = useState<VacancyQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [total, setTotal] = useState(0);

  const fetch = useCallback((queryParams: VacancyQueryParams, e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);

    api
      .get<Paginated<Vacancy[]>>('/jobs', {
        params: { ...queryParams, page: queryParams.page + 1, is_active: queryParams.is_active ? 1 : 0 },
      })
      .then(response => {
        setTotal(response.data.total);

        setVacancies(
          response.data.items.map((item: Vacancy) => ({
            ...item,
            formattedCreatedAt: format(new Date(item.created_at), 'P', { locale: ptBR }),
            formattedSalary: moneyFormat(item.salary),
            username: item.user.name,
          })),
        );
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleChangeQueryParams(index: keyof VacancyQueryParams, value: any) {
    const query = {
      ...queryParams,
      [index]: value,
    };

    setQueryParams(queryParams => ({
      ...queryParams,
      [index]: value,
    }));

    if (index === 'page' || index === 'rows') {
      fetch(query);
    }
  }

  return {
    fetch,
    loading,
    queryParams,
    handleChangeQueryParams,
    vacancies,
    total,
  };
}
