import React from 'react';
import { AbcProduct } from 'types/abcProduct';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  '& > .content': {
    margin: '10px 0',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

type AbcProductItemModuleProps = {
  product: AbcProduct;
};

const AbcProductItemModule: React.FC<AbcProductItemModuleProps> = ({ product }) => {
  return (
    <ListItemStyled>
      <Typography align="left" variant="body1">
        {product.produto}
      </Typography>

      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Quantidade
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.qtd}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Unitário
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedUnitary}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedTotal}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Spiff
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedSpiff}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          MC
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedMc}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Custo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {product.formattedCoust}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default AbcProductItemModule;
