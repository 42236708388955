import { Button, Icon, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Replacement } from 'types/replacement';
import { ArrowDropDown, Clear, StackedBarChart, TaskAlt } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import TopShoppingSuggestionAggregated from 'pages/purchase/order/list/shopping-suggestion/TopShoppingSuggestionAggregated';
import { AggregatedProduct } from 'pages/purchase/order/list/shopping-suggestion/ShoppingSuggestion';
import { AgroupedSubgroupReplacement } from './hooks/useFetchAnalysisReplacementSubgroup';
import { numberFormat } from 'helpers/numberFormat';

interface Props {
  replacements: Replacement[];
  subgroupsOpen: number[];
  onClickButton: (index: number) => void;
  filteredSubgroups: AgroupedSubgroupReplacement[];
  handleSort(index: keyof AgroupedSubgroupReplacement): void;
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
  gridColumn: '1/4',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1/3',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1/2',
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#eeeff8',
  padding: 10,
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 10px',
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
      paddingRight: 5,
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  '& > span:not(:last-child)': {
    paddingRight: 5,
    borderRight: '1px solid #eee',
  },
  '& > button': {
    padding: 0,
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  alignItems: 'center',
  gridTemplateColumns: '40px 220px 100px 100px 100px 90px 100px 100px 100px 100px 90px',
  '& .subgroup-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '&.open': {
    transform: 'rotate(180deg)',
  },
  transition: 'transform 0.3s ease',
  color: theme.palette.primary.main,
}));

const List = styled('ul')({
  display: 'grid',
});

const AnalysisReplacementSubgroupMaster: React.FC<Props> = ({
  replacements,
  onClickButton,
  subgroupsOpen,
  filteredSubgroups,
  handleSort,
}) => {
  const [open, setOpen] = useState(false);
  const { rowsPerPage, page } = usePagination();

  const branches = useMemo(() => {
    return ['POC', 'JUA', ' FS', ' LF', 'VDC', 'BRA', 'ALA', 'PAF', 'SAJ', 'IRC'];
  }, []);

  const calculateAggregatedData = useCallback((): AggregatedProduct[] => {
    return Object.values(
      replacements.reduce((acc, item) => {
        const { id_produto, produto, filial } = item;

        const key = id_produto;

        if (!acc[key]) {
          acc[key] = {
            id_subgrupo: item.id_subgrupo.toString(),
            subgrupo: item.subgrupo,
            id_produto,
            produto,
            sugestao_total: 0,
            estoque_total: 0,
            forecast_total: 0,
            ...Object.fromEntries(branches.map(filial => [`est_${filial.toLowerCase()}`, 0])),
            ...Object.fromEntries(branches.map(filial => [`sug_${filial.toLowerCase()}`, 0])),
          };
        }

        acc[key].sugestao_total += Number(item.sugestao);
        acc[key].estoque_total += Number(item.estoque);
        acc[key].forecast_total += Number(item.forecast);
        acc[key][`est_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.estoque.toString());
        acc[key][`sug_${filial.slice(-3).toLowerCase()}`] = parseFloat(item.sugestao.toString());

        return acc;
      }, {} as { [key: string]: AggregatedProduct }),
    );
  }, [branches, replacements]);

  function handleClickButton() {
    setOpen(true);
  }

  return (
    <div>
      {open && <></>}
      <Button
        style={{ marginBottom: 10 }}
        startIcon={<StackedBarChart />}
        variant="contained"
        onClick={handleClickButton}
      >
        Montar reposição
      </Button>
      <Container>
        <Title>
          <Typography fontSize={13}>Por subgrupo</Typography>
        </Title>
        <Content>
          <List>
            <Row className="header">
              <Typography></Typography>

              <Typography onClick={() => handleSort('subgrupo')} variant="caption" fontWeight={600}>
                Subgrupo
              </Typography>
              <Typography onClick={() => handleSort('subgrupo')} variant="caption" fontWeight={600}>
                Comprador
              </Typography>
              <Typography align="right" onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
                Estoque Total
              </Typography>
              <Typography align="right" onClick={() => handleSort('pedidos')} variant="caption" fontWeight={600}>
                Pedidos
              </Typography>
              <Typography align="right" onClick={() => handleSort('reserva')} variant="caption" fontWeight={600}>
                Reserva
              </Typography>
              <Typography align="right" onClick={() => handleSort('forecast')} variant="caption" fontWeight={600}>
                Forecast Total
              </Typography>
              <Typography align="right" onClick={() => handleSort('sugestao')} variant="caption" fontWeight={600}>
                Sugestão Total
              </Typography>
              <Typography align="right" onClick={() => handleSort('dias_estoque')} variant="caption" fontWeight={600}>
                Dias Estoque
              </Typography>
              <Typography align="right" onClick={() => handleSort('investimento')} variant="caption" fontWeight={600}>
                Investimento
              </Typography>

              <Typography align="center" onClick={() => handleSort('visto')} variant="caption" fontWeight={600}>
                Visualizado
              </Typography>
            </Row>

            {filteredSubgroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subgroup, index) => (
              <List key={index}>
                <Row className="item">
                  <Tooltip title="Visualizar itens do subgrupo">
                    <CustomIconButton
                      onClick={() => onClickButton(index)}
                      className={subgroupsOpen.includes(index) ? 'open' : ''}
                      size="small"
                    >
                      <ArrowDropDown fontSize="small" />
                    </CustomIconButton>
                  </Tooltip>

                  <Typography className="subgroup-name" variant="caption">
                    {subgroup.subgrupo}
                  </Typography>

                  <Typography variant="caption">{subgroup.comprador}</Typography>

                  <Typography align="right" variant="caption">
                    {subgroup.estoque}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {subgroup.pedidos}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {subgroup.reserva}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {subgroup.forecast}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {subgroup.sugestao}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {numberFormat(subgroup.dias_estoque, 0)}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {subgroup.formattedInvestimento}
                  </Typography>
                  {subgroup.visto ? (
                    <Icon style={{ width: '100%' }} fontSize="small" color={'success'}>
                      <TaskAlt fontSize="small" />
                    </Icon>
                  ) : (
                    <Icon style={{ width: '100%' }} fontSize="small" color={'error'}>
                      <Clear fontSize="small" />
                    </Icon>
                  )}
                </Row>

                {subgroupsOpen.includes(index) && (
                  <TopShoppingSuggestionAggregated
                    setSelectedProduct={() => {}}
                    branches={branches}
                    data={calculateAggregatedData().filter(
                      _item => _item.id_subgrupo === subgroup.id_subgrupo.toString(),
                    )}
                  />
                )}
              </List>
            ))}
          </List>
        </Content>

        <Pagination count={filteredSubgroups.length} />
      </Container>
    </div>
  );
};

export default AnalysisReplacementSubgroupMaster;
