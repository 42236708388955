import { AddTask } from '@mui/icons-material';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { PurchaseOrderProduct } from 'types/purchaseOrder';
import PurchaseOrderSelectProduct from './PurchaseOrderSelectProduct';

interface PurchaseOrderListProductsProps {
  products: PurchaseOrderProductWithEan[];
  handleChange(productId: number, eans: string[]): void;
}

export interface PurchaseOrderProductWithEan extends PurchaseOrderProduct {
  eans: string[];
}

const List = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: 5,
  gridTemplateColumns: '120px 60px 1fr 60px 30px',
  borderBottom: '1px solid #eee',
  gap: 5,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '100px 50px 1fr 50px 30px',
  },
  '& > h6': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const CustomIconButton = styled(IconButton)({
  minHeight: 24,
  padding: 0,
});

const PurchaseOrderListProducts: React.FC<PurchaseOrderListProductsProps> = ({ products, handleChange }) => {
  const [selectedProduct, setSelectedProduct] = useState<PurchaseOrderProductWithEan | null>(null);

  return (
    <Container>
      {selectedProduct && (
        <PurchaseOrderSelectProduct
          handleChange={handleChange}
          onExited={() => setSelectedProduct(null)}
          selectedProduct={selectedProduct}
        />
      )}
      <List>
        <Typography align="left" variant="subtitle2" fontWeight={600} fontSize={13}>
          EAN
        </Typography>
        <Typography align="left" variant="subtitle2" fontWeight={600} fontSize={13}>
          COD
        </Typography>
        <Typography align="left" variant="subtitle2" fontWeight={600} fontSize={13}>
          Produto
        </Typography>
        <Typography align="left" variant="subtitle2" fontWeight={600} fontSize={13}>
          QTD
        </Typography>
      </List>
      {products.map(item => (
        <List key={item.codigo_produto}>
          <Typography align="left" variant="subtitle2" fontSize={12}>
            {item.codigo_barra}
          </Typography>
          <Typography align="left" variant="subtitle2" fontSize={12}>
            {item.codigo_produto}
          </Typography>
          <Tooltip title={item.produto}>
            <Typography align="left" variant="subtitle2" fontSize={12}>
              {item.produto}
            </Typography>
          </Tooltip>
          <Typography align="left" variant="subtitle2" fontSize={12}>
            {item.quantidade}
          </Typography>
          <CustomIconButton onClick={() => setSelectedProduct(item)} size="small">
            <AddTask />
          </CustomIconButton>
        </List>
      ))}
    </Container>
  );
};

export default PurchaseOrderListProducts;
