import React from 'react';
import Appbar from 'components/appbar/Appbar';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useFetchViews } from './hooks/useFetchViews';
import { styled } from '@mui/material';
import { useAbcManufacturers } from './hooks/useAbcManufacturers';
import Pagination from 'components/pagination/Pagination';
import { AbcManufacturerProvider } from './hooks/useAbcManufacturer';
import AbcManufacturerFilterBox from './AbcManufacturerFilterBox';
import ManufacturerListTable from './manufacturer/list/table/ManufacturerListTable';
import ManufacturerListModule from './manufacturer/list/module/ManufacturerListModule';
import AbcManufacturerDateFilter from './AbcManufacturerDateFilter';
import { AbcManufacturersFilterParams } from './CurveAbcManufacturer';
import FilterMore from 'components/filter-more/FilterMore';
import AbcManufacturerExport from './export/AbcManufacturerExport';
import AbcManufacturerTotal from './total/AbcManufacturerTotal';
import InsideLoading from 'components/loading/InsideLoading';
import AbcManufacturerActions from './actions/AbcManufacturerActions';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface AbcManufacturerPageProps {
  filter: AbcManufacturersFilterParams;
  handleChangeFilter(index: keyof AbcManufacturersFilterParams, value: any): void;
}

const AbcManufacturerPage: React.FC<AbcManufacturerPageProps> = ({ filter, handleChangeFilter }) => {
  const {
    filteredManufacturers,
    handleSortManufacturer,
    displayMode,
    searchText,
    handleSearch,
    handleSearchInputChange,
    handleSearchSubmit,
    loading,
    orderedIndex,
    formattedTotal,
  } = useAbcManufacturers();

  const [
    handleCloseDialogDesktop,
    handleCloseDialog,
    dialogDate,
    excelExport,
    dialogDateDesktop,
    setExcelExport,
    setDialogDate,
    setDialogDateDesktop,
  ] = useFetchViews(handleSearch, searchText);

  return (
    <AbcManufacturerProvider value={{ filter, handleChangeFilter, searchValue: searchText }}>
      {dialogDate && (
        <AbcManufacturerDateFilter
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onSearch={handleCloseDialog}
          onExited={() => setDialogDate(false)}
        />
      )}

      {dialogDateDesktop && (
        <FilterMore
          onSearch={handleCloseDialogDesktop}
          filter={filter}
          handleChangeFilter={handleChangeFilter}
          onExited={() => setDialogDateDesktop(false)}
        />
      )}

      {excelExport && (
        <AbcManufacturerExport manufacturers={filteredManufacturers} onExited={() => setExcelExport(false)} />
      )}

      <Appbar
        title="Curva Abc de Fabricante"
        ActionsComponent={
          <AbcManufacturerActions
            handleOpenExcel={() => setExcelExport(true)}
            disabled={loading}
            handleOpenDialog={() => setDialogDate(true)}
          />
        }
      />

      <AbcManufacturerFilterBox
        filter={filter}
        disabled={filteredManufacturers.length === 0}
        handleOpenExcel={() => setExcelExport(true)}
        handleOpenDialogDesktop={() => setDialogDateDesktop(true)}
        searchText={searchText}
        handleSearchInputChange={handleSearchInputChange}
        handleChangeFilter={handleChangeFilter}
        handleSearchSubmit={handleSearchSubmit}
      />

      <AbcManufacturerTotal formattedTotal={formattedTotal} />

      {loading ? (
        <InsideLoading />
      ) : filteredManufacturers.length === 0 ? (
        <NoData message="Nada para mostrar" />
      ) : (
        <PaginationProvider>
          <Container>
            {filteredManufacturers.length === 0 ? (
              <NoData message="Nenhum fabricante encontrado." />
            ) : displayMode === 'list' ? (
              <ManufacturerListTable
                isSubgroup
                manufacturers={filteredManufacturers}
                handleSort={handleSortManufacturer}
                orderedIndex={orderedIndex}
              />
            ) : (
              <ManufacturerListModule manufacturers={filteredManufacturers} />
            )}
            <Pagination count={filteredManufacturers.length} />
          </Container>
        </PaginationProvider>
      )}
    </AbcManufacturerProvider>
  );
};

export default AbcManufacturerPage;
