import React, { useState } from 'react';
import List from 'components/list/List';
import ItemEntryItemModule from './ItemEntryItemModule';
import { ItemEntry } from 'types/itemEntry';
import ItemEntryListNotes from '../../dialog/ItemEntryListNotes';

type ItemEntryListModuleProps = {
  entries: ItemEntry[];
};

const ItemEntryListModule: React.FC<ItemEntryListModuleProps> = ({ entries }) => {
  const [selectedEntry, setSelectedEntry] = useState<ItemEntry | null>(null);

  function handleClick(entry: ItemEntry) {
    setSelectedEntry(entry);
  }

  return (
    <>
      <List>
        {selectedEntry && <ItemEntryListNotes onExited={() => setSelectedEntry(null)} selectedEntry={selectedEntry} />}

        {entries.map((entry, index) => (
          <ItemEntryItemModule handleClick={handleClick} key={index} entry={entry} />
        ))}
      </List>
    </>
  );
};

export default ItemEntryListModule;
