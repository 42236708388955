import React from 'react';
import { MonthlyPeriod } from 'types/monthlyComparison';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
  '&.bottom': {
    justifyContent: 'initial',
  },
});

type PeriodItemModuleProps = {
  period: MonthlyPeriod;
};

const PeriodItemModule: React.FC<PeriodItemModuleProps> = ({ period }) => {
  return (
    <ListItemStyled>
      <Typography variant="body1">
        {period.mes_extenso} - {period.mes_ano}
      </Typography>

      <Grid className="bottom">
        <Typography variant="caption">Vl Total:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {period.formattedTotal}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Vl Custo:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {period.formattedCoust}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Vl Unitário:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {period.formattedUnitary}
        </Typography>
      </Grid>
    </ListItemStyled>
  );
};

export default PeriodItemModule;
