import React, { useState, useEffect, useCallback } from 'react';
import { Button, styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { priceProtectionTableTemplate } from './priceProtectionTableTemplate';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import useTableOrder from 'hooks/tableOrder';
import { PriceProtection } from 'types/priceProtection';
import PriceProtectionListTable from './list/table/PriceProtectionListTable';
import Pagination from 'components/pagination/Pagination';
import PriceProtectionListModule from './list/module/PriceProtectionListModule';
import history from 'services/history';
import { moneyFormat } from 'helpers/numberFormat';
import PriceProtectionFilterBox from './PriceProtectionFilterBox';
import { PriceProtectionProvider } from './hooks/usePriceProtection';
import UpdatePriceProtectionModal from './_registration/UpdatePriceProtectionModal';
import { useDisplayMode } from 'hooks/useDisplayMode';
import PriceProtectionTabs from './tab/PriceProtectionTabs';
import PriceProtectionDashboard from './dashboard/PriceProtectionDashboard';
import { usePermissionRules } from 'hooks/permissionRules';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export type PriceProtectionQuery = {
  term: string;
  pago: 'S' | 'N' | 'all';
};

const PriceProtectionPage: React.FC = () => {
  const { h2iApi } = useApp();
  const { checkPermission } = usePermissionRules();
  const [tab, setTab] = useState<'products' | 'dashboard'>('dashboard');
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [datas, setDatas] = useState<PriceProtection[]>([]);
  const [filtered, setFiltered] = useState<PriceProtection[]>([]);
  const [displayMode] = useDisplayMode();
  const [query, setQuery] = useState<PriceProtectionQuery>({ term: '', pago: 'all' });
  const [selected, setSelected] = useState<PriceProtection | null>(null);

  const loadPrices = useCallback(
    _query => {
      if (!h2iApi) {
        return;
      }

      setLoading(true);

      h2iApi
        .get(`/api/getpriceprotection?PRODUTO=${_query.term}&pago=${_query.pago === 'all' ? '' : _query.pago}`)
        .then(response => {
          if (response.data[0].result) {
            setDatas([]);
            return;
          }
          setDatas(
            response.data
              .sort((a, b) => b.id - a.id)
              .map(item => ({
                ...item,
                formattedValue: moneyFormat(item.valor),
                formattedCoust: moneyFormat(item.custo_atual),
                formattedNewCoust: moneyFormat(item.novo_custo),
                formattedPaid: item.pago === 'S' ? 'Sim' : 'Não',
                numero_titulo: item.numero_titulo || '-',
              })),
          );
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi],
  );

  useEffect(() => {
    setFiltered(datas);
  }, [datas]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSave() {
    setSelected(null);

    loadPrices({ term: query.term, pago: query.pago });
  }

  return (
    <PriceProtectionProvider value={{ selected, setSelected }}>
      {selected && <UpdatePriceProtectionModal onSave={onSave} onExited={() => setSelected(null)} />}

      <Appbar title="Price Protection" />

      <PageHeaderActions
        title="Price Protection"
        ActionComponent={
          checkPermission('registrations.price-protection-new') ? (
            <Button variant="contained" onClick={() => history.push('/price-protection/new')}>
              Novo
            </Button>
          ) : (
            <></>
          )
        }
      />

      <PriceProtectionFilterBox
        onButtonClick={() => loadPrices(query)}
        loading={loading}
        query={query}
        setQuery={setQuery}
      />

      <PriceProtectionTabs tab={tab} handleChange={value => setTab(value)} />

      <TableContainer tableTemplate={priceProtectionTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nada encontrado" />
        ) : tab === 'products' ? (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <PriceProtectionListTable datas={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <PriceProtectionListModule datas={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        ) : (
          <PriceProtectionDashboard datas={datas} />
        )}
      </TableContainer>
    </PriceProtectionProvider>
  );
};

export default PriceProtectionPage;
