import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/selector';
import { styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import InsideLoading from 'components/loading/InsideLoading';
import history from 'services/history';
import ProjectReportAppbarActions from 'pages/projects/report/ProjectReportAppbarActions';
import { Waste, WasteOnList } from '../types/waste';
import { useApp } from 'hooks/app';
import { useWasteFormatter } from '../hooks/useWasteFormatter';
import { useSearchParams } from 'react-router-dom';

const Container = styled('div')({
  '& .label': {
    fontSize: 10,
    color: '#888',
    marginBottom: 7,
  },
});

const Row = styled('div')({
  display: 'grid',
  gap: 5,
  gridTemplateColumns: '30px 140px 120px 1fr 80px 50px 80px 80px',
  alignItems: 'center',
  '& > p': {
    fontSize: 12,
  },
});

const HeaderRightContent = styled('div')({
  '& p': {
    color: '#888',
    fontSize: 12,
  },
});

const ProductsContent = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 20,
  border: '1px solid #eee',
  '& .table-header': {
    fontWeight: 'bold',
  },
});

const TotalContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  border: '1px solid #eee',
  marginTop: 10,
  '& .grid': {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    columnGap: 20,
    rowGap: 7,
    '& > p': {
      textAlign: 'right',
      fontSize: 13,
    },
    '& .value': {
      fontWeight: 'bold',
    },
  },
});

const Content = styled('div')({
  border: '1px solid #eee',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 10,
  '& > p, span': {
    fontSize: 12,
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  '& > p > span': {
    fontWeight: 'bold',
  },
});

const HeaderContent = styled('div')({
  backgroundColor: '#eee',
  margin: -20,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginBottom: 10,
  '& > p': {
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const WasteReports: React.FC = () => {
  const user = useSelector(state => state.user);
  const params = window.location.search;
  const [wastes, setWastes] = useState<WasteOnList[]>([]);
  const [loading, setLoading] = useState(false);
  const formatter = useWasteFormatter();
  const { h2iApi } = useApp();
  const [searchParams] = useSearchParams();
  const initial_date = searchParams.get('data_ini');
  const final_date = searchParams.get('data_fim');

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/getreee${params}`)
      .then(_response => {
        const response: Waste[] = _response.data.reee;
        if (!response) {
          setWastes([]);
          return;
        }
        setWastes(response.map(item => formatter(item)));
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [formatter, params, h2iApi]);

  const formattedTotal = useMemo(() => {
    if (!wastes.length) {
      return moneyFormat(0);
    }
    return moneyFormat(wastes.reduce((sum, item) => sum + item.valor_total, 0));
  }, [wastes]);

  return (
    <Dialog
      fullScreen
      title={`R3E`}
      onExited={() => history.push('/waste')}
      ComponentActions={<ProjectReportAppbarActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : (
        <Container>
          <div className="page">
            <div className="header">
              <div>
                <h3>
                  Relatório de {initial_date} até {final_date}
                </h3>
              </div>
              <HeaderRightContent>
                <p>{user?.name}</p>
                <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
              </HeaderRightContent>
            </div>

            <Content>
              <HeaderContent>
                <p>Informações da empresa</p>
              </HeaderContent>
              <p>
                <span>Razão social:</span> Eltecno Distribuição de Informática
              </p>
              <p>
                <span>CNPJ:</span> 07.482.781/0001-47
              </p>
              <p>
                <span>Telefone:</span> (71) 3038-7757
              </p>
            </Content>

            <ProductsContent>
              <HeaderContent>
                <p>Informações</p>
              </HeaderContent>

              <Row className="table-header">
                <p>ID</p>
                <p>Documento</p>
                <p>Filial</p>
                <p>Nome</p>
                <p className="right">Total</p>
                <p>Pago</p>
                <p>Pagamento</p>
                <p>Cidade</p>
              </Row>
              {wastes.map((waste, index) => (
                <Row key={index}>
                  <p>{waste.id}</p>
                  <p>{waste.cnpj_cpf}</p>
                  <p>{waste.filial}</p>
                  <p>{waste.nome}</p>
                  <p className="right">{waste.formattedTotal}</p>
                  <p>{waste.pago}</p>
                  <p>{waste.data_pagamento}</p>
                  <p>{waste.cidade}</p>
                </Row>
              ))}
            </ProductsContent>

            <TotalContent>
              <p className="label">
                <strong>Totais</strong>
              </p>
              <div className="grid">
                <p>Total</p>
                <p className="value">{formattedTotal}</p>
              </div>
            </TotalContent>
          </div>
        </Container>
      )}
    </Dialog>
  );
};

export default WasteReports;
