import { IconButton, styled, ListItemButton, Menu, MenuItem, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { MouseEvent, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import history from 'services/history';
import { ProductList } from 'types/product';
import { useProduct } from '../../productContext';

type ProductItemProps = {
  product: ProductList;
};

const Image = styled('img')({
  width: '100%',
});

const ListItemButtonStyled = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 120,
  justifyContent: 'space-between',
  flexDirection: 'column',
  '& .data': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const ImageWrapper = styled('div')({
  width: 100,
  height: 100,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  flexShrink: 0,
});
const Collapse = styled('div')({
  display: 'flex',
  height: 0,
  transition: 'all 0.2s ease',
  opacity: 0,
  width: '100%',
  '& > .content': {
    margin: '10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    flex: 1,
  },
});
const Row = styled('div')({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});
const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const FaChevronDownStyled = styled(FaChevronDown)({
  flexShrink: 0,
  transition: 'transform 0.2s ease',
  marginLeft: 10,
});

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  const { handleSelectProduct, openImagePreview } = useProduct();
  const [collapse, setCollapse] = useState(false);
  const [selectedProduct, setSelectProduct] = useState<ProductList | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleClick(product: ProductList) {
    setCollapse(!collapse);
    handleSelectProduct(product);
  }

  function handleImageClick(e: MouseEvent<HTMLImageElement>, product: ProductList) {
    openImagePreview();
    handleSelectProduct(product);
    e.stopPropagation();
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>, product: ProductList) {
    setSelectProduct(product);
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  }

  return (
    <>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => history.push(`/products/${selectedProduct?.codigo_produto}`)}>Editar</MenuItem>
      </Menu>
      <ListItemButtonStyled
        style={{ opacity: parseFloat(product.est) <= 0 ? 0.5 : 1 }}
        onClick={() => handleClick(product)}
      >
        <Row>
          <div className="data">
            <Typography color="primary" variant="caption" gutterBottom>
              {product.codigo_produto} | {product.subgrupo}
            </Typography>
            <Typography variant="body2" className="product-name">
              {product.produto}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.fabricante}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.grupo}
            </Typography>
          </div>
          <ImageWrapper>
            <Image src={product.foto_principal} alt="Foto produto" onClick={e => handleImageClick(e, product)} />
          </ImageWrapper>
          <FaChevronDownStyled size={16} style={collapse ? { transform: 'rotate(180deg)' } : undefined} color="#888" />
        </Row>
        <Row>
          <Collapse style={collapse ? { height: 150, opacity: 1 } : undefined}>
            <div className="content">
              <Typography variant="body2">Custo</Typography>
              <Typography variant="body2" color="secondary">
                {product.formattedCusto}
              </Typography>
              <Typography variant="body2">Margem</Typography>
              <Typography variant="body2" color="secondary">
                {product.formattedMargem}
              </Typography>
              <Typography variant="body2">P2PJ</Typography>
              <Typography variant="body2">{product.formattedP2PJ}</Typography>
              <Typography variant="body2">PromoPJ</Typography>
              <Typography variant="body2">{product.formattedPromoPJ}</Typography>
              <Typography variant="body2">MC - MC %</Typography>
              <Typography variant="body2">
                {product.formattedMC} - {product.formattedPercentualMC}
              </Typography>
              <Typography variant="body2">Estoque</Typography>
              <Typography variant="body2">{product.est}</Typography>
            </div>
          </Collapse>
        </Row>
        <CustomIconButton onClick={e => handleMoreClick(e, product)}>
          <MoreVert />
        </CustomIconButton>
      </ListItemButtonStyled>
    </>
  );
};

export default ProductItem;
