import React from 'react';
import { Tab, Tabs } from '@mui/material';

export type AbProductTabOptions =
  | 'branch'
  | 'product'
  | 'unit'
  | 'segment'
  | 'subgroup'
  | 'manufacturer'
  | 'seller'
  | 'customer';

type Props = {
  onTabChange(value: AbProductTabOptions): void;
  value: AbProductTabOptions;
};

const AbcProductTabs: React.FC<Props> = ({ onTabChange, value }) => {
  return (
    <Tabs value={value} onChange={(e, value) => onTabChange(value)}>
      <Tab label="Filiais" value="branch" />
      <Tab label="Produtos" value="product" />
      <Tab label="Unidades" value="unit" />
      <Tab label="Segmentos" value="segment" />
      <Tab label="Subgrupos" value="subgroup" />
      <Tab label="Fabricantes" value="manufacturer" />
      <Tab label="Pedido por" value="seller" />
      <Tab label="Clientes" value="customer" />
    </Tabs>
  );
};

export default AbcProductTabs;
