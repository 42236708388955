import React from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { saleTableTemplate } from '../template/saleTableTemplate';
import { GroupedSale } from 'pages/reports/orders-list/dashboard/list/order-by/list/table/GroupedSaleListTable';

type DailySummarySaleItemTableProps = {
  sale: GroupedSale;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const DailySummarySaleItemTable: React.FC<DailySummarySaleItemTableProps> = ({ sale }) => {
  return (
    <>
      {saleTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item => (
          <div
            key={_item.id}
            className={
              _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
            }
          >
            <Tooltip title={sale[_item.id]}>
              <Description variant="body2" fontSize={12}>
                {sale[_item.id]}
              </Description>
            </Tooltip>
          </div>
        ))}
    </>
  );
};

export default DailySummarySaleItemTable;
