import React, { FormEvent } from 'react';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialogs/Dialog';
import DesktopDatePicker from 'components/pickers/DesktopDatePicker';
import { Search } from '@mui/icons-material';
import { useInvoices } from '../hooks/useInvoices';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > .content': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const DatePickerContainer = styled('div')({
  display: 'block',
});

interface InvoicesMoreFilterProps {
  onExited(): void;
}

const InvoicesMoreFilter: React.FC<InvoicesMoreFilterProps> = ({ onExited }) => {
  const { handleChangeFilter, handleSearch, filter, loading, accounts, type } = useInvoices();

  function handleSubmit(closeModal: () => void, event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    handleSearch();
    closeModal();
  }

  return (
    <Dialog onExited={onExited} maxWidth="sm" height="100vh" title="Filtros">
      <Container>
        <DialogConsumer>
          {context => (
            <form className="content" onSubmit={event => handleSubmit(context.handleClose, event)}>
              <DatePickerContainer>
                <DesktopDatePicker
                  label="Data inicial"
                  value={filter.initial_date}
                  maxDate={filter.final_date}
                  onChange={date => handleChangeFilter('initial_date', date)}
                  textFieldProps={{
                    margin: 'none',
                    fullWidth: true,
                  }}
                />

                <DesktopDatePicker
                  label="Data Final"
                  value={filter.final_date}
                  minDate={filter.initial_date}
                  onChange={date => handleChangeFilter('final_date', date)}
                  textFieldProps={{
                    margin: 'normal',
                    fullWidth: true,
                  }}
                />

                <DesktopDatePicker
                  label="Mês/Ano"
                  value={filter.month_year}
                  onChange={date => handleChangeFilter('month_year', date)}
                  format="MMMM-yyyy"
                  views={['month', 'year']}
                  textFieldProps={{
                    margin: 'normal',
                    fullWidth: true,
                  }}
                  localeText={{
                    clearButtonLabel: 'Limpar',
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                />
              </DatePickerContainer>

              <TextField
                autoFocus
                label="Descrição do lançamento"
                placeholder="Descrição do lançamento"
                value={filter.classification}
                onChange={e => handleChangeFilter('classification', e.target.value)}
                margin="none"
              />

              <TextField
                label={type === 'F' ? 'Funcionário' : type === 'T' ? 'Fornecedor' : 'Classificação'}
                placeholder={type === 'F' ? 'Funcionário' : type === 'T' ? 'Fornecedor' : 'Classificação'}
                value={filter.supplier}
                onChange={e => handleChangeFilter('supplier', e.target.value)}
                margin="normal"
              />

              <TextField
                label="Nº Nota"
                placeholder="Nº Nota"
                value={filter.invoice_number}
                onChange={e => handleChangeFilter('invoice_number', e.target.value)}
                margin="normal"
              />

              <TextField
                fullWidth
                margin="normal"
                value={filter.is_paid}
                onChange={e => handleChangeFilter('is_paid', e.target.value)}
                select
                label="Pago"
              >
                <MenuItem value="T">Todos</MenuItem>
                <MenuItem value="S">Pago</MenuItem>
                <MenuItem value="N">Não Pago</MenuItem>
              </TextField>

              <DesktopDatePicker
                label="Data Apropriação"
                value={filter.appropriation_date}
                onChange={date => handleChangeFilter('appropriation_date', date)}
                textFieldProps={{
                  margin: 'normal',
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                value={filter.totalizingAccountId}
                onChange={e => handleChangeFilter('totalizingAccountId', e.target.value)}
                select
                label="Conta totalizadora"
              >
                <MenuItem value={0}>Nenhuma conta selecionada</MenuItem>
                {accounts.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nome} - {item.formattedSaldo}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                value={filter.is_appropriated}
                onChange={e => handleChangeFilter('is_appropriated', e.target.value)}
                select
                label="Apropriado"
                margin="normal"
              >
                <MenuItem value="T">Todos</MenuItem>
                <MenuItem value="N">Não Apropriado</MenuItem>
                <MenuItem value="A">Apropriados</MenuItem>
              </TextField>

              <TextField
                fullWidth
                label="CT Informado"
                placeholder="CT Informado"
                value={filter.informedAccount}
                onChange={e => handleChangeFilter('informedAccount', e.target.value)}
                margin="normal"
                select
              >
                <MenuItem value="T">Todos</MenuItem>
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </TextField>

              <button type="submit" style={{ display: 'none' }} />
            </form>
          )}
        </DialogConsumer>

        <DialogConsumer>
          {context => (
            <Button
              disabled={loading}
              onClick={() => handleSubmit(context.handleClose)}
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<Search />}
            >
              Pesquisar
            </Button>
          )}
        </DialogConsumer>
      </Container>
    </Dialog>
  );
};

export default InvoicesMoreFilter;
