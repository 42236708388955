import React, { useEffect, useRef } from 'react';
import { Vacancy } from 'types/vacancy';
import { VacancyValidation } from './validation/vacancyValidation';
import { MenuItem, styled, TextField, Tooltip, Typography } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import ImageUpload from 'components/image-upload/ImageUpload';

interface VacancyFormProps {
  vacancy: Vacancy;
  handleChange(index: keyof Vacancy, value: any): void;
  validation: VacancyValidation;
}

const Form = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 0.8fr',
  gap: 15,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const Column1 = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 15,
}));

const Column2 = styled('div')(({ theme }) => ({
  padding: '10px 40px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 15,
}));

const Preview = styled('div')(({ theme }) => ({
  border: '1px solid #000',
}));

const Image = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: 180,
}));

const VacancyForm: React.FC<VacancyFormProps> = ({ vacancy, handleChange, validation }) => {
  const inputs = {
    role: useRef<HTMLInputElement>(null),
    local: useRef<HTMLInputElement>(null),
    work_model: useRef<HTMLInputElement>(null),
    work_hours: useRef<HTMLInputElement>(null),
    work_experience: useRef<HTMLInputElement>(null),
    salary: useRef<HTMLInputElement>(null),
    benefits: useRef<HTMLInputElement>(null),
    observation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Form>
      <Column1>
        <ImageUpload
          label="Banner topo da página"
          handleSetImage={image => {
            handleChange('image', image);
            handleChange('image_id', image?.id);
          }}
          handleRemoveImage={() => handleChange('image', null)}
          image={
            vacancy.image
              ? {
                  id: vacancy.image?.id,
                  imageUrl: vacancy.image?.url,
                  filename: 'Banner topo da página',
                  imageThumbUrl: vacancy.image.url,
                  thumb: vacancy.image.url,
                }
              : null
          }
        />

        <TextField
          select
          label="Área de interesse da vaga"
          placeholder="Informe a área de interesse da vaga"
          autoFocus
          value={vacancy.department}
          onChange={e => handleChange('department', e.target.value)}
        >
          <MenuItem value="administrative">Administrativo</MenuItem>
          <MenuItem value="box">Caixa</MenuItem>
          <MenuItem value="shopping">Compras</MenuItem>
          <MenuItem value="commercial_sales">Comercial / Vendas</MenuItem>
          <MenuItem value="accounting_tax">Contábil / Fiscal</MenuItem>
          <MenuItem value="personal_department">Departamento Pessoal</MenuItem>
          <MenuItem value="stock_shipping">Estoque / Expedição</MenuItem>
          <MenuItem value="financial">Financeiro</MenuItem>
          <MenuItem value="it">Informática / TI</MenuItem>
          <MenuItem value="logistics">Logística</MenuItem>
          <MenuItem value="marketing">Marketing</MenuItem>
          <MenuItem value="driver">Motorista</MenuItem>
          <MenuItem value="human_resources">Recursos Humanos</MenuItem>
          <MenuItem value="general_services">Serviços Gerais</MenuItem>
        </TextField>

        <TextField
          inputRef={inputs.role}
          helperText={validation.role}
          error={!!validation.role}
          value={vacancy.role}
          onChange={e => handleChange('role', e.target.value)}
          label="Título da vaga"
          placeholder="Digite o cargo para preencher a vaga"
        />

        <TextField
          inputRef={inputs.local}
          helperText={validation.local}
          error={!!validation.local}
          value={vacancy.local}
          onChange={e => handleChange('local', e.target.value)}
          label="Localidade da vaga"
          placeholder="Digite o local da vaga"
        />

        <TextField
          inputRef={inputs.benefits}
          helperText={validation.benefits}
          error={!!validation.benefits}
          value={vacancy.benefits}
          onChange={e => handleChange('benefits', e.target.value)}
          label="Benefícios"
          placeholder="Digite os benefícios"
        />

        <TextField
          inputRef={inputs.work_experience}
          helperText={validation.work_experience}
          error={!!validation.work_experience}
          value={vacancy.work_experience}
          onChange={e => handleChange('work_experience', e.target.value)}
          label="Experiência"
          placeholder="Digite a experiência necessária para a vaga"
        />

        <TextField
          inputRef={inputs.work_hours}
          helperText={'Ex.: 44h' || validation.work_hours}
          error={!!validation.work_hours}
          value={vacancy.work_hours}
          onChange={e => handleChange('work_hours', e.target.value)}
          label="Jornada de trabalho (h/semana)"
          placeholder="Digite a quantidade de horas por semana"
        />

        <TextField
          inputRef={inputs.work_model}
          helperText={'Ex.: Presencial' || validation.work_model}
          error={!!validation.work_model}
          value={vacancy.work_model}
          onChange={e => handleChange('work_model', e.target.value)}
          label="Modelo de trabalho"
          placeholder="Digite o modelo de trabalho para a vaga"
        />

        <TextField
          inputRef={inputs.observation}
          helperText={validation.observation}
          error={!!validation.observation}
          value={vacancy.observation}
          onChange={e => handleChange('observation', e.target.value)}
          label="Atividades a exercer"
          placeholder="Digite observações sobre a vaga"
          multiline
          rows={2}
        />

        <TextField
          inputRef={inputs.salary}
          helperText={validation.salary}
          error={!!validation.salary}
          value={vacancy.salary}
          onChange={e => handleChange('salary', e.target.value)}
          label="Salário"
          placeholder="Digite o salário da vaga"
          inputMode="decimal"
          type="number"
        />
      </Column1>

      <Tooltip title="Preview">
        <Preview>
          <Image
            sx={{
              backgroundSize: 'contain',
              background: `linear-gradient(to bottom, rgba(255, 225, 255, 0) 20%, rgba(255, 255, 255, 1)), center top url(${
                vacancy.image?.url || ''
              })`,
            }}
          />
          <Column2>
            <Typography variant="subtitle1" fontSize={18} fontWeight={900}>
              {vacancy.role}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Local:</strong> {vacancy.local}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Benefícios:</strong> {vacancy.benefits}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Experiência:</strong> {vacancy.work_experience}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Jornada de trabalho:</strong> {vacancy.work_hours}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Modelo de trabalho:</strong> {vacancy.work_model}
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Atividades a exercer:</strong>
              <div dangerouslySetInnerHTML={{ __html: vacancy.observation }} />
            </Typography>

            <Typography variant="subtitle2" fontSize={14}>
              <strong>Remuneração:</strong> {moneyFormat(vacancy.salary)}
            </Typography>
          </Column2>
        </Preview>
      </Tooltip>

      <button type="submit" style={{ display: 'none' }}></button>
    </Form>
  );
};

export default VacancyForm;
