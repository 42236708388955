import React, { MouseEvent } from 'react';
import { IconButton, Tooltip, Typography, styled } from '@mui/material';
import { replacementTableTemplate } from '../../template/replacementTableTemplate';
import { Replacement } from 'types/replacement';
import { BarChart } from '@mui/icons-material';

type AnalysisReplacementItemTableProps = {
  replacement: Replacement;
};

const Description = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: 'vertical',
});

const AnalysisReplacementItemTable: React.FC<AnalysisReplacementItemTableProps> = ({ replacement }) => {
  function handleRedirect(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    window.open(`/commercial-bi/analysis-product/${replacement.id_produto}`, '_blank');
  }

  return (
    <>
      {replacementTableTemplate
        .filter(_item => !_item.notShow)
        .map(_item =>
          _item.id === 'action' ? (
            <Tooltip key={_item.id} title="Análise de produto">
              <IconButton size="small" color="primary" onClick={e => handleRedirect(e)}>
                <BarChart fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <div
              style={{ background: _item.id === 'sugestao' ? '#f8eef3' : _item.id === 'dias_estoque' ? '#f1f7ef' : '' }}
              key={_item.id}
              className={
                _item.dataType === 'number' ? 'numericData' : _item.dataType === 'quantity' ? 'quantityData' : ''
              }
            >
              <Tooltip title={replacement[_item.id]}>
                <Description
                  style={{
                    fontSize: 10,
                    fontWeight: _item.id === 'sugestao' ? 600 : _item.id === 'dias_estoque' ? 600 : '',
                  }}
                  variant="body2"
                >
                  {replacement[_item.id]}
                </Description>
              </Tooltip>
            </div>
          ),
        )}
    </>
  );
};

export default AnalysisReplacementItemTable;
