import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { WasteValidation } from './validation/wasteValidation';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import SelectBranch from 'components/select-branches/SelectBranch';
import { useWaste } from './hooks/useWaste';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 30,
  backgroundColor: '#fff',
  '& .subtitle': {
    marginBottom: 20,
  },
  [theme.breakpoints.down('sm')]: {
    padding: 5,
  },
}));

const CustomButton = styled(Button)({
  alignSelf: 'center',
  justifySelf: 'end',
  maxWidth: 200,
});

const FirstRow = styled('div')(props => ({
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '220px 200px 1fr',
  gap: 15,
  marginBottom: 10,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const SecondRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
  },
}));

const ThirdRow = styled('div')(props => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 15,
  [props.theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gap: 5,
    '& > .state': {
      gridRow: '1 / 2',
      gridColumn: '1 / 2',
    },
    '& > .city': {
      gridRow: '1 / 2',
      gridColumn: '2 / 3',
    },
    '& button': {
      gridColumn: '1 / 3',
    },
  },
}));

interface WasteFormProps {
  validation?: WasteValidation;
  handleValidation(): void;
  disableButton: boolean;
}

const WasteForm: React.FC<WasteFormProps> = ({ validation, handleValidation, disableButton }) => {
  const [singleCustomer, setSingleCustomer] = useState(false);
  const [disabledCustomer, setDisabledCustomer] = useState(false);
  const {
    handleChange,
    handleDocumentChange,
    loadingCustomer,
    handlePostalCodeChange,
    loadingPostalCode: loading,
    waste,
  } = useWaste();

  const inputs = {
    id_filial: useRef<HTMLSelectElement>(null),
    nome: useRef<HTMLInputElement>(null),
    cnpj_cpf: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    if (!validation) return;
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function onClick(checked: boolean) {
    setSingleCustomer(checked);
    if (checked) {
      handleDocumentChange('60.824.483/0001-70');
      setDisabledCustomer(true);
      return;
    }

    handleDocumentChange('');
    handleChange('nome', '');
    handleChange('cep', '');
    handleChange('bairro', '');
    handleChange('endereco', '');
    handleChange('cidade', '');
    handleChange('uf', '');
    setDisabledCustomer(false);
  }

  return (
    <Content>
      <Typography variant="h6" className="subtitle" color="primary">
        Dados do cliente
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            onChange={event => onClick(event.target.checked)}
            size="small"
            color="primary"
            checked={singleCustomer}
          />
        }
        label="Cliente avulso"
      />

      <FirstRow>
        <SelectBranch
          label="Filial"
          viewAllBranches={false}
          branch_id={waste.id_filial}
          handleChange={branchId => handleChange('id_filial', branchId)}
        />

        <TextField
          disabled={disabledCustomer}
          label="CPF/CNPJ"
          placeholder="CPF ou CNPJ"
          fullWidth
          error={!!validation?.cnpj_cpf}
          value={waste.cnpj_cpf}
          helperText={validation?.cnpj_cpf}
          onChange={e => handleDocumentChange(e.target.value)}
          margin="normal"
          inputRef={inputs.cnpj_cpf}
          InputProps={{
            inputComponent: CpfCnpjInput as any,
          }}
        />

        <TextField
          label="Nome do cliente"
          placeholder="Nome do cliente"
          fullWidth
          disabled={disableButton || disabledCustomer}
          error={!!validation?.nome}
          value={waste.nome}
          helperText={validation?.nome}
          onChange={e => handleChange('nome', e.target.value)}
          margin="normal"
          inputRef={inputs.nome}
          InputProps={{
            endAdornment: loadingCustomer && (
              <InputAdornment position="start">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
        />
      </FirstRow>

      <Typography variant="caption" fontWeight={500} fontSize={12}>
        Endereço do cliente
      </Typography>

      <SecondRow>
        <TextField
          label="CEP"
          placeholder="CEP"
          disabled={disableButton || disabledCustomer}
          fullWidth
          value={waste.cep}
          onChange={e => handlePostalCodeChange(e.target.value)}
          margin="normal"
          InputProps={{
            inputComponent: PostalCodeInput as any,
          }}
        />

        {waste.cep.replace(/\D/g, '').length > 7 ? (
          <>
            <TextField
              disabled={disableButton || disabledCustomer}
              label="Endereço"
              placeholder="Endereço"
              fullWidth
              value={waste.endereco}
              onChange={e => handleChange('endereco', e.target.value)}
              margin="normal"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              disabled={disableButton || disabledCustomer}
              label="Bairro"
              placeholder="Bairro"
              fullWidth
              value={waste.bairro}
              onChange={e => handleChange('bairro', e.target.value)}
              margin="normal"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <CustomButton disabled={disableButton} variant="contained" onClick={handleValidation}>
            Avançar
          </CustomButton>
        )}
      </SecondRow>

      {waste.cep.replace(/\D/g, '').length > 7 && (
        <>
          <ThirdRow>
            <TextField
              disabled={disableButton || disabledCustomer}
              className="city"
              label="Cidade"
              placeholder="Cidade"
              fullWidth
              value={waste.cidade}
              onChange={e => handleChange('cidade', e.target.value)}
              margin="normal"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              disabled={disableButton || disabledCustomer}
              className="state"
              label="Estado"
              placeholder="Estado"
              fullWidth
              value={waste.uf}
              onChange={e => handleChange('uf', e.target.value)}
              margin="normal"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />

            <CustomButton disabled={disableButton} variant="contained" onClick={handleValidation}>
              Prosseguir
            </CustomButton>
          </ThirdRow>
        </>
      )}
    </Content>
  );
};

export default WasteForm;
