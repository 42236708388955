import React from 'react';
import SidebarItem from '../SidebarItem';
import { Collapse } from '@mui/material';
import { useSidebar } from '../hook/useSidebar';
import { usePermissionRules } from 'hooks/permissionRules';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { Badge, Diversity1, Groups, PsychologyAlt, Work, WorkHistory } from '@mui/icons-material';

const Rh: React.FC = () => {
  const { checkPermission } = usePermissionRules();
  const { handleCollapseClick, collapsible } = useSidebar();

  return (
    <>
      {checkPermission('rh') && (
        <>
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('rh')}
            icon={<Diversity1 />}
            text="RH"
            opened={collapsible.rh}
          />
          <Collapse in={collapsible.rh}>
            <SidebarItem to="/rh/justification" icon={<PsychologyAlt />} text="Justificativa" nested={5} />

            <SidebarItem to="/rh/requirements" icon={<Groups />} text="Solicitações" nested={5} />

            {checkPermission('rh.recruitment') && (
              <>
                <SidebarItemExpendable
                  onClick={() => handleCollapseClick('recruitment', false)}
                  icon={<Badge />}
                  text="Recrutamento"
                  nested={5}
                  opened={collapsible.recruitment}
                />

                <Collapse in={collapsible.recruitment}>
                  {checkPermission('rh.recruitment.curriculum') && (
                    <SidebarItem to="/rh/curriculum" icon={<WorkHistory />} text="Currículos" nested={8} />
                  )}

                  {checkPermission('rh.recruitment.vacancies') && (
                    <SidebarItem to="/rh/vacancies" icon={<Work />} text="Vagas de emprego" nested={8} />
                  )}
                </Collapse>
              </>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default Rh;
