import React, { useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import CommercialDashboardFilterBox from './CommercialDashboardFilterBox';
import CommercialDashboardHeader from './CommercialDashboardHeader';
import CommercialDashboardContent from './CommercialDashboardContent';
import { useFetchCommercialDashboard } from './hooks/useFetchCommercialDashboard';
import ConfirmDialog from 'components/confirm-dialog/ConfirmDialog';

const Container = styled('div')({
  gap: 10,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export interface CommercialFilterProps {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
}

const CommercialDashboardPage: React.FC = () => {
  const user = useSelector(state => state.user);
  const [customerContacted, setCustomerContacted] = useState(false);
  const [filter, setFilter] = useState<CommercialFilterProps>({
    initial_date: new Date(),
    final_date: new Date(),
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 12 ? '0' : user.branchList[0]) : 99,
  });
  const { loading, orders, fetch, groupOrdersByPedidoPor } = useFetchCommercialDashboard(filter);

  useEffect(() => {
    setFilter(state => ({
      ...state,
      selectedBranchId: user?.branchList?.length === 12 ? 0 : parseInt(user?.branchList[0] || '99'),
    }));
  }, [user]);

  function handleChangeFilter(index: keyof CommercialFilterProps, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <>
      <Appbar title="Dashboard Comercial" />

      {customerContacted && (
        <ConfirmDialog
          title="Confirma contato com o cliente?"
          handleNoClick={() => setCustomerContacted(false)}
          handleYesClick={() => setCustomerContacted(false)}
        />
      )}

      <CommercialDashboardFilterBox
        onSearch={fetch}
        loading={loading}
        filter={filter}
        handleChangeFilter={handleChangeFilter}
      />

      <Container>
        <CommercialDashboardHeader loading={loading} orders={orders} />

        <CommercialDashboardContent
          setCustomerContacted={setCustomerContacted}
          groupOrdersByPedidoPor={groupOrdersByPedidoPor}
          loading={loading}
          orders={orders}
        />
      </Container>
    </>
  );
};

export default CommercialDashboardPage;
