import { TableTemplate } from 'types/tableTemplate';

export const commercialDashboardTotalTableTemplate: TableTemplate[] = [
  {
    id: 'Pedido_por',
    description: 'Pedido por',
    originalId: 'Pedido_por',
    width: 220,
  },
  {
    id: 'formattedTotal',
    description: 'Valor',
    originalId: 'valor_total',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'formattedPercentual',
    description: 'Percentual',
    originalId: 'percentual',
    width: 100,
    dataType: 'number',
  },
  {
    id: 'qtd',
    description: 'QTD',
    originalId: 'qtd',
    width: 50,
  },
];
