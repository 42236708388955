import React, { useState, useEffect, useRef } from 'react';
import PurchaseOrderForm from '../PurchaseOrderForm';
import Appbar from 'components/appbar/Appbar';
import { useApp } from 'hooks/app';
import InsideSaving from 'components/loading/InsideSaving';
import PageHeader from 'components/page-header/PageHeader';
import { PurchaseOrderProvider } from '../hook/usePurchaseOrder';
import { Button, Tab, Tabs, styled } from '@mui/material';
import { useFetchPurchaseOrder } from '../hook/useFetchPurchaseOrder';
import PurchaseOrderProducts from '../product/PurchaseOrderProducts';
import StepsPurchaseOrder from '../steps/StepsPurchaseOrder';
import PurchaseOrderActions from './PurchaseOrderActions';
import PurchaseOrderPayment from '../dialog/PurchaseOrderPayment';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading/Loading';
import PurchaseOrderCalculationForm from '../calculation-options/PurchaseOrderCalculationForm';
import PurchaseOrderPaymentsInform from '../payments/PurchaseOrderPaymentsInform';

const Container = styled('div')(({ theme }) => ({
  gap: 20,
  flex: 1,
  padding: 15,
  display: 'flex',
  background: '#fff',
  position: 'relative',
  flexDirection: 'column',
  margin: '10px auto 10px auto',
  [theme.breakpoints.up('lg')]: {
    minWidth: 1440,
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    minWidth: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const PurchaseOrderUpdate: React.FC = () => {
  const { windowWidth } = useApp();
  const [informPayment, setInformPayment] = useState(false);
  const [tab, setTab] = useState('product');
  const { id } = useParams<{ id: string }>();
  const {
    saving,
    handleChange,
    order,
    disabled,
    validation,
    dispatch,
    handleValidation,
    handleBack,
    secondValidation,
    thirdValidation,
    step,
    handleNext,
    handleCustomerSelect,
    reseller,
    loading,
  } = useFetchPurchaseOrder(id);

  const inputs = {
    id: useRef<HTMLInputElement>(null),
    cnpj: useRef<HTMLInputElement>(null),
    cliente: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(step === 2 ? secondValidation : validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [secondValidation]); // eslint-disable-line

  function handleClickInformPayment() {
    setInformPayment(true);
  }

  return (
    <PurchaseOrderProvider
      value={{
        validation,
        secondValidation,
        thirdValidation,
        handleBack,
        handleNext,
        handleChange,
        order,
        step,
        inputs,
        handleCustomerSelect,
        reseller,
        disabled,
        dispatch,
        handleClickInformPayment,
      }}
    >
      <Appbar
        title="Pedido de compras"
        ActionsComponent={<PurchaseOrderActions handleValidation={handleValidation} />}
      />

      <PageHeader title="Pedido de compras" backUrl="/purchasing/registration" />
      <Container>
        {loading && <Loading />}
        {saving && <InsideSaving />}
        {informPayment && <PurchaseOrderPayment type="update" order={order} onExited={() => setInformPayment(false)} />}

        {windowWidth < 660 ? (
          <>
            <StepsPurchaseOrder />

            <Actions>
              {step !== 1 && (
                <Button onClick={handleBack} variant="contained">
                  Voltar
                </Button>
              )}
              <Button onClick={handleNext} variant="contained">
                {step === 7 ? 'Processar' : 'Avançar'}
              </Button>
            </Actions>
          </>
        ) : (
          <>
            <form style={{ flex: 0 }} onSubmit={e => handleValidation('form', e)}>
              <PurchaseOrderForm />
            </form>

            <div>
              <Tabs value={tab} onChange={(e, value) => setTab(value)} variant="scrollable">
                <Tab disabled={disabled} label="Produto" value="product" />
                <Tab disabled label="Conhecimento" value="knowledge" />
                <Tab disabled={disabled} label="Opções de cálculo" value="calculation" />
                <Tab disabled={disabled} label="Inf. Pagamentos" value="payments" />
              </Tabs>
            </div>

            {tab === 'product' ? (
              <PurchaseOrderProducts type="update" />
            ) : tab === 'calculation' ? (
              <PurchaseOrderCalculationForm />
            ) : (
              <PurchaseOrderPaymentsInform />
            )}
          </>
        )}
      </Container>
    </PurchaseOrderProvider>
  );
};

export default PurchaseOrderUpdate;
