import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { H2iCustomer } from 'types/h2iCustomer';
import { useCustomers } from 'pages/customers/hooks/useCustomers';

const CustomListItemButton = styled(ListItemButton)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
});

const Grid = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '90px 1fr',
  gap: 10,
  flex: 1,
});

type CustomerItemModuleProps = {
  customer: H2iCustomer;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const { setSelectedCustomer, setEditCustomerDialog } = useCustomers();

  function handleClick() {
    setSelectedCustomer(customer);
    setEditCustomerDialog(true);
  }

  return (
    <CustomListItemButton onClick={handleClick}>
      <Grid>
        <Typography variant="caption">Código:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {customer.codigo}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Nome:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {customer.nome}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">CNPJ/CPF:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {customer.cnpj_cpf}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Cidade/UF:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {`${customer.cidade} / ${customer.uf}`}
        </Typography>
      </Grid>

      <Grid className="bottom">
        <Typography variant="caption">Atividade:</Typography>

        <Typography align="left" variant="caption" fontWeight={600}>
          {customer.atividade}
        </Typography>
      </Grid>
    </CustomListItemButton>
  );
};

export default CustomerItemModule;
