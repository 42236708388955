import { DisplaySettings, Done, FilterList, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Tooltip, styled } from '@mui/material';
import React from 'react';
import { useExtracts } from './hooks/useExtracts';

const Container = styled('div')(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

interface BankStatementActionsProps {
  handleDialogFilter(): void;
}

const BankStatementActions: React.FC<BankStatementActionsProps> = ({ handleDialogFilter }) => {
  const { setShowTotals, showTotals, setDefaultView, defaultView, filter, selectedExtracts, setAppropriationDialog } =
    useExtracts();
  return (
    <Container>
      <IconButton
        color="inherit"
        onClick={() => setDefaultView(defaultView === 'type' ? 'default' : defaultView === 'default' ? 'date' : 'type')}
      >
        <DisplaySettings />
      </IconButton>

      <IconButton onClick={() => setShowTotals(state => !state)} color="inherit">
        {showTotals ? <Visibility /> : <VisibilityOff />}
      </IconButton>

      <IconButton onClick={handleDialogFilter} color="inherit">
        <FilterList />
      </IconButton>

      {filter.branchId !== 1001 && selectedExtracts.length > 0 && (
        <Tooltip title="Apropriar selecionados">
          <IconButton color="inherit" onClick={() => setAppropriationDialog(true)}>
            <Done />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
};

export default BankStatementActions;
