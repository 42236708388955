import { Button, Fade, Paper, Popper, Typography, styled } from '@mui/material';
import React from 'react';

interface PurchaseOrderPopperProps {
  open: boolean;
  handleAccept(): void;
  handleDeny(): void;
  title: string;
  subtitle: string;
}

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  gap: 5,
});

const Container = styled('div')({
  padding: '20px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const PurchaseOrderPopper: React.FC<PurchaseOrderPopperProps> = ({
  open,
  handleAccept,
  title,
  subtitle,
  handleDeny,
}) => {
  return (
    <Popper
      sx={{
        zIndex: 1,
        position: 'absolute!important',
        bottom: -130,
        right: 0,
        top: 'unset!important',
        left: 'unset!important',
      }}
      disablePortal={true}
      transition
      open={open}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper>
            <Container>
              <Typography variant="subtitle2" fontSize={15} fontWeight={600}>
                {title}
              </Typography>
              <Typography variant="subtitle2" fontSize={13}>
                {subtitle}
              </Typography>

              <Actions>
                <Button onClick={handleDeny}>Não</Button>
                <Button onClick={handleAccept}>Sim</Button>
              </Actions>
            </Container>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default PurchaseOrderPopper;
