import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import TransferAccountItemTable from './TransferAccountItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { usePagination } from 'hooks/pagination';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';
import { TransferAccount } from 'types/transferAccount';
import { accountsTableTemplate } from '../../accountsTableTemplate';
import { useTransferAccounts } from '../../hooks/useTransferAccount';
import TransferAccountListMenu from '../TransferAccountListMenu';

const HeaderItem = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: 10,
    fontSize: 20,
  },
});

interface TransferAccountListTableProps {
  accounts: TransferAccount[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const TransferAccountListTable: React.FC<TransferAccountListTableProps> = ({ accounts, handleSort, orderedIndex }) => {
  const { rowsPerPage, page } = usePagination();
  const { setSelectedTransferAccount } = useTransferAccounts();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <TransferAccountListMenu
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
      />
      <TableContainer tableTemplate={accountsTableTemplate}>
        <TableContent>
          <TableHeader>
            {accountsTableTemplate.map(item => (
              <HeaderItem key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>

                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </HeaderItem>
            ))}
          </TableHeader>
          <TableBody useMaxHeight={false}>
            {accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(account => (
              <TableRow onClick={() => setSelectedTransferAccount(account)} key={account.id}>
                <TransferAccountItemTable setSelectedId={setSelectedId} setAnchorEl={setAnchorEl} account={account} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default TransferAccountListTable;
