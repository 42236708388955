import React from 'react';
import { AbcProductUnity } from 'types/abcProduct';
import { Typography, ListItem, styled } from '@mui/material';

const ListItemStyled = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #f5f5f5',
  backgroundColor: '#fff',
  minHeight: 100,
  alignItems: 'flex-start',
  '& > .content': {
    margin: '10px 0',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '90px 1fr',
    alignItems: 'center',
  },
});

type UnityItemModuleProps = {
  unity: AbcProductUnity;
};

const UnityItemModule: React.FC<UnityItemModuleProps> = ({ unity }) => {
  return (
    <ListItemStyled>
      <Typography align="left" variant="body1">
        {unity.unidade}
      </Typography>

      <div className="content">
        <Typography variant="body2" color="textSecondary">
          Quantidade
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.qtd}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Unitário
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedUnitary}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Vl Total
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedTotal}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Spiff
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedSpiff}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          MC
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedMc}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Custo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {unity.formattedCoust}
        </Typography>
      </div>
    </ListItemStyled>
  );
};

export default UnityItemModule;
