import React, { Dispatch, SetStateAction } from 'react';
import TableRow from 'components/table/TableRow';
import { usePagination } from 'hooks/pagination';
import { OrderIndexData } from 'hooks/tableOrder';
import TableBody from 'components/table/TableBody';
import { StoreProduct } from '../../SelectedBranch';
import TableHeader from 'components/table/TableHeader';
import TableContent from 'components/table/TableContent';
import TableContainer from 'components/table/TableContainer';
import SelectedBranchItemTable from './SelectedBranchItemTable';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import { selectedBranchTableTemplate } from '../../selectedBranchTableTemplate';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#eee',
  },
  tableRow: {
    '& > div:nth-child(even)': {
      backgroundColor: '#eee',
    },
  },
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
  },
});

type SelectedBranchListTableProps = {
  products: StoreProduct[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
  setProduct: Dispatch<SetStateAction<StoreProduct | null>>;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const SelectedBranchListTable: React.FC<SelectedBranchListTableProps> = ({
  products,
  handleSort,
  setProduct,
  orderedIndex,
  setAnchorEl,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container style={{ overflow: 'auto' }}>
      <Grid item xs={12}>
        <TableContainer tableTemplate={selectedBranchTableTemplate}>
          <TableContent>
            <TableHeader className={classes.header}>
              {selectedBranchTableTemplate.map(item => (
                <div
                  className={
                    item.dataType === 'number'
                      ? `${classes.headerItem} ${classes.headerItemNumeric}`
                      : classes.headerItem
                  }
                  key={item.id}
                  onClick={() => handleSort(item.originalId)}
                >
                  <Typography style={{ fontWeight: 'bold' }} variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            <TableBody useMaxHeight maxHeight={500}>
              <div className={classes.tableRow}>
                {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                  <TableRow key={index}>
                    <SelectedBranchItemTable setProduct={setProduct} setAnchorEl={setAnchorEl} product={product} />
                  </TableRow>
                ))}
              </div>
            </TableBody>
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SelectedBranchListTable;
