import React, { useState } from 'react';
import { styled } from '@mui/material';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';
import InsideLoading from 'components/loading/InsideLoading';
import TopPayments from '../flow/TopPayments';
import TopOverduePayments from '../flow/TopOverduePayments';
import TopPaymentsDetails from './TopPaymentsDetails';
import PurchaseOrderFlowTabs from './PurchaseOrderFlowTabs';

const Container = styled('div')(({ theme }) => ({
  padding: 10,
  gap: 10,
  backgroundColor: '#fff',
  display: 'grid',
  gridTemplateColumns: '0.4fr 0.6fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const PurchaseOrderFlow: React.FC = () => {
  const { payments, overduePayments, detailsPayments, loading } = usePurchaseOrder();
  const [tab, setTab] = useState('main');
  return (
    <Container>
      {loading.paymentFlow ? (
        <div style={{ gridColumn: '1/3' }}>
          <InsideLoading />
        </div>
      ) : (
        <>
          <PurchaseOrderFlowTabs handleChange={tab => setTab(tab)} value={tab} />
          {tab === 'details' ? (
            <TopPaymentsDetails data={detailsPayments} />
          ) : (
            <>
              <TopPayments data={payments} />
              <TopOverduePayments data={overduePayments} />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default PurchaseOrderFlow;
