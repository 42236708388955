import React, { MouseEvent } from 'react';
import { Icon, Tab, Tabs, styled } from '@mui/material';
import { FaFileExcel } from 'react-icons/fa';
import { usePermissionRules } from 'hooks/permissionRules';
import { api } from 'services/api';
import { uuidv4 } from 'helpers/uuidv4';
import { download } from 'helpers/download';
import { usePurchaseOrder } from '../../hooks/PurchaseOrderProvider';

interface PurchaseOrderFlowTabsProps {
  value: string;
  handleChange(value: string): void;
}

const CustomIcon = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#ddd',
    borderRadius: 50,
  },
});

const CustomTabs = styled(Tabs)({
  backgroundColor: '#fff',
  gridColumn: '1/3',
});

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 30,
  '&.Mui-selected': {
    '& svg': {
      color: theme.palette.success.main,
    },
  },
}));

const PurchaseOrderFlowTabs: React.FC<PurchaseOrderFlowTabsProps> = ({ handleChange, value }) => {
  const { checkPermission } = usePermissionRules();
  const { detailsPayments } = usePurchaseOrder();

  function handleClick(event: MouseEvent<HTMLSpanElement>) {
    event.stopPropagation();

    handleDownload('pagamentos-vencimento', detailsPayments);
  }

  async function handleDownload(title: string, data: any[]) {
    try {
      const response = await api.post('/excel-exports', data, { responseType: 'blob' });

      download(response.data, `${title}-${uuidv4()}`);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <CustomTabs value={value} onChange={(e, value) => handleChange(value)} variant="scrollable">
      <CustomTab value="main" label="Geral" />
      <CustomTab
        value="details"
        label="Detalhamento"
        icon={
          checkPermission('general.excel_export_button') ? (
            <CustomIcon onClick={e => handleClick(e)}>
              <FaFileExcel style={{ height: 18, width: 18 }} />
            </CustomIcon>
          ) : (
            <></>
          )
        }
        iconPosition="end"
      />
    </CustomTabs>
  );
};

export default PurchaseOrderFlowTabs;
