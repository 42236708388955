import { FormEvent, useCallback, useMemo, useState } from 'react';
import { subDays, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { api } from 'services/api';
import { Curriculum } from 'types/curriculum';
import { Paginated } from 'types/paginated';

export interface ResumeQueryParams {
  term: string;
  department: string;
  city: string;
  initial_date: Date;
  final_date: Date;
  rows: number;
  page: number;
}

const queryParamsInitialValue: ResumeQueryParams = {
  term: '',
  city: '',
  final_date: new Date(),
  initial_date: subDays(new Date(), 30),
  department: 'all',
  page: 0,
  rows: 20,
};

export function useFetchResumes() {
  const [queryParams, setQueryParams] = useState<ResumeQueryParams>(queryParamsInitialValue);
  const [loading, setLoading] = useState(false);
  const [resumes, setResumes] = useState<Curriculum[]>([]);
  const [total, setTotal] = useState(0);

  const education_level = useMemo(() => {
    return {
      fundamental: 'Ensino Fundamental',
      complete_high_school: 'Ensino Médio Completo',
      incomplete_high_school: 'Ensino Médio Incompleto',
      complete_higher_education: 'Ensino Superior Completo',
      incomplete_higher_education: 'Ensino Superior Incompleto',
      technical_course: 'Curso Técnico',
    };
  }, []);

  const departments = useMemo(() => {
    return {
      administrative: 'Administrativo',
      box: 'Caixa',
      shopping: 'Compras',
      commercial_sales: 'Comercial / Vendas',
      accounting_tax: 'Contábil / Fiscal',
      stock_shipping: 'Estoque / Expedição',
      financial: 'Financeiro',
      human_resources: 'Recursos Humanos',
      it: 'Informática / TI ',
      logistics: 'Logística',
      driver: 'Motorista',
      marketing: 'Marketing',
      personal_department: 'Departamento Pessoal',
      general_services: 'Serviços Gerais',
    };
  }, []);

  const fetch = useCallback(
    (queryParams: ResumeQueryParams, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      setLoading(true);

      api
        .get<Paginated<Curriculum[]>>('/curriculums', {
          params: {
            ...queryParams,
            page: queryParams.page + 1,
            department: queryParams.department === 'all' ? '' : queryParams.department,
          },
        })
        .then(response => {
          setTotal(response.data.total);
          setResumes(
            response.data.items.map(item => ({
              ...item,
              department: departments[item.department],
              education_level: education_level[item.education_level],
              formattedCreatedAt: formatDistanceToNow(new Date(item.created_at), { addSuffix: true, locale: ptBR }),
            })),
          );
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [departments, education_level],
  );

  function handleChangeQueryParams(index: keyof ResumeQueryParams, value: any) {
    const query = {
      ...queryParams,
      [index]: value,
    };

    setQueryParams(queryParams => ({
      ...queryParams,
      [index]: value,
    }));

    if (index === 'page' || index === 'rows') {
      fetch(query);
    }
  }

  return {
    fetch,
    loading,
    queryParams,
    handleChangeQueryParams,
    resumes,
    setLoading,
    total,
  };
}
