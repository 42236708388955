import React, { useEffect, useState } from 'react';
import { darken, lighten, styled, Typography } from '@mui/material';
import useTableOrder from 'hooks/tableOrder';
import { Reserve } from 'types/reserve';
import NoData from 'components/no-data/NoData';

interface Props {
  reserves: Reserve[];
}

const Container = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  position: 'relative',
  zoom: '90%',
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  padding: 10,
  background: '#fafafa',
  '& > p': {
    color: darken('#e3e3e3', 0.5),
    fontWeight: 600,
  },
});

const Content = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Row = styled('li')(({ theme }) => ({
  padding: '2px 10px',
  '&.header': {
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    right: 0,
    left: 0,
    '& > span': {
      cursor: 'pointer',
    },
  },
  '&.item': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  display: 'grid',
  columnGap: 10,
  gridTemplateColumns: '120px 120px 70px 1fr 60px 1fr 60px 50px 100px 70px',
  borderBottom: '1px solid #eee',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '110px 90px 70px 1fr 50px 1fr 60px 50px 100px 70px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '110px 90px 70px 1fr 50px 1fr 60px 50px 100px 70px',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const List = styled('ul')(({ theme }) => ({
  display: 'grid',
  '& > li:nth-child(even)': {
    background: lighten(theme.palette.primary.light, 0.9),
    color: darken(theme.palette.primary.light, 0.5),
  },
}));

const CommercialAnalysisProductReserve: React.FC<Props> = ({ reserves }) => {
  const [filtered, setFiltered] = useState<Reserve[]>([]);
  const [, sort] = useTableOrder();

  useEffect(() => {
    setFiltered(reserves);
  }, [reserves]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <Container>
      <Title>
        <Typography fontSize={13}>Reservas</Typography>
      </Title>
      <Content>
        <List>
          <Row className="header">
            <Typography onClick={() => handleSort('filial')} variant="caption" fontWeight={600}>
              Filial
            </Typography>

            <Typography onClick={() => handleSort('formattedDate')} variant="caption" fontWeight={600}>
              Data
            </Typography>

            <Typography onClick={() => handleSort('id_reserva')} variant="caption" fontWeight={600}>
              Reserva
            </Typography>

            <Typography onClick={() => handleSort('cliente')} variant="caption" fontWeight={600}>
              Cliente
            </Typography>

            <Typography onClick={() => handleSort('id_produto')} variant="caption" fontWeight={600}>
              Cod Prod
            </Typography>

            <Typography onClick={() => handleSort('produto')} variant="caption" fontWeight={600}>
              Produto
            </Typography>

            <Typography align="center" onClick={() => handleSort('estoque')} variant="caption" fontWeight={600}>
              Estoque
            </Typography>

            <Typography align="center" onClick={() => handleSort('qtd')} variant="caption" fontWeight={600}>
              Qtd
            </Typography>

            <Typography align="right" onClick={() => handleSort('valor_unitario')} variant="caption" fontWeight={600}>
              Valor unitário
            </Typography>

            <Typography align="right" onClick={() => handleSort('valor_total')} variant="caption" fontWeight={600}>
              Valor total
            </Typography>
          </Row>

          {filtered.length === 0 ? (
            <NoData message="Não há produtos na reserva" />
          ) : (
            filtered.map((item, index) => (
              <Row className="item" key={index}>
                <Typography variant="caption">{item.filial}</Typography>
                <Typography variant="caption">{item.data}</Typography>
                <Typography variant="caption">{item.id_reserva}</Typography>
                <Typography variant="caption">{item.cliente}</Typography>
                <Typography variant="caption">{item.id_produto}</Typography>
                <Typography variant="caption">{item.produto}</Typography>
                <Typography variant="caption" align="center">
                  {item.estoque}
                </Typography>
                <Typography variant="caption" align="center">
                  {item.qtd}
                </Typography>
                <Typography variant="caption" align="center">
                  {item.formattedUnitValue}
                </Typography>
                <Typography variant="caption" align="center">
                  {item.formattedTotalValue}
                </Typography>
              </Row>
            ))
          )}
        </List>
      </Content>
    </Container>
  );
};

export default CommercialAnalysisProductReserve;
