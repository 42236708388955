import { TableTemplate } from 'types/tableTemplate';

export const segmentTableTemplate: TableTemplate[] = [
  {
    id: 'more',
    description: '',
    originalId: 'more',
    width: 40,
  },
  {
    id: 'segmento',
    description: 'Segmento',
    originalId: 'segmento',
    width: 250,
  },
  {
    id: 'formattedCustoTotal',
    description: 'Custo Total',
    originalId: 'CustoTotal',
    width: 120,
    dataType: 'number',
  },
  {
    id: 'Estoque',
    description: 'Estoque',
    originalId: 'Estoque',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'Reserva',
    description: 'Reserva',
    originalId: 'Reserva',
    width: 80,
    dataType: 'quantity',
  },
  {
    id: 'EstTotal',
    description: 'Est Total',
    originalId: 'EstTotal',
    width: 80,
    dataType: 'quantity',
  },
];
