import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import Pagination from 'components/pagination/Pagination';
import { Rma } from 'types/rma';
import PageHeader from 'components/page-header/PageHeader';
import RmaTabs from './tab/RmaTabs';
import RmaFilterBox from './RmaFilterBox';
import { useFetchRma } from './hooks/useFetchRma';
import RmaDashboard from './dashboard/RmaDashboard';
import RmaListTable from './list/table/RmaListTable';
import RmaListModule from './list/module/RmaListModule';
import RmaAnalysisProduct from './product/RmaAnalysisProduct';

export interface ItemsSoldFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const RmaAnalysisPage: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const [tab, setTab] = useState<string>('dashboard');
  const { loading, items, filter, buyers, loadingBuyers, handleChangeFilter, onSearch } = useFetchRma();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<Rma[]>([]);

  useEffect(() => {
    setFiltered(items);
  }, [items]);

  function handleSort(index: string) {
    const filtered = sort(index, items);
    setFiltered(filtered);
  }

  return (
    <>
      <Appbar title="RMA" />
      <PageHeader title="RMA" />

      <RmaFilterBox
        filter={filter}
        loadingBuyers={loadingBuyers}
        buyers={buyers}
        handleChangeFilter={handleChangeFilter}
        loading={loading}
        onSearch={onSearch}
      />

      <RmaTabs tab={tab} handleChange={value => setTab(value)} />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : tab === 'items' ? (
        filtered.length === 0 ? (
          <NoData message="Nenhum item para mostrar" />
        ) : (
          <PaginationProvider>
            <div>
              {displayMode === 'list' ? (
                <RmaListTable items={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <RmaListModule items={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )
      ) : tab === 'dashboard' ? (
        <RmaDashboard items={items} />
      ) : (
        <RmaAnalysisProduct items={items} />
      )}
    </>
  );
};

export default RmaAnalysisPage;
