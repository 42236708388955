import { Close, Done } from '@mui/icons-material';
import { IconButton, TextField, Tooltip, Typography, styled } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import React, { useEffect, useState } from 'react';
import PurchaseOrderPopper from './PurchaseOrderPopper';
import { PurchaseOrderProductWithEan } from './PurchaseOrderListProducts';

interface PurchaseOrderSelectProductProps {
  selectedProduct: PurchaseOrderProductWithEan;
  onExited(): void;
  handleChange(productId: number, eans: string[]): void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 30,
}));

const Submit = styled('div')({
  gap: 5,
  display: 'grid',
  gridTemplateColumns: '1fr 40px',
  position: 'relative',
});

const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
});

const Header = styled('div')({
  gap: 5,
  margin: -15,
  padding: 5,
  display: 'grid',
  alignItems: 'center',
  backgroundColor: '#ddd',
  gridTemplateColumns: '40px 1fr 40px',
});

const PurchaseOrderSelectProduct: React.FC<PurchaseOrderSelectProductProps> = ({
  onExited,
  selectedProduct,
  handleChange,
}) => {
  const [ean, setEan] = useState('');
  const [eans, setEans] = useState<string[]>([]);
  const [existsEan, setExistsEan] = useState(false);
  const [differentEan, setDifferentEan] = useState(false);

  useEffect(() => {
    setEans(selectedProduct.eans);
  }, [selectedProduct.eans]);

  async function onSubmit() {
    if (!ean) {
      return;
    }

    const _eans = await [...eans, ean];
    setEans(_eans);

    setEan('');

    handleChange(selectedProduct.codigo_produto, _eans);
  }

  function handleValidation() {
    const _existsEan = eans.find(item => item === ean);
    const _differentEan = selectedProduct.codigo_barra !== ean;

    if (_differentEan) {
      setDifferentEan(true);
      return;
    }

    if (_existsEan) {
      setExistsEan(true);
      return;
    }

    onSubmit();
  }

  function handleDeny() {
    setExistsEan(false);
    setDifferentEan(false);
    setEan('');
  }

  function handleAccept() {
    setExistsEan(false);
    setDifferentEan(false);

    onSubmit();
  }

  return (
    <Dialog contentStyle={{ marginTop: 0 }} height="40vh" onExited={onExited} maxWidth="sm">
      <Container>
        <Header>
          <Tooltip title="Fechar">
            <IconButton onClick={onExited}>
              <Close />
            </IconButton>
          </Tooltip>

          <Typography variant="subtitle2" fontWeight={600} fontSize={13}>
            {selectedProduct.produto}
          </Typography>
        </Header>

        <Submit>
          <TextField type="number" inputMode="decimal" value={ean} onChange={e => setEan(e.target.value)} label="EAN" />
          <Tooltip title="Adicionar">
            <IconButton onClick={handleValidation}>
              <Done />
            </IconButton>
          </Tooltip>

          <PurchaseOrderPopper
            title="Deseja adicionar esse EAN?"
            subtitle="EAN informado diferente do físico"
            handleDeny={handleDeny}
            handleAccept={handleAccept}
            open={differentEan}
          />

          <PurchaseOrderPopper
            title="Deseja adicionar novamente esse EAN?"
            subtitle="EAN já adicionado"
            handleDeny={handleDeny}
            handleAccept={handleAccept}
            open={existsEan}
          />
        </Submit>

        <List>
          <Typography variant="subtitle2" fontWeight={600} fontSize={13}>
            Lista de EAN
          </Typography>

          {eans.length > 0 ? (
            eans.map((item, index) => (
              <Typography variant="subtitle2" key={index} fontSize={12}>
                {item}
              </Typography>
            ))
          ) : (
            <Typography align="center" variant="caption">
              {':('} Nenhuma EAN adicionada
            </Typography>
          )}
        </List>
      </Container>
    </Dialog>
  );
};

export default PurchaseOrderSelectProduct;
