import { moneyFormat } from 'helpers/numberFormat';
import { PriceProtection } from 'types/priceProtection';

export interface AgroupedManufacturerPriceProtection {
  fabricante: string;
  valor: number;
  formattedTotal: string;
}

export interface AgroupedProductPriceProtection {
  produto: string;
  valor: number;
  id_produto: number;
  formattedTotal: string;
}

export function useFetchPriceProtectionDashboard(datas: PriceProtection[]) {
  function groupByManufacturer(data: PriceProtection[]): AgroupedManufacturerPriceProtection[] {
    const manufacturerMap = new Map();

    data.forEach(item => {
      if (!manufacturerMap.has(item.fabricante)) {
        manufacturerMap.set(item.fabricante, { fabricante: item.fabricante, valor: 0 });
      }
      const entry: AgroupedManufacturerPriceProtection = manufacturerMap.get(item.fabricante);
      entry.valor += item.valor;
      entry.formattedTotal = moneyFormat(entry.valor);
    });

    return Array.from(manufacturerMap.values());
  }

  function groupByProduct(data: PriceProtection[]): AgroupedProductPriceProtection[] {
    const productMap = new Map();

    data.forEach(item => {
      if (!productMap.has(item.produto)) {
        productMap.set(item.produto, { produto: item.produto, valor: 0 });
      }
      const entry: AgroupedProductPriceProtection = productMap.get(item.produto);
      entry.id_produto = item.id_produto;
      entry.valor += item.valor;
      entry.formattedTotal = moneyFormat(entry.valor);
    });

    return Array.from(productMap.values());
  }

  const groupedManufacturers = groupByManufacturer(datas).sort((a, b) => b.valor - a.valor);
  const groupedProducts = groupByProduct(datas).sort((a, b) => b.valor - a.valor);

  return {
    groupedManufacturers,
    groupedProducts,
  };
}
