import React, { useState } from 'react';
import List from 'components/list/List';
import { NoteEntry } from 'types/noteEntry';
import NoteEntryItemModule from './NoteEntryItemModule';
import NoteEntryListItems from '../../dialog/NoteEntryListItems';

type NoteEntryListModuleProps = {
  notes: NoteEntry[];
};

const NoteEntryListModule: React.FC<NoteEntryListModuleProps> = ({ notes }) => {
  const [selectedNote, setSelectedNote] = useState<NoteEntry | null>(null);

  function handleClick(note: NoteEntry) {
    setSelectedNote(note);
  }

  function onExited() {
    setSelectedNote(null);
  }

  return (
    <>
      <List>
        {selectedNote && <NoteEntryListItems onExited={onExited} selectedNote={selectedNote} />}
        {notes.map((note, index) => (
          <NoteEntryItemModule handleClick={handleClick} key={index} note={note} />
        ))}
      </List>
    </>
  );
};

export default NoteEntryListModule;
