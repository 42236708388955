import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import { useMessaging } from 'hooks/messaging';
import { CommercialFilterProps } from '../CommercialDashboard';
import { CommercialDashboard, CommercialDashboardTotal } from 'types/commercialDashboard';

export function useFetchCommercialDashboard(filter: CommercialFilterProps) {
  const { h2iApi } = useApp();
  const { handleOpen } = useMessaging();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<CommercialDashboard[]>([]);

  const fetch = useCallback(() => {
    if (!h2iApi) {
      return;
    }
    const formattedInitialDate = format(filter.initial_date, "dd'/'MM'/'yyyy");
    const formattedFinalDate = format(filter.final_date, "dd'/'MM'/'yyyy");

    setLoading(true);
    h2iApi
      .get('/api/getorcamentos', {
        params: {
          data_ini: formattedInitialDate,
          data_fim: formattedFinalDate,
          id_filial: filter.branch_id || '',
        },
      })
      .then(response => {
        if (response.data.MESSAGE) {
          setOrders([]);

          return;
        }
        setOrders(
          response.data.map(order => ({
            ...order,
            formattedTotal: moneyFormat(order.valor_total),
          })),
        );
      })
      .catch(() => {
        setOrders([]);
        handleOpen('Aconteceu um erro na pesquisa');
      })
      .finally(() => setLoading(false));
  }, [h2iApi, handleOpen, filter]);

  const groupOrdersByPedidoPor = useCallback((): CommercialDashboardTotal[] => {
    const groupedOrders: { [key: string]: CommercialDashboardTotal } = {};

    orders.forEach(sale => {
      if (!groupedOrders[sale.Pedido_por]) {
        groupedOrders[sale.Pedido_por] = {
          Pedido_por: sale.Pedido_por,
          formattedPercentual: '',
          percentual: 0,
          valor_total: 0,
          qtd: 0,
          formattedTotal: '',
        };
      }

      groupedOrders[sale.Pedido_por].qtd += 1;
      groupedOrders[sale.Pedido_por].valor_total += sale.valor_total;
      groupedOrders[sale.Pedido_por].percentual = (groupedOrders[sale.Pedido_por].qtd / orders.length) * 100;
      groupedOrders[sale.Pedido_por].formattedTotal = moneyFormat(groupedOrders[sale.Pedido_por].valor_total);
      groupedOrders[sale.Pedido_por].formattedPercentual = percentFormat(groupedOrders[sale.Pedido_por].percentual);
    });

    return Object.values(groupedOrders);
  }, [orders]);

  return {
    loading,
    orders,
    fetch,
    setOrders,
    groupOrdersByPedidoPor,
  };
}
