import React, { useState, useEffect } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { ExpensesProvider } from './hooks/useExpenses';
import PageHeaderButtons from './PageHeaderButtons';
import { Expense } from 'types/expense';
import { useFetchExpenses } from './hooks/useFetchExpenses';
import useTableOrder from 'hooks/tableOrder';
import { useSelector } from 'store/selector';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { styled } from '@mui/material';
import { expensesTableTemplate } from './expensesTableTemplate';
import Pagination from 'components/pagination/Pagination';
import ExpensesFilterBox from './ExpensesFilterBox';
import ExpensesReport from './report/ExpensesReport';
import NewExpense from './registration/new/NewExpense';
import ExpenseListTable from './list/table/ExpenseListTable';
import ExpenseListModule from './list/module/ExpenseListModule';
import { useApp } from 'hooks/app';
import EditExpense from './registration/edit/EditExpense';
import { useFinancial } from '../hooks/useFinancial';
import ExpensesActions from './ExpensesActions';

export interface ExpensesFilterProps {
  description: string;
  branchId: number;
}

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const filterInitialState = {
  description: '',
  branchId: 1001,
};

const Expenses: React.FC = () => {
  const { handleBack } = useFinancial();
  const user = useSelector(state => state.user);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const { isMobile, windowWidth } = useApp();
  const [filtered, setFiltered] = useState<Expense[]>([]);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [orderedIndex, sort] = useTableOrder();
  const [filter, setFilter] = useState<ExpensesFilterProps>(filterInitialState);
  const [isNewExpenseOpen, setIsNewExpenseOpen] = useState(false);
  const [expenses, loading, fetch] = useFetchExpenses(filter);
  const [isReportOpen, setIsReportOpen] = useState(false);

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFiltered(expenses);
  }, [expenses]);

  useEffect(() => {
    const branchId =
      user && user.branchList.length ? parseInt(user.branchList.length === 10 ? '0' : user.branchList[0]) : 999;

    setFilter(state => ({
      ...state,
      branch_id: branchId,
    }));
  }, [user]);

  function handleChangeFilter(index: keyof ExpensesFilterProps, value: any) {
    setFilter(filter => ({
      ...filter,
      [index]: value,
    }));
  }

  function handleSearch() {
    fetch();
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function onSubmit() {
    setIsNewExpenseOpen(false);
    setSelectedExpense(null);
    fetch();
  }

  return (
    <ExpensesProvider
      value={{
        expenses,
        loading,
        setIsNewExpenseOpen,
        setIsReportOpen,
        handleChangeFilter,
        handleSearch,
        setSelectedExpense,
        filter,
      }}
    >
      {selectedExpense && (
        <EditExpense
          selectedExpense={selectedExpense}
          setSelectedExpense={setSelectedExpense}
          onExited={() => setSelectedExpense(null)}
          onSubmit={onSubmit}
        />
      )}

      {isReportOpen && <ExpensesReport onExited={() => setIsReportOpen(false)} />}

      {isNewExpenseOpen && <NewExpense onSubmit={onSubmit} onExited={() => setIsNewExpenseOpen(false)} />}

      <Appbar title="Classificação de despesas" backAction={handleBack} ActionsComponent={<ExpensesActions />} />

      <PageHeaderActions title="Classificação de despesas" ActionComponent={<PageHeaderButtons />} />

      {windowWidth < 600 && <PageHeaderButtons />}

      <ExpensesFilterBox />

      <TableContainer tableTemplate={expensesTableTemplate}>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma despesa encontrada" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ExpenseListTable expenses={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ExpenseListModule expenses={filtered} />
              )}
              <Pagination count={filtered.length} />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </ExpensesProvider>
  );
};

export default Expenses;
