import { TableTemplate } from 'types/tableTemplate';

export const vacancyTableTemplate: TableTemplate[] = [
  {
    id: 'action',
    description: '',
    originalId: 'action',
    width: 40,
  },
  {
    id: 'formattedCreatedAt',
    description: 'Publicada em',
    originalId: 'created_at',
    width: 130,
  },
  {
    id: 'role',
    description: 'Título',
    originalId: 'role',
    width: 200,
  },
  {
    id: 'local',
    description: 'Local',
    originalId: 'local',
    width: 120,
  },
  {
    id: 'formattedSalary',
    description: 'Salário',
    originalId: 'salary',
    width: 150,
  },
  {
    id: 'benefits',
    description: 'Benefícios',
    originalId: 'benefits',
    width: 130,
  },
  {
    id: 'work_experience',
    description: 'Experiência',
    originalId: 'work_experience',
    width: 100,
  },
  {
    id: 'work_model',
    description: 'Modelo',
    originalId: 'work_model',
    width: 100,
  },
  {
    id: 'work_hours',
    description: 'Horas de trabalho',
    originalId: 'work_hours',
    width: 120,
  },
  {
    id: 'observation',
    description: '+ Informações',
    originalId: 'observation',
    width: 300,
  },
  {
    id: 'username',
    description: 'Publicada por',
    originalId: 'username',
    width: 150,
  },
];
