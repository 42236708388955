import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';

interface StyleProps {
  templateColumns: string;
  smtemplatecolumns?: string;
  width?: number;
}

const TableWrapper = styled('div')({
  position: 'relative',
});

const Header = styled('div')<StyleProps>(({ templateColumns, width, smtemplatecolumns, theme }) => ({
  display: 'grid',
  gridTemplateColumns: templateColumns,
  borderBottom: '1px solid #ddd',
  flexShrink: 0,
  width: width ? `${width}px` : '100%',
  backgroundColor: '#fff',
  position: 'sticky',
  top: 0,
  zIndex: 31,
  '&.headerSubgroup': {
    backgroundColor: '#d9f8e5',
  },
  '& > div': {
    '&.numericData': {
      justifyContent: 'flex-end',
    },
    '&.quantity, &.quantityData': {
      justifyContent: 'center',
    },
    padding: '5px 10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: smtemplatecolumns || '',
  },
  '& div:not(:last-child)': {
    borderRight: '1px solid #ddd',
  },
}));

interface TableHeaderProps {
  className?: string;
  children: ReactNode;
  style?: React.CSSProperties;
  smtemplatecolumns?: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ children, className, style, smtemplatecolumns = '' }) => {
  const { width, templateColumns } = useTable();

  return (
    <TableWrapper>
      <Header
        width={width}
        templateColumns={templateColumns}
        style={style}
        smtemplatecolumns={smtemplatecolumns}
        className={className}
      >
        {children}
      </Header>
    </TableWrapper>
  );
};

export default TableHeader;
